import { FC, Fragment, ReactNode, useMemo } from 'react'

import Tokens from 'config/tokens'
import { isNotNilOrEmpty } from 'fp/isNilOrEmpty'
import { useTranslation } from 'react-i18next'

import makeStyles from '@mui/styles/makeStyles'

type KeyPrimitive = string | number | symbol
type Items = Record<KeyPrimitive, ReactNode> | Array<[KeyPrimitive, ReactNode]> | Map<KeyPrimitive, ReactNode>

type DescriptionListProps = {
  items: Items
  dense?: boolean
}

const useStyles = makeStyles(({ spacing, breakpoints }) => ({
  dl: {
    [breakpoints.up('sm')]: {
      display: 'grid',
      gridTemplate: 'auto / 40% 60%',

      '& > dt': {
        gridColumn: 1,
      },
      '& > dd': {
        gridColumn: 2,
      },
    },

    '& > dt': {
      color: Tokens.color.neutral.grey[102],
    },
    '& > dd': {
      paddingLeft: Tokens.rhythm,
    },
    '& > dd, dt': {
      overflowWrap: 'break-word',
      margin: 0,
      paddingTop: ({ dense }: Pick<DescriptionListProps, 'dense'>) => spacing(dense ? 1 : 4),
      paddingBottom: ({ dense }: Pick<DescriptionListProps, 'dense'>) => spacing(dense ? 1 : 4),
    },
  },
}))

const DescriptionList: FC<DescriptionListProps> = ({ items, dense = false }) => {
  const { t } = useTranslation()
  const classes = useStyles({ dense })
  // convert items into an array of tuples
  // TS really does not infer from these typeguards correctly, so I'm casting it for now
  const elements = useMemo(() => {
    // TS doesn't like me placing this below the array check, no idea why
    if (items instanceof Map) {
      return [...items.entries()]
    }
    if (Array.isArray(items)) {
      return items as unknown
    }
    // must be a plain object
    return [...Object.entries(items)]
  }, [items]) as Array<[string | number, ReactNode]>

  if (!elements.length) {
    return null
  }

  return (
    <dl className={classes.dl}>
      {elements.map(([term, details]) => (
        <Fragment key={term}>
          <dt>{term}</dt>
          <dd>{isNotNilOrEmpty(details) ? details : t('common:emptyValue')}</dd>
        </Fragment>
      ))}
    </dl>
  )
}

export default DescriptionList
