import Tokens from 'config/tokens'

import MuiAccordionDetails from '@mui/material/AccordionDetails'
import withStyles from '@mui/styles/withStyles'

const AccordionDetails = withStyles((theme) => ({
  root: {
    padding: theme.spacing(0),
    backgroundColor: Tokens.color.neutral.grey[250],
  },
}))(MuiAccordionDetails)

export default AccordionDetails
