import { FC, useState, useMemo, useCallback, cloneElement, ReactElement } from 'react'

import Tokens from 'config/tokens'
import { useSearchPatientsLazyQuery, Patient } from 'generated/graphql'
import debounce from 'lodash/debounce'
import { selectPatients } from 'modules/Patient/selectors'
import { formatE164ToUSPhoneNumber } from 'utils/phone/format'

import { TextField, CircularProgress, Grid, Typography, Hidden } from '@mui/material'
import Autocomplete from '@mui/material/Autocomplete'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'

import Address from 'ui/TextFormatters/Address'
import Age from 'ui/TextFormatters/Age'
import Sex from 'ui/TextFormatters/Sex/Sex'

const identity = <T,>(x: T): T => x
interface PatientsAutocompleteProps {
  label?: string
  onPatientSelected?: (selectedPatient: Partial<Patient> | null) => Promise<void | undefined> | void | undefined
  existingPatientIds?: Array<number> | null
}

export type PatientSearchResult = Partial<Patient> | null

const useStyles = makeStyles(() =>
  createStyles({
    option: {
      color: Tokens.color.ui.charcoal.base,
      borderBottom: `1px solid ${Tokens.color.neutral.grey[250]}`,
      '& :last-child': {
        borderBottom: 'none',
      },
    },
    light: {
      color: Tokens.color.ui.charcoal.transparent[50],
    },
  }),
)

const PatientAutocompleteOption: FC<{ option: Partial<Patient> }> = ({ option }) => {
  const classes = useStyles()

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6} md={3}>
        <Typography color="primary" noWrap>
          {`${option.firstName} `}
          {option.preferredName && <q>{option.preferredName}</q>}
          {` ${option.lastName}`}
        </Typography>
        <Hidden mdDown>
          <Typography className={classes.light}>
            <Sex sex={option.sex} />
          </Typography>
        </Hidden>
      </Grid>
      <Grid item xs={5} sm={6} md={2}>
        {option.dob ?? ''}
        <Hidden mdDown>
          <br />
          <Typography className={classes.light}>
            <Age dob={option.dob ?? ''} /> years
          </Typography>
        </Hidden>
      </Grid>
      <Grid item xs={7} sm={6} md={2}>
        {option.preferredPhoneNumber ? formatE164ToUSPhoneNumber(option.preferredPhoneNumber) : null}
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <Address
          address1={option.address1 ?? ''}
          address2={option.address2 ?? ''}
          city={option.city ?? ''}
          state={option.state ?? ''}
          zip={option.zip ?? ''}
          splitLine={false}
        />
      </Grid>
    </Grid>
  )
}

const PatientsAutocomplete = ({
  onPatientSelected,
  label,
  existingPatientIds,
}: PatientsAutocompleteProps): JSX.Element => {
  const classes = useStyles()
  const [getData, { loading, data }] = useSearchPatientsLazyQuery({ fetchPolicy: 'network-only' })
  const [inputValue, setInputValue] = useState('')

  const searchPatients = useCallback(
    debounce((event, searchString: string) => {
      getData({ variables: { name: searchString } })
    }, 300),
    [getData],
  )

  const patientOptions = useMemo(() => (!(inputValue.length > 1) ? [] : selectPatients(data) ?? []), [data, inputValue])

  // const renderSearchResult =
  //   (props, option: Partial<Patient>) => <PatientAutocompleteOption option={option} key={option.patientId} />;

  return (
    <Autocomplete
      onChange={(event, patient) => {
        if (patient && onPatientSelected && typeof patient !== 'string') onPatientSelected(patient)

        setInputValue('')
      }}
      onInputChange={searchPatients}
      inputValue={inputValue}
      classes={{
        option: classes.option,
      }}
      autoHighlight
      freeSolo
      loading={loading}
      options={patientOptions}
      getOptionLabel={(patient) => `${patient.firstName} ${patient.lastName}`}
      renderOption={(props, option: Partial<Patient>) => (
        <li {...props} key={option.patientId}>
          <PatientAutocompleteOption option={option} key={option.patientId} />
        </li>
      )}
      filterOptions={
        !existingPatientIds
          ? identity
          : (options) => options.filter(({ patientId }) => patientId && !existingPatientIds.includes(patientId))
      }
      value={null}
      renderInput={(params) => (
        <TextField
          sx={{ marginTop: 0 }}
          {...params}
          onChange={(event) => {
            setInputValue(event.target.value)
          }}
          label={label ?? 'Search for a patient'}
          variant="outlined"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading && <CircularProgress color="inherit" size={20} sx={{ mr: 8 }} />}
                {cloneElement(params.InputProps.endAdornment as ReactElement, { onClick: () => setInputValue('') })}
              </>
            ),
          }}
        />
      )}
    />
  )
}

export default PatientsAutocomplete
