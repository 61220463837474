import { FC, useCallback } from 'react'

import insurancePlans, { InsurancePlan } from 'config/insurancePlans'
import { matchSorter } from 'match-sorter'
import { useTranslation } from 'react-i18next'

import Autocomplete from '@mui/material/Autocomplete'
import TextField, { OutlinedTextFieldProps } from '@mui/material/TextField'
import { FilterOptionsState } from '@mui/material/useAutocomplete'

export type InsurancePlanOption = Partial<InsurancePlan> & { isNew?: true | null }

export type InsuranceCompanySelectProps = {
  i18nKey?: string
  // ControlledFieldProps
  value?: InsurancePlanOption | null
  onChange: (x: InsurancePlanOption) => void
} & Partial<Omit<OutlinedTextFieldProps, 'onChange' | 'value'>>

const OPTIONS = insurancePlans
const MAX_OPTIONS_DISPLAY = 30

const DEFAULT_I18N_KEY = 'components:ui.Inputs.InsuranceCompanySelect'

const filterOptions = (
  options: InsurancePlanOption[],
  state: FilterOptionsState<InsurancePlanOption>,
): InsurancePlanOption[] => {
  const { inputValue } = state
  const filtered: InsurancePlanOption[] = matchSorter(options, inputValue, { keys: ['company'] }).slice(
    0,
    MAX_OPTIONS_DISPLAY,
  )
  if (!(inputValue == null || inputValue === '')) {
    filtered.push({
      company: inputValue,
      isNew: true,
    })
  }

  return filtered
}
const getOptionLabel = (option: InsurancePlanOption): string => option?.company ?? ''
const getOptionSelected = (option: InsurancePlanOption, value: InsurancePlanOption): boolean =>
  option.company === value.company

const InsuranceCompanySelect: FC<InsuranceCompanySelectProps> = ({
  i18nKey = DEFAULT_I18N_KEY,
  value,
  onChange,
  ...textFieldProps
}) => {
  const { t } = useTranslation()

  const i18nKeyLabel = `${i18nKey}.label`

  const renderOption = useCallback(
    (props, option: InsurancePlanOption) => {
      const isNew = option?.isNew ?? false
      const valueName = option?.company ?? null

      if (isNew) {
        return (
          <li {...props} key="newOption">
            {t(`${i18nKey}.option.newOption.label`, { userInput: valueName })}
          </li>
        )
      }
      return (
        <li {...props} key={option.packageId}>
          {valueName}
        </li>
      )
    },
    [i18nKey],
  )

  const handleOnChange = useCallback((event, newValue) => onChange(newValue ?? null), [onChange])

  return (
    <Autocomplete
      freeSolo
      clearOnBlur
      options={OPTIONS}
      value={value}
      onChange={handleOnChange}
      getOptionLabel={getOptionLabel}
      isOptionEqualToValue={getOptionSelected}
      filterOptions={filterOptions}
      renderOption={renderOption}
      renderInput={(params) => (
        <TextField
          data-testid="InsuranceCompanySelect"
          {...textFieldProps}
          {...params}
          label={t(i18nKeyLabel)}
          variant="outlined"
          fullWidth
        />
      )}
    />
  )
}

export default InsuranceCompanySelect
