import { InsurancePlan } from 'config/insurancePlans'
import { InsuranceRecord, SexEnum } from 'generated/graphql'
import InsuranceRelationshipToPolicyHolder from 'modules/Patient/Insurance/InsuranceRelationshipToPolicyHolder'
import * as Yup from 'yup'
import makeRequired from 'yupSchemas/helpers/makeRequired'

export type InsuranceSchema = {
  company?: InsurancePlan['company'] | null
  memberId?: string | null
  groupNumber?: string | null
  relationshipToPolicyHolder?: InsuranceRelationshipToPolicyHolder | string | null
  policyHolderFirstName?: string | null
  policyHolderLastName?: string | null
  policyHolderGender?: SexEnum | null
  policyHolderDob?: string | null
  // TODO: backend sends this as a string, we need to make it a number everywhere
  packageId?: InsurancePlan['packageId'] | string | null
  insuranceRecordId?: InsuranceRecord['insuranceRecordId'] | null
}

// RCO doesn't use our enum, lets uppercase any possible entry for "self" so that it aligns with our enum "SELF"
export const isSelf = (input?: InsuranceSchema['relationshipToPolicyHolder']): boolean => {
  const uppercaseInput = typeof input === 'string' ? input.toUpperCase() : null
  if (!uppercaseInput) return false
  return uppercaseInput === InsuranceRelationshipToPolicyHolder.Self
}

const insuranceSchema = Yup.object().shape({
  company: makeRequired(Yup.string(), 'validation:patient.insurance.company.required'),
  memberId: makeRequired(Yup.string(), 'validation:patient.insurance.memberId.required'),
  groupNumber: makeRequired(Yup.string(), 'validation:patient.insurance.groupNumber.required'),
  relationshipToPolicyHolder: makeRequired(
    Yup.string().oneOf(Object.values(InsuranceRelationshipToPolicyHolder)),
    'validation:patient.insurance.relationshipToPolicyHolder.required',
  ),
  policyHolderFirstName: Yup.string().when(
    'relationshipToPolicyHolder',
    (relationshipToPolicyHolder: InsuranceSchema['relationshipToPolicyHolder'], schema: Yup.StringSchema) =>
      isSelf(relationshipToPolicyHolder)
        ? schema.nullable()
        : makeRequired(schema, 'validation:patient.insurance.policyHolderFirstName.required'),
  ),
  policyHolderLastName: Yup.string().when(
    'relationshipToPolicyHolder',
    (relationshipToPolicyHolder: InsuranceSchema['relationshipToPolicyHolder'], schema: Yup.StringSchema) =>
      isSelf(relationshipToPolicyHolder)
        ? schema.nullable()
        : makeRequired(schema, 'validation:patient.insurance.policyHolderLastName.required'),
  ),
  policyHolderGender: Yup.string().when(
    'relationshipToPolicyHolder',
    (relationshipToPolicyHolder: InsuranceSchema['relationshipToPolicyHolder'], schema: Yup.StringSchema) =>
      isSelf(relationshipToPolicyHolder)
        ? schema.nullable()
        : makeRequired(
            schema.oneOf(Object.values(SexEnum)),
            'validation:patient.insurance.policyHolderGender.required',
          ),
  ),
  policyHolderDob: Yup.date().when(
    'relationshipToPolicyHolder',
    (relationshipToPolicyHolder: InsuranceSchema['relationshipToPolicyHolder'], schema: Yup.DateSchema) =>
      isSelf(relationshipToPolicyHolder)
        ? schema.nullable()
        : makeRequired(
            schema
              .min(new Date('01/01/1900').toLocaleDateString(), 'validation:patient.insurance.policyHolderDob.min')
              .max(new Date().toLocaleDateString(), 'validation:patient.insurance.policyHolderDob.max'),
            'validation:patient.insurance.policyHolderFirstName.required',
          ),
  ),

  // optional
  packageId: Yup.string().nullable(),
})

export default insuranceSchema
