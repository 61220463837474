import TextField, { OutlinedTextFieldProps } from '@mui/material/TextField'

export type LastNameInputProps = Partial<OutlinedTextFieldProps>

const LastNameInput = ({ value, ...props }: LastNameInputProps): JSX.Element => (
  <TextField
    id="lastName"
    name="lastName"
    required
    fullWidth
    {...props} // Options above this line can be overwritten
    label="Legal Last Name"
    value={value ?? ''}
  />
)

export default LastNameInput
