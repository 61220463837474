/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import find from 'lodash/find'
import get from 'lodash/get'
import ZenDeskClient from 'utils/ZenDeskClient'

type IdentityArrayType = {
  value: string
}

const findAndParsePhoneNumber = (identityArray: IdentityArrayType) =>
  // take the phoneNumber value of the array and remove the +1
  // before being sent to the lambda
  get(identityArray, ['value']).slice(2)

const callerInfo = async () => {
  if (!ZenDeskClient) return ''
  try {
    const data = await ZenDeskClient.get('ticket.requester')

    // lodash methods to see save the type of the incoming ticket
    // NOTE: Don't remove `.` in the following key.
    const name = get(data, ['ticket.requester', 'identities'])
    const zenDeskIdentityArray = find(name)
    const type = get(zenDeskIdentityArray, ['type'])

    // finding the phone number
    const phoneNumberValue = type === 'phone_number' ? findAndParsePhoneNumber(zenDeskIdentityArray) : ''

    return phoneNumberValue
  } catch (err) {
    return `${err} - There was an error checking the caller information`
  }
}

export default callerInfo
