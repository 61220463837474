import { FC } from 'react'

import Tokens from 'config/tokens'
import { ChannelAttributionsSelect } from 'modules/Appointment/AppointmentPartnership/AppointmentPartnershipEdit'
import { selectMarketId } from 'modules/Appointment/selectors'
import { PatientLookupType } from 'modules/Appointments/Create/AppointmentCreateFormSchema'
import EncounterDetailsEdit from 'modules/Patient/EncounterDetails/EncounterDetailsEdit'
import { useTranslation } from 'react-i18next'
import ControlledField from 'utils/ControlledField/ControlledField'

import { Grid, Paper, Typography } from '@mui/material'
import Box from '@mui/system/Box'

import CardLabeled from 'ui/CardLabeled'
import PatientHeaderProfile from 'ui/PatientHeader/PatientHeaderProfile'

import useGetAppointmentQueryContext from '../useGetAppointmentQueryContext'

type AddPatientDetailsProps = {
  patient: PatientLookupType
}

const AddPatientDetails: FC<AddPatientDetailsProps> = ({ patient }) => {
  const { t } = useTranslation()
  const { data } = useGetAppointmentQueryContext()
  const marketId = selectMarketId(data)

  return (
    <Paper elevation={0}>
      {/* Patient Header */}
      <Box sx={{ px: 2, py: 3, borderBottom: `2px solid ${Tokens.color.neutral.black.transparent[10]}` }}>
        <PatientHeaderProfile patient={patient} />
      </Box>

      {/* Patient Details */}
      <Box sx={{ display: 'flex', backgroundColor: Tokens.color.neutral.grey[250] }}>
        <Grid container spacing={6} sx={{ p: { md: '1rem' } }}>
          {/* left column */}
          <Grid item xs={12} md={7}>
            {/* Encounter Details */}
            <CardLabeled
              title={t('components:modules.Patient.EncounterDetailsCard.title')}
              testId="EncounterDetails"
              editing={true}
            >
              <EncounterDetailsEdit enableCovidQuestions />
            </CardLabeled>
          </Grid>

          {/* right column */}
          <Grid item xs={12} md={5}>
            {/* Partnership/Attribution */}
            <CardLabeled
              title={t('components:modules.Appointments.appointmentPartnership')}
              testId="Partnership"
              editing={true}
            >
              <Typography>
                {patient.firstName} {patient.lastName}
              </Typography>

              <ControlledField
                name="channelAttributionId"
                Component={ChannelAttributionsSelect}
                ComponentProps={{ fullWidth: true, marketId }}
              />
            </CardLabeled>
          </Grid>
        </Grid>
      </Box>
    </Paper>
  )
}

export default AddPatientDetails
