import * as Yup from 'yup'

import {
  firstNameRequired,
  lastNameRequired,
  dobSchemaRequired,
  genderSchemaRequired,
  preferredNameBase,
  genderIdentitySchemaNullable,
  genderPronounSchemaNullable,
  racesSchemaNullable,
} from './items'

export const nameAndDemographicsSchema = Yup.object().shape({
  preferredName: preferredNameBase,
  firstName: firstNameRequired,
  lastName: lastNameRequired,
  sex: genderSchemaRequired,
  genderIdentity: genderIdentitySchemaNullable,
  genderPronoun: genderPronounSchemaNullable,
  dob: dobSchemaRequired,
  races: racesSchemaNullable,
})
