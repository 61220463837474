import { GenderPronounEnum } from 'generated/graphql'

import MenuItem from '@mui/material/MenuItem'
import TextField, { OutlinedTextFieldProps } from '@mui/material/TextField'

import { formatGenderPronoun } from 'ui/TextFormatters/GenderPronoun/GenderPronoun'

export type GenderPronounSelectProps = Partial<Omit<OutlinedTextFieldProps, 'onChange'>> & {
  value?: GenderPronounEnum | ''
  onChange: (newGenderIdentity: GenderPronounEnum) => void
}

const GenderPronounSelect = ({ onChange, value, ...props }: GenderPronounSelectProps): JSX.Element => {
  const handleOnChange = (e: React.ChangeEvent<{ value: unknown }>) => {
    const newGenderIdentity = (e.target.value as GenderPronounEnum) ?? ''
    onChange(newGenderIdentity)
  }

  return (
    <TextField
      fullWidth
      name="genderIdentity"
      {...props}
      value={value ?? ''}
      select
      label="Preferred Gender Pronouns"
      onChange={handleOnChange}
    >
      {Object.values(GenderPronounEnum).map((option) => (
        <MenuItem key={option} value={option}>
          {formatGenderPronoun[option]}
        </MenuItem>
      ))}
    </TextField>
  )
}

export default GenderPronounSelect
