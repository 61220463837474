import { SexEnum } from 'generated/graphql'
import * as Yup from 'yup'
import makeNullable from 'yupSchemas/helpers/makeNullable'
import makeRequired from 'yupSchemas/helpers/makeRequired'

export const genderRequirementBase = Yup.string().oneOf(
  Object.values(SexEnum),
  'Responder Gender Requirement must be a valid selection',
)
export const genderRequirementSchemaNullable = makeNullable(genderRequirementBase)

export const reasonForEncounterBase = Yup.string().max(255)
export const reasonForEncounterRequired = makeRequired(
  reasonForEncounterBase,
  'validation:appointmentTime.appointment.reasonForEncounter.required',
)

export const serviceLineBase = Yup.number().positive('validation:appointmentTime.appointment.serviceLine.required')
export const serviceLineRequired = makeRequired(
  serviceLineBase,
  'validation:appointmentTime.appointment.serviceLine.required',
)
