import { useCallback } from 'react'

import { useToggle as useToggleBase } from 'react-use'
import NullaryVoidFunction from 'types/NullaryVoidFunction'

const useToggle = (
  initialState = false,
): [boolean, (nextValue?: boolean) => void, NullaryVoidFunction, NullaryVoidFunction] => {
  const [value, toggle] = useToggleBase(initialState)
  const set = useCallback(() => toggle(true), [])
  const unset = useCallback(() => toggle(false), [])

  return [value, toggle, set, unset]
}

export default useToggle
