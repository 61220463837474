import { createContext, FC, useContext, useMemo } from 'react'

import { Appointment, GetAppointmentQuery, useGetAppointmentQuery } from 'generated/graphql'
import useIsCreateModeContext from 'modules/Appointments/useIsCreateModeContext'

import CircularProgress from '@mui/material/CircularProgress'
import Box from '@mui/system/Box'

import AppointmentNotFound from './AppointmentNotFound'

type GetAppointmentQueryContextType = {
  data?: GetAppointmentQuery
  id?: Appointment['appointmentId']
}

type GetAppointmentQueryProviderProps = {
  id: Appointment['appointmentId']
}

const GetAppointmentQueryContext = createContext<GetAppointmentQueryContextType | undefined>(undefined)

export const GetAppointmentQueryProvider: FC<GetAppointmentQueryProviderProps> = ({ id, children }) => {
  const { data, loading, error, refetch } = useGetAppointmentQuery({
    variables: {
      appointmentId: Number(id),
    },
    skip: id === null,
    notifyOnNetworkStatusChange: true,
  })
  const contextValue = useMemo(() => ({ data, id, error }), [data, id, error])

  // TODO: show loading only on initial fetch, after that we show progress optimistically
  // TODO: pass loading etc. to children for more refined loading states

  if (loading) {
    return (
      <Box width={1} my={12} minHeight={512} display="flex" justifyContent="center" alignItems="center">
        <CircularProgress size={64} />
      </Box>
    )
  }

  // sometimes the server returns 400, and sometimes it just returns a `null` appointment
  // the latter case will have no error message, so there is an inconsistency in UX here.
  if (error || id == null || data?.appointment === null) {
    return <AppointmentNotFound refetch={refetch} error={error} />
  }

  return <GetAppointmentQueryContext.Provider value={contextValue}>{children}</GetAppointmentQueryContext.Provider>
}

const useGetAppointmentQueryContext = (): GetAppointmentQueryContextType => {
  const context = useContext(GetAppointmentQueryContext)

  const isCreateMode = useIsCreateModeContext()
  if (isCreateMode && context === undefined) {
    // in Create Mode we fail silently and allow destructuring of the return value
    // this lets any Appointment related code be agnostic (Create Mode & View-Only Mode)
    return {}
  }

  if (context === undefined) {
    const error = new Error('useGetAppointmentQueryContext must be used within a GetAppointmentQueryProvider')
    if (Error.captureStackTrace) Error.captureStackTrace(error, useGetAppointmentQueryContext)
    throw error
  }

  return context
}

export default useGetAppointmentQueryContext
