import { LanguagesEnum } from 'generated/graphql'
import { Maybe } from 'graphql/jsutils/Maybe'

export type LanguageNullable = LanguagesEnum | null

export type LanguagesProps = {
  languages: LanguageNullable[]
}

export type LanguageProps = {
  language?: LanguagesEnum | null
}

export const formatLanguage = {
  ALBANIAN: 'Albania',
  AMHARIC: 'Amharic',
  ARABIC: 'Arabic',
  ARMENIAN: 'Armenian',
  BENGALI: 'Bengali',
  CHINESE: 'Chinese',
  CROATION: 'Croatian',
  CZECH: 'Czech',
  DANISH: 'Danish',
  DUTCH: 'Dutch',
  ENGLISH: 'English',
  FINNISH: 'Finnish',
  FRENCH: 'French',
  FRYSIAN: 'Frysian',
  GERMAN: 'German',
  GUJARATI: 'Gujarati',
  GREEK: 'Greek',
  HAITIAN_CREOLE: 'Haitian Creole',
  HINDI: 'Hindi',
  HMONG: 'Hmong',
  IBO: 'Ibo',
  ILOCANO: 'Ilocano',
  ITALIAN: 'Italian',
  JAPANESE: 'Japanese',
  KRU: 'Kru (Bassa)',
  KOREAN: 'Korean',
  LAOTIAN: 'Laotian',
  MONKHMER_CAMBODIAN: 'Mon-Khmer, Cambodian',
  NORWEGIAN: 'Norwegian',
  PANJABI: 'Panjabi',
  PERSIAN: 'Persian (Farsi)',
  POLISH: 'Polish',
  PORTUGUESE: 'Portuguese',
  PUNJABI: 'Punjabi',
  RUSSIAN: 'Russian',
  SAMOAN: 'Samoan',
  SERBIAN: 'Serbian',
  SPANISH: 'Spanish',
  SWEDISH: 'Swedish',
  TAGALOG: 'Tagalog',
  TELEGU: 'Telegu',
  THAI: 'Thai',
  URDU: 'Urdu',
  VIETNAMESE: 'Vietnamese',
  YIDDISH: 'Yiddish',
  YORUBA: 'Yoruba',
}

export type LanguagesSelectProps = {
  languages?: Maybe<Maybe<LanguagesEnum>[]>
}

export function Language({ language }: LanguageProps): JSX.Element {
  return <>{formatLanguage[language as LanguagesEnum] ?? '-'}</>
}

function Languages({ languages }: LanguagesSelectProps): JSX.Element {
  return (
    <>
      {Object.values(languages ?? []).map((language, index, arr) => (
        <span key={language}>
          <Language language={language as LanguagesEnum} />
          {index + 1 < arr.length && ', '}
        </span>
      )) ?? '-'}
    </>
  )
}

export default Languages
