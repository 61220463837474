import { memo, useEffect, useMemo, useState, useRef } from 'react'

import { yupResolver } from '@hookform/resolvers/yup'
import { useUpdatePatientMutation } from 'generated/graphql'
import createUpdatePatientOptions from 'graphql/mutations/UpdatePatientOptions'
import useToggle from 'hooks/useToggle'
import { pickBy } from 'lodash'
import { createAddressLine } from 'modules/Patient/selectors'
import { FormProvider, useForm, SubmitHandler, DeepMap } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useMountedState } from 'react-use'
import { addressSchemaWithoutMarketAndZone, AddressSchemaWithoutMarketAndZone } from 'yupSchemas/address/items'

import Alert from '@mui/material/Alert'
import Box from '@mui/system/Box'

import CardLabeled from 'ui/CardLabeled'

import SaveAppointmentAddressButton from '../AppointmentAddress/SaveAppointmentAddressButton'
import useGoogleMapsApiContext, {
  GoogleMapsApiContextProvider,
  selectGeoLocation,
} from '../AppointmentAddress/useGoogleMapsApiContext'

import AddressEdit from './AddressEdit'
import AddressRead from './AddressRead'

// TODO: This component is nearly identical to AppointmentAddressCard, we should consolidate them

type AddressCardProps = {
  patientId: number
  address1?: string | null
  address2?: string | null
  city?: string | null
  state?: string | null
  zip?: string | null
  addressLat?: number | null
  addressLng?: number | null
  unit?: string | null
  specialInstructions?: string | null
}

const AddressCard = ({
  patientId,
  address1,
  address2,
  city,
  state,
  zip,
  addressLat,
  addressLng,
  unit,
  specialInstructions,
}: AddressCardProps): JSX.Element => {
  const isMounted = useMountedState()
  const [isSaving, , setIsSaving, unsetIsSaving] = useToggle(false)
  const [isEditing, setEditing] = useState(false)
  const { t } = useTranslation()

  // hooks relating to lat/lng
  const [hasGeocodeError, , setGeocodeError, unsetGeocodeError] = useToggle(false)
  const { fetchGeocodeComponents, status: googleMapsStatus } = useGoogleMapsApiContext()
  const isGoogleMapsReady = googleMapsStatus === 'success'

  const setEditingTrue = () => setEditing(true)

  const [updatePatient, { loading: loadingPatient }] = useUpdatePatientMutation()

  const defaultValues: AddressSchemaWithoutMarketAndZone = useMemo(
    () => ({
      address: {
        address1: address1 ?? null,
        address2: address2 ?? null,
        city: city ?? null,
        state: state ?? null,
        zip: zip ?? null,
      },
      geoLocation: {
        addressLat: addressLat ?? null,
        addressLng: addressLng ?? null,
      },

      unit: unit ?? null,
      specialInstructions: specialInstructions ?? null,
    }),
    [address1, address2, city, state, zip, unit, specialInstructions, addressLat, addressLng],
  )

  const methods = useForm<AddressSchemaWithoutMarketAndZone>({
    mode: 'onBlur',
    reValidateMode: 'onChange',
    defaultValues: useMemo(() => defaultValues, Object.values(defaultValues)),
    resolver: yupResolver(addressSchemaWithoutMarketAndZone),
  })

  const {
    reset,
    handleSubmit,
    formState: { isValid, isDirty },
  } = methods

  useEffect(() => {
    reset(defaultValues)
  }, [address1, address2, city, state, zip, unit, specialInstructions, addressLat, addressLng])

  // dirtyFields is not available within the onSubmit handler, we store it in this ref
  const dirtyFieldsRef = useRef<DeepMap<AddressSchemaWithoutMarketAndZone, true>>()
  useEffect(() => {
    dirtyFieldsRef.current = methods.formState.dirtyFields
  })

  const handleCancel = () => {
    setEditing(false)
    reset()
  }

  const handleSave: SubmitHandler<AddressSchemaWithoutMarketAndZone> = async (values) => {
    unsetGeocodeError()
    setIsSaving()

    const dirtyKeys = Object.keys(dirtyFieldsRef.current ?? {})
    if (!dirtyKeys) {
      unsetIsSaving()
      return
    }
    const newValues = pickBy(values, (value, key) =>
      dirtyKeys.includes(key),
    ) as Partial<AddressSchemaWithoutMarketAndZone>

    const hasLatLng = values.geoLocation.addressLat && values.geoLocation.addressLng
    if ('address' in dirtyKeys && !hasLatLng) {
      try {
        // useless typeguard as save is disabled when google maps isn't available
        if (!fetchGeocodeComponents) {
          throw new Error('<AddressCard />: GoogleMapsApiContext was not available, this should never be reached.')
        }

        const addressLine = createAddressLine(values.address)
        const { geocoderResult } = await fetchGeocodeComponents({ address: addressLine })
        const { addressLat: lat, addressLng: lng } = selectGeoLocation(geocoderResult)

        Object.assign(newValues, { addressLat: lat, addressLng: lng })
      } catch (e) {
        if (!isMounted) return
        setGeocodeError()

        // eslint-disable-next-line no-console
        console.error(e)

        return
      }
    }
    if (!isMounted) return

    const { address, geoLocation, ...remainingFields } = newValues

    const baseOptions = createUpdatePatientOptions(patientId, { ...address, ...geoLocation, ...remainingFields })

    updatePatient(baseOptions)
    reset(undefined, { keepValues: true })
    setEditing(false)
    unsetIsSaving()
  }

  return (
    <FormProvider {...methods}>
      <CardLabeled
        title={t('components:modules.Patient.HomeAddressCard.title')}
        testId="AddressCard"
        editing={isEditing}
        onEdit={setEditingTrue}
        onCancel={handleCancel}
        onAccept={handleSubmit(handleSave)}
        acceptText={
          <SaveAppointmentAddressButton
            loading={isSaving || loadingPatient}
            isValid={isValid && isDirty && isGoogleMapsReady}
          />
        }
        acceptDisabled={!(isDirty && isValid && isGoogleMapsReady)}
      >
        {isEditing ? (
          <GoogleMapsApiContextProvider>
            <AddressEdit />
          </GoogleMapsApiContextProvider>
        ) : (
          <AddressRead
            address1={address1}
            address2={address2}
            city={city}
            state={state}
            zip={zip}
            unit={unit}
            patientId={patientId}
            specialInstructions={specialInstructions}
          />
        )}

        {isEditing && hasGeocodeError ? (
          <Box mt={4}>
            <Alert severity="error">
              {t('components:modules.Appointments.appointmentAddress.geocodeError.message')}
            </Alert>
          </Box>
        ) : null}
      </CardLabeled>
    </FormProvider>
  )
}

export default memo(AddressCard)
