import { Controller } from 'react-hook-form'
import ControlledField from 'utils/ControlledField/ControlledField'
import safeJsonParse from 'utils/safeJsonParse'

import FirstNameInput from 'ui/Inputs/FirstNameInput/FirstNameInput'
import GenderIdentitySelect from 'ui/Inputs/GenderIdentitySelect/GenderIdentitySelect'
import GenderPronounsSelect from 'ui/Inputs/GenderPronounSelect/GenderPronounSelect'
import LastNameInput from 'ui/Inputs/LastNameInput/LastNameInput'
import PreferredFirstNameInput from 'ui/Inputs/PreferredFirstNameInput'
import RacesSelect from 'ui/Inputs/RacesSelect'
import SexSelect from 'ui/Inputs/SexSelect/SexSelect'
import BirthdaySelect from 'ui/MaterialUI/DatePicker/BirthdaySelect'
import SpacedItems from 'ui/SpacedItems'

const NameAndDemographicsEdit = (): JSX.Element => (
  <SpacedItems direction="column" rhythmMultiplier={3}>
    <ControlledField name="preferredName" Component={PreferredFirstNameInput} />
    <ControlledField name="firstName" Component={FirstNameInput} />
    <ControlledField name="lastName" Component={LastNameInput} />
    <ControlledField name="dob" Component={BirthdaySelect} />
    <ControlledField name="sex" Component={SexSelect} />
    <ControlledField name="genderIdentity" Component={GenderIdentitySelect} />
    <ControlledField name="genderPronoun" Component={GenderPronounsSelect} />
    <Controller
      name="races"
      render={({ field: { onChange, onBlur, value, ref }, fieldState: { invalid, error } }) => (
        <RacesSelect
          onChange={(val) => onChange(JSON.stringify(val.sort()))}
          onBlur={onBlur}
          value={safeJsonParse(value)}
          inputRef={ref}
          error={invalid}
          helperText={error?.message}
        />
      )}
    />
  </SpacedItems>
)
export default NameAndDemographicsEdit
