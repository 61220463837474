import { FC } from 'react'

import { appointmentsRoutes } from 'config/routes'
import AppointmentRoute from 'modules/Appointment/AppointmentRoute'
import { Route, Switch } from 'react-router-dom'

import Appointments from './Appointments'
import AppointmentsCreate from './Create/AppointmentsCreate'

const AppointmentRoutes: FC<unknown> = () => (
  <Switch>
    <Route exact path={appointmentsRoutes.create}>
      <AppointmentsCreate />
    </Route>

    <Route exact path={appointmentsRoutes.details}>
      <AppointmentRoute />
    </Route>

    <Route exact path={appointmentsRoutes.overview}>
      <Appointments />
    </Route>

    {/* TODO: handle 'page not found' for this sub route, here */}
  </Switch>
)

export default AppointmentRoutes
