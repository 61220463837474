import Appointment from './Appointment'
import ChannelAttribution from './ChannelAttribution'
import Encounter from './Encounter'
import Market from './Market'
import Patient from './Patient'
import PatientNote from './PatientNote'
import Query from './Query'
import ServiceLine from './ServiceLine'
import User from './User'
import Zone from './Zone'
import ZoneZipcode from './ZoneZipcode'

const typePolicies = {
  Appointment,
  ChannelAttribution,
  User,
  Patient,
  PatientNote,
  Encounter,
  ServiceLine,
  Market,
  Zone,
  ZoneZipcode,
  Query,
}

export default typePolicies
