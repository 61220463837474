import { FC, ForwardRefExoticComponent, RefAttributes } from 'react'

import Tokens from 'config/tokens'
import { IconProps } from 'phosphor-react'

import Box from '@mui/system/Box'

export type EmptyStateProps = {
  /**
   * The empty state message.
   */
  message: string

  /**
   * The icon above the message.
   */
  icon?: ForwardRefExoticComponent<IconProps & RefAttributes<SVGSVGElement>>

  /**
   * The `data-testid` attribute.
   */
  testId?: string

  /**
   * whether the empty state has it's own container dimensions
   * (currently removes minHeight)
   */
  slim?: boolean
}

const EmptyState: FC<EmptyStateProps> = ({ icon: IconComponent, message, testId, slim = false }) => (
  <Box
    sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', ...(!slim && { minHeight: '30vh' }) }}
    data-testid={testId}
  >
    <Box
      sx={{
        display: 'inline-block',
        color: Tokens.color.ui.charcoal.base,
        fontFamily: Tokens.font.family,
        fontSize: Tokens.font.size.paragraph.large,
        fontWeight: Tokens.font.weight.semiBold,
        lineHeight: Tokens.lineHeight.paragraph.small,
        textAlign: 'center',
      }}
    >
      {IconComponent && (
        // classname is used for jest test
        <Box component="span" sx={{ marginBottom: 4 }} className="icon">
          <IconComponent size={24} weight="fill" />
        </Box>
      )}
      <div>{message}</div>
    </Box>
  </Box>
)

export default EmptyState
