import { GetZonesQuery, Zone } from 'generated/graphql'
import { createSelector } from 'reselect'

const getZonesList = (data?: GetZonesQuery) => data?.zonesList ?? null
export const selectZonesList = createSelector(getZonesList, (x) => x)

const filterActiveZones = (zones: Partial<Zone>[] | null) => {
  if (!zones) return null
  return zones.filter((zone) => zone?.isActive)
}

export const selectActiveZonesList = createSelector(selectZonesList, filterActiveZones)
