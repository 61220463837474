import { FC, InputHTMLAttributes } from 'react'

import { omit } from 'lodash'

const HiddenInput: FC<InputHTMLAttributes<HTMLInputElement>> = ({ ...controlledFieldProps }) => (
  // omit props coming from ControlledField that can't be applied to an input element
  <input type="hidden" {...omit(controlledFieldProps, ['error', 'helperText', 'inputRef'])} />
)

export default HiddenInput
