import { FC } from 'react'

import Config from 'config/config'
import { generateAppointmentDetailsPath } from 'config/routes'
import { useGetAppointmentPreviewQuery } from 'generated/graphql'
import AppointmentOverview, { AppointmentOverviewSkeleton } from 'modules/AppointmentOverview/AppointmentOverview'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

import { Skeleton } from '@mui/material'
import Button from '@mui/material/Button'
import makeStyles from '@mui/styles/makeStyles'

import LabeledSection from 'ui/LabeledSection'
import Modal from 'ui/Modal'

import AppointmentEncounterPreview from './AppointmentEncounterPreview'
import { selectAvailabilityOverride, selectEncounters } from './selectors'

type AppointmentPreviewModalProps = {
  id: number
  open: boolean
  onClose: () => void
  onExited?: () => void
}

const useStyles = makeStyles({
  modalPaper: {
    // set a natural minHeight (loosely based on actual content) to minimize jittery behavior
    minHeight: 454,
  },
})

const AppointmentPreviewModal: FC<AppointmentPreviewModalProps> = ({ id, open, onClose, onExited }) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const { data, loading } = useGetAppointmentPreviewQuery({
    variables: {
      appointmentId: id,
    },
    skip: id === null,
  })

  const availabilityOverride = selectAvailabilityOverride(data)

  const encounters = selectEncounters(data)

  const history = useHistory()

  const { skeduloJobId } = data?.appointment || {}

  return (
    <Modal
      open={open}
      onClose={onClose}
      TransitionProps={{
        onExited,
      }}
      fullWidth
      maxWidth="md"
      classes={{ paper: classes.modalPaper }}
      actions={
        <>
          <Button
            variant="outlined"
            color="secondary"
            disabled={!skeduloJobId}
            onClick={() => {
              if (skeduloJobId) {
                window.open(`${Config.skeduloUrl}/job/${skeduloJobId}`)
              }
            }}
          >
            {t('actions:appointment.openSkedulo')}
          </Button>

          <Button onClick={() => history.push(generateAppointmentDetailsPath(data?.appointment?.appointmentId))}>
            {t('actions:appointment.viewAppointment')}
          </Button>

          <Button variant="outlined" color="secondary" onClick={onClose}>
            {t('actions:close')}
          </Button>
        </>
      }
    >
      <LabeledSection label={t('components:modules.Appointment.heading')} as={'h2'}>
        {loading ? (
          <AppointmentOverviewSkeleton />
        ) : (
          <AppointmentOverview
            availabilityOverride={availabilityOverride}
            scheduledFor={data?.appointment?.scheduledFor}
            createdAt={data?.appointment?.createdAt}
            createdBy={data?.appointment?.userByCreatedBy}
            status={data?.appointment?.status}
            marketName={data?.appointment?.market?.name ?? ''}
            timeZone={data?.appointment?.market?.timezone ?? ''}
            address1={data?.appointment?.address1 ?? ''}
            address2={data?.appointment?.address2 ?? ''}
            city={data?.appointment?.city ?? ''}
            state={data?.appointment?.state ?? ''}
            zip={data?.appointment?.zip ?? ''}
            appointmentId={data?.appointment?.appointmentId ?? -1}
            systemOfOrigin={data?.appointment?.systemOfOrigin}
            appointmentCancellation={data?.appointment?.cancelledReason}
            appointmentReschedule={data?.appointment?.rescheduledReason}
            cancelledReasonOther={data?.appointment?.cancelledReasonOther}
            rescheduledFrom={data?.appointment?.rescheduledFrom}
            rescheduledAt={data?.appointment?.rescheduledAt}
            rescheduledToAppointmentId={data?.appointment?.rescheduledTo?.appointmentId}
            cancelledBy={data?.appointment?.cancelledBy?.name}
          />
        )}
      </LabeledSection>

      {/* skeleton state encounters */}
      {loading && (!encounters || encounters.length < 1) ? (
        <LabeledSection
          label={t('components:modules.AppointmentPreviewModal.encounter.heading')}
          as={'h2'}
          style={{ paddingTop: '2em' }}
        >
          <Skeleton variant="rectangular" width="100%" height={84} />
        </LabeledSection>
      ) : null}

      {encounters && encounters.length > 0 && (
        <LabeledSection
          label={t('components:modules.AppointmentPreviewModal.encounter.heading')}
          as={'h2'}
          style={{ paddingTop: '2em' }}
        >
          {encounters.map((encounter) => (
            <AppointmentEncounterPreview key={encounter.encounterId} encounter={encounter} />
          ))}
        </LabeledSection>
      )}
    </Modal>
  )
}

export default AppointmentPreviewModal
