import { SexEnum } from 'generated/graphql'

export type GenderRequirementProps = {
  genderRequirement?: SexEnum | null
}

export const formatGenderRequirement = {
  MALE: 'Require Male Responder',
  FEMALE: 'Require Female Responder',
  OTHER: 'Other',
}

function GenderRequirement({ genderRequirement }: GenderRequirementProps): JSX.Element {
  return <>{(genderRequirement && formatGenderRequirement[genderRequirement]) ?? '-'}</>
}

export default GenderRequirement
