import { GenderPronounEnum } from 'generated/graphql'

export type GenderPronounProps = {
  genderPronoun?: GenderPronounEnum | null
}

export const formatGenderPronoun = {
  SHE_HER: 'She/Her',
  HE_HIM: 'He/Him',
  THEY_THEM: 'They/Them',
}

function GenderPronoun({ genderPronoun }: GenderPronounProps): JSX.Element {
  return <>{(genderPronoun && formatGenderPronoun[genderPronoun]) || '-'}</>
}

export default GenderPronoun
