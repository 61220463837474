import React, { useEffect, useState } from 'react'

import Routes, { appointmentsRoutes, attributionRoutes, marketsRoutes } from 'config/routes'
import AppointmentsRoutes from 'modules/Appointments/AppointmentsRoutes'
import { Route, Switch, useHistory, useLocation } from 'react-router-dom'
import hasFeatureFlag from 'utils/featureFlagsUtilities'

import PageNotFound from './PageNotFound'

const MarketsRoutes = React.lazy(() => import('modules/Markets/MarketsRoutes'))
const AttributionRoutes = React.lazy(() => import('modules/Attribution/AttributionRoutes'))

const AppRoutes = (): JSX.Element => {
  const location = useLocation()
  const history = useHistory()
  const [hasTriedRedirects, stateHasTriedRedirects] = useState(false)

  // HAX: This is a hack to make sure that the redirects are only done once.
  // This allows us to deep link into the app when the user is not logged in.
  useEffect(() => {
    if (hasTriedRedirects) return

    // ROOT redirects to Appointments Overview
    if (document.location.pathname === '/') {
      history.push(Routes.appointments)
    }

    if (location.pathname !== document.location.pathname) {
      history.push(document.location.pathname)
    }
    stateHasTriedRedirects(true)
  }, [location.pathname, document.location.pathname])

  return (
    <Switch>
      <Route path={appointmentsRoutes.root}>
        <AppointmentsRoutes />
      </Route>

      <Route path={marketsRoutes.root}>
        <MarketsRoutes />
      </Route>

      {hasFeatureFlag('showAttributionController') && (
        <Route path={attributionRoutes.root}>
          <AttributionRoutes />
        </Route>
      )}

      {hasTriedRedirects && (
        <Route>
          <PageNotFound />
        </Route>
      )}
    </Switch>
  )
}

export default AppRoutes
