import { ModuleNameEnum } from 'generated/graphql'
import * as Yup from 'yup'
import makeNullable from 'yupSchemas/helpers/makeNullable'
import makeRequired from 'yupSchemas/helpers/makeRequired'

export const modulesSchemaBase = Yup.array().of(
  Yup.string().oneOf(Object.values(ModuleNameEnum), 'appointment.modules.required'),
)
export const modulesSchemaNullable = makeNullable(modulesSchemaBase)

export const marketIdSchemaBase = Yup.number().positive('validation:appointmentTime.appointment.market.required')
export const marketIdSchemaRequired = makeRequired(
  marketIdSchemaBase,
  'validation:appointmentTime.appointment.market.required',
)

export const zoneIdSchemaBase = Yup.number().positive('validation:appointmentTime.appointment.zone.required')
export const zoneIdSchemaRequired = makeRequired(
  zoneIdSchemaBase,
  'validation:appointmentTime.appointment.zone.required',
)

export const specialInstructionsSchemaBase = makeNullable(Yup.string())
