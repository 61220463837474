import { CommunicationNeedEnum } from 'generated/graphql'

import Checkbox from '@mui/material/Checkbox'
import MenuItem from '@mui/material/MenuItem'
import TextField, { OutlinedTextFieldProps } from '@mui/material/TextField'

import {
  formatSpecialCommunicationNeeds,
  CommunicationNullable,
} from 'ui/TextFormatters/SpecialCommunicationNeeds/SpecialCommunicationNeeds'

export type SpecialCommunicationNeedProps = Partial<Omit<OutlinedTextFieldProps, 'onChange'>> & {
  value?: CommunicationNullable[]
  onChange: (newValue: CommunicationNeedEnum[]) => void
}

const removeNullValues = (values: CommunicationNullable[]) => values.filter((value) => value !== null)

const CommunicationNeedSelect = ({
  onChange,
  SelectProps,
  value,
  ...props
}: SpecialCommunicationNeedProps): JSX.Element => {
  const handleOnChange = (e: React.ChangeEvent<{ value: unknown }>) => {
    const newValue = (e.target.value as CommunicationNeedEnum[]) ?? []
    onChange(newValue)
  }

  const valuesWithoutNull = removeNullValues(value ?? [])

  return (
    <TextField
      fullWidth
      name="specialCommunicationNeeds"
      {...props}
      value={valuesWithoutNull}
      select
      label="Special Communication Needs (optional)"
      SelectProps={{
        // ...SelectProps,
        multiple: true,
        renderValue: (selected) => {
          const selectedArr = selected as CommunicationNeedEnum[]
          return selectedArr.map((item) => formatSpecialCommunicationNeeds[item]).join(', ')
        },
      }}
      onChange={handleOnChange}
    >
      {Object.values(CommunicationNeedEnum).map((option) => (
        <MenuItem key={option} value={option}>
          <Checkbox
            style={{
              paddingRight: '5px', // TODO better padding via theme
            }}
            checked={(valuesWithoutNull?.indexOf(option) ?? -1) > -1}
          />
          {formatSpecialCommunicationNeeds[option]}
        </MenuItem>
      ))}
    </TextField>
  )
}

export default CommunicationNeedSelect
