import { memo, FC } from 'react'

import { appointmentsRoutes } from 'config/routes'
import { Patient } from 'generated/graphql'
import { size } from 'lodash'
import AppointmentAddressEdit from 'modules/Appointment/AppointmentAddress/AppointmentAddressEdit'
import { GoogleMapsApiContextProvider } from 'modules/Appointment/AppointmentAddress/useGoogleMapsApiContext'
import AppointmentPartnershipEdit from 'modules/Appointment/AppointmentPartnership/AppointmentPartnershipEdit'
import AppointmentTimeEditCreateConnected from 'modules/Appointment/AppointmentTime/AppointmentTimeEditCreateConnected'
import { selectPatientIds } from 'modules/Appointments/selectors'
import { Layout } from 'modules/Layout'
import EncounterDetailsEdit from 'modules/Patient/EncounterDetails/EncounterDetailsEdit'
import PatientDetailsById from 'modules/Patient/PatientDetailsById'
import { findExistingIndexElementEqualsPatientId } from 'modules/Patient/selectors'
import { FormProvider } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useSet } from 'react-use'
import hasFeatureFlag from 'utils/featureFlagsUtilities'
import isInZendeskSidebar from 'utils/isInZendeskSidebar'

import Button from '@mui/material/Button'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'
import Box from '@mui/system/Box'

import CardLabeled from 'ui/CardLabeled'
import LabeledSection from 'ui/LabeledSection'
import SpacedItems from 'ui/SpacedItems'
import Spinner from 'ui/Spinner'

import { IsCreateModeContextProvider } from '../useIsCreateModeContext'

import { PatientFieldType } from './AppointmentCreateFormSchema'
import PatientLookup from './PatientLookup'
import useAppointmentCreateState from './useAppointmentCreateState'
import ZipCodeChecker from './ZipCodeChecker'

const AppointmentsCreate: FC<unknown> = () => {
  const { t } = useTranslation()

  const { methods, submit, loading, hasGenericServerError, patientFields, appendPatient, removePatient } =
    useAppointmentCreateState()

  const {
    formState: { errors, isSubmitted },
  } = methods

  // handles patient accordion show/hide for each patient
  const [, { add, has, remove }] = useSet(new Set())
  const handleTogglePatient = (id: number) => (has(id) ? remove(id) : add(id))

  const handleOnPatientSelected = (
    selectedPatient: Partial<PatientFieldType> & { patientId: Patient['patientId'] },
  ) => {
    appendPatient(selectedPatient)
    handleTogglePatient(selectedPatient.patientId)
  }
  const patientRemoved = (patientId: number) => {
    const patientFieldKeyById = findExistingIndexElementEqualsPatientId(patientFields, patientId)
    if (patientFieldKeyById !== null) {
      removePatient(patientFieldKeyById)
    }
  }

  const patientIds = selectPatientIds(patientFields)

  return (
    <GoogleMapsApiContextProvider>
      <IsCreateModeContextProvider>
        <Layout
          title={t('actions:appointment.createAppointment')}
          breadcrumbsProps={[
            {
              title: t('common:breadcrumb.appointments.overview'),
              href: appointmentsRoutes.overview,
            },
            { title: t('common:breadcrumb.appointments.create'), href: appointmentsRoutes.create },
          ]}
        >
          <Container maxWidth={false}>
            <FormProvider {...methods}>
              {/* Add Patient */}
              <LabeledSection label={t('components:modules.Patient.patientLookup')} as={'h2'}>
                {!hasFeatureFlag('showZipCodeChecker') && (
                  <PatientLookup patients={patientFields} onPatientSelected={handleOnPatientSelected} />
                )}
                {hasFeatureFlag('showZipCodeChecker') && (
                  <Grid
                    container
                    spacing={6}
                    style={{
                      paddingTop: '1rem',
                    }}
                  >
                    <Grid item xs={12} md={7}>
                      <PatientLookup patients={patientFields} onPatientSelected={handleOnPatientSelected} />
                    </Grid>
                    {patientFields.length === 0 && (
                      <Grid item xs={12} md={5}>
                        <ZipCodeChecker />
                      </Grid>
                    )}
                  </Grid>
                )}
              </LabeledSection>

              {/* Patients Accordion */}
              {patientFields.length > 0 && (
                <LabeledSection label="Patients" as={'h2'} style={{ paddingTop: '2rem' }}>
                  <Box paddingBottom={2}>
                    {patientFields.map(({ patientId }, patientFieldKey) => (
                      <PatientDetailsById
                        key={patientId}
                        patientId={patientId}
                        isExpanded={has(patientId)}
                        onExpand={() => {
                          handleTogglePatient(patientId)
                        }}
                        onRemove={patientRemoved}
                        EncountersComponent={
                          <CardLabeled
                            title={t('components:modules.Patient.EncounterDetailsCard.title')}
                            testId="EncounterDetailsCard"
                            editing={true}
                          >
                            <EncounterDetailsEdit patientFieldKey={patientFieldKey} enableCovidQuestions />
                          </CardLabeled>
                        }
                      />
                    ))}
                  </Box>
                </LabeledSection>
              )}

              {/* Appointment Address, Time, Attribution & Submit */}
              {patientFields.length > 0 && (
                <LabeledSection
                  label={t('components:modules.Appointment.heading')}
                  as={'h2'}
                  style={{ paddingTop: '2rem' }}
                >
                  <Grid
                    container
                    spacing={6}
                    style={{
                      paddingTop: '1rem',
                    }}
                  >
                    <Grid item xs={12} md={7}>
                      {/* Appointment Address */}
                      <SpacedItems direction="column">
                        <CardLabeled
                          title={t('components:modules.Appointments.appointmentAddress')}
                          testId="AppointmentAddress"
                          editing={true}
                        >
                          <AppointmentAddressEdit patientIds={patientIds} />
                        </CardLabeled>

                        <CardLabeled
                          title={t('components:modules.Appointments.appointmentPartnership')}
                          testId="AppointmentPartnership"
                          editing={true}
                        >
                          <AppointmentPartnershipEdit />
                        </CardLabeled>

                        {/* Appointment Time */}
                        <CardLabeled
                          title={t('components:modules.Appointments.appointmentTime')}
                          testId="AppointmentTime"
                          editing={true}
                        >
                          <AppointmentTimeEditCreateConnected />
                        </CardLabeled>
                      </SpacedItems>
                    </Grid>

                    {/* Attribution / Partnership */}
                    {!isInZendeskSidebar() && (
                      <Grid item xs={12} md={5}>
                        <SpacedItems direction="column">{/** Empty Column */}</SpacedItems>
                      </Grid>
                    )}
                  </Grid>

                  <Box display="flex" flexDirection="column" alignItems="flex-end" paddingBottom="6rem">
                    {/* Client-side validation errors */}
                    {isSubmitted && size(errors) > 0 ? (
                      <Paper variant="outlined" style={{ margin: '1rem 0' }}>
                        <Box padding="1rem" color="red">
                          {t('validation:appointment.creation.hasValidationErrors')}
                        </Box>
                      </Paper>
                    ) : null}

                    {/* Server error (generic) */}
                    {hasGenericServerError && (
                      <Paper variant="outlined" style={{ margin: '1rem 0' }}>
                        <Box padding="1rem" color="red">
                          {t('validation:appointment.creation.serverError')}
                        </Box>
                      </Paper>
                    )}

                    {/* Submit button */}
                    <SpacedItems direction="row" style={{ marginTop: '20px' }}>
                      <Box display="flex" alignItems="center">
                        {loading && <Spinner />}
                      </Box>

                      <Button onClick={submit} disabled={loading}>
                        {t('actions:appointment.createAppointment')}
                      </Button>
                    </SpacedItems>
                  </Box>
                </LabeledSection>
              )}
            </FormProvider>
          </Container>
        </Layout>
      </IsCreateModeContextProvider>
    </GoogleMapsApiContextProvider>
  )
}

export default memo(AppointmentsCreate)
