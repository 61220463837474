import { FC } from 'react'

import { useTranslation } from 'react-i18next'

import TextField from '@mui/material/TextField'

// TODO: create type for controllerfield props based on its ControlledField render props
const CancellationReasonOtherInput: FC<unknown> = ({ ...controlledFieldProps }) => {
  const { t } = useTranslation()

  return (
    <TextField
      id="appointment-cancelled-reason-other"
      name="cancellationReasonOther"
      fullWidth
      multiline
      rows={4}
      label={t('components:ui.Inputs.AppointmentCancellationOtherInput.label')}
      placeholder={t('components:ui.Inputs.AppointmentCancellationOtherInput.placeholder')}
      {...controlledFieldProps}
    />
  )
}

export default CancellationReasonOtherInput
