import { FC, useMemo, useState, memo } from 'react'

import { yupResolver } from '@hookform/resolvers/yup'
import { generateAppointmentDetailsPath } from 'config/routes'
import { GetAppointmentDocument, GetAppointmentQuery, useRescheduleSkeduloAppointmentMutation } from 'generated/graphql'
import { groupBy, pick } from 'lodash'
import AppointmentTimeEdit from 'modules/Appointment/AppointmentTime/AppointmentTimeEdit'
import AppointmentTimeRead from 'modules/Appointment/AppointmentTime/AppointmentTimeRead'
import { selectIsAnyEncounterServiceLinesCommunityCareEnrollment } from 'modules/AppointmentPreviewModal/selectors'
import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { useToggle } from 'react-use'
import safeJsonParse from 'utils/safeJsonParse'
import { appointmentTimeRescheduleSchema, AppointmentTimeRescheduleSchema } from 'yupSchemas/appointmentTime'

import Alert from '@mui/material/Alert'
import Box from '@mui/system/Box'

import CardLabeled from 'ui/CardLabeled'

import {
  selectAddress,
  selectDefaultedTimezone,
  selectGetScheduledFor,
  selectMarketId,
  selectPatientEncounterDetails,
  selectZoneId,
} from '../selectors'
import useGetAppointmentQueryContext from '../useGetAppointmentQueryContext'

import assertAppointmentIsReschedulable from './assertAppointmentIsReschedulable'
import RescheduleButtonIcon from './RescheduleButtonIcon'

// this approach is messy, however, this part requires some refactoring
// we are using multiple forms, and the legacy useFormState in a child
// let's just have it work in the simplest isolated way, so we don't create more technical debt
const createRescheduleSkeduloAppointmentInput = (
  appointmentData: GetAppointmentQuery,
  formValues: AppointmentTimeRescheduleSchema,
) => {
  if (!appointmentData) {
    return null
  }

  const { startTimes, forceScheduling, rescheduledReason, resourceRequired, jobType, duration, jobTags } = formValues
  const { appointment } = appointmentData

  if (!appointment) {
    return null
  }

  const parsedStartTimes = safeJsonParse(startTimes as string)
  const parsedJobTags = safeJsonParse(jobTags as string)

  const {
    appointmentId,
    contactName,
    primaryPhone,
    systemOfOrigin,
    marketOverride,

    encountersList,

    address1,
    city,
    state,
    zip,
    addressLat,
    addressLng,
    unit,
    specialInstructions,
    zoneId,
    marketId,
  } = appointment

  // ts type guards, if any of this data is not available we have a bigger problem
  if (!(address1 && city && state && zip && addressLat && addressLng)) return null
  if (!(contactName && primaryPhone)) return null
  if (marketOverride == null) return null
  if (!rescheduledReason) return null

  const rescheduleSkeduloAppointmentInput = {
    appointmentId,
    rescheduleReason: rescheduledReason,
    appointment: {
      createdBy: appointment?.userByCreatedBy?.userId,
      contactName,
      primaryPhone,
      systemOfOrigin,
      marketOverride,

      address1,
      city,
      state,
      zipCode: zip,
      addressLat,
      addressLng,
      unit,
      specialInstructions,
      zoneId,
      marketId,

      startTimes: !forceScheduling ? parsedStartTimes : [parsedStartTimes[0]],
      duration,
      jobType,
      jobTags: parsedJobTags,
      resourceRequired,
      forceScheduling,
    },
    patients: Object.entries(groupBy(encountersList, 'patientId')).map(([patientId, encounters]) => ({
      patientId: parseFloat(patientId),
      encounters: encounters.map((encounter) =>
        pick(encounter, ['serviceLineId', 'modules', 'genderRequirement', 'reasonForEncounter']),
      ),
    })),
  }

  return rescheduleSkeduloAppointmentInput
}

const DEFAULT_VALUES_TIME_CARD_STATE = {
  // Appointment Time
  startTimes: JSON.stringify([]),
  forceScheduling: false,
  rescheduledReason: null,

  // Skedulo Attributes
  resourceRequired: 0,
  jobType: '',
  duration: 0,
  jobTags: JSON.stringify([]),
}

const useAppointmentTimeCardForm = () => {
  const methods = useForm<AppointmentTimeRescheduleSchema>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: DEFAULT_VALUES_TIME_CARD_STATE,
    resolver: yupResolver(appointmentTimeRescheduleSchema),
  })

  return methods
}

/**
 * This component is the entry for View-Only Mode
 * Create Mode uses the `AppointmentTimeEditCreateConnected` component:
 * src/modules/Appointment/AppointmentTime/AppointmentTimeEditCreateConnected.tsx
 */
const AppointmentTimeCard: FC<unknown> = () => {
  const { data: appointmentData, id: appointmentId } = useGetAppointmentQueryContext()
  const [hasGenericServerError, toggleHasGenericServerError] = useToggle(false)
  const history = useHistory()
  const [rescheduleSkeduloAppointment, { loading: loadingReschedule }] = useRescheduleSkeduloAppointmentMutation({
    onCompleted({ rescheduleSkeduloAppointment: response }) {
      if (response?.appointmentId == null) {
        toggleHasGenericServerError(true)
        return
      }

      const hasCommunityCareVisit = selectIsAnyEncounterServiceLinesCommunityCareEnrollment(appointmentData)

      if (response && !response.error) {
        history.push(generateAppointmentDetailsPath(response.appointmentId), {
          showCreateVisitInHealthCallModal: hasCommunityCareVisit,
        })
      }
    },
  })

  const [isEditing, setEditing] = useState(false)
  const { t } = useTranslation()
  const methods = useAppointmentTimeCardForm()

  const patients = selectPatientEncounterDetails(appointmentData)
  const scheduledFor = selectGetScheduledFor(appointmentData)
  const marketId = selectMarketId(appointmentData)
  const timeZone = selectDefaultedTimezone(appointmentData)
  const zoneId = selectZoneId(appointmentData)
  const { address1, city, state, zip, addressLat, addressLng } = selectAddress(appointmentData) ?? {}

  const isReschedulable = useMemo(
    () => assertAppointmentIsReschedulable(appointmentData?.appointment),
    [appointmentData],
  )

  const setEditingTrue = () => setEditing(true)
  const handleCancel = () => {
    methods.reset()
    setEditing(false)
    toggleHasGenericServerError(false)
  }

  const handleSave = async () => {
    if (!appointmentData) {
      return
    }

    toggleHasGenericServerError(false)

    const input = createRescheduleSkeduloAppointmentInput(appointmentData, methods.getValues())
    if (!input) {
      return
    }

    try {
      await rescheduleSkeduloAppointment({
        variables: input,
        refetchQueries: [{ query: GetAppointmentDocument, variables: { appointmentId } }],
      })
      setEditing(false)
    } catch (e) {
      toggleHasGenericServerError(true)
    }
  }

  // following line is useless, just a type guard to satisfy typescript
  if (!(address1 && city && state && zip)) return null

  const {
    formState: { isValid, isDirty },
  } = methods

  return (
    <FormProvider {...methods}>
      <CardLabeled
        key={`Appointment-Time-${appointmentId}`}
        title={t('components:modules.Appointments.appointmentTime')}
        testId="AppointmentTimeCard"
        editing={isEditing}
        editText={t('glossary:reschedule')}
        onEdit={setEditingTrue}
        onCancel={handleCancel}
        acceptText={<RescheduleButtonIcon loading={loadingReschedule} isValid={isValid && isDirty} />}
        onAccept={handleSave}
        cancelDisabled={loadingReschedule}
        // we need to check for isDirty as well, as the form doesn't reset validation on methods.reset() (when pressing cancel)
        acceptDisabled={loadingReschedule || !isValid || !isDirty}
        isEditable={isReschedulable}
        editButtonTitle={
          !isReschedulable
            ? t('components:modules.Appointment.AppointmentTimeCard.editButtonTitle_disabled')
            : undefined
        }
      >
        {!isEditing && <AppointmentTimeRead />}

        {isEditing && (
          <AppointmentTimeEdit
            scheduledFor={scheduledFor}
            timeZone={timeZone}
            address1={address1}
            city={city}
            state={state}
            zip={zip}
            addressLat={addressLat}
            addressLng={addressLng}
            marketId={marketId}
            zoneId={zoneId}
            patients={patients}
          />
        )}

        {hasGenericServerError && (
          <Box mt={4}>
            <Alert severity="error">{t('components:modules.Appointment.CancelAppointmentDialog.genericError')}</Alert>
          </Box>
        )}
      </CardLabeled>
    </FormProvider>
  )
}

export default memo(AppointmentTimeCard)
