import { CancellationReasonEnum } from 'generated/graphql'
import * as Yup from 'yup'
import makeRequired from 'yupSchemas/helpers/makeRequired'

export type CancelEncountersSchema = {
  cancellationReason: CancellationReasonEnum | null
  cancellationReasonOther?: string | null
  encounterIds: number[] | null
}

const cancelEncountersSchema = Yup.object().shape({
  cancellationReason: makeRequired(
    Yup.string().oneOf(Object.values(CancellationReasonEnum), 'validation:encounters.cancellationReason.oneOf'),
    'validation:encounters.cancellationReason.required',
  ),
  cancellationReasonOther: Yup.string().when(
    'cancellationReason',
    (cancellationReason: CancelEncountersSchema['cancellationReason'], schema: Yup.StringSchema) =>
      cancellationReason === CancellationReasonEnum.Other
        ? makeRequired(schema, 'validation:encounters.cancellationReasonOther.required')
        : schema.nullable(),
  ),
  encounterIds: Yup.array()
    .ensure()
    .test('min-elements-required', 'validation:encounters.cancelEncounterIds.min', (value) => {
      if (!value) return false
      return value.filter(Boolean).length > 0
    })
    .min(1, 'validation:encounters.cancelEncounterIds.min'),
})

export default cancelEncountersSchema
