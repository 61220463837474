import Tokens from 'config/tokens'

import Box from '@mui/system/Box'

import { TabProps } from './types'

const Tab = ({ children, count, selected = false, onClick }: TabProps): JSX.Element => (
  <Box
    onClick={onClick}
    sx={{
      color: selected ? Tokens.color.ui.charcoal.base : Tokens.color.ui.slate.base,
      cursor: 'pointer',
      fontFamily: Tokens.font.family,
      fontSize: Tokens.font.size.paragraph.base,
      marginRight: `calc(${Tokens.rhythm} * 4)`,
      padding: `calc(${Tokens.rhythm} - 2px) 0 ${Tokens.rhythm} 0`,

      ...(selected && {
        borderBottom: `${Tokens.color.ui.charcoal.base} 2px solid`,
        fontWeight: Tokens.font.weight.semiBold,
      }),

      '&:hover': {
        color: Tokens.color.ui.charcoal.base,
      },
    }}
    {...(selected ? { 'data-selected-tab': selected } : null)}
  >
    {children}

    {count || count === 0 ? (
      <Box
        component="span"
        sx={{
          backgroundColor: Tokens.color.neutral.black.transparent[4],
          borderRadius: 10,
          lineHeight: Tokens.font.size.paragraph.large,
          padding: `0 ${Tokens.rhythm}`,
          marginLeft: '4px',

          '&:hover': {
            backgroundColor: Tokens.color.neutral.black.transparent[10],
          },

          ...(selected && {
            backgroundColor: Tokens.color.ui.charcoal.base,
            color: Tokens.color.neutral.white.base,
          }),
        }}
      >
        {count}
      </Box>
    ) : null}
  </Box>
)

export default Tab
