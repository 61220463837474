import { AppointmentStatusEnum, EncounterResponderStatusEnum, GetAppointmentQuery } from 'generated/graphql'

const assertAppointmentIsReschedulable = (
  appointment: GetAppointmentQuery['appointment'] | null | undefined,
): boolean => {
  if (!appointment) return false

  const appointmentStatus = appointment.status
  const encounterStatuses = appointment.encountersList.map(({ responderStatus }) => responderStatus)

  const isCancellableAppointmentStatus = appointmentStatus
    ? ![AppointmentStatusEnum.Cancelled, AppointmentStatusEnum.Rescheduled, AppointmentStatusEnum.Completed].includes(
        appointmentStatus,
      )
    : false
  const isCancellableResponderStatus = encounterStatuses.every(
    (status) => status !== EncounterResponderStatusEnum.Completed,
  )

  return isCancellableAppointmentStatus && isCancellableResponderStatus
}

export default assertAppointmentIsReschedulable
