import React, { createContext, FC, useCallback, useEffect, useMemo } from 'react'

import { AppointmentStatusEnum } from 'generated/graphql'
import useFormState from 'hooks/useFormState'
import { isEmpty } from 'lodash'
import { DateTime } from 'luxon'
import { useLocalStorage, usePrevious } from 'react-use'

type MarketIdFilter = number[] | null
type ServiceLinesFilter = number[] | null
type StatusFilter = AppointmentStatusEnum[] | null

type FilterStateTypes = {
  query: string
  marketId: MarketIdFilter | undefined
  status: StatusFilter | undefined
  serviceLines: ServiceLinesFilter | undefined
  startDate: DateTime
  endDate: DateTime
}

type ContextValueType = {
  query: string
  setQuery: (value: string) => void

  marketId: MarketIdFilter | undefined
  setMarketId: (newValue: MarketIdFilter) => void

  status: StatusFilter | undefined
  setStatus: (newValue: StatusFilter) => void

  serviceLines: ServiceLinesFilter | undefined
  setServiceLines: (newValue: ServiceLinesFilter) => void

  startDate: DateTime
  setStartDate: (newValue: DateTime | null) => void

  endDate: DateTime
  setEndDate: (newValue: DateTime | null) => void

  handleReset: () => void
  hasValuesChanged: boolean
  isFiltered: boolean
}

const DATE_TODAY_START = DateTime.now().startOf('day')
const DATE_TODAY_END = DateTime.now().endOf('day')

const initialState: FilterStateTypes = {
  query: '',
  marketId: undefined,
  status: undefined,
  serviceLines: undefined,
  startDate: DATE_TODAY_START,
  endDate: DATE_TODAY_END,
}

const KEY_STORAGE_MARKET_ID = 'appointment-filter-market-id'
const KEY_STORAGE_STATUS = 'appointment-filter-status'
const KEY_STORAGE_SERVICELINES = 'appointment-filter-servicelines'
const KEY_STORAGE_START_DATE = 'appointment-filter-start-date'
const KEY_STORAGE_END_DATE = 'appointment-filter-end-date'

const AppointmentFiltersContext = createContext<ContextValueType | null>(null)

/**
 * Provides Getter/Setter for Appointment Filters, used for searchAppointments query.
 *
 * Note: The filters that persist in localStorage differentiate between `undefined` and `null` state:
 *  - `undefined` -> initial state, before we've checked if they are available in localStorage
 *  - `null` -> the value in localStorage is empty,
 *  Use-case: We can guarantee that if the value is `undefined` another render cycle will occur for getting the values from localStorage
 *
 * Note: The date filters have a typing of `DateTime` in the formstate, the components that set them as well as localStorage use `null` for empty or default value, instead.
 * The reason for this is that we are attempting to keep the empty/default value & logic only in this hook, and let everything else use a more generic empty/default value of `null`
 * e.g. The default/empty value is implemented as the date of today, however the components and localStorage don't control this logic and don't have this as their default,
 * (this hook should always the the only place to contain that logic)
 *
 */
export const AppointmentFilterContextProvider: FC<unknown> = ({ children }) => {
  const {
    values,
    handleSetValue,
    handleReset: handleResetFormState,
    hasValuesChanged,
  } = useFormState({
    initialState,
  })

  // dates don't have a distinctive empty value, their empty value (DATE_TODAY) is a valid input
  // so to check whether we are not dealing with an empty value, we need to compare the previous value to the latest
  // as in, if a user selects today, they must have selected it because the previous value was not today
  const previousStartDate = usePrevious(values.startDate)
  const previousEndDate = usePrevious(values.endDate)

  const [marketIdLocal, setMarketIdLocal] = useLocalStorage<MarketIdFilter>(KEY_STORAGE_MARKET_ID, null)
  const [serviceLinesLocal, setServiceLinesLocal] = useLocalStorage<ServiceLinesFilter>(KEY_STORAGE_SERVICELINES, null)
  const [statusLocal, setStatusLocal] = useLocalStorage<StatusFilter>(KEY_STORAGE_STATUS, null)
  const [startDateLocal, setStartDateLocal] = useLocalStorage<string | null>(KEY_STORAGE_START_DATE, null)
  const [endDateLocal, setEndDateLocal] = useLocalStorage<string | null>(KEY_STORAGE_END_DATE, null)

  // the following useEffect's are for two-way binding with localStorage

  useEffect(() => {
    setMarketIdLocal(values.marketId ?? null)
  }, [values.marketId])

  useEffect(() => {
    setStatusLocal(values.status ?? null)
  }, [values.status])

  useEffect(() => {
    setServiceLinesLocal(values.serviceLines ?? null)
  }, [values.serviceLines])

  useEffect(() => {
    setStartDateLocal(+values.startDate !== +DATE_TODAY_START ? values.startDate.toISO() : null)
  }, [values.startDate])

  useEffect(() => {
    setEndDateLocal(+values.endDate !== +DATE_TODAY_END ? values.endDate.toISO() : null)
  }, [values.endDate])

  useEffect(() => {
    if (values.marketId === undefined) {
      handleSetValue('marketId', marketIdLocal)
    }
    if (values.status === undefined) {
      handleSetValue('status', statusLocal)
    }
    if (values.serviceLines === undefined) {
      handleSetValue('serviceLines', serviceLinesLocal)
    }

    const localStartDateTime = startDateLocal ? DateTime.fromISO(startDateLocal) : DATE_TODAY_START
    if (
      +values.startDate === +DATE_TODAY_START &&
      (previousStartDate == null || +previousStartDate === +DATE_TODAY_START) &&
      +localStartDateTime !== +DATE_TODAY_START
    ) {
      handleSetValue('startDate', localStartDateTime)
    }

    const localEndDateTime = endDateLocal ? DateTime.fromISO(endDateLocal) : DATE_TODAY_END
    if (
      +values.endDate === +DATE_TODAY_END &&
      (previousEndDate == null || +previousEndDate === +DATE_TODAY_END) &&
      +localEndDateTime !== +DATE_TODAY_END
    ) {
      handleSetValue('endDate', localEndDateTime)
    }
  }, [
    marketIdLocal,
    statusLocal,
    serviceLinesLocal,
    startDateLocal,
    endDateLocal,
    values.marketId,
    values.status,
    values.serviceLines,
    values.startDate,
    values.endDate,
  ])

  const setQuery = useCallback((value: string) => handleSetValue('query', value), [handleSetValue])
  const setMarketId = useCallback((value: MarketIdFilter) => handleSetValue('marketId', value), [handleSetValue])
  const setStatus = useCallback((value: StatusFilter) => handleSetValue('status', value), [handleSetValue])
  const setServiceLines = useCallback(
    (value: ServiceLinesFilter) => handleSetValue('serviceLines', value),
    [handleSetValue],
  )
  const setStartDate = useCallback(
    (value: DateTime | null) => handleSetValue('startDate', value ? value.startOf('day') : DATE_TODAY_START),
    [handleSetValue],
  )
  const setEndDate = useCallback(
    (value: DateTime | null) => handleSetValue('endDate', value ? value.endOf('day') : DATE_TODAY_END),
    [handleSetValue],
  )

  const handleReset = useCallback(() => {
    setMarketIdLocal(null)
    setStatusLocal(null)
    setServiceLinesLocal(null)

    setStartDateLocal(null)
    setEndDateLocal(null)

    handleResetFormState()
  }, [])

  const contextValue: ContextValueType = useMemo(
    () => ({
      query: values.query,
      setQuery,

      marketId: values.marketId,
      setMarketId,

      status: values.status,
      setStatus,

      serviceLines: values.serviceLines,
      setServiceLines,

      startDate: values.startDate,
      setStartDate,

      endDate: values.endDate,
      setEndDate,

      handleReset,
      hasValuesChanged,
      isFiltered: !(
        isEmpty(values.query) &&
        values.marketId == null &&
        values.status == null &&
        values.serviceLines == null &&
        +values.startDate === +DATE_TODAY_START &&
        +values.endDate === +DATE_TODAY_END
      ),
    }),
    [values, handleReset, hasValuesChanged, setQuery, setMarketId, setStatus],
  )

  return <AppointmentFiltersContext.Provider value={contextValue}>{children}</AppointmentFiltersContext.Provider>
}

const useAppointmentFilterContext = (): ContextValueType => {
  const context = React.useContext(AppointmentFiltersContext)
  if (context === undefined) {
    const error = new Error('useAppointmentFilterContext must be used within a AppointmentFilterContextProvider')
    if (Error.captureStackTrace) Error.captureStackTrace(error, useAppointmentFilterContext)
    throw error
  }
  return context as ContextValueType
}

export default useAppointmentFilterContext
