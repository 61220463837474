// import { EncounterData } from 'modules/Appointments/Create/AppointmentCreateFormSchema';

import { SexEnum } from 'generated/graphql'
import * as Yup from 'yup'
import { encountersSchema } from 'yupSchemas/encounters'
import makeRequired from 'yupSchemas/helpers/makeRequired'

// TODO: import from appointmentCreateFormSchema
type EncounterData = {
  serviceLineId: number | null
  modules: string
  genderRequirement: SexEnum | null
  reasonForEncounter: string
  patientHasSymptoms?: boolean | null
  patientCloseContactSymptoms?: boolean | null
}

type AddPatientFormSchema = EncounterData & { patientId: number | null; channelAttributionId?: number | null }

export const addPatientFormSchema = encountersSchema.shape({
  patientId: Yup.number().required(),
  channelAttributionId: makeRequired(Yup.number(), 'validation:appointment.patients.channelAttributionId.required'),
})

export default AddPatientFormSchema
