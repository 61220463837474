export type AddressProps = {
  address1?: string
  address2?: string
  city?: string
  state?: string
  zip?: string
  splitLine?: boolean
}

const Address = ({
  address1 = '',
  address2 = '',
  city = '',
  state = '',
  zip = '',
  splitLine = true,
}: AddressProps): JSX.Element => {
  if (!address1 && !address2 && !city && !state && !zip) {
    return (
      <>
        -{splitLine && <br />}
        {splitLine && '-'}
      </>
    )
  }

  return (
    <>
      {address1} {address2}
      {!splitLine && ', '}
      {splitLine && <br />}
      {city}, {state} {zip}
    </>
  )
}

export default Address
