import Tokens from 'config/tokens'
import styled from 'styled-components'

import Text from '../Text'

type StyledLabelTypes = 'h1' | 'h2' | 'h3' | 'h4' | 'h5'

type StyledLabelProps = {
  as: StyledLabelTypes
}

export type LabeledSectionProps = {
  label: string
  children: React.ReactNode
  as: StyledLabelTypes
  style?: React.CSSProperties
}

const StyledLabel = styled.div<StyledLabelProps>(({ as }) => {
  let fontSizeVal = `${Tokens.font.size.heading[5]}`
  let paddingBottom = `calc(1 * ${Tokens.rhythm})`

  switch (as) {
    case 'h1':
      fontSizeVal = `2em`
      paddingBottom = `calc(3 * ${Tokens.rhythm})`
      break
    case 'h2':
      fontSizeVal = `1.5em`
      paddingBottom = `calc(2 * ${Tokens.rhythm})`
      break
    case 'h3':
      fontSizeVal = `1em`
      paddingBottom = `calc(2 * ${Tokens.rhythm})`
      break
    case 'h4':
      fontSizeVal = `1em`
      paddingBottom = `calc(1 * ${Tokens.rhythm})`
      break
    default:
      break
  }

  return `
    padding-bottom: ${paddingBottom};
    font-size: ${fontSizeVal};
    line-height: normal;
    font-weight: ${Tokens.font.weight.normal};
    color: ${Tokens.color.neutral.grey[102]};
    margin: 0;
  `
})

function LabeledSection({ label, children, style = {}, as = 'h1' }: LabeledSectionProps): JSX.Element {
  return (
    <Text
      style={{
        display: 'flex',
        flexDirection: 'column',
        ...style,
      }}
      aria-labelledby={label}
      as={'div'}
    >
      <StyledLabel id={label} as={as}>
        {label}
      </StyledLabel>
      {children}
    </Text>
  )
}

export default LabeledSection
