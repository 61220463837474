import { memo } from 'react'

import Tokens from 'config/tokens'
import { List } from 'phosphor-react'

import IconButton from '@mui/material/IconButton'
import Box from '@mui/system/Box'

import Logo from 'ui/Logo'

type Props = {
  setSidebarIsOpen?: (arg: boolean) => void
}

const Header = ({ setSidebarIsOpen = () => {} }: Readonly<Props>): JSX.Element => (
  <Box
    display="flex"
    alignItems="center"
    sx={{
      padding: `calc(${Tokens.rhythm} * 2)`,
      '& > * + *': {
        marginLeft: `calc(${Tokens.rhythm} * 2)`,
      },
    }}
  >
    <IconButton
      aria-label="close menu"
      onClick={() => setSidebarIsOpen(false)}
      style={{
        backgroundColor: Tokens.color.neutral.white.transparent[10],
      }}
    >
      <List size={22} color={Tokens.color.neutral.white.base} />
    </IconButton>

    <Logo />
  </Box>
)

export default memo(Header)
