import isNilOrEmpty from 'fp/isNilOrEmpty'
import { Encounter, EncounterResponderStatusEnum } from 'generated/graphql'
import { useTranslation } from 'react-i18next'

import LabeledItem from 'ui/LabeledItem'
import EncounterStatus from 'ui/TextFormatters/EncounterStatus'
import GenderRequirement, { GenderRequirementProps } from 'ui/TextFormatters/GenderRequirement/GenderRequirement'

type EncounterDetailsReadProps = {
  id: number
  reasonForEncounter?: string | null
  serviceLineName?: string
  genderRequirement: GenderRequirementProps['genderRequirement']
  modulesFriendly?: string
  responderStatus?: EncounterResponderStatusEnum | null
} & Pick<Encounter, 'cancellationReason' | 'cancellationReasonOther' | 'cancelledAt'>

const I18N_CANCELLATION_REASON_KEY = 'glossary:appointmentCancellationReason'

// TODO: Revisit encounter status with: https://readyresponders.atlassian.net/browse/SKED-582

const EncounterDetailsRead = ({
  reasonForEncounter,
  serviceLineName,
  genderRequirement,
  modulesFriendly,
  cancellationReason,
  cancellationReasonOther,
  cancelledAt,
  responderStatus,
}: EncounterDetailsReadProps): JSX.Element => {
  const { t } = useTranslation()

  return (
    <div>
      <LabeledItem label="Responder Status:" inline>
        <EncounterStatus responderStatus={responderStatus} />
      </LabeledItem>
      <LabeledItem label="Reason for Encounter:" inline>
        {reasonForEncounter}
      </LabeledItem>
      <LabeledItem label="Service Line:" inline>
        {serviceLineName}
      </LabeledItem>
      <LabeledItem label="Modules:" inline>
        {modulesFriendly}
      </LabeledItem>
      <LabeledItem label="Responder Gender:" inline>
        <GenderRequirement genderRequirement={genderRequirement} />
      </LabeledItem>
      {cancelledAt && (
        <LabeledItem label="Cancellation Reason:" inline warning>
          {t(I18N_CANCELLATION_REASON_KEY, { context: cancellationReason })}
          {!isNilOrEmpty(cancellationReasonOther) && <> - {cancellationReasonOther}</>}
        </LabeledItem>
      )}
    </div>
  )
}

export default EncounterDetailsRead
