/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-param-reassign */
import { DateTime } from 'luxon'
import datetime from 'utils/dates/datetime'

import createVisitText from './createVisitText'
import getTicketId from './getZendeskTicketId'
import makeZendeskLambdaRequest from './makeZendeskLambdaRequest'

// @ts-ignore
const createInternalNote = async (appointment): Promise<void> => {
  const firstPatient = appointment?.patientsByEncounterAppointmentIdAndPatientIdList?.[0]
  const additionalPatients = appointment.patientsByEncounterAppointmentIdAndPatientIdList.slice(1)

  const updatedPatientsByEncounterAppointmentIdAndPatientIdList =
    appointment.patientsByEncounterAppointmentIdAndPatientIdList.map((patient: any) => {
      const encounters = appointment.encountersList.filter(
        // @ts-ignore
        (encounter) => encounter.patientId === patient.patientId,
      )
      const patientWithEncounters = { ...patient, encounters }
      return patientWithEncounters
    })

  const appointmentWithEncountersInPatient = {
    ...appointment,
    friendlyScheduledFor: datetime(DateTime.fromISO(appointment.scheduledFor, { zone: appointment.market.timezone })),
    patients: updatedPatientsByEncounterAppointmentIdAndPatientIdList,
  }

  const idNumber = await getTicketId()

  const internalNoteText = createVisitText({
    appointment: appointmentWithEncountersInPatient,
    firstPatient,
    additionalPatients,
  })

  const postData = {
    ticket: {
      comment: {
        html_body: internalNoteText,
        is_public: false,
      },
    },
  }

  const postJSONdata = JSON.stringify(postData)

  return makeZendeskLambdaRequest({
    url: `/api/v2/tickets/${idNumber}`,
    method: 'PUT',
    data: postJSONdata,
  })
}

export default createInternalNote
