import Tokens from 'config/tokens'
import styled from 'styled-components'

import { AvatarProps } from './types'

const StyledAvatar = styled.img<AvatarProps>(({ size }) => {
  const sizeVars = (() => {
    switch (size) {
      case 'xs':
        return `
          width: ${Tokens.rhythm};
          height: ${Tokens.rhythm};`
      case 's':
        return `
          width: calc(${Tokens.rhythm} * 2);
          height: calc(${Tokens.rhythm} * 2);`
      case 'm':
        return `
          width: calc(${Tokens.rhythm} * 3);
          height: calc(${Tokens.rhythm} * 3);`
      case 'lg':
        return `
          width: calc(${Tokens.rhythm} * 4);
          height: calc(${Tokens.rhythm} * 4);`
      default:
        return `
          width: calc(${Tokens.rhythm} * 2);
          height: calc(${Tokens.rhythm} * 2);`
    }
  })()

  return `
    ${sizeVars}
    border-radius: 50%;
    display: block;
  `
})

/**
 * The base avatar component.
 * @param props
 */
const Avatar = ({ size = 's', src = 'https://via.placeholder.com/150/303C46' }: Readonly<AvatarProps>): JSX.Element => (
  <StyledAvatar size={size} src={src} />
)

export default Avatar
