import { createContext, FC, useContext, useEffect, useState } from 'react'

import useScript from 'hooks/useScript'
import { useUnmountPromise } from 'react-use'
import { AddressValues } from 'types/AddressValues'

import { Box, CircularProgress } from '@mui/material'

// NOTE: we need to update our typings in package.json to match this (@types/google.maps)
// prefer 'quarterly' | 'weekly'
const VERSION_MAPS_API = '3.45'

type GoogleMapsApiStatus = 'idle' | 'loading' | 'success' | 'error'

type FetchGeocodeComponentsReturnValue = {
  geocoderResult: google.maps.GeocoderResult[]
}

type GetAppointmentQueryContextType = {
  status: GoogleMapsApiStatus
  autocompleteService: google.maps.places.AutocompleteService | null
  geocoder: google.maps.Geocoder | null
  fetchGeocodeComponents: null | ((request: google.maps.GeocoderRequest) => Promise<FetchGeocodeComponentsReturnValue>)
}

export class GeocoderError extends Error {
  constructor(message: string) {
    super(message)

    this.name = 'GeocoderError'
  }
}

const MAX_RETRY_GEOCODER = 3

const GoogleMapsApiContext = createContext<GetAppointmentQueryContextType | null>(null)

const SRC_GOOGLE_MAPS_API = `https://maps.googleapis.com/maps/api/js?v=${VERSION_MAPS_API}&key=${process.env.REACT_APP_GOOGLE_MAPS_AUTH_TOKEN}&libraries=places`

// moved from <GoogleMapAutoComplete />
export const selectGeocoderAddressValues = (
  result: google.maps.GeocoderResult[],
): Pick<AddressValues, 'address1' | 'city' | 'state' | 'zip'> => {
  const addressObject = result?.[0]?.address_components.reduce((fullAddress, addressPart) => {
    // type = the types specified in the address
    const type = addressPart.types.filter((addressType) => addressType !== 'political')[0]
    // eslint-disable-next-line no-param-reassign
    fullAddress[type] = {
      short_name: addressPart.short_name,
      long_name: addressPart.long_name,
    }
    return fullAddress
  }, {} as { [key: string]: { short_name: string; long_name: string } })

  let addressConcatenated = ''
  // This is concatenating the street address with the three key inputs
  // Street number , street name , and any building numbers
  addressConcatenated += `${addressObject?.street_number?.short_name ?? ''}`
  addressConcatenated += ` ${addressObject?.route?.short_name ?? ''}`
  addressConcatenated += ` ${addressObject?.premise?.short_name ?? ''}`

  // the city value can be returned using any of these 3 keys
  const parsedCity =
    addressObject?.locality?.short_name ||
    addressObject?.sublocality?.short_name ||
    addressObject?.neighborhood?.short_name ||
    ''

  const parsedStreetAddress = addressConcatenated
  const parsedState = addressObject?.administrative_area_level_1?.short_name ?? ''
  const parsedZip = addressObject?.postal_code?.short_name ?? ''

  return {
    address1: parsedStreetAddress,
    city: parsedCity,
    state: parsedState,
    zip: parsedZip,
  }
}

export const selectGeoLocation = (
  result: google.maps.GeocoderResult[],
): Pick<AddressValues, 'addressLat' | 'addressLng'> => {
  const latLng = result?.[0]?.geometry?.location
  const lat = latLng?.lat() ?? null
  const lng = latLng?.lng() ?? null
  return { addressLat: lat, addressLng: lng }
}

const fetchGeocodeComponents =
  (geocoder: google.maps.Geocoder) =>
  (request: google.maps.GeocoderRequest): Promise<FetchGeocodeComponentsReturnValue> =>
    new Promise((resolve, reject) => {
      const fetch = (retry = MAX_RETRY_GEOCODER) => {
        geocoder.geocode(request, (result: google.maps.GeocoderResult[] | null, status: google.maps.GeocoderStatus) => {
          if (
            status === google.maps.GeocoderStatus.ERROR ||
            status === google.maps.GeocoderStatus.UNKNOWN_ERROR ||
            result == null
          ) {
            if (retry > -1) {
              // eslint-disable-next-line no-param-reassign
              retry -= 1
              fetch(retry)
              return
            }

            reject(new GeocoderError(`fetchGeocodeComponents: Geocoder failed after ${MAX_RETRY_GEOCODER} retries`))
            return
          }
          if (status !== google.maps.GeocoderStatus.OK) {
            reject(new GeocoderError(`fetchGeocodeComponents: Geocoder return following error status: ${status}`))
            return
          }

          resolve({ geocoderResult: result })
        })
      }
      fetch()
    })

export const GoogleMapsApiContextProvider: FC<unknown> = ({ children }) => {
  const mounted = useUnmountPromise()
  const [contextValue, dispatch] = useState<GetAppointmentQueryContextType>({
    status: 'idle',
    autocompleteService: null,
    geocoder: null,
    fetchGeocodeComponents: null,
  })
  const scriptStatus = useScript(SRC_GOOGLE_MAPS_API)

  useEffect(() => {
    if (scriptStatus === 'loading') {
      dispatch((state) => ({ ...state, status: 'loading' }))
    }
    if (scriptStatus === 'ready') {
      const geocoder = new window.google.maps.Geocoder()

      dispatch({
        status: 'success',
        autocompleteService: new window.google.maps.places.AutocompleteService(),
        geocoder,
        fetchGeocodeComponents: (request) => mounted(fetchGeocodeComponents(geocoder)(request)),
      })
    }
  }, [scriptStatus])

  if (contextValue.status === 'loading') {
    return (
      <Box width={1} my={12} minHeight={512} display="flex" justifyContent="center" alignItems="center">
        <CircularProgress size={64} />
      </Box>
    )
  }

  // TODO: error state

  return <GoogleMapsApiContext.Provider value={contextValue}>{children}</GoogleMapsApiContext.Provider>
}

const useGoogleMapsApiContext = (): GetAppointmentQueryContextType => {
  const context = useContext(GoogleMapsApiContext)
  if (context === null) {
    throw new Error('useGoogleMapsApiContext must be used within a GoogleMapApiContextProvider')
  }
  return context
}

export default useGoogleMapsApiContext
