import { FC } from 'react'

import { EncounterFieldsFragment, GenderPronounEnum, Patient, EncounterResponderStatusEnum } from 'generated/graphql'
import { useConfirm } from 'hooks/useConfirm'
import AddressCard from 'modules/Appointment/Address/AddressCard'
import CommunicationsCard from 'modules/Appointment/Communications/CommunicationsCard'
import PatientNotes from 'modules/Appointment/PatientNotes/PatientNotes'
import NameAndDemographicsCard from 'modules/Patient/NameAndDemographics/NameAndDemographicsCard'
import { X } from 'phosphor-react'

import Grid from '@mui/material/Grid'
import { useTheme } from '@mui/material/styles'

import PatientHeader from 'ui/PatientHeader'
import SpacedItems from 'ui/SpacedItems'

import EncounterDetailsCard from './EncounterDetails/EncounterDetailsCard'
import PatientInsuranceCards from './Insurance/PatientInsuranceCards'

export type PatientDetailsProps = {
  specialInstructions: Patient['specialInstructions']
  firstName: Patient['firstName']
  lastName: Patient['lastName']
  preferredName: Patient['preferredName']
  preferredPhoneNumber: Patient['preferredPhoneNumber']
  patientId: Patient['patientId']
  dob: Patient['dob']
  sex: Patient['sex']
  email: Patient['email']
  languages: Patient['languages']
  communicationNeed: Patient['communicationNeed']
  genderIdentity: Patient['genderIdentity']
  globalHumanId: Patient['globalHumanId']
  genderPronoun: Patient['genderPronoun']
  races: Patient['races']
  address1: Patient['address1']
  address2: Patient['address2']
  city: Patient['city']
  state: Patient['state']
  addressLat: Patient['addressLat']
  addressLng: Patient['addressLng']
  unit: Patient['unit']
  zip: Patient['zip']
  isExpanded: boolean
  onExpand: (id: string) => void
  onRemove?: (patientId: number) => void
  encounters?: EncounterFieldsFragment[] | null
  EncountersComponent?: JSX.Element
  responderStatus?: EncounterResponderStatusEnum | null
}

const PatientDetails: FC<PatientDetailsProps> = ({
  specialInstructions,
  firstName,
  lastName,
  preferredPhoneNumber,
  patientId,
  dob,
  sex,
  email,
  languages,
  communicationNeed,
  genderPronoun,
  address1,
  address2,
  city,
  state,
  addressLat,
  addressLng,
  unit,
  zip,
  isExpanded,
  onExpand,
  onRemove,
  encounters,
  EncountersComponent,
  responderStatus,
}) => {
  const confirm = useConfirm()
  const theme = useTheme()
  const handleRemove: React.MouseEventHandler<SVGSVGElement> = async (event) => {
    event.stopPropagation()
    if (!patientId) return

    const shouldRemove = await confirm({
      title: 'Remove Patient',
      message: `Are you sure you want to remove ${firstName} ${lastName} from this appointment?`,
    })

    if (shouldRemove && onRemove) onRemove(patientId)
  }

  if (!patientId) return null

  return (
    <PatientHeader
      firstName={firstName ?? ''}
      lastName={lastName ?? ''}
      sex={sex ?? undefined}
      dob={dob ?? ''}
      genderPronoun={genderPronoun ?? GenderPronounEnum.TheyThem}
      expanded={isExpanded}
      menu={onRemove && <X onClick={handleRemove} size={16} color={theme.palette.error.light} />}
      onChange={onExpand}
      patientId={patientId}
      responderStatus={responderStatus}
    >
      <Grid container spacing={6} sx={{ p: { md: '1rem' } }}>
        <Grid item xs={12} md={7}>
          <SpacedItems direction="column">
            <NameAndDemographicsCard patientId={patientId} />

            <AddressCard
              patientId={patientId}
              address1={address1}
              address2={address2}
              city={city}
              state={state}
              zip={zip}
              addressLat={addressLat}
              addressLng={addressLng}
              unit={unit}
              specialInstructions={specialInstructions}
            />

            <CommunicationsCard
              patientId={patientId}
              preferredPhoneNumber={preferredPhoneNumber}
              email={email ?? ''}
              languages={languages}
              communicationNeed={communicationNeed}
            />

            <PatientInsuranceCards patientId={patientId} encounterId={encounters?.[0]?.encounterId ?? null} />

            {EncountersComponent ||
              encounters?.map((encounter) => (
                <EncounterDetailsCard
                  key={`encounter-${encounter.encounterId}`}
                  patientId={patientId}
                  encounterId={encounter.encounterId}
                  serviceLineName={encounter.serviceLine?.friendlyName}
                  serviceLine={encounter.serviceLine?.serviceLineId}
                  reasonForEncounter={encounter.reasonForEncounter}
                  genderRequirement={encounter?.genderRequirement}
                  modules={encounter?.modules}
                  modulesFriendly={encounter?.modulesFriendly}
                  responderStatus={encounter?.responderStatus}
                  cancellationReason={encounter?.cancellationReason}
                  cancellationReasonOther={encounter?.cancellationReasonOther}
                  cancelledAt={encounter?.cancelledAt}
                />
              ))}
          </SpacedItems>
        </Grid>
        <Grid item xs={12} md={5}>
          <PatientNotes patientId={patientId} />
        </Grid>
      </Grid>
    </PatientHeader>
  )
}

export default PatientDetails
