import { CommunicationNeedEnum } from 'generated/graphql'

export type CommunicationNullable = CommunicationNeedEnum | null

export type SpecialCommunicationProps = {
  specialCommunicationNeed: CommunicationNullable[]
}

export const formatSpecialCommunicationNeeds = {
  BLIND: 'Blind',
  DEAF: 'Deaf',
  TRANSLATOR_INTERPRETER: 'Translator/Interpreter',
  BRAIL: 'Brail',
  SIGN_LANGUAGE: 'Sign Language',
  LIP_READER: 'Lip Reader',
  NONE: 'None',
}

export type SpecialCommunicationNeedProps = {
  communicationNeed?: CommunicationNullable
}

export function SpecialCommunicationNeed({ communicationNeed }: SpecialCommunicationNeedProps): JSX.Element {
  return <>{(communicationNeed && formatSpecialCommunicationNeeds[communicationNeed]) ?? '-'}</>
}

function SpecialCommunicationNeeds({ specialCommunicationNeed }: SpecialCommunicationProps): JSX.Element {
  return (
    <>
      {specialCommunicationNeed.map((communicationNeed, index) => (
        <span key={communicationNeed}>
          <SpecialCommunicationNeed communicationNeed={communicationNeed} />
          {index + 1 < specialCommunicationNeed.length && ', '}
        </span>
      )) ?? '-'}
    </>
  )
}

export default SpecialCommunicationNeeds
