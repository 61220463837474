export type FullNameProps = {
  id?: number | string
  firstName?: string | null
  lastName?: string | null
  fullName?: string | null
}

function FullName({ firstName, lastName, fullName }: FullNameProps): JSX.Element {
  if (fullName != null) {
    return <>{fullName}</>
  }

  return (
    <>
      {firstName ?? ''} {lastName ?? ''}
    </>
  )
}

export default FullName
