import { useEffect, useRef, DependencyList } from 'react'

import GenericFunction from 'types/GenericFunction'

/**
 * Run effect once when predicate is truthy
 */
const useEffectOnceWhen = <EffectFn extends GenericFunction>(
  predicate: boolean,
  effect: EffectFn,
  dependencies: DependencyList = [],
): void => {
  const ranRef = useRef(false)

  useEffect(() => {
    if (ranRef.current) return undefined
    if (!predicate) return undefined

    ranRef.current = true
    return effect()
  }, dependencies)
}

export default useEffectOnceWhen
