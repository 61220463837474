import { memo, FC, useState } from 'react'

import { MagnifyingGlass } from 'phosphor-react'

import { Alert } from '@mui/material'
import Button from '@mui/material/Button'
import Collapse from '@mui/material/Collapse'
import InputAdornment from '@mui/material/InputAdornment'
import TextField from '@mui/material/TextField'
import makeStyles from '@mui/styles/makeStyles'
import Box from '@mui/system/Box'

import AppointmentStatusSelect from 'ui/Inputs/AppointmentStatusSelect'
import MarketsListSelect from 'ui/Inputs/MarketsListSelect'
import ServiceLineSelectMultiple from 'ui/Inputs/ServiceLineSelect/ServiceLineSelectMultiple'
import AppointmentFilterDateSelect from 'ui/MaterialUI/DatePicker/AppointmentFilterDateSelect'
import SpacedItems from 'ui/SpacedItems'

import ExportButton, { ExportButtonUpdateParams } from './components/ExportButton'
import useAppointmentFilterContext from './useAppointmentFiltersContext'

const useStyles = makeStyles(({ spacing }) => ({
  itemsInnerContainer: {
    flexWrap: 'wrap',
    display: 'flex',
    width: '100%',

    // item spacing
    '& > * + *': {
      marginLeft: spacing(2),
    },
  },
  resetButton: {
    whiteSpace: 'nowrap',
    // align with select margins
    flexGrow: 1,
    marginTop: spacing(2),
    marginBottom: spacing(1),
  },
}))

const AppointmentFilters: FC<unknown> = () => {
  const classes = useStyles()
  const [exportAlert, setExportAlert] = useState({
    text: '',
    severity: 'success',
    show: false,
  } as ExportButtonUpdateParams)
  const {
    query,
    setQuery,
    marketId,
    setMarketId,
    status,
    setStatus,
    serviceLines,
    setServiceLines,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    handleReset,
    isFiltered,
  } = useAppointmentFilterContext()

  return (
    <>
      <Collapse in={exportAlert.show}>
        <Alert severity={exportAlert.severity}>{exportAlert.text}</Alert>
      </Collapse>
      <Box p={3}>
        <TextField
          fullWidth
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <MagnifyingGlass size={22} />
              </InputAdornment>
            ),
          }}
          margin="dense"
          variant="outlined"
          placeholder="Search appointments by Patient, Address, Phone, or Responder"
          value={query}
          onChange={(event) => {
            setQuery(event.target.value)
          }}
        />

        <SpacedItems direction="row" style={{ alignItems: 'center' }}>
          <div className={classes.itemsInnerContainer}>
            <MarketsListSelect
              value={marketId}
              onChange={setMarketId}
              placeholder="All Markets"
              noneLabel="All"
              label="Filter by Markets"
              showNone={false}
              multiple
            />

            <AppointmentStatusSelect
              value={status}
              onChange={setStatus}
              i18nKey="components:~AppointmentFilters.AppointmentStatusSelect"
              showNone={false}
              multiple
            />

            <ServiceLineSelectMultiple
              value={serviceLines}
              onChange={setServiceLines}
              showNone={false}
              i18nKey="components:~AppointmentFilters.ServiceLineSelectMultiple"
            />

            <AppointmentFilterDateSelect
              id="appointment-filter-by-start-date"
              label="Filter by Start Date"
              value={startDate}
              onChange={setStartDate}
            />
            <AppointmentFilterDateSelect
              id="appointment-filter-by-end-date"
              label="Filter by End Date"
              value={endDate}
              onChange={setEndDate}
            />

            <Button color="secondary" className={classes.resetButton} onClick={handleReset} disabled={!isFiltered}>
              Reset
            </Button>

            <ExportButton handleUpdate={setExportAlert} />
          </div>
        </SpacedItems>
      </Box>
    </>
  )
}
export default memo(AppointmentFilters)
