import { createContext, useContext, FC } from 'react'

import useToggle from 'hooks/useToggle'

const AddPatientModeContext = createContext<ReturnType<typeof useToggle> | undefined>(undefined)

export const AddPatientModeContextProvider: FC<unknown> = ({ children }) => {
  const toggleState = useToggle(false)
  return <AddPatientModeContext.Provider value={toggleState}>{children}</AddPatientModeContext.Provider>
}

const useAddPatientModeContext = (): ReturnType<typeof useToggle> => {
  const context = useContext(AddPatientModeContext)

  if (context === undefined) {
    const error = new Error('useAddPatientModeContext must be used within a AddPatientModeContextProvider')
    if (Error.captureStackTrace) Error.captureStackTrace(error, useAddPatientModeContext)
    throw error
  }

  return context
}

export default useAddPatientModeContext
