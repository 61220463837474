import { FC } from 'react'

import Tokens from 'config/tokens'

import makeStyles from '@mui/styles/makeStyles'

const useStyles = makeStyles({
  '@global': {
    body: {
      fontFamily: Tokens.font.family,
      fontSize: parseFloat(Tokens.font.size.paragraph.base),
    },
    h1: {
      fontSize: parseFloat(Tokens.font.size.heading[1]),
      fontWeight: Tokens.font.weight.normal,
    },
    h2: {
      fontSize: parseFloat(Tokens.font.size.heading[1]),
      fontWeight: Tokens.font.weight.normal,
    },
  },
})

const AppCssBaseline: FC<unknown> = () => {
  useStyles()
  return null
}

export default AppCssBaseline
