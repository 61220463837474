import Tokens from 'config/tokens'
import styled from 'styled-components'

const StyledLayout = styled.div`
  font-family: ${Tokens.font.family};
  background-color: ${Tokens.color.ui.steel.base};

  h2 {
    color: ${Tokens.color.ui.charcoal.base};
  }

  & > .sidebar {
    --width: 244px;

    background-color: ${Tokens.color.ui.charcoal.base};
    bottom: 0;
    left: calc(-1 * var(--width));
    position: fixed;
    right: 100%;
    top: 0;
    transition-property: left right;
    transition-duration: ${Tokens.transition.duration[3]};
    transition-timing-function: ${Tokens.transition.timing.easeIn};
    width: var(--width);
    z-index: 10;

    &.visible {
      left: 0;
      right: calc(100% - var(--width));
      transition-timing-function: ${Tokens.transition.timing.easeOut};
    }
  }

  & > .overlay {
    background-color: ${Tokens.color.neutral.black.transparent[25]};
    bottom: 0;
    cursor: pointer;
    left: 0;
    opacity: 0.5;
    position: fixed;
    right: 0;
    top: 0;
    visibility: hidden;
    width: var(--width);
    z-index: 10;

    &.visible {
      visibility: visible;
    }
  }

  & > main {
    & > header {
      background-color: rgba(255, 255, 255, 0.3);
      color: ${Tokens.color.ui.charcoal.base};
      font-size: ${Tokens.font.size.paragraph.small};
      font-weight: ${Tokens.font.weight.semiBold};
      line-height: 1;
      padding: 12px 0;

      & > div {
        display: flex;
        align-items: center;

        & > * + * {
          margin-left: ${Tokens.rhythm};
        }
      }
    }
  }
`

export default StyledLayout
