import Tokens from 'config/tokens'
import { GenderPronounEnum, SexEnum, EncounterResponderStatusEnum } from 'generated/graphql'
import useIsCreateModeContext from 'modules/Appointments/useIsCreateModeContext'
import EncountersCancellation from 'modules/Patient/EncounterCancellation/EncountersCancellation'
import { Trans, useTranslation } from 'react-i18next'

import makeStyles from '@mui/styles/makeStyles'
import Box from '@mui/system/Box'

import PrimaryAccordion from 'ui/MaterialUI/PrimaryAccordion/PrimaryAccordion'
import { Tab, Tabs } from 'ui/Tabs'

import EncounterStatus from '../TextFormatters/EncounterStatus'

import PatientHeaderProfile from './PatientHeaderProfile'

const useStyles = makeStyles((theme) => ({
  tabsContainer: {
    background: 'white',
    padding: theme.spacing(0, 4),
    alignItems: 'center',
    borderBottom: `2px solid ${Tokens.color.neutral.black.transparent[10]}`,
    // allow child border to overlap with this container's border
    '&& > *': {
      marginBottom: -2,
    },

    // align "cancel encounters" button to the right
    display: 'flex',
    '&& > *:first-child': {
      flex: 1,
    },
  },
}))

export type PatientHeaderProps = {
  // Patient
  patientId: number
  firstName: string
  lastName: string
  genderPronoun: GenderPronounEnum
  dob: string
  sex?: SexEnum | null

  onChange: (id: string) => void
  expanded: boolean
  children: JSX.Element
  menu?: JSX.Element
  responderStatus?: EncounterResponderStatusEnum | null
}

const PatientHeader = ({
  patientId,
  firstName,
  lastName,
  sex,
  dob,
  genderPronoun,
  onChange,
  expanded,
  children,
  menu,
  responderStatus,
}: PatientHeaderProps): JSX.Element => {
  const { t } = useTranslation()
  const classes = useStyles()
  const isCreateMode = useIsCreateModeContext()

  return (
    <PrimaryAccordion
      expanded={expanded}
      id={firstName + lastName}
      onChange={onChange}
      menu={menu}
      title={
        <Box display="flex" justifyContent="space-between" alignItems="center" width="100%">
          <PatientHeaderProfile patient={{ firstName, lastName, sex, dob, genderPronoun }} />

          {!isCreateMode ? (
            <div>
              <Trans
                t={t}
                i18nKey={'components:ui.PatientHeader.encounterStatus'}
                components={{ encounterStatus: <EncounterStatus responderStatus={responderStatus} /> }}
              />
            </div>
          ) : null}
        </Box>
      }
    >
      <Box display="flex" flexDirection="column" width="100%">
        <div className={classes.tabsContainer}>
          <Tabs selectedIndex={0}>
            <Tab onClick={() => {}}>Patient Information</Tab>
            <div />
          </Tabs>
          <Box pb={2}>
            <EncountersCancellation patientId={patientId} />
          </Box>
        </div>
        {children}
      </Box>
    </PrimaryAccordion>
  )
}

export default PatientHeader
