import config, { Environment } from './config'

export const isDevelopment = process.env.REACT_APP_STAGE === 'development'
export const isStaging = process.env.REACT_APP_STAGE === 'staging'
export const isDevelopmentOrStaging = isDevelopment || isStaging
export const isLocal = () => config.environment === Environment.Local

/**
 * Update our confluence page as well:
 * https://readyresponders.atlassian.net/wiki/spaces/SKED/pages/1474625537/Feature+Flag+Status
 */

const featureFlags = {
  showEmblemCovidQuestions: true,
  enableZonesInMarketDetails: isLocal(),
  showExistingInsuranceSelection: isLocal(),
  editInsuranceInAppointmentViewMode: isLocal(),
  enableZonesEditZipCodes: false,
  showUpdatedCancellationReasons: true,
  hideUpdatedRescheduleReasons: isLocal(),
  showAddPatientInAppointmentDetails: isLocal(),
  editCancellationInOverview: true,
  editRescheduleReasonInOverview: true,
  showDispatchesSectionInAppointment: true,
  showRescheduledFromLinkInOverview: true,
  showRescheduledToLinkInOverview: true,
  removeCommunityCareModal: true,
  showZipCodeChecker: true,

  /**
   *  Attribution feature flag
   *  1. showAttributionController:
   *      - show attribution as link and as URL
   */
  showAttributionController: false,
}

export type FeatureFlags = typeof featureFlags
export type FeatureFlagsEnum = keyof FeatureFlags

export default featureFlags
