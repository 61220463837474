import * as Yup from 'yup'

export type AppointmentPartnershipCardEditFormSchema = {
  encounters?:
    | {
        encounterId: number
        patientId: number
      }[]
    | null
  patients?:
    | {
        firstName: string | null
        lastName: string | null
        patientId: number
        channelAttributionId: number | null
      }[]
    | null
  marketId?: number | null
}

export const appointmentPartnershipCardEditFormSchema = Yup.object().shape({
  patients: Yup.array().of(
    Yup.object().shape({
      patientId: Yup.number().required(),
      channelAttributionId: Yup.number().required(),
    }),
  ),
  encounters: Yup.array().of(
    Yup.object().shape({
      patientId: Yup.number().required(),
      encounterId: Yup.number().required(),
    }),
  ),
})
