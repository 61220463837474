import { FC, useCallback } from 'react'

import isEmpty from 'lodash/isEmpty'
import { DateTime } from 'luxon'

import Box from '@mui/material/Box'
import TextField from '@mui/material/TextField'

export type DatePickerProps = {
  value: DateTime | null
  onChange: (datetime: DateTime | null) => void
  id: string
  label: string
}

const AppointmentFilterDateSelect: FC<DatePickerProps> = ({ value, onChange, id, label }) => {
  const handleChange = useCallback(
    (event) => {
      const targetValue = event.target.value
      if (isEmpty(targetValue)) {
        return
      }
      onChange(DateTime.fromISO(targetValue))
    },
    [onChange],
  )

  return (
    <Box minWidth={160}>
      <TextField
        fullWidth
        id={id}
        label={label}
        value={value?.toFormat('yyyy-MM-dd') ?? ''}
        onChange={handleChange}
        type="date"
      />
    </Box>
  )
}

export default AppointmentFilterDateSelect
