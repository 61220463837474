import { RaceEnum } from 'generated/graphql'

export type RaceNullable = RaceEnum | null

export type RacesProps = {
  races: RaceNullable[]
}

export const formatRace = {
  AMERICAN_INDIAN: 'American Indian',
  NATIVE_ALASKAN: 'Native Alaskan',
  ASIAN: 'Asian',
  AFRICAN_AMERICAN: 'African American',
  AFRICAN: 'African',
  HISPANIC: 'Hispanic',
  LATINO: 'Latino',
  PACIFIC_ISLANDER: 'Pacific Islander',
  NATIVE_HAWAIIAN: 'Native Hawaiian',
  CAUCASIAN: 'Caucasian',
  OTHER: 'Other',
  UNKNOWN: 'Unknown',
  DECLINED: 'Declined',
}

export type RaceProps = {
  race?: RaceNullable
}

export function Race({ race }: RaceProps): JSX.Element {
  return <>{(race && (formatRace[race] ?? formatRace.UNKNOWN)) ?? '-'}</>
}

function Races({ races }: RacesProps): JSX.Element {
  return (
    <>
      {races.map((race, index) => (
        <span key={race}>
          <Race race={race} />
          {index + 1 < races.length && ', '}
        </span>
      )) ?? '-'}
    </>
  )
}

export default Races
