import { SexEnum } from 'generated/graphql'
import SexEnumWithoutOtherType from 'types/SexEnumWithoutOtherType'

import MenuItem from '@mui/material/MenuItem'
import TextField, { OutlinedTextFieldProps } from '@mui/material/TextField'

import Sex from 'ui/TextFormatters/Sex/Sex'

export type SexSelectProps = Partial<Omit<OutlinedTextFieldProps, 'onChange'>> & {
  value?: SexEnum | ''
  label?: string
  required?: boolean
  onChange: (newSex: SexEnum) => void
}

const { Other, ...SexEnumWithoutOther } = SexEnum

const SexSelect = ({ onChange, label, required = true, ...props }: SexSelectProps): JSX.Element => {
  const handleOnChange = (e: React.ChangeEvent<{ value: unknown }>) => {
    const newSex = (e.target.value as SexEnum) ?? ''
    onChange(newSex)
  }

  return (
    <TextField
      fullWidth
      name="sex"
      {...props}
      select
      label={label ?? 'Sex at Birth'}
      onChange={handleOnChange}
      required={required}
    >
      {Object.values(SexEnumWithoutOther).map((option: SexEnumWithoutOtherType) => (
        <MenuItem key={option} value={option}>
          <Sex sex={option} />
        </MenuItem>
      ))}
    </TextField>
  )
}

export default SexSelect
