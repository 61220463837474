import { useMemo, FC, memo } from 'react'

import Tokens from 'config/tokens'
import { useGetMarketsQuery } from 'generated/graphql'
import { isEmpty } from 'lodash'

import MenuItem from '@mui/material/MenuItem'
import TextField, { OutlinedTextFieldProps } from '@mui/material/TextField'
import makeStyles from '@mui/styles/makeStyles'

import Spinner from 'ui/Spinner'

import { selectMarketNameById, selectMarketsList, selectActiveMarketsList, assertMarketHasMarketId } from './selectors'

// TODO: migrate to i18nKey, see AppointmentStatusSelect for reference

const useStyles = makeStyles((theme) => ({
  container: {
    position: 'relative',
    minWidth: 200,
    maxWidth: 400,
  },
  inputLoading: {
    pointerEvents: 'none',
    position: 'absolute',
    top: 20,
    right: 32,
  },
  optionLoading: {
    pointerEvents: 'none',

    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',

    padding: '32px 16px',
    backgroundColor: Tokens.color.ui.steel.base,

    '& > p': {
      ...theme.typography.body1,
      color: Tokens.color.neutral.grey[102],
    },
  },
}))

const VALUE_NONE = 'none'
const DEFAULT_PLACEHOLDER_NONE = 'Select Market'
const DEFAULT_OPTION_NONE = 'None'

// https://github.com/microsoft/TypeScript/issues/30581
type Multiple = true | false
type MultipleValue<T extends Multiple> = T extends true ? number[] : T extends false ? number : never
type MultipleValueOnChange<T extends Multiple> = (newValue: MultipleValue<T> | null) => void
type MultipleValueRecord<T extends Multiple> = {
  /**
   * Specify whether it is a single select or multi select dropdown.
   * @required
   */
  multiple: T
  value?: MultipleValue<T> | null
  onChange: MultipleValueOnChange<T>
}

export type MarketsListSelectProps = Partial<Omit<OutlinedTextFieldProps, 'onChange' | 'value'>> & {
  showNone?: boolean
  label?: string
  fullWidth?: boolean

  /**
   * Although this select has an internal loading state,
   * this prop triggers the small loading spinner.
   * This is useful when an external state is controlling the value and has its own loading state
   * If the external state will trigger a refetch of markets, it's best to use this prop in unison with `disabled` prop
   */
  loading?: boolean

  /**
   * filters markets by isActive prop
   */
  showIsActive?: boolean

  /**
   * The select has a selectable default option (VALUE_NONE)
   *
   * When selected, it will display the `placeholder` in the select input (not-open state)
   * Within the dropdown it will display the `noneLabel` in the option (open state)
   */
  placeholder?: string | null
  noneLabel?: string | null
} & (MultipleValueRecord<true> | MultipleValueRecord<false>)

const MarketsListSelect: FC<MarketsListSelectProps> = ({
  value,
  onChange,
  placeholder,
  noneLabel,
  multiple,
  fullWidth,
  showNone = false,
  showIsActive = false,
  loading: externalLoading = false,
  ...textFieldProps
}) => {
  const classes = useStyles({ fullWidth })
  function handleOnChange(event: { target: { value: number[] } }): void
  function handleOnChange(event: { target: { value: number | string } }): void
  function handleOnChange(event: { target: { value: number[] | number | string } }): void {
    const {
      target: { value: optionValue },
    } = event
    const onChangeAs = onChange as MultipleValueOnChange<typeof multiple>

    if (Array.isArray(optionValue)) {
      const multipleOptionValue = optionValue as MultipleValue<true>
      onChangeAs(multipleOptionValue.length ? multipleOptionValue : null)
      return
    }
    onChangeAs(optionValue === VALUE_NONE ? null : Number(optionValue))
  }

  const { data, loading } = useGetMarketsQuery()
  const markets = showIsActive ? selectActiveMarketsList(data) : selectMarketsList(data)

  const existingValue = useMemo(() => {
    if (!(value && markets)) return VALUE_NONE
    if (!markets.length) return VALUE_NONE
    if (multiple && Array.isArray(value) && !value.length) return VALUE_NONE
    return (assertMarketHasMarketId(markets, value) && value) || VALUE_NONE
  }, [value, markets])

  return (
    <div className={classes.container}>
      <TextField
        fullWidth
        name="marketsList"
        label="Markets List"
        value={multiple && existingValue === VALUE_NONE ? [] : existingValue}
        {...textFieldProps}
        select
        onChange={handleOnChange}
        SelectProps={{
          multiple,
          ...(multiple && { displayEmpty: true }),
          renderValue: (optionValue) => {
            if (Array.isArray(optionValue) && isEmpty(optionValue)) {
              return placeholder ?? DEFAULT_PLACEHOLDER_NONE
            }
            if (Array.isArray(optionValue)) {
              return optionValue.map(selectMarketNameById(data)).join(', ')
            }
            return optionValue === VALUE_NONE
              ? placeholder ?? DEFAULT_PLACEHOLDER_NONE
              : selectMarketNameById(data)(optionValue as number) ?? (optionValue as number)
          },
        }}
      >
        <MenuItem value={VALUE_NONE} disabled={multiple} {...(!showNone && { style: { display: 'none' } })}>
          {noneLabel ?? DEFAULT_OPTION_NONE}
        </MenuItem>

        {loading && (
          <li className={classes.optionLoading}>
            <Spinner size="large" foregroundColor={Tokens.color.ui.blue.base} />
            <p>Fetching Available Markets</p>
          </li>
        )}
        {!(loading || markets?.length) ? (
          <MenuItem disabled>There are no Markets available</MenuItem>
        ) : (
          markets?.map(({ marketId, name }) => (
            <MenuItem key={marketId} value={marketId}>
              {name}
            </MenuItem>
          ))
        )}
      </TextField>

      {(loading || externalLoading) && (
        <div className={classes.inputLoading}>
          <Spinner size="small" foregroundColor={Tokens.color.ui.blue.base} />
        </div>
      )}
    </div>
  )
}

export default memo(MarketsListSelect)
