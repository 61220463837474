import { E164_PHONE_NUMBER_REGEXP } from 'utils/phone/regexp'
import * as Yup from 'yup'

import { firstNameRequired, lastNameRequired, dobSchemaRequired, genderSchemaRequired } from '../demographics/items'
import makeRequired from '../helpers/makeRequired'

export const preferredPhoneNumberSchema = Yup.string().matches(
  E164_PHONE_NUMBER_REGEXP,
  'validation:patient.preferredPhoneNumber.match',
)

export const preferredPhoneNumberRequired = makeRequired(
  preferredPhoneNumberSchema,
  'validation:patient.preferredPhoneNumber.required',
)

export const createPatient = Yup.object().shape({
  firstName: firstNameRequired,
  lastName: lastNameRequired,
  dob: dobSchemaRequired,
  sex: genderSchemaRequired,
  preferredPhoneNumber: preferredPhoneNumberRequired,
})
