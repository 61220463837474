import { Reducer, useCallback, useMemo, useReducer } from 'react'

import { GetAppointmentAvailabilitiesQuery } from 'generated/graphql'
import { findKey } from 'lodash'
import { DateTime } from 'luxon'
import { TFunction, useTranslation } from 'react-i18next'

const TUPLES_TIMESLOT_AVAILABILITY_START_END = [
  [8, 10],
  [10, 12],
  [12, 14],
  [14, 16],
  [16, 18],
  [18, 20],
]

const MINUTE_CURRENT_DATE_SAFE_MARGIN = 5
const getClientDateNowWithMargin = () => DateTime.now().plus({ minutes: MINUTE_CURRENT_DATE_SAFE_MARGIN })

type Timeslot = {
  start: number
  end: number
  respondersAvailable: number
  startTimes: string[]
  label: string
  isPast: boolean
}

type InitAvailabilityTimeslotsType = (datetime: DateTime, t: TFunction<'translation'>) => Record<string, Timeslot>

export const initAvailabilityTimeslots: InitAvailabilityTimeslotsType = (datetime, t) => {
  // TODO: we shouldn't rely on client time
  // add another 30 minutes so that a timeslot is in the past when it has no 30 min slot available
  const currentDate = getClientDateNowWithMargin().plus({ minutes: 30 })

  return Object.assign(
    {},
    ...TUPLES_TIMESLOT_AVAILABILITY_START_END.map(([start, end]) => {
      const dateStart = datetime.set({ hour: start })
      const dateEnd = datetime.set({ hour: end })
      return {
        [dateStart.toISO()]: {
          start,
          end,
          respondersAvailable: 0,
          startTimes: [],
          label: t(`common:time_${start}00-${end}00`),
          isPast: dateEnd < currentDate,
        },
      }
    }),
  )
}

type TimeslotAction =
  | {
      type: 'UPDATE'
      data: GetAppointmentAvailabilitiesQuery
      timeZone: string
      forceScheduling?: boolean
    }
  | {
      type: 'RESET'
      datetime: DateTime
      t: TFunction<'translation'>
    }

export const timeslotReducer: Reducer<Record<string, Timeslot>, TimeslotAction> = (state, action) => {
  switch (action.type) {
    case 'UPDATE': {
      // TODO: we shouldn't rely on client time
      const currentDate = getClientDateNowWithMargin()

      // we push `startTimes` below, start with an empty `startTimes`
      const nextState = Object.assign(
        {},
        ...Object.entries(state).map(([key, slotState]) => ({ [key]: { ...slotState, startTimes: [] } })),
      )

      return (action.data?.getAppointmentAvailabilities?.appointmentSlots ?? []).reduce(
        (result, slot) => {
          const start = slot?.start
          if (!start) return result

          const dtStart = DateTime.fromISO(start ?? '', { zone: action.timeZone })

          // If slot is in the past, we skip it
          if (dtStart < currentDate) {
            return result
          }
          // If the slot is 8 AM. We skip keeping it, we don't want to book exactly at 8 AM
          if (Number(dtStart.toFormat('h')) === 8 && dtStart.minute === 0) {
            return result
          }

          // if no availability for slot and availabilityOverride is disabled, we skip
          if (!action.forceScheduling && (slot?.respondersAvailable ?? 0) === 0) {
            return result
          }

          const bestSlot = findKey(state, (value) => value?.start <= dtStart.hour && dtStart.hour < value?.end) ?? ''
          if (bestSlot) {
            // eslint-disable-next-line no-param-reassign
            result[bestSlot].respondersAvailable += slot?.respondersAvailable ?? 0
            result[bestSlot].startTimes.push(start)
          }

          return result
        },
        { ...nextState },
      )
    }
    case 'RESET': {
      return initAvailabilityTimeslots(action.datetime, action.t)
    }
    default:
      return state
  }
}

const useAvailabilityTimeslots = (
  datetime: DateTime,
): {
  availabilityData: Record<string, Timeslot>
  resetAvailability: () => void
  dispatchAvailabilityData: (arg0: TimeslotAction) => void
} => {
  const { t } = useTranslation()
  const [availabilityData, dispatchAvailabilityData] = useReducer(
    timeslotReducer,
    initAvailabilityTimeslots(datetime, t),
  )
  const resetAvailability = useCallback(() => {
    dispatchAvailabilityData({ type: 'RESET', datetime, t })
  }, [datetime, t, dispatchAvailabilityData])

  return useMemo(
    () => ({
      availabilityData,
      resetAvailability,
      dispatchAvailabilityData,
    }),
    [availabilityData, resetAvailability, dispatchAvailabilityData],
  )
}

export default useAvailabilityTimeslots
