import { GenderIdentityEnum } from 'generated/graphql'

import MenuItem from '@mui/material/MenuItem'
import TextField, { OutlinedTextFieldProps } from '@mui/material/TextField'

import GenderIdentity from 'ui/TextFormatters/GenderIdentity/GenderIdentity'

type GenderIdentityEnumNullable = GenderIdentityEnum | ''

export type GenderIdentitySelectProps = Partial<Omit<OutlinedTextFieldProps, 'onChange'>> & {
  value?: GenderIdentityEnumNullable
  onChange: (newGenderIdentity: GenderIdentityEnumNullable) => void
}

const GenderIdentitySelect = ({ onChange, value, ...props }: GenderIdentitySelectProps): JSX.Element => {
  const handleOnChange = (e: React.ChangeEvent<{ value: unknown }>) => {
    const newGenderIdentity = (e.target.value as GenderIdentityEnumNullable) ?? ''
    onChange(newGenderIdentity)
  }

  return (
    <TextField
      fullWidth
      name="genderIdentity"
      {...props}
      value={value ?? ''}
      select
      label="Gender Identity"
      onChange={handleOnChange}
    >
      {Object.values(GenderIdentityEnum).map((option) => (
        <MenuItem key={option} value={option}>
          <GenderIdentity genderIdentity={option} />
        </MenuItem>
      ))}
    </TextField>
  )
}

export default GenderIdentitySelect
