import { FC, useMemo } from 'react'

import { Patient } from 'generated/graphql'
import useToggle from 'hooks/useToggle'
import PatientCreateModal from 'modules/Patient/PatientCreateModal'
import { findExistingIndexElementEqualsPatientId } from 'modules/Patient/selectors'
import { useTranslation } from 'react-i18next'
import { useMountedState } from 'react-use'

import Button from '@mui/material/Button'
import Box from '@mui/system/Box'

import PatientsAutocomplete from 'ui/PatientsAutocomplete'

import { PatientLookupType } from './AppointmentCreateFormSchema'

type PatientLookupProps = {
  patients: Array<PatientLookupType> | null
  onPatientSelected: (selectedPatient: PatientLookupType) => void | Promise<void>
}

const PatientLookup: FC<PatientLookupProps> = ({ patients, onPatientSelected }) => {
  const { t } = useTranslation()
  const isMounted = useMountedState()
  const [showModal, , setShowModal, unsetShowModal] = useToggle(false)

  const patientIds = useMemo(() => (patients == null ? null : patients.map(({ patientId }) => patientId)), [patients])

  const handlePatientSelected = async (selectedPatient: Partial<Patient> | null) => {
    if (!selectedPatient || selectedPatient.patientId == null) return

    const isDuplicatePatient = findExistingIndexElementEqualsPatientId(patients, selectedPatient.patientId) != null

    if (isDuplicatePatient) return

    // we have a safeguard and know patientId is not undefined, typescript still struggles
    await onPatientSelected(selectedPatient as PatientLookupType)

    if (!isMounted) return

    unsetShowModal()
  }

  return (
    <>
      <PatientsAutocomplete
        label={t('components:modules.Patient.addExistingPatient')}
        onPatientSelected={handlePatientSelected}
        existingPatientIds={patientIds}
      />

      <Box>
        <Button onClick={setShowModal} variant="outlined">
          {t('components:modules.Appointments.Create.PatientLookup.CreateNewPatient.label')}
        </Button>
      </Box>

      <PatientCreateModal open={showModal} onClose={unsetShowModal} onCreatePatient={handlePatientSelected} />
    </>
  )
}

export default PatientLookup
