import TextField, { OutlinedTextFieldProps } from '@mui/material/TextField'

export type PreferredFirstNameInputProps = Partial<OutlinedTextFieldProps>

const PreferredFirstNameInput = ({ value, ...props }: PreferredFirstNameInputProps): JSX.Element => (
  <TextField
    id="preferredName"
    name="preferredName"
    fullWidth
    {...props} // Options above this line can be overwritten
    label="Preferred First Name (Optional)"
    value={value ?? ''}
  />
)

export default PreferredFirstNameInput
