import React from 'react'

import Dialog, { DialogProps } from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'

export type ModalProps = {
  title?: React.ReactNode
  children: React.ReactNode
  actions?: React.ReactNode
} & DialogProps

const Modal = ({ title, children, actions, ...props }: ModalProps): JSX.Element => (
  <Dialog {...props} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
    {title && (
      <DialogTitle style={{ paddingBottom: 4 }} id="alert-dialog-title">
        {title}
      </DialogTitle>
    )}
    <DialogContent>
      <DialogContentText component="div">{children}</DialogContentText>
    </DialogContent>
    {actions && <DialogActions style={{ flexWrap: 'wrap', padding: '16px 24px' }}>{actions}</DialogActions>}
  </Dialog>
)

export default Modal
