import NumberFormat, { NumberFormatProps } from 'react-number-format'
import { formatUSPhoneNumberToE164 } from 'utils/phone/format'

import { TextField } from '@mui/material'

type PhoneNumberInputProps = Omit<NumberFormatProps, 'onChange' | 'value'> & {
  onChange: (newValue: string) => void
  value: string
}

const PhoneNumberInput = ({ onChange, value, ...props }: PhoneNumberInputProps): JSX.Element => (
  <NumberFormat
    fullWidth={true}
    {...props} // Options above this line can be overwritten
    customInput={TextField}
    format="(###) ###-####"
    mask="_"
    label="Preferred Phone Number"
    // we propagate the non-formatted value, e.g "1234567890"
    // as we store it in the form, that is then sent to RCO
    // if we have a use-case where we need to propagate the formattedValue,
    // we should probably add a prop `formatOnChangeValue`
    // onValueChange={({ formattedValue, value }) => onChange(formatOnChangeValue ? formattedValue : value)}
    onValueChange={({ value: unformattedValue }) => onChange(`+1${unformattedValue}`)}
    // make sure we don't have any incorrectly formatted db values, e.g. "(123) 456-7890"
    // also, remove +1, as we're adding it in the onChange, this part is necessary until we add country codes
    value={formatUSPhoneNumberToE164(value).replace(/^\+1/, '')}
  />
)

export default PhoneNumberInput
