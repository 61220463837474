import React from 'react'

import Box from '@mui/system/Box'

import { TabsProps } from './types'

export const InvalidSelectedIndexError = Error

const Tabs = ({ children, selectedIndex = 0 }: TabsProps): JSX.Element => {
  if (selectedIndex > children.length - 1 || selectedIndex < 0) {
    throw InvalidSelectedIndexError('selectedIndex is invalid')
  }
  const [controlledIndex, setControlledIndex] = React.useState(selectedIndex)
  const handleTabClick = (index: number, onClick: () => void) => {
    setControlledIndex(index)
    onClick()
  }
  return (
    <Box
      component="ul"
      display="flex"
      m={0}
      p={0}
      sx={{
        listStyleType: 'none',
      }}
    >
      {React.Children.map(children, (child, idx) =>
        React.cloneElement(child, {
          selected: controlledIndex === idx,
          onClick: () => handleTabClick(idx, child.props.onClick),
        }),
      )}
    </Box>
  )
}

export default Tabs
