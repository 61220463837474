import template from 'lodash/template'

const createVisitText = template(`
    <% if (appointment.friendlyScheduledFor) { %>
      <strong>Appointment Date: </strong> <%= appointment.friendlyScheduledFor %><br />
    <% } %>

    <% if (appointment.primaryPhone) { %>
      <strong>Phone #: </strong> <%= appointment.primaryPhone %><br />
    <% } %>
    <% if (appointment.address1) { %>
      <strong>Full Address: </strong> <%= appointment.address1 %><br />
    <% } %>
    <% if (appointment.city && appointment.state) { %>
      <strong>City and State: </strong> <%= appointment.city %>, <%= appointment.state %><br />
    <% } %>
    <% if (appointment.zip) { %>
      <strong>Zip: </strong> <%= appointment.zip %><br />
    <% } %>
    <% if (firstPatient.gateCode) { %>
    <strong>Gate code/Apt #: </strong> <%= firstPatient.gateCode %><br />
    <% } %>
    <% if (firstPatient.unit) { %>
    <strong>Unit: </strong> <%= firstPatient.unit %><br />
    <% } %>

    <% if (appointment.specialInstructions) { %>
      <strong>Special Notes: </strong> <%= appointment.specialInstructions %><br />
    <% } %>

    <% appointment.patients.forEach(function(patient) { %>
      <hr />

      <% if (patient.firstName || patient.lastName) { %>
        <h2><%= patient.firstName %> <%= patient.lastName %></h2>
      <% } %>
      <% if (patient.appointmentDate) { %>
        <strong>Appointment Date: </strong> <%= patient.appointmentDate %><br />
      <% } %>
      <% if (patient.dob) { %>
        <strong>Date of Birth: </strong> <%= patient.dob %><br />
      <% } %>
      <% if (patient.sex) { %>
        <strong>Gender: </strong> <%= patient.sex %><br />
       <% } %>

       <% patient.encounters.forEach(function(encounter) { %>

          <% if (encounter.reasonForEncounter) { %>
           <strong>Reason for Encounter: </strong> <%= encounter.reasonForEncounter %><br />
          <% } %>
          <% if (encounter.genderRequirement) { %>
            <strong>Gender Requirement: </strong> <%= encounter.genderRequirement %><br />
          <% } %>
          <% if (encounter.serviceLine.friendlyName) { %>
            <strong>Service Line: </strong> <%= encounter.serviceLine.friendlyName %><br />
          <% } %>

          <% if (encounter.primaryInsuranceRecord) { %>
            <br />
            <h3>Primary Insurance</h3>
            <% if (encounter.primaryInsuranceRecord.company) { %>
              <strong>Company: </strong> <%= encounter.primaryInsuranceRecord.company %><br />
            <% } %>
            <% if (encounter.primaryInsuranceRecord.groupNumber) { %>
              <strong>Group Number: </strong> <%= encounter.primaryInsuranceRecord.groupNumber %><br />
            <% } %>
            <% if (encounter.primaryInsuranceRecord.memberId) { %>
              <strong>Member ID: </strong> <%= encounter.primaryInsuranceRecord.memberId %><br />
            <% } %>
          <% } %>

          <% if (encounter.secondaryInsuranceRecord) { %>
            <br />
            <h3>Secondary Insurance</h3>
            <% if (encounter.secondaryInsuranceRecord.company) { %>
              <strong>Company: </strong> <%= encounter.secondaryInsuranceRecord.company %><br />
            <% } %>
            <% if (encounter.secondaryInsuranceRecord.groupNumber) { %>
              <strong>Group Number: </strong> <%= encounter.secondaryInsuranceRecord.groupNumber %><br />
            <% } %>
            <% if (encounter.secondaryInsuranceRecord.memberId) { %>
              <strong>Member ID: </strong> <%= encounter.secondaryInsuranceRecord.memberId %><br />
            <% } %>
          <% } %>


        <% }); %>

        <br />
    <% }); %>
  <br />
  `)

export default createVisitText
