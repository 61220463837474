import { DateTime } from 'luxon'
import { DateToYMD } from 'utils/dates'

import DatePicker, { DatePickerProps } from './DatePicker'

export type BirthdaySelectProps = Omit<DatePickerProps, 'value'> & {
  value: string
  label?: string | null
  required?: boolean
}

const BirthdaySelect = ({ value, label, required = true, ...props }: BirthdaySelectProps): JSX.Element => {
  const currentValue = DateTime.fromISO(value)

  return (
    <DatePicker
      name="dob"
      {...props}
      value={currentValue}
      required={required}
      label={label ?? 'Date of Birth'}
      InputProps={{
        inputProps: {
          max: DateToYMD(new Date()),
        },
      }}
    />
  )
}

export default BirthdaySelect
