/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import sanitizePhoneNumber from 'utils/phone/sanitizePhoneNumber'
import ZenDeskClient from 'utils/ZenDeskClient'

import callerInfo from './callerInfo'
import makeZendeskLambdaRequest from './makeZendeskLambdaRequest'

type TransformPatientParams = { firstName: string; lastName: string; dob: string; sex: string }

const transformPatient = ({ firstName, lastName, dob, sex }: TransformPatientParams) => ({
  firstName,
  lastName,
  dob,
  gender: String(sex).toLocaleLowerCase(),
})

// @ts-ignore
const getData = async (appointment) => {
  const firstPatient = appointment.patientsByEncounterAppointmentIdAndPatientIdList[0]
  const phoneNumber = sanitizePhoneNumber(appointment.primaryPhone)
  const callInNumber = await callerInfo()

  const payload = {
    market: appointment.market.name,
    marketId: String(appointment.marketId),
    timeZone: appointment.market.timezone,
    streetAddress: appointment.address1,
    unitNum: appointment.unit,
    gateCode: '',
    city: appointment.city,
    state: appointment.state,
    zipCode: String(appointment.zip),
    latitude: appointment.addressLat,
    longitude: appointment.addressLng,
    ...transformPatient(firstPatient),

    // multi-patient array, minus first patients
    patients: appointment.patientsByEncounterAppointmentIdAndPatientIdList.slice(1).map(transformPatient),

    phoneNum: phoneNumber,
    callInNum: sanitizePhoneNumber(callInNumber),
    chiefComplaint: appointment.encountersList[0].reasonForEncounter,
    visitDetails: 'house',
    scheduledFor: appointment.scheduledFor,
  }
  return payload
}

// @ts-ignore
const writeRedshift = async (appointment) => {
  if (!ZenDeskClient) return ''

  try {
    const payload = await getData(appointment)
    const data = JSON.stringify(payload)

    const response = await makeZendeskLambdaRequest({
      url: `${process.env.REACT_APP_READY_ZENDESK_LAMBDA_BASE_URL}/writeRedshift`,
      method: 'POST',
      type: 'POST',
      data,
    })

    return response
  } catch (err) {
    return `${err} - There was an error checking the caller information`
  }
}

export default writeRedshift
