import * as Sentry from '@sentry/react'
import Config, { Environment, LogLevel } from 'config/config'

const isLocal = () => Config.environment === Environment.Local

const logger = {
  /**
   * Logs a message with debug level severity
   * @param message the debug message
   */
  debug: (message: string): void => {
    if (LogLevel.debug >= Config.logLevel) {
      if (isLocal()) {
        console.log(message) // eslint-disable-line no-console
        return
      }
      Sentry.captureMessage(message, { level: Sentry.Severity.Debug })
    }
  },
  /**
   * Logs a message with info level severity
   * @param message the info message
   */
  info: (message: string): void => {
    if (LogLevel.info >= Config.logLevel) {
      if (isLocal()) {
        console.log(message) // eslint-disable-line no-console
        return
      }
      Sentry.captureMessage(message, { level: Sentry.Severity.Info })
    }
  },
  /**
   * Logs a message with warning level severity
   * @param message the warning message
   */
  warn: (message: string): void => {
    if (LogLevel.warn >= Config.logLevel) {
      if (isLocal()) {
        console.warn(message) // eslint-disable-line no-console
        return
      }
      Sentry.captureMessage(message, { level: Sentry.Severity.Warning })
    }
  },
  /**
   * Logs a message or exception with error level severity
   * @param message the debug message or exception
   */
  error: (error: string | Error): void => {
    if (isLocal()) {
      console.error(error) // eslint-disable-line no-console
      return
    }
    if (typeof error === 'string') {
      Sentry.captureMessage(error, { level: Sentry.Severity.Error })
      return
    }
    Sentry.captureException(error)
  },
}

export default logger
