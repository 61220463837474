import { SexEnum, GenderIdentityEnum, GenderPronounEnum, RaceEnum } from 'generated/graphql'
import * as Yup from 'yup'
import makeNullable from 'yupSchemas/helpers/makeNullable'

import makeRequired from '../helpers/makeRequired'

// import { E164_PHONE_NUMBER_REGEXP } from '../../utils/phonenumber-utils';

export const genderSchemaBase = Yup.string().oneOf(Object.values(SexEnum), 'Gender must be a valid selection')
export const genderSchemaRequired = makeRequired(genderSchemaBase, 'Gender is required')

export const genderIdentitySchemaBase = Yup.string().oneOf(
  Object.values(GenderIdentityEnum),
  'Gender Identity must be a valid selection',
)
export const genderIdentitySchemaNullable = makeNullable(genderIdentitySchemaBase)
export const genderIdentitySchemaRequired = makeRequired(genderIdentitySchemaBase, 'Gender Identity is required')

export const genderPronounSchemaBase = Yup.string().oneOf(
  Object.values(GenderPronounEnum),
  'Gender Pronoun must be a valid selection',
)
export const genderPronounSchemaNullable = makeNullable(genderPronounSchemaBase)
export const genderPronounSchemaRequired = makeRequired(genderPronounSchemaBase, 'Gender Pronoun is required')

export const racesSchemaBase = Yup.array().of(
  Yup.string().oneOf(Object.values(RaceEnum), 'Races must be a valid selection'),
)
export const racesSchemaNullable = makeNullable(racesSchemaBase)

export const dobSchemaBase = Yup.date()
  .min(new Date('01/01/1900').toLocaleDateString(), 'Please enter a valid date of birth')
  .max(new Date().toLocaleDateString(), 'Please enter a valid date of birth')
export const dobSchemaRequired = makeRequired(dobSchemaBase, 'Birthdate is required')

export const firstNameBase = Yup.string()
export const firstNameRequired = makeRequired(firstNameBase, 'First name is required')

export const lastNameBase = Yup.string()
export const lastNameRequired = makeRequired(lastNameBase, 'Last name is required')

export const preferredNameBase = makeNullable(Yup.string())

// export const phoneNumberSchemaBase = Yup.string().matches(
//   E164_PHONE_NUMBER_REGEXP,
//   'Must be a valid phone number, e.g. (555) 867-5309',
// );
// export const phoneNumberSchemaRequired = makeRequired(
//   phoneNumberSchemaBase,
//   'Phone # is required',
// );
