import { MouseEventHandler, MouseEvent, useCallback, useState } from 'react'

import Tokens from 'config/tokens'
import { useUpdatePatientNoteMutation } from 'generated/graphql'
import { DateTime } from 'luxon'

import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import { IconButton, Menu, MenuItem, Paper } from '@mui/material'
import { Theme } from '@mui/material/styles'
import makeStyles from '@mui/styles/makeStyles'
import Box from '@mui/system/Box'

import FormattedDate from 'ui/TextFormatters/FormattedDate'

import NoteLabel from './NoteLabel'
import NotePreview from './NotePreview'
import NotePriorityDisplay from './NotePriorityDisplay'
import { NoteProps } from './types'

const useStyles = makeStyles(({ spacing }: Theme) => ({
  title: {
    flex: '1 1 auto',
    fontWeight: Tokens.font.weight.semiBold,
  },
  labels: {
    flex: '1 1 auto',
    display: 'flex',
    flexWrap: 'wrap',
    '& > *': {
      marginBottom: spacing(1),
      marginRight: spacing(1),
    },
  },
}))

const useMenuToggle = () => {
  const [menuAnchor, setMenuAnchor] = useState<Element | null>(null)
  const showMenu = useCallback((event: MouseEvent) => setMenuAnchor(event.currentTarget), [setMenuAnchor])
  const hideMenu = useCallback(() => setMenuAnchor(null), [setMenuAnchor])

  return { isMenuOpen: Boolean(menuAnchor), menuAnchor, showMenu, hideMenu }
}

const Note = ({
  patientNoteId,
  date,
  note,
  label,
  friendlyLabel,
  author,
  isDeletable = false,
  isPriority = false,
}: Readonly<NoteProps>): JSX.Element => {
  const classes = useStyles({ isPriority })
  const { isMenuOpen, menuAnchor, showMenu, hideMenu } = useMenuToggle()
  const [updatePatientNote] = useUpdatePatientNoteMutation()
  const deletePatientNote = useCallback(() => {
    hideMenu()

    if (!patientNoteId) return

    updatePatientNote({
      variables: { patientNotePatch: { isDeleted: true, note }, patientNoteId },
      refetchQueries: ['GetPatientNotes'],
    })
  }, [patientNoteId, updatePatientNote])

  return (
    <>
      <Paper elevation={1}>
        <Box maxWidth={1} p={4} pr={3}>
          <Box display="flex" position="relative" flexDirection="column">
            {isPriority && <NotePriorityDisplay />}
            <Box display="flex" alignItems="center">
              <div className={classes.title}>{date && <FormattedDate dateTime={DateTime.fromJSDate(date)} />}</div>
              <Box flexShrink={0}>
                <IconButton
                  aria-label="Open Note Menu"
                  onClick={showMenu as MouseEventHandler<HTMLElement>}
                  disabled={!isDeletable}
                  size="large"
                >
                  <MoreHorizIcon />
                </IconButton>
              </Box>
            </Box>
            <Box mt={2} mb={2}>
              <NotePreview note={note} />
            </Box>
            <Box display="flex" mt={1} mb={1} alignItems="center">
              <div className={classes.labels}>{label && <NoteLabel label={label} friendlyLabel={friendlyLabel} />}</div>
              <Box flexShrink={0} alignSelf="flex-end" mr={3}>
                {author ?? 'Anonymous'}
              </Box>
            </Box>
          </Box>
        </Box>
      </Paper>
      <Menu id="Note-menu" anchorEl={menuAnchor} keepMounted open={isMenuOpen} onClose={hideMenu}>
        <MenuItem onClick={deletePatientNote}>Delete Note</MenuItem>
      </Menu>
    </>
  )
}

export default Note
