import { FC, useMemo } from 'react'

import Tokens from 'config/tokens'
import { reject, isEmpty } from 'lodash'

import makeStyles from '@mui/styles/makeStyles'

type NotePreviewProps = {
  note?: string | null
}

const useStyles = makeStyles({
  paragraph: {
    marginTop: 0,
    marginBottom: 0,
    '&:not(:first-child):not(:last-child)': {
      marginTop: 14,
      marginBottom: 14,
    },
  },
  emptyParagraph: {
    color: Tokens.color.neutral.grey[134],
    marginTop: 14,
    marginBottom: 14,
  },
})

const NotePreview: FC<NotePreviewProps> = ({ note = '' }) => {
  const classes = useStyles()
  const noteLines = useMemo(() => (note?.length ? reject(note.split(/\r?\n/), isEmpty) : []), [note])

  if (!note) {
    return (
      <div>
        <p className={classes.emptyParagraph}>(This note is empty)</p>
      </div>
    )
  }

  return (
    <div>
      {noteLines.map((line, key) => (
        <p className={classes.paragraph} key={key}>
          {line}
        </p>
      ))}
    </div>
  )
}

export default NotePreview
