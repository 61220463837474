import { FC, memo, useCallback } from 'react'

import {
  Encounter,
  InsuranceRecordInput,
  Patient,
  useCreateInsuranceRecordMutation,
  useGetPatientQuery,
  useUpdateEncounterMutation,
} from 'generated/graphql'
import useIsCreateModeContext from 'modules/Appointments/useIsCreateModeContext'
import { useFormContext } from 'react-hook-form'
import hasFeatureFlag from 'utils/featureFlagsUtilities'
import { InsuranceSchema } from 'yupSchemas/insurance'

import { InsuranceVariant } from './InsuranceVariant'
import PatientInsuranceCard from './PatientInsuranceCard'
import { DEFAULT_VALUES_PATIENT_INSURANCE, PatientInsuranceStateProvider } from './usePatientInsuranceStateFromContext'

type PatientInsuranceCardsProps = { patientId: Patient['patientId']; encounterId: Encounter['encounterId'] | null }

const PatientInsuranceCardsEditMode: FC<Pick<PatientInsuranceCardsProps, 'patientId' | 'encounterId'>> = ({
  patientId,
  encounterId,
}) => {
  const [createInsuranceRecord] = useCreateInsuranceRecordMutation()
  const [updateEncounter] = useUpdateEncounterMutation()
  const { refetch: getPatient } = useGetPatientQuery({ variables: { patientId }, skip: true })

  const handleAddInsurance = useCallback(
    async (
      patientInsuranceState: InsuranceSchema | null,
      insuranceCard: 'primaryInsurance' | 'secondaryInsurance',
      insuranceRecordId?: number | null,
    ) => {
      if (patientInsuranceState === null || patientInsuranceState === undefined) return

      let encounterInsuranceRecordId = insuranceRecordId

      if (!insuranceRecordId) {
        const { packageId, ...insuranceRecord } = patientInsuranceState

        const patientInsData: InsuranceRecordInput = { patientId }

        if (insuranceRecord.relationshipToPolicyHolder === 'SELF') {
          const { data: patientData } = await getPatient()
          patientInsData.policyHolderFirstName = patientData?.patient?.firstName
          patientInsData.policyHolderLastName = patientData?.patient?.lastName
          patientInsData.policyHolderDob = patientData?.patient?.dob
          patientInsData.policyHolderGender = patientData?.patient?.sex
        }

        // TODO: Handle error cases. What do we want to do?

        // Create Insurance Card
        const { data } = await createInsuranceRecord({
          variables: {
            input: {
              insuranceRecord: {
                ...insuranceRecord,
                ...patientInsData,
                packageId: String(packageId),
              },
            },
          },
        })

        // Get the new Insurance Record ID
        encounterInsuranceRecordId = data?.createInsuranceRecord?.insuranceRecord?.insuranceRecordId
      }

      if (!encounterInsuranceRecordId || !encounterId) return

      // Add Insurance Card to Encounter
      await updateEncounter({
        variables: {
          encounterId,
          encounter: {
            [insuranceCard]: encounterInsuranceRecordId,
          },
        },
      })
    },
    [patientId, encounterId],
  )

  const handleSubmitPrimary = useCallback(
    (patientInsuranceState?: InsuranceSchema | null, insuranceRecordId?: number | null) => {
      handleAddInsurance(patientInsuranceState ?? null, 'primaryInsurance', insuranceRecordId)
    },
    [handleAddInsurance],
  )

  const handleSubmitSecondary = useCallback(
    (patientInsuranceState?: InsuranceSchema | null, insuranceRecordId?: number | null) => {
      handleAddInsurance(patientInsuranceState ?? null, 'secondaryInsurance', insuranceRecordId)
    },
    [handleAddInsurance],
  )

  const handleRemovePrimary = useCallback(
    (insuranceCard: 'primaryInsurance' | 'secondaryInsurance') => {
      if (!encounterId) return

      updateEncounter({
        variables: {
          encounterId,
          encounter: {
            [insuranceCard]: null,
          },
        },
      })
    },
    [encounterId],
  )

  return (
    <>
      <PatientInsuranceStateProvider
        patientId={patientId}
        onSet={handleSubmitPrimary}
        onUnset={() => handleRemovePrimary('primaryInsurance')}
        variant={InsuranceVariant.Primary}
      >
        <PatientInsuranceCard
          isEditable={hasFeatureFlag('editInsuranceInAppointmentViewMode')}
          testId="PrimaryInsurance"
        />
      </PatientInsuranceStateProvider>

      <PatientInsuranceStateProvider
        patientId={patientId}
        onSet={handleSubmitSecondary}
        onUnset={() => handleRemovePrimary('secondaryInsurance')}
        variant={InsuranceVariant.Secondary}
      >
        <PatientInsuranceCard
          isEditable={hasFeatureFlag('editInsuranceInAppointmentViewMode')}
          testId="SecondaryInsurance"
        />
      </PatientInsuranceStateProvider>
    </>
  )
}

const PatientInsuranceCardsCreateMode: FC<Pick<PatientInsuranceCardsProps, 'patientId'>> = ({ patientId }) => {
  // AppointmentCreate form
  const { setValue, getValues } = useFormContext()

  // we use these submit handlers for "onUnset" as the delete action is not a reset, it's closer to an unset
  // we're doing `setValue` instead of `ControlledField` as this component uses none of the other field or fieldstate props internally
  const handleSubmitPrimary = useCallback(
    (patientInsuranceState?: InsuranceSchema | null) => {
      setValue('primaryInsurances', {
        ...getValues('primaryInsurances'),
        ...{ [patientId]: patientInsuranceState ?? DEFAULT_VALUES_PATIENT_INSURANCE },
      })
    },
    [patientId],
  )
  const handleSubmitSecondary = useCallback(
    (patientInsuranceState?: InsuranceSchema | null) => {
      setValue('secondaryInsurances', {
        ...getValues('secondaryInsurances'),
        ...{ [patientId]: patientInsuranceState ?? DEFAULT_VALUES_PATIENT_INSURANCE },
      })
    },
    [patientId],
  )

  return (
    <>
      <PatientInsuranceStateProvider
        patientId={patientId}
        variant={InsuranceVariant.Primary}
        onSet={handleSubmitPrimary}
        onUnset={handleSubmitPrimary}
      >
        <PatientInsuranceCard isEditable={true} />
      </PatientInsuranceStateProvider>

      <PatientInsuranceStateProvider
        patientId={patientId}
        variant={InsuranceVariant.Secondary}
        onSet={handleSubmitSecondary}
        onUnset={handleSubmitSecondary}
      >
        <PatientInsuranceCard isEditable={true} />
      </PatientInsuranceStateProvider>
    </>
  )
}

const PatientInsuranceCards: FC<PatientInsuranceCardsProps> = ({ patientId, encounterId }) => {
  const isCreateMode = useIsCreateModeContext()
  return isCreateMode ? (
    <PatientInsuranceCardsCreateMode patientId={patientId} />
  ) : (
    <PatientInsuranceCardsEditMode patientId={patientId} encounterId={encounterId} />
  )
}

export default memo(PatientInsuranceCards)
