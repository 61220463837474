import { useEffect, useRef, useMemo } from 'react'

import { yupResolver } from '@hookform/resolvers/yup'
import { useUpdatePatientMutation, useGetPatientQuery } from 'generated/graphql'
import createUpdatePatientOptions from 'graphql/mutations/UpdatePatientOptions'
import useCreateMutatatedDispatch from 'hooks/useCreateMutatedDispatch'
import pickBy from 'lodash/pickBy'
import { DeepMap, useForm } from 'react-hook-form'
import safeJsonParse from 'utils/safeJsonParse'
import { nameAndDemographicsSchema } from 'yupSchemas/demographics/index'

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const useNameAndDemographicsState = ({
  patientId,
  onSet,
  onReset,
}: {
  patientId: number
  onSet?: () => void
  onReset?: () => void
}) => {
  const patientIdIsNil = patientId == null
  const { data: { patient } = {}, loading } = useGetPatientQuery({
    ...(patientId != null && { variables: { patientId } }),
    skip: patientIdIsNil,
  })
  const [updatePatient] = useUpdatePatientMutation()

  const { firstName, lastName, dob, sex, preferredName, genderIdentity, genderPronoun, races, globalHumanId } =
    patient ?? {}

  const defaultValues = {
    firstName: firstName ?? '',
    lastName: lastName ?? '',
    dob,
    sex: sex ?? '',
    preferredName,
    genderIdentity,
    genderPronoun,
    races: JSON.stringify([...(races ?? [])].sort()),
    globalHumanId,
  }

  const methods = useForm<typeof defaultValues>({
    mode: 'onBlur',
    reValidateMode: 'onChange',
    defaultValues,
    resolver: yupResolver(nameAndDemographicsSchema),
  })

  // dirtyFields is not available within the onSubmit handler, we store it in this ref
  const dirtyFieldsRef = useRef<DeepMap<typeof defaultValues, true>>()
  useEffect(() => {
    dirtyFieldsRef.current = methods.formState.dirtyFields
  })

  useEffect(() => {
    methods.reset(defaultValues)
  }, [patient])

  const reset = useCreateMutatatedDispatch(() => {
    methods.reset()

    onReset?.()
  })
  const set = useCreateMutatatedDispatch(async () => {
    await methods.handleSubmit(
      (nameAndDemographicsState) => {
        const dirtyKeys = Object.keys(dirtyFieldsRef.current ?? {})

        const newValues = pickBy(nameAndDemographicsState, (value, key) => dirtyKeys.includes(key))

        if (newValues.races) {
          newValues.races = safeJsonParse(newValues.races)
        }
        // dob is coerced by yup to a date, use original value instead as that is a string
        if (newValues.dob) {
          newValues.dob = methods.getValues('dob')
        }

        const baseOptions = createUpdatePatientOptions(patientId, newValues)
        updatePatient(baseOptions)
      },
      (error) => {
        // eslint-disable-next-line no-console
        console.log('NameAndDemographics Form Error: ', error)
      },
    )()

    onSet?.()
  })

  return useMemo(
    () => ({
      loading,
      methods,
      reset,
      set,
    }),
    [loading, methods, reset, set],
  )
}

export default useNameAndDemographicsState
