import { FC } from 'react'

import Tokens from 'config/tokens'

import Box from '@mui/system/Box'

import { TextProps } from './types'

const fontSizeVal = ({ fontSize }: TextProps) => {
  switch (fontSize) {
    case 'large':
      return Tokens.font.size.paragraph.large
    case 'small':
      return Tokens.font.size.paragraph.small
    case 'default':
    default:
      return Tokens.font.size.paragraph.base
  }
}

const lineHeightVal = ({ lineHeight }: TextProps) => {
  switch (lineHeight) {
    case 'large':
      return Tokens.lineHeight.paragraph.large
    case 'small':
      return Tokens.lineHeight.paragraph.small
    case 'default':
    default:
      return Tokens.lineHeight.paragraph.base
  }
}

const Text: FC<TextProps> = ({
  fontSize = 'default',
  lineHeight = 'default',
  warning = false,

  as = 'div',
  children,
  style,
}) => (
  <Box
    component={as}
    style={style}
    sx={{
      fontFamily: Tokens.font.family,
      color: warning ? Tokens.color.brand.red.base : Tokens.color.ui.charcoal.base,

      ...(warning && { fontWeight: 600 }),
      fontSize: fontSizeVal({ fontSize }),
      lineHeight: lineHeightVal({ lineHeight }),

      marginBottom: as === 'p' ? `calc(0.5 * ${Tokens.rhythm})` : 0,
      marginTop: 0,
    }}
  >
    {children}
  </Box>
)

export default Text
