import useEffectOnceWhen from 'hooks/useEffectOnceWhen'
import { addressIsValid, createAddressLine } from 'modules/Patient/selectors'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useMountedState, useToggle } from 'react-use'
import { AddressValues } from 'types/AddressValues'
import ControlledField from 'utils/ControlledField/ControlledField'

import { Alert } from '@mui/material'

import HiddenInput from 'ui/Inputs/HiddenInput'
import SpecialInstructionsInput from 'ui/Inputs/SpecialInstructionsInput/SpecialInstructionsInput'
import UnitNumberInput from 'ui/Inputs/UnitNumberInput/UnitNumberInput'
import SpacedItems from 'ui/SpacedItems'

import useGoogleMapsApiContext, { selectGeoLocation } from '../AppointmentAddress/useGoogleMapsApiContext'
import GoogleMapsAutoComplete from '../GoogleMapsAutoComplete'

const AddressEdit = (): JSX.Element => {
  const isMounted = useMountedState()
  const { t } = useTranslation()
  const { setValue, watch, getValues } = useFormContext()
  const { fetchGeocodeComponents } = useGoogleMapsApiContext()

  const [updatedLatLng, setUpdatedLatLng] = useToggle(false)

  const address = watch('address')

  // legacy patients might not have a lat/lng in their record yet, we do a one time check to see if we need to fetch it
  useEffectOnceWhen(
    fetchGeocodeComponents != null,
    () => {
      // useless typeguard
      if (!fetchGeocodeComponents) return
      ;(async () => {
        const [addressField, geoLocationField] = getValues(['address', 'geoLocation'])
        const { address1, address2, city, state, zip } = addressField
        const { addressLat, addressLng } = geoLocationField

        if (!addressLat && !addressLng && addressIsValid({ address1, city, state, zip })) {
          const { geocoderResult } = await fetchGeocodeComponents({
            address: createAddressLine({ address1, address2, city, state, zip }),
          })
          const { addressLat: nextAddressLat, addressLng: nextAddressLng } = selectGeoLocation(geocoderResult)

          if (!isMounted) return

          if (nextAddressLat && nextAddressLng) {
            setUpdatedLatLng(true)
            setValue(
              'geoLocation',
              { addressLat: nextAddressLat, addressLng: nextAddressLng },
              {
                shouldDirty: true,
                shouldTouch: true,
                shouldValidate: true,
              },
            )
          }
        }
      })()
    },
    [fetchGeocodeComponents, getValues],
  )

  const setAddressValue = (addressValuesObject: Partial<AddressValues>) => {
    // previously we had to set lat/lng before address, or else the above useEffect would
    // incorrectly believe address is valid without lat/lng
    // this is most likely no longer necessary, but keeping the comment here in case
    const { addressLat, addressLng, address1, address2, city, state, zip } = addressValuesObject
    setValue(
      'geoLocation',
      { addressLat, addressLng },
      {
        shouldDirty: true,
        shouldTouch: true,
      },
    )

    setValue(
      'address',
      { address1, address2, city, state, zip },
      { shouldDirty: true, shouldTouch: true, shouldValidate: true },
    )

    setUpdatedLatLng(false)
  }

  const clearAddressValue = () => {
    setValue(
      'address',
      {
        address1: null,
        address2: null,
        city: null,
        state: null,
        zip: null,
      },
      { shouldTouch: true, shouldValidate: true },
    )
    setValue('geoLocation', { addressLat: null, addressLng: null })

    setUpdatedLatLng(false)
  }

  return (
    <SpacedItems direction="column">
      <GoogleMapsAutoComplete
        setAddressValue={setAddressValue}
        clearAddressValue={clearAddressValue}
        address={address}
      />

      <ControlledField name="unit" Component={UnitNumberInput} />

      <ControlledField name="specialInstructions" Component={SpecialInstructionsInput} />

      <ControlledField name="geoLocation" Component={HiddenInput} />

      {updatedLatLng && <Alert severity="info">{t('components:modules.Patient.AddressEdit.infoUpdatedLatLng')}</Alert>}
    </SpacedItems>
  )
}

export default AddressEdit
