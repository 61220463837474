import { useEffect, useMemo, useState } from 'react'

import { yupResolver } from '@hookform/resolvers/yup'
import { CommunicationNeedEnum, LanguagesEnum, Maybe, useUpdatePatientMutation } from 'generated/graphql'
import createUpdatePatientOptions from 'graphql/mutations/UpdatePatientOptions'
import { pickBy } from 'lodash'
import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import safeJsonParse from 'utils/safeJsonParse'
import { communicationsSchema } from 'yupSchemas/communications/index'

import CardLabeled from 'ui/CardLabeled'

import CommunicationsEdit from './CommunicationsEdit'
import CommunicationsRead from './CommunicationsRead'

type CommunicationsCardProps = {
  patientId: number
  preferredPhoneNumber?: string | null
  email?: string | null
  languages?: Maybe<Maybe<LanguagesEnum>[]>
  communicationNeed?: Array<CommunicationNeedEnum | null> | null
}

const CommunicationsCard = ({
  patientId,
  preferredPhoneNumber,
  email,
  languages,
  communicationNeed,
}: CommunicationsCardProps): JSX.Element => {
  const [isEditing, setEditing] = useState(false)
  const { t } = useTranslation()

  const setEditingTrue = () => setEditing(true)

  const [updatePatient] = useUpdatePatientMutation()

  const defaultValues = {
    preferredPhoneNumber: preferredPhoneNumber ?? '',
    email: email ?? '',
    languages: JSON.stringify(languages ?? []),
    communicationNeed: JSON.stringify(communicationNeed ?? []),
  }

  const methods = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: useMemo(() => defaultValues, Object.values(defaultValues)),
    resolver: yupResolver(communicationsSchema),
  })

  const {
    // control,
    reset,
    getValues,
    handleSubmit,
    formState: { isValid, isDirty, dirtyFields },
  } = methods

  useEffect(() => {
    reset(defaultValues)
  }, Object.values(defaultValues))

  const handleCancel = () => {
    reset()
    setEditing(false)
  }

  const handleSave = () => {
    const allValues = getValues()

    const dirtyKeys = Object.keys(dirtyFields)
    const newValues = pickBy(allValues, (value, key) => dirtyKeys.includes(key))

    if (newValues.languages) {
      newValues.languages = safeJsonParse(newValues.languages)
    }

    if (newValues.communicationNeed) {
      newValues.communicationNeed = safeJsonParse(newValues.communicationNeed)
    }

    const baseOptions = createUpdatePatientOptions(patientId, newValues)
    updatePatient(baseOptions)
    reset(undefined, { keepValues: true })
    setEditing(false)
  }

  return (
    <FormProvider {...methods}>
      <CardLabeled
        title={t('components:modules.Patient.CommunicationsCard.title')}
        testId="CommunicationsCard"
        editing={isEditing}
        onEdit={setEditingTrue}
        onCancel={handleCancel}
        onAccept={handleSubmit(handleSave)}
        acceptDisabled={!isDirty || !isValid}
      >
        {isEditing ? (
          <CommunicationsEdit />
        ) : (
          <CommunicationsRead
            preferredPhoneNumber={preferredPhoneNumber}
            email={email}
            languages={languages}
            communicationNeed={communicationNeed}
          />
        )}
      </CardLabeled>
    </FormProvider>
  )
}

export default CommunicationsCard
