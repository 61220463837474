import { E164_PHONE_NUMBER_REGEXP, US_PHONE_NUMBER_REGEXP } from './regexp'

/**
 * Convert a E. 164 phone number to U.S. format
 * @param {String} phoneNumber An E. 164 phone number, e.g. +15558675309
 * @return {String} U.S. formatted phone number, e.g. (555) 867-5309
 */
export type FormatPhoneNumber = (phoneNumber: string) => string
export const formatE164ToUSPhoneNumber: FormatPhoneNumber = (phoneNumber) => {
  try {
    const groups: RegExpMatchArray = phoneNumber.match(E164_PHONE_NUMBER_REGEXP)!
    return `(${groups[2]}) ${groups[3]}-${groups[4]}`
  } catch (err) {
    return phoneNumber
  }
}

/**
 * Convert a U.S. phone number to E. 164 format
 * @param {String} phoneNumber A U.S. phone number, e.g. (555) 867-5309
 * @return {String} E. 164 formatted phone number, e.g. +15558675309
 */
export const formatUSPhoneNumberToE164: FormatPhoneNumber = (phoneNumber) => {
  const groups: RegExpMatchArray = phoneNumber.match(US_PHONE_NUMBER_REGEXP)!
  try {
    return `+1${groups[2]}${groups[3]}${groups[4]}`
  } catch (err) {
    return phoneNumber
  }
}
