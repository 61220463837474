import Tokens from 'config/tokens'
import styled, { CSSProperties } from 'styled-components'

import { SpinnerProps } from './types'

export const StyledSpinner = styled.div<SpinnerProps>(({ backgroundColor, foregroundColor, size }) => {
  const iconSize = (() => {
    switch (size) {
      case 'small':
        return `calc(${Tokens.icon.size.small} - 2px)`
      case 'large':
        return `calc(${Tokens.icon.size.large} - 6px)`
      case 'default':
      default:
        return `calc(${Tokens.icon.size.base} - 4px)`
    }
  })()

  return `
      border-radius: 50%;
      width: ${iconSize};
      height: ${iconSize};
      position: relative;
      border-width: ${size === 'large' ? '3px' : '2px'};
      border-style: solid;
      border-color: ${backgroundColor};
      border-left-color: ${foregroundColor};
      transform: translateZ(0);
      animation: spin 1.1s infinite linear;

      &:after {
        border-radius: 50%;
        width: ${iconSize};
        height: ${iconSize};
      }

      @keyframes spin {
        0% {
          transform: rotate(0deg);
        }
        100% {
          transform: rotate(360deg);
        }
      }
    `
})

const centeredStyles: CSSProperties = {
  position: 'absolute',
  inset: 0,
  margin: 'auto',
}

const Spinner = ({
  foregroundColor = Tokens.color.ui.charcoal.base,
  backgroundColor = Tokens.color.ui.steel.base,
  centered = false,
  size = 'default',
  testId,
  className,
  ...rest
}: Readonly<SpinnerProps>): JSX.Element => (
  <div data-testid={testId} className={className} style={centered ? centeredStyles : {}}>
    <StyledSpinner
      foregroundColor={foregroundColor}
      backgroundColor={backgroundColor}
      size={size}
      testId={testId}
      style={centered ? centeredStyles : {}}
      {...rest}
    />
  </div>
)

export default Spinner
