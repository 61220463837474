import { TypePolicy, FieldPolicy } from '@apollo/client'
import { UserRole } from 'generated/graphql'

// no idea what i'm doing
type Nullable<T> = T | null | undefined
const isNotNil = <T>(x: Nullable<T>) => x != null

const MAP_ROLES_FRIENDLY = {
  [UserRole.Clinician]: 'Clinician',
  [UserRole.Responder]: 'Responder',
  [UserRole.It]: 'It',
  [UserRole.Supervisor]: 'Supervisor',
  [UserRole.Unassigned]: '',
}

const User: TypePolicy = {
  keyFields: ['userId'],
  fields: {
    // Adds @client field roles
    roles: {
      read(value, { readField }) {
        const userRoles = []

        if (readField('isClinician')) userRoles.push('CLINICIAN')
        if (readField('isIt')) userRoles.push('IT')
        if (readField('isResponder')) userRoles.push('RESPONDER')
        if (readField('isSupervisor')) userRoles.push('SUPERVISOR')

        if (userRoles.length < 1) userRoles.push('UNASSIGNED')

        return userRoles
      },
    } as FieldPolicy,

    friendlyRoles: {
      read(value, { readField }) {
        const userRoles: Readonly<Array<UserRole>> | undefined = readField('roles')

        if (!userRoles || !userRoles.length) {
          return null
        }

        return userRoles.map((role) => MAP_ROLES_FRIENDLY[role]).filter(isNotNil)
      },
    } as FieldPolicy,

    // Add @client field fullName
    fullName: {
      read(value, { readField }) {
        const firstName = readField('firstName')
        const lastName = readField('lastName')

        const fullName = [firstName, lastName].filter(isNotNil).join(' ')

        // return null if empty string
        return fullName || null
      },
    } as FieldPolicy,
  },
}

export default User
