export const colors = {
  brand: {
    gray: {
      base: 'rgba(46,46,46,1)',
    },
    red: {
      base: 'rgba(247,11,11,1)',
      dark: 'rgba(221,4,4,1)',
      light: 'rgba(255,77,65,1)',
    },
  },
  neutral: {
    white: {
      base: 'rgba(255,255,255,1)',
      transparent: {
        75: 'rgba(255,255,255,0.75)',
        50: 'rgba(255,255,255,0.50)',
        25: 'rgba(255,255,255,0.25)',
        10: 'rgba(255,255,255,0.10)',
        8: 'rgba(255,255,255,0.08)',
        6: 'rgba(255,255,255,0.06)',
        4: 'rgba(255,255,255,0.04)',
        2: 'rgba(255,255,255,0.02)',
      },
    },
    grey: {
      base: 'rgba(134,134,134,1)',
      250: 'rgba(250,250,250,1)',
      240: 'rgba(240,240,240,1)',
      219: 'rgba(219,219,219,1)',
      191: 'rgba(191,191,191,1)',
      153: 'rgba(153,153,153,1)',
      134: 'rgba(134,134,134,1)',
      102: 'rgba(102,102,102,1)',
      94: 'rgba(94,94,94,1)',
      82: 'rgba(82,82,82,1)',
      64: 'rgba(64,64,64,1)',
      48: 'rgba(48,48,48,1)',
      31: 'rgba(31,31,31,1)',
    },
    black: {
      base: 'rgba(0,0,0,1)',
      transparent: {
        50: 'rgba(0,0,0,0.50)',
        25: 'rgba(0,0,0,0.25)',
        10: 'rgba(0,0,0,0.10)',
        8: 'rgba(0,0,0,0.08)',
        6: 'rgba(0,0,0,0.06)',
        4: 'rgba(0,0,0,0.04)',
        2: 'rgba(0,0,0,0.02)',
      },
    },
  },
  ui: {
    blue: {
      base: 'rgba(31,117,203,1)',
      light: 'rgba(31,117,203,0.25)',
    },
    charcoal: {
      base: 'rgba(48,53,70,1)',
      transparent: {
        90: 'rgba(48,53,70,.9)',
        50: 'rgba(48,53,70,.5)',
      },
    },
    error: {
      base: 'rgba(222,7,7,1)',
      transparent: {
        90: 'rgba(222,7,7,0.9)',
        10: 'rgba(222,7,7,0.1)',
      },
    },
    link: {
      base: 'rgba(40,88,225,1)',
    },
    slate: {
      base: 'rgba(82,94,125,1)',
    },
    steel: {
      base: 'rgba(245,245,250,1)',
    },
    success: {
      base: 'rgba(16,133,72,1)',
      transparent: {
        10: 'rgba(16,133,72,0.1)',
      },
    },
  },
}

export const Tokens = {
  border: {
    radius: '4px',
    size: '1px',
    color: colors.neutral.grey.base,
  },
  boxShadow: {
    light: '0px 1px 3px 0px rgba(0, 0, 0, 0.08)',
    dark: '0px 1px 3px 0px rgba(0, 0, 0, 0.25)',
  },
  color: colors,
  dimensions: {
    iPad: {
      portrait: '768px',
      landscape: '1024px',
    },
  },
  font: {
    family: '"Source Sans Pro", sans-serif',
    size: {
      paragraph: {
        base: '14px',
        large: '16px',
        small: '12px',
      },
      heading: {
        1: '24px',
        2: '16px',
        3: '14px',
        4: '12px',
        5: '12px',
        6: '12px',
      },
    },
    weight: {
      normal: 400,
      semiBold: 600,
      bold: 700,
    },
  },
  icon: {
    size: {
      small: '16px',
      base: '24px',
      large: '32px',
    },
  },
  lineHeight: {
    paragraph: {
      base: '20px',
      large: '24px',
      small: '16px',
    },
    heading: {
      1: '32px',
      2: '24px',
      3: '20px',
      4: '16px',
      5: '16px',
      6: '16px',
    },
  },
  outline: `4px solid ${colors.ui.blue.light}`,
  pill: {
    color: {
      default: 'rgba(240,240,240,1)',
    },
  },
  rhythm: '8px',
  transition: {
    duration: {
      1: '130ms',
      2: '190ms',
      3: '250ms',
      4: '350ms',
      5: '400ms',
    },
    timing: {
      easeIn: 'cubic-bezier(0.5,0,1,1)',
      easeInOut: 'cubic-bezier(0.45,0,0.4,1)',
      easeOut: 'cubic-bezier(0,0,0.4,1)',
    },
  },
}

export default Tokens
