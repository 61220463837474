import { useMemo, FC } from 'react'

import Tokens from 'config/tokens'
import { Patient, useGetPatientsQuery } from 'generated/graphql'
import { selectPatientHomeAddressOptions } from 'modules/Patient/selectors'
import { useTranslation } from 'react-i18next'
import { AddressValues } from 'types/AddressValues'

import MenuItem from '@mui/material/MenuItem'
import TextField, { OutlinedTextFieldProps } from '@mui/material/TextField'
import makeStyles from '@mui/styles/makeStyles'

import Spinner from 'ui/Spinner'

const VALUE_NONE = '__none__'
const DEFAULT_I18N_KEY = 'components:ui.Inputs.PatientHomeAddressSelect'

export type OptionValue = Patient['patientId'] | null

export type PatientHomeAddressSelectProps = Partial<Omit<OutlinedTextFieldProps, 'onChange' | 'value'>> & {
  i18nKey?: string
  showNone?: boolean
  fullWidth?: boolean

  value?: OptionValue
  onChange: (newValue: (Partial<Patient> & AddressValues) | null) => void
  patientIds: number[] | null
}

const useStyles = makeStyles((theme) => ({
  container: {
    position: 'relative',
    minWidth: 200,
    maxWidth: ({ fullWidth = false }: Pick<PatientHomeAddressSelectProps, 'fullWidth'>) => (fullWidth ? 'none' : 300),
  },
  inputLoading: {
    pointerEvents: 'none',
    position: 'absolute',
    top: 20,
    right: 32,
  },
  optionLoading: {
    pointerEvents: 'none',

    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',

    padding: '32px 16px',
    backgroundColor: Tokens.color.ui.steel.base,

    '& > p': {
      ...theme.typography.body1,
      color: Tokens.color.neutral.grey[102],
    },
  },
}))

const PatientHomeAddressSelect: FC<PatientHomeAddressSelectProps> = ({
  value,
  onChange,
  patientIds,
  i18nKey = DEFAULT_I18N_KEY,
  showNone = true,
  fullWidth = false,
  ...textFieldProps
}) => {
  const { t } = useTranslation()
  const classes = useStyles({ fullWidth })
  const { data, loading } = useGetPatientsQuery({
    variables: { patientIds },
    skip: !(patientIds && patientIds?.length),
  })

  const optionValues = useMemo(() => {
    if (!data) return null
    return selectPatientHomeAddressOptions(data)
  }, [data])

  function handleOnChange(event: { target: { value: OptionValue | string } }): void {
    if (!optionValues) return
    const {
      target: { value: optionValue },
    } = event

    const selectedOption = optionValues.find((option) => option?.patientId === optionValue)

    onChange(
      optionValue === VALUE_NONE || !selectedOption ? null : (selectedOption as Partial<Patient> | AddressValues),
    )
  }

  const existingValue = useMemo(() => value ?? VALUE_NONE, [value, optionValues])

  const optionsLabeli18nKey = `${i18nKey}.options.label`
  const textFieldLabel = t([`${i18nKey}.label`, 'components:ui.Inputs.generic.select.label'])
  const noneLabel = t([`${i18nKey}.option.none.label`, 'components:ui.Inputs.generic.option.none.label'])
  const nonePlaceholder = t([
    `${i18nKey}.option.none.placeholder`,
    'components:ui.Inputs.generic.option.none.placeholder',
  ])

  return (
    <div className={classes.container} style={{ whiteSpace: 'nowrap' }}>
      <TextField
        fullWidth
        name="patientHomeAddress"
        value={existingValue ?? ''}
        {...textFieldProps}
        select
        onChange={handleOnChange}
        SelectProps={{
          renderValue: (optionValue) => {
            if (optionValue === VALUE_NONE || !optionValues) {
              return nonePlaceholder
            }
            const selectedOption = optionValues.find((option) => option?.patientId === optionValue)
            if (!selectedOption) {
              return nonePlaceholder
            }
            const { fullName, addressLine } = selectedOption
            return t(optionsLabeli18nKey, { fullName, addressLine })
          },
        }}
        label={textFieldLabel}
      >
        <MenuItem value={VALUE_NONE} {...(!showNone && { style: { display: 'none' } })}>
          {noneLabel}
        </MenuItem>

        {loading && (
          <li className={classes.optionLoading}>
            <Spinner size="large" foregroundColor={Tokens.color.ui.blue.base} />
            <p>{t(`${i18nKey}.loading`)}</p>
          </li>
        )}

        {!(loading || optionValues?.length) ? (
          <MenuItem disabled>{t(`${i18nKey}.noOptions`)}</MenuItem>
        ) : (
          optionValues?.map((option) => {
            if (!(option && option.patientId)) return null
            const { patientId, fullName, addressLine } = option

            return (
              <MenuItem key={patientId} value={patientId} style={{ whiteSpace: 'normal' }}>
                {t(optionsLabeli18nKey, { fullName, addressLine })}
              </MenuItem>
            )
          })
        )}
      </TextField>

      {loading && (
        <div className={classes.inputLoading}>
          <Spinner size="small" foregroundColor={Tokens.color.ui.blue.base} />
        </div>
      )}
    </div>
  )
}

export default PatientHomeAddressSelect
