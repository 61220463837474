import { FC, useCallback, useEffect, useState, useRef } from 'react'

import { yupResolver } from '@hookform/resolvers/yup'
import { isNotNilOrEmpty } from 'fp/isNilOrEmpty'
import { GetAppointmentDocument, useUpdateEncounterMutation } from 'generated/graphql'
import createUpdateEncounterOptions from 'graphql/mutations/UpdateEncounterOptions'
import { findElementEqualsPatientId, findExistingIndexElementEqualsPatientId } from 'modules/Patient/selectors'
import { DeepMap, FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useToggle } from 'react-use'
import {
  appointmentPartnershipCardEditFormSchema,
  AppointmentPartnershipCardEditFormSchema,
} from 'yupSchemas/partnership'

import Alert from '@mui/material/Alert'
import Box from '@mui/system/Box'

import CardLabeled from 'ui/CardLabeled'

import { selectMarketId, selectPartnershipEncounters, selectPartnershipPatient } from '../selectors'
import useGetAppointmentQueryContext from '../useGetAppointmentQueryContext'

import AppointmentPartnershipEdit from './AppointmentPartnershipEdit'
import AppointmentPartnershipRead from './AppointmentPartnershipRead'
import SavePartnerShipEditButton from './SavePartnerShipEditButton'

const AppointmentPartnershipCard: FC<unknown> = () => {
  const { t } = useTranslation()
  const { data, id: appointmentId } = useGetAppointmentQueryContext()
  const [hasGenericServerError, toggleGenericServerError] = useToggle(false)
  const [updateEncounter, { loading: loadingUpdateEncounter }] = useUpdateEncounterMutation()
  const methods = useForm<AppointmentPartnershipCardEditFormSchema>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      encounters: selectPartnershipEncounters(data),
      patients: selectPartnershipPatient(data),
      marketId: selectMarketId(data),
    },
    resolver: yupResolver(appointmentPartnershipCardEditFormSchema),
  })
  const {
    reset,
    handleSubmit,
    formState: { isValid, isDirty },
  } = methods

  // reset form with new data
  useEffect(() => {
    reset({
      encounters: selectPartnershipEncounters(data),
      patients: selectPartnershipPatient(data),
      marketId: selectMarketId(data),
    })
  }, [data])

  const [isEditing, setEditing] = useState(false)
  const setEditingTrue = () => setEditing(true)

  const handleCancel = () => {
    methods.reset()
    setEditing(false)
  }

  // dirtyFields is not available within the onSubmit handler, we store it in this ref
  const dirtyFieldsRef = useRef<DeepMap<AppointmentPartnershipCardEditFormSchema, true>>()
  useEffect(() => {
    dirtyFieldsRef.current = methods.formState.dirtyFields
  })

  const handleSave = useCallback(async ({ encounters, patients }: AppointmentPartnershipCardEditFormSchema) => {
    setEditing(false)

    toggleGenericServerError(false)

    const encounterBaseOptions = encounters
      ?.map((encounter) => {
        const { encounterId, patientId } = encounter
        const associatedPatient = findElementEqualsPatientId(patients, patientId)

        if (!associatedPatient) {
          return null
        }

        // see if field is dirty
        const { patients: dirtyPatientFields } = dirtyFieldsRef.current ?? {}
        if (!dirtyPatientFields) {
          return null
        }
        const associatedPatientIndex = findExistingIndexElementEqualsPatientId(patients, patientId)
        if (associatedPatientIndex == null || !dirtyPatientFields[associatedPatientIndex]) {
          return null
        }

        const { channelAttributionId } = associatedPatient

        return createUpdateEncounterOptions(encounterId, patientId, { channelAttributionId })
      })
      .filter(Boolean)

    if (!encounterBaseOptions) {
      toggleGenericServerError(true)
      return
    }

    let hasFetchResults
    try {
      // reverse spread, spread first N elements (init elements), and the last one separately (tail element).
      const [initBaseOptions, [tailBaseOption]] = [encounterBaseOptions.slice(0, -1), encounterBaseOptions.slice(-1)]

      const allEncounterResults = await Promise.all([
        ...initBaseOptions.map((baseOptions) => (baseOptions ? updateEncounter(baseOptions) : null)),
        updateEncounter({
          ...tailBaseOption,
          refetchQueries: [{ query: GetAppointmentDocument, variables: { appointmentId } }],
        }),
      ])

      hasFetchResults = allEncounterResults.every(isNotNilOrEmpty)
    } catch (e) {
      // eslint-disable-next-line no-empty
    }

    if (!hasFetchResults) {
      toggleGenericServerError(true)
      return
    }

    setEditing(false)
  }, [])

  return (
    <FormProvider {...methods}>
      <CardLabeled
        title={t('components:modules.Appointments.appointmentPartnership')}
        testId="AppointmentPartnershipCard"
        onEdit={setEditingTrue}
        editing={isEditing}
        onCancel={handleCancel}
        onAccept={handleSubmit(handleSave)}
        acceptDisabled={loadingUpdateEncounter || !isDirty || !isValid}
        acceptText={<SavePartnerShipEditButton loading={loadingUpdateEncounter} isValid={isValid && isDirty} />}
        cancelDisabled={loadingUpdateEncounter}
      >
        {isEditing ? <AppointmentPartnershipEdit /> : <AppointmentPartnershipRead />}

        {hasGenericServerError && (
          <Box mt={4}>
            <Alert severity="error">
              {t('components:modules.Appointment.AppointmentPartnershipCard.genericError')}
            </Alert>
          </Box>
        )}
      </CardLabeled>
    </FormProvider>
  )
}

export default AppointmentPartnershipCard
