import Tokens from 'config/tokens'
import { useGetInsuranceRecordListQuery } from 'generated/graphql'
import { useTranslation } from 'react-i18next'

import { Typography } from '@mui/material'
import MenuItem from '@mui/material/MenuItem'
import TextField, { OutlinedTextFieldProps } from '@mui/material/TextField'
import Box from '@mui/system/Box'

import Spinner from 'ui/Spinner'

const VALUE_NONE = '__none__'

export type InsuranceRecordSelectProps = Partial<Omit<OutlinedTextFieldProps, 'onChange' | 'value'>> & {
  showNone?: boolean
  patientId: number
  value?: number | null
  onChange: (newValue: number | null) => void
}

const I18N_RELATIONSHIP_KEY = 'glossary:InsuranceRelationshipToPolicyHolder'

const InsuranceRecordSelect = ({
  showNone = true,
  patientId,
  onChange,
  value,
  ...props
}: InsuranceRecordSelectProps): JSX.Element => {
  const { t } = useTranslation()
  const handleOnChange = (e: React.ChangeEvent<{ value: string | typeof VALUE_NONE }>) => {
    const newValue = e.target.value
    onChange(newValue === VALUE_NONE ? null : parseFloat(newValue))
  }

  const { data, loading } = useGetInsuranceRecordListQuery({
    variables: { insuranceRecordsListCondition: { patientId } },
    skip: typeof patientId !== 'number',
  })

  const existingValue = value ?? VALUE_NONE

  const insuranceRecords = data?.insuranceRecordsList?.filter((insurance) => !insurance.isDeleted) ?? []

  return (
    <TextField
      fullWidth
      {...props}
      value={existingValue}
      select
      label={t('components:modules.Patient.Insurance.selectExistingRecord')}
      onChange={handleOnChange}
      disabled={insuranceRecords.length === 0}
      data-testid="InsuranceRecordSelect"
    >
      {loading && (
        <Box
          component="li"
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          px={16}
          py={8}
          bgcolor={Tokens.color.ui.steel.base}
          data-testid={`InsuranceRecordSelect-MenuItem-Loading`}
        >
          <Spinner size="large" foregroundColor={Tokens.color.ui.blue.base} />
          <Typography sx={{ color: Tokens.color.neutral.grey[102] }}>{t('glossary:loadingElapses')}</Typography>
        </Box>
      )}

      <MenuItem
        value={VALUE_NONE}
        {...(!showNone && { style: { display: 'none' } })}
        data-testid="InsuranceRecordSelect-MenuItem-New"
      >
        {t('components:modules.Patient.Insurance.newInsurance')}
      </MenuItem>

      {!loading &&
        insuranceRecords.map((item, index) => (
          <MenuItem
            key={item.insuranceRecordId}
            value={item.insuranceRecordId}
            data-testid={`InsuranceRecordSelect-MenuItem-${index}`}
          >
            <Box>
              <div>
                <b>{item.company}</b>
              </div>
              <div>
                ID: {item.memberId} Group: {item.groupNumber} -{' '}
                {t(I18N_RELATIONSHIP_KEY, { context: item.relationshipToPolicyHolder?.toUpperCase() })}
              </div>
            </Box>
          </MenuItem>
        ))}
    </TextField>
  )
}

export default InsuranceRecordSelect
