import Tokens from 'config/tokens'
import { useGetZonesQuery, Zone } from 'generated/graphql'

import MenuItem from '@mui/material/MenuItem'
import TextField, { OutlinedTextFieldProps } from '@mui/material/TextField'
import makeStyles from '@mui/styles/makeStyles'

import Spinner from 'ui/Spinner'

import { selectZonesList, selectActiveZonesList } from './selectors'

export type ZoneListSelectProps = Partial<Omit<OutlinedTextFieldProps, 'onChange' | 'value'>> & {
  showNone?: boolean
  value?: number | null
  onChange: (newValue: number | null) => void
  marketId: number | null
  fullWidth?: boolean

  /**
   * Although this select has an internal loading state,
   * this prop triggers the small loading spinner.
   * This is useful when an external state is controlling the value and has its own loading state
   * If the external state will trigger a refetch of zones, it's best to use this prop in unison with `disabled` prop
   */
  loading?: boolean

  /**
   * filters zones by isActive prop
   */
  showIsActive?: boolean
}

const useStyles = makeStyles(() => ({
  container: {
    position: 'relative',
    minWidth: 200,
    maxWidth: ({ fullWidth = false }: Pick<ZoneListSelectProps, 'fullWidth'>) => (fullWidth ? 'none' : 300),
  },
  inputLoading: {
    pointerEvents: 'none',
    position: 'absolute',
    top: 20,
    right: 32,
  },
}))

const VALUE_NONE = '__none__'

const ZoneListSelect = ({
  onChange,
  value,
  marketId,
  fullWidth,
  showNone = false,
  showIsActive = false,
  loading: externalLoading = false,
  ...props
}: ZoneListSelectProps): JSX.Element => {
  const classes = useStyles({ fullWidth })
  const handleOnChange = (e: React.ChangeEvent<{ value: string }>) => {
    const targetValue = e.target.value
    onChange(targetValue !== VALUE_NONE ? parseFloat(targetValue) : null)
  }

  const { data, loading } = useGetZonesQuery({
    variables: { condition: { marketId } },
    skip: !marketId || marketId < 0,
  })
  const zones = showIsActive ? selectActiveZonesList(data) : selectZonesList(data)
  const existingValue = value ?? VALUE_NONE

  return (
    <div className={classes.container}>
      <TextField fullWidth name="zone" {...props} value={existingValue} select label="Zone" onChange={handleOnChange}>
        {loading && (
          <MenuItem key={'loading'} value={existingValue}>
            Loading...
          </MenuItem>
        )}

        <MenuItem value={VALUE_NONE} {...(!showNone && { style: { display: 'none' } })} />

        {zones?.map((item: Partial<Zone>) => (
          <MenuItem key={item.zoneId} value={item.zoneId}>
            {item.name}
          </MenuItem>
        ))}
      </TextField>

      {(loading || externalLoading) && (
        <div className={classes.inputLoading}>
          <Spinner size="small" foregroundColor={Tokens.color.ui.blue.base} />
        </div>
      )}
    </div>
  )
}

export default ZoneListSelect
