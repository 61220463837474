import * as Yup from 'yup'
import {
  address1SchemaRequired,
  address2SchemaBase,
  citySchemaRequired,
  stateSchemaRequired,
  unitSchema,
  zipSchemaRequired,
} from 'yupSchemas/address/items'
import { encounterWithEmblemSchema } from 'yupSchemas/encounters'
import makeRequired from 'yupSchemas/helpers/makeRequired'

import { marketIdSchemaRequired, specialInstructionsSchemaBase, zoneIdSchemaRequired } from './items'

const createAppointmentSchema = Yup.object().shape({
  patients: Yup.array().of(
    Yup.object().shape({
      patientId: Yup.number().required(),
      channelAttributionId: makeRequired(Yup.number(), 'validation:appointment.patients.channelAttributionId.required'),
      encounters: Yup.array().of(encounterWithEmblemSchema).required(),
    }),
  ),

  // Appointment Time
  startTimes: Yup.array()
    .of(Yup.string())
    .min(1, 'validation:appointmentTime.appointment.startTimes.required')
    .required('validation:appointmentTime.appointment.startTimes.required'),

  // Appointment address
  address: Yup.object().shape({
    address1: address1SchemaRequired,
    address2: address2SchemaBase,
    city: citySchemaRequired,
    state: stateSchemaRequired,
    zip: zipSchemaRequired,
  }),

  // we should fetch geoLocation when saving if it doesn't
  geoLocation: Yup.object().shape({
    addressLat: Yup.number().min(-90).max(90).nullable(),
    addressLng: Yup.number().min(-180).max(180).nullable(),
  }),

  unit: unitSchema,
  specialInstructions: specialInstructionsSchemaBase,

  marketId: marketIdSchemaRequired,
  zoneId: zoneIdSchemaRequired,
})

export const createAppointmentSchemaReadyZen = Yup.object().shape({
  patients: Yup.array().of(
    Yup.object().shape({
      patientId: Yup.number().required(),
      channelAttributionId: makeRequired(Yup.number(), 'validation:appointment.patients.channelAttributionId.required'),
      encounters: Yup.array().of(encounterWithEmblemSchema).required(),
    }),
  ),

  // Appointment Time
  startTimes: Yup.array()
    .of(Yup.string())
    .min(1, 'validation:appointmentTime.appointment.startTimes.required')
    .required('validation:appointmentTime.appointment.startTimes.required'),

  // Appointment address
  address: Yup.object().shape({
    address1: address1SchemaRequired,
    address2: address2SchemaBase,
    city: citySchemaRequired,
    state: stateSchemaRequired,
    zip: zipSchemaRequired,
  }),

  // we should fetch geoLocation when saving if it doesn't
  geoLocation: Yup.object().shape({
    addressLat: Yup.number().min(-90).max(90).nullable(),
    addressLng: Yup.number().min(-180).max(180).nullable(),
  }),

  unit: unitSchema,
  specialInstructions: specialInstructionsSchemaBase,

  marketId: marketIdSchemaRequired,
  zoneId: zoneIdSchemaRequired,
})

export default createAppointmentSchema
