import { FC, useCallback } from 'react'

import { FetchResult } from '@apollo/client'
import { yupResolver } from '@hookform/resolvers/yup'
import Tokens from 'config/tokens'
import { CancelAppointmentMutation, CancellationReasonEnum } from 'generated/graphql'
import { ProhibitInset } from 'phosphor-react'
import { useForm, FormProvider } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useToggle } from 'react-use'
import ControlledField from 'utils/ControlledField/ControlledField'
import hasFeatureFlag from 'utils/featureFlagsUtilities'
import cancelAppointmentSchema, { CancelAppointmentSchema } from 'yupSchemas/appointment/cancelAppointment'

import { Box, Alert } from '@mui/material'
import Button from '@mui/material/Button'

import AppointmentCancelReasonSelect from 'ui/Inputs/CancellationReasonSelect'
import Modal from 'ui/Modal'
import SpacedItems from 'ui/SpacedItems'
import Spinner from 'ui/Spinner'

import CancellationReasonOtherInput from './CancellationReasonOtherInput'

export const isDescriptionNeeded = (reason: CancellationReasonEnum | null) => {
  if (reason === CancellationReasonEnum.Other) {
    return true
  }
  if (hasFeatureFlag('showUpdatedCancellationReasons')) {
    if (
      reason === CancellationReasonEnum.ServicesNotAvailable ||
      reason === CancellationReasonEnum.SoughtCareElsewhere ||
      reason === CancellationReasonEnum.CapacityRelated
    ) {
      return true
    }
  }
  return false
}

const initialState = {
  cancellationReason: null,
  cancellationReasonOther: null,
}

type AppointmentCancelDialogProps = {
  open: boolean
  onClose: () => void
  onSubmit: ({
    cancellationReason,
    cancellationReasonOther,
  }: CancelAppointmentSchema) => Promise<FetchResult<CancelAppointmentMutation> | null>
  loading: boolean
}

const AppointmentCancelDialog: FC<AppointmentCancelDialogProps> = ({ open, onClose, onSubmit, loading }) => {
  const { t } = useTranslation()
  const [showGenericError, toggleShowGenericError] = useToggle(false)
  const methods = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: initialState,
    resolver: yupResolver(cancelAppointmentSchema),
  })

  const {
    reset,
    getValues,
    handleSubmit,
    watch,
    formState: { isValid, isDirty },
  } = methods

  const handleClose = useCallback(() => {
    onClose()
    reset()
    toggleShowGenericError(false)
  }, [onClose, reset])

  const handleSubmitCancel = useCallback(async () => {
    toggleShowGenericError(false)

    let fetchResult
    try {
      fetchResult = await onSubmit({ ...getValues() })
      // eslint-disable-next-line no-empty
    } catch (error) {
      // we don't need this as we are only showing a generic error, which is handled by fetchResult being nil
    }

    if (!fetchResult) {
      toggleShowGenericError(true)
      return
    }

    reset()
    onClose()
  }, [getValues, handleSubmit])

  const cancellationReason = watch('cancellationReason')

  return (
    <Modal
      open={open}
      fullWidth={true}
      maxWidth="sm"
      onClose={handleClose}
      title={t('components:modules.Appointment.CancelAppointmentDialog.heading')}
      actions={
        <>
          <Button variant="outlined" color="secondary" onClick={handleClose}>
            {t('components:modules.Appointment.CancelAppointmentDialog.cancelButton')}
          </Button>

          <Button
            color="error"
            onClick={handleSubmit(handleSubmitCancel)}
            disabled={loading || !isValid || !isDirty}
            endIcon={
              <>
                {loading ? (
                  <Spinner size="small" foregroundColor={Tokens.color.neutral.black.base} />
                ) : (
                  <ProhibitInset
                    size={14}
                    color={!isValid ? Tokens.color.neutral.grey[82] : Tokens.color.neutral.white.base}
                  />
                )}
              </>
            }
          >
            {t('components:modules.Appointment.CancelAppointmentDialog.submitButton')}
          </Button>
        </>
      }
    >
      <FormProvider {...methods}>
        <SpacedItems direction="column">
          <ControlledField
            name="cancellationReason"
            Component={AppointmentCancelReasonSelect}
            ComponentProps={{ fullWidth: true, showNone: false }}
          />
          {isDescriptionNeeded(cancellationReason) && (
            <ControlledField name="cancellationReasonOther" Component={CancellationReasonOtherInput} />
          )}
        </SpacedItems>
      </FormProvider>
      {showGenericError && (
        <Box mt={4}>
          <Alert severity="error">{t('components:modules.Appointment.CancelAppointmentDialog.genericError')}</Alert>
        </Box>
      )}
    </Modal>
  )
}

export default AppointmentCancelDialog
