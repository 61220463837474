import { BaseMutationOptions } from '@apollo/client'
import { AppointmentPatch, UpdateAppointmentMutation } from 'generated/graphql'

const createUpdateAppointmentOptions = (
  appointmentId: number,
  appointmentPatch: AppointmentPatch,
): BaseMutationOptions<
  Partial<UpdateAppointmentMutation>,
  {
    appointmentId: number
    appointmentPatch: Partial<AppointmentPatch>
  }
> => ({
  variables: {
    appointmentId,
    appointmentPatch: {
      ...appointmentPatch,
    },
  },
  optimisticResponse: {
    updateAppointment: {
      // Spent 2 hours on trying to satisfy TS, maybe someone else can have a go
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      appointment: {
        ...appointmentPatch,
        appointmentId,
      },
    },
  },
})

export default createUpdateAppointmentOptions
