import { FC } from 'react'

import { ReadyCoreErrorCodesEnum } from '@getready/readycore-errors'
import { useCreatePatientMutation, Patient, PatientInputRecordInput, ReadyCoreStatusCodeEnum } from 'generated/graphql'
import useFormValidation from 'hooks/useFormValidation'
import useToggle from 'hooks/useToggle'
import { useTranslation } from 'react-i18next'
import { createPatient as createPatientSchema } from 'yupSchemas/createPatient/index'

import Alert from '@mui/material/Alert'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import Box from '@mui/system/Box'

import FirstNameInput from 'ui/Inputs/FirstNameInput/FirstNameInput'
import LastNameInput from 'ui/Inputs/LastNameInput/LastNameInput'
import PhoneNumberInput from 'ui/Inputs/PhoneNumberInput'
import SexSelect from 'ui/Inputs/SexSelect'
import BirthdaySelect from 'ui/MaterialUI/DatePicker/BirthdaySelect'
import Modal from 'ui/Modal'

interface PatientCreateDialogProps {
  open: boolean
  onClose: () => void
  onCreatePatient: (patient: Partial<Patient>) => void
}

const initialState: PatientInputRecordInput = {
  firstName: '',
  lastName: '',
  dob: '',
  sex: null,
  preferredPhoneNumber: '',
}

const PatientCreateDialog: FC<PatientCreateDialogProps> = ({ open, onCreatePatient, onClose }) => {
  const { t } = useTranslation()
  const [hasGenericServerError, , setGenericServerError, unsetGenericServerError] = useToggle(false)
  const [isDuplicatePatientError, , setDuplicatePatientError, unsetDuplicatePatientError] = useToggle(false)
  const { errors, handleSetValue, hasValueChanged, values, handleReset, hasErrors } = useFormValidation({
    initialState,
    dataSchema: createPatientSchema,
  })
  const [save, { loading, error }] = useCreatePatientMutation({
    onCompleted({ createPatient }) {
      if (createPatient?.code === ReadyCoreStatusCodeEnum.OperationSuccessful) {
        onCreatePatient(createPatient.patient as Partial<Patient>)
        handleReset()
        return
      }
      if (createPatient?.code === ReadyCoreStatusCodeEnum.DuplicateResourceFound) {
        setDuplicatePatientError()
        return
      }
      setGenericServerError()
    },
  })
  const handleClose = () => {
    onClose()
    handleReset()
  }

  const createPatient = async () => {
    unsetGenericServerError()
    unsetDuplicatePatientError()
    let result
    try {
      result = await save({
        variables: { patient: values },
      })
    } catch (e) {
      // eslint-disable-next-line no-empty
    }
    if (!result) {
      setGenericServerError()
    }
  }

  return (
    <Modal
      fullWidth={true}
      maxWidth="sm"
      onClose={handleClose}
      open={open}
      title="Create a Patient"
      actions={
        <>
          <Button onClick={handleClose} variant="outlined" color="secondary">
            Cancel
          </Button>
          <Button disabled={loading || hasErrors} onClick={createPatient}>
            Create Patient
          </Button>
        </>
      }
    >
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <FirstNameInput
            onChange={(event) => handleSetValue('firstName', event.target.value)}
            value={values.firstName}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <LastNameInput onChange={(event) => handleSetValue('lastName', event.target.value)} value={values.lastName} />
        </Grid>

        <Grid item xs={12} sm={6}>
          <BirthdaySelect
            value={values.dob}
            onChange={(event) => handleSetValue('dob', event.target.value as string)}
            error={hasValueChanged('dob') && Boolean(errors?.dob?.message)}
            helperText={hasValueChanged('dob') && errors?.dob ? errors?.dob?.message : ''}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <SexSelect
            value={values.sex ?? ''}
            onChange={(newSex) => {
              handleSetValue('sex', newSex)
            }}
            required
            error={Boolean(hasValueChanged('sex') && errors?.sex?.message)}
            helperText={hasValueChanged('sex') && errors?.sex ? errors?.sex?.message : ''}
          />
        </Grid>

        <Grid item xs={12} sm={12}>
          <PhoneNumberInput
            id="preferredPhoneNumber"
            name="preferredPhoneNumber"
            label="Preferred Phone Number"
            value={values.preferredPhoneNumber ?? ''}
            onChange={(value) => handleSetValue('preferredPhoneNumber', value)}
            required
            error={hasValueChanged('preferredPhoneNumber') && Boolean(errors?.preferredPhoneNumber?.message)}
            helperText={
              hasValueChanged('preferredPhoneNumber') && errors?.preferredPhoneNumber
                ? t(errors?.preferredPhoneNumber?.message)
                : ''
            }
          />
        </Grid>

        {error && (
          <Grid item xs={12} sm={12}>
            {t('validation:patient.creation.hasValidationErrors')}
          </Grid>
        )}

        {/* generic error */}
        {hasGenericServerError && (
          <Grid item xs={12} sm={12}>
            <Box mt={4}>
              <Alert severity="error">{t('components:modules.Appointment.PatientCreateDialog.genericError')}</Alert>
            </Box>
          </Grid>
        )}

        {/* duplicate patient error */}
        {isDuplicatePatientError && (
          <Grid item xs={12} sm={12}>
            <Box mt={4}>
              <Alert severity="error">
                {t('components:modules.Appointment.PatientCreateDialog.serverError', {
                  context: ReadyCoreErrorCodesEnum.DuplicateResourceFound,
                })}
              </Alert>
            </Box>
          </Grid>
        )}
      </Grid>
    </Modal>
  )
}

export default PatientCreateDialog
