import { DeprecatedThemeOptions } from '@mui/material'
import { createTheme } from '@mui/material/styles'

import tokens from './tokens'

const overrideBoxShadows = [
  'none',
  '0 1px 2px 0 rgba(0, 0, 0, 0.05)',
  '0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)',
  '0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)',
  '0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)',
  '0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04)',
  '0 25px 50px -12px rgba(0, 0, 0, 0.25)',
]

const baseTheme = createTheme()
const shadows = [...baseTheme.shadows]
shadows.splice(0, overrideBoxShadows.length, ...overrideBoxShadows)

const theme = createTheme({
  shadows: shadows as DeprecatedThemeOptions['shadows'],
  spacing: 4,
  shape: {
    borderRadius: 0,
  },
  typography: {
    fontFamily: tokens.font.family,
    fontSize: +tokens.font.size.paragraph.base.replace(/\D/g, ''),
    h1: {
      fontSize: +tokens.font.size.heading[1].replace(/\D/g, ''),
      fontWeight: tokens.font.weight.normal,
    },
    h2: {
      fontSize: +tokens.font.size.heading[2].replace(/\D/g, ''),
      fontWeight: tokens.font.weight.normal,
    },
    h3: {
      fontSize: +tokens.font.size.heading[3].replace(/\D/g, ''),
      fontWeight: tokens.font.weight.normal,
    },
  },
  palette: {
    primary: {
      main: tokens.color.ui.charcoal.base,
    },
    secondary: {
      main: tokens.color.ui.slate.base,
    },
    error: {
      main: tokens.color.ui.error.base,
    },
    success: {
      main: tokens.color.ui.success.base,
    },
  },
  components: {
    MuiAutocomplete: {
      styleOverrides: {
        paper: {
          boxShadow: shadows[3],
        },
      },
    },
    MuiBreadcrumbs: {
      defaultProps: {
        separator: '/',
      },
      styleOverrides: {
        root: {
          fontSize: '1rem',
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        label: {
          userSelect: 'none' as const,
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          backgroundColor: tokens.color.neutral.white.base,
          '& input.Mui-disabled': {
            backgroundColor: tokens.color.neutral.black.transparent[6],
          },
        },
      },
    },
    MuiTextField: {
      defaultProps: {
        variant: 'outlined' as const,
        margin: 'dense' as const,
        size: 'small' as const,
        InputLabelProps: {
          shrink: true,
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          padding: 0,
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none' as const,
          '&:focus': {
            outline: 'rgba(0, 0, 0, 0.87) auto 2px',
          },
        },
      },
      defaultProps: {
        variant: 'contained' as const,
        size: 'small' as const,
        color: 'primary' as const,
      },
    },
    MuiButtonBase: {
      styleOverrides: {
        root: {
          textTransform: 'none' as const,
          '&:focus': {
            outline: `${baseTheme.palette.action.focus} auto 2px`,
          },
        },
      },
      defaultProps: {
        disableRipple: true, // No more ripple! 💣!
      },
    },
  },
})

export const muiTheme = theme

export default theme
