import hasFeatureFlag from 'utils/featureFlagsUtilities'
import * as Yup from 'yup'
import { modulesSchemaNullable } from 'yupSchemas/appointment/items'
import makeRequired from 'yupSchemas/helpers/makeRequired'

import { genderRequirementSchemaNullable, reasonForEncounterRequired, serviceLineRequired } from './items'

const READY_AT_HOME_COVID = 2

export const encountersSchema = Yup.object().shape({
  reasonForEncounter: reasonForEncounterRequired,
  genderRequirement: genderRequirementSchemaNullable,
  serviceLineId: serviceLineRequired,
  modules: modulesSchemaNullable,
})

export const encounterWithEmblemSchema = Yup.object().shape({
  reasonForEncounter: reasonForEncounterRequired,
  genderRequirement: genderRequirementSchemaNullable,
  serviceLineId: serviceLineRequired,
  modules: modulesSchemaNullable,

  // the following two fields are used to decide whether to exclude `ModuleNameEnum.Clinician` from `modules`
  // The code for this is currently only in Appointment CreateMode flow
  // as it is also dependent on the patient having an Emblem insurance.
  //
  // note: Add Patient flow has these fields, but they are not functional yet.
  //
  // the code for the above mentioned functionality looks something like this:
  // (modules/Appointments/Create/useAppointmentCreateState.ts ~line 205~)
  //
  //  const hasEmblemInsurance =
  //    isEmblem(Number(primaryInsurance?.packageId) ?? -1) || isEmblem(Number(secondaryInsurance?.packageId) ?? -1);
  //
  //  if (hasEmblemInsurance && patientHasSymptoms === false && patientCloseContactSymptoms === false) {
  //    modules = modules.filter((moduleName) => moduleName !== ModuleNameEnum.Clinician);
  //  }
  patientCloseContactSymptoms: Yup.boolean().when('serviceLineId', (serviceLineId: number, schema: Yup.BooleanSchema) =>
    hasFeatureFlag('showEmblemCovidQuestions') && serviceLineId === READY_AT_HOME_COVID
      ? makeRequired(Yup.boolean(), 'Please select an answer.')
      : schema.nullable(),
  ),
  patientHasSymptoms: Yup.boolean().when('serviceLineId', (serviceLineId: number, schema: Yup.BooleanSchema) =>
    hasFeatureFlag('showEmblemCovidQuestions') && serviceLineId === READY_AT_HOME_COVID
      ? makeRequired(Yup.boolean(), 'Please select an answer.')
      : schema.nullable(),
  ),
})
