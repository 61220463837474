/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import ZenDeskClient from 'utils/ZenDeskClient'

const setZendeskTicketAsConverted = () => {
  if (!ZenDeskClient) return

  ZenDeskClient.set(process.env.REACT_APP_ZENDESK_CALL_CONVERTED_FIELD, 'yes')
}

export default setZendeskTicketAsConverted
