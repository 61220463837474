import { FC, useState, useEffect, useMemo } from 'react'

import { useGetMarketByZipLazyQuery } from 'generated/graphql'

import { AcUnit } from '@mui/icons-material'
import Box from '@mui/material/Box'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'

const ZipCodeChecker: FC = () => {
  const [zip, setZip] = useState('')
  const [getMarket, { loading, data, error }] = useGetMarketByZipLazyQuery()

  useEffect(() => {
    if (zip.length !== 5) return
    getMarket({ variables: { zipCode: zip } })
  }, [zip, getMarket])

  const markets = data?.zipcode?.zones?.nodes
  const activeMarkets = markets?.filter(
    (market) => market.market?.isDeleted === false && market.market?.isActive === true,
  )
  const { market } = activeMarkets?.[0] ?? {}

  const inputState = useMemo<{ helperText: string | JSX.Element; state: 'error' | 'success' | 'neutral' }>(() => {
    if (loading) {
      return {
        helperText: 'Loading...',
        state: 'neutral',
      }
    }

    if (error) {
      return { helperText: 'There was an error checking the zipcode.', state: 'error' }
    }

    if (zip.length !== 5) {
      return { helperText: 'Not sure the patient is within the Ready service area? Look it up.', state: 'neutral' }
    }

    if (!/^[0-9]{5}$/.test(zip)) {
      return { helperText: 'Please enter a valid zip code.', state: 'error' }
    }

    if (!market) {
      return { helperText: 'This zip code is outside of Ready coverage area.', state: 'error' }
    }

    if (market?.isFrozen) {
      return {
        helperText: (
          <Box display="flex">
            <AcUnit fontSize="small" /> This market is currently frozen.
          </Box>
        ),
        state: 'error',
      }
    }

    if (!market.isFrozen) {
      return {
        helperText: <span style={{ color: '#108548' }}>Zip code is within the Ready service area.</span>,
        state: 'success',
      }
    }

    return { helperText: '', state: 'neutral' }
  }, [market, loading, error, zip])

  return (
    <Box sx={{ border: '1px solid darkgrey', padding: '1em' }}>
      <Typography variant="h3">Verify zip code is a Ready market</Typography>
      <TextField
        value={zip}
        onChange={(event) => {
          setZip(event.target.value)
        }}
        inputProps={{
          maxLength: 5,
        }}
        fullWidth
        variant="outlined"
        color={inputState.state === 'success' ? 'success' : undefined}
        error={inputState.state === 'error'}
        helperText={inputState.helperText}
      />
    </Box>
  )
}

export default ZipCodeChecker
