import { generatePath } from 'react-router-dom'

const Routes = {
  root: '/',
  markets: '/markets',
  newAppointments: `/appointments/new`,
  appointment: (id: string): string => `/appointments/${id}`,
  patient: (id: string): string => `/patient/${id}`,
  users: '/users',
  appointments: '/appointments',
  attribution: '/attribution',
}

export const appointmentsRoutes = {
  root: '/appointments',
  overview: '/appointments',
  details: '/appointments/:id',
  create: '/appointments/new',
}

export const marketsRoutes = {
  root: '/markets',
  overview: '/markets',
  details: '/markets/:id',
}

export const attributionRoutes = {
  root: '/attribution',
  overview: '/attribution',
}

// loosely create path or default to overview
export const generateAppointmentDetailsPath = (id?: string | number | null): string => {
  if (id == null) {
    return appointmentsRoutes.overview
  }
  return generatePath(appointmentsRoutes.details, { id })
}

export const generateMarketDetailsPath = (id?: string | number | null): string => {
  if (id == null) {
    return marketsRoutes.overview
  }
  return generatePath(marketsRoutes.details, { id })
}

export default Routes
