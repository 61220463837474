import { FC } from 'react'

import Tokens from 'config/tokens'
import { AppointmentPreviewEncountersFragment } from 'generated/graphql'

import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'

import LabeledItem from 'ui/LabeledItem'
import Age from 'ui/TextFormatters/Age'
import EncounterStatus from 'ui/TextFormatters/EncounterStatus'
import FullName from 'ui/TextFormatters/FullName'
import Sex from 'ui/TextFormatters/Sex'

import Note from './Note'
import { selectSortedExistingPatientNotes } from './selectors'

const AppointmentEncounterPreview: FC<{
  encounter?: AppointmentPreviewEncountersFragment | null
}> = ({ encounter }) => {
  if (!encounter) return null

  const patientNotes = selectSortedExistingPatientNotes(encounter)

  return (
    <Paper
      key={encounter.encounterId}
      elevation={0}
      style={{
        backgroundColor: Tokens.color.neutral.grey[240],
        padding: '1em',
      }}
    >
      <Grid container spacing={3} direction="row">
        <Grid item xs={12} sm={2}>
          <FullName firstName={encounter.patient?.firstName || ''} lastName={encounter.patient?.lastName || ''} />
          <br />
          <Age dob={encounter.patient?.dob} />, <Sex sex={encounter.patient?.sex} />
        </Grid>
        <Grid item xs={12} sm={10}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={3}>
              <LabeledItem label="Service Line">
                {encounter.serviceLine?.name}
                {' - '}
                {encounter.serviceLine?.subType}
              </LabeledItem>
            </Grid>
            <Grid item xs={12} sm={2}>
              <LabeledItem label="Status">
                <EncounterStatus responderStatus={encounter?.responderStatus} />
              </LabeledItem>
            </Grid>
            <Grid item xs={12} sm={7}>
              <LabeledItem label="Reason For Encounter">{encounter.reasonForEncounter}</LabeledItem>
            </Grid>
            {patientNotes && patientNotes.length > 0 && (
              <Grid item xs={12}>
                <LabeledItem label="High Priority Notes">
                  {patientNotes.map((note, index, notes) => (
                    <div
                      key={note.patientNoteId}
                      style={{
                        paddingTop: index !== 0 ? '1em' : 'none',
                        paddingBottom: index !== notes.length - 1 ? '1em' : 'none',
                        borderBottom:
                          index !== notes.length - 1 ? `${Tokens.border.size} ${Tokens.border.color} solid` : 'none',
                      }}
                    >
                      <Note note={note} />
                    </div>
                  ))}
                </LabeledItem>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  )
}

export default AppointmentEncounterPreview
