import { createContext, useContext, FC } from 'react'

const IsCreateModeContext = createContext(false)

/**
 *  Wrap AppointmentCreate in this context so children know they're in Create Mode
 */

export const IsCreateModeContextProvider: FC<unknown> = ({ children }) => (
  <IsCreateModeContext.Provider value={true}>{children}</IsCreateModeContext.Provider>
)

const useIsCreateModeContext = (): boolean => {
  const context = useContext(IsCreateModeContext)
  return context
}

export default useIsCreateModeContext
