import get from 'lodash/get'
import ZenDeskClient from 'utils/ZenDeskClient'

type ZenDeskClientTicketId = {
  'ticket.id': number
}

const getTicketId: () => Promise<number> = async () => {
  if (!ZenDeskClient) return -1
  return ZenDeskClient.get('ticket.id')
    .then((data: ZenDeskClientTicketId) => {
      const id = get(data, ['ticket.id'], -1)
      return id
    })
    .catch(() => -1)
}

export default getTicketId
