import { RescheduleReasonEnum } from 'generated/graphql'
import * as Yup from 'yup'
import { zipSchemaRequired, addressLatSchema, addressLngSchema } from 'yupSchemas/address/items'
import { marketIdSchemaRequired, modulesSchemaNullable, zoneIdSchemaRequired } from 'yupSchemas/appointment/items'
import { genderRequirementSchemaNullable, serviceLineRequired } from 'yupSchemas/encounters/items'
import makeRequired from 'yupSchemas/helpers/makeRequired'

const appointmentTimeSchema = Yup.object().shape({
  start: Yup.date(),
  end: Yup.date(),
  appointment: makeRequired(
    Yup.object().shape({
      address: makeRequired(Yup.string(), 'validation:appointmentTime.appointment.address.required'),
      zipcode: zipSchemaRequired,
      marketId: marketIdSchemaRequired,
      zoneId: zoneIdSchemaRequired,
      // we fetch lat/lng on fetchAvailability if they do not exist
      address_lat: addressLatSchema.nullable(),
      address_lng: addressLngSchema.nullable(),
    }),
    'appointmentTime.appointment.details.required',
  ),
  patients: Yup.array()
    .of(
      Yup.object().shape({
        serviceLineId: serviceLineRequired,
        modules: modulesSchemaNullable,
        responderGender: genderRequirementSchemaNullable,
      }),
    )
    .min(1, 'validation:appointmentTime.patients.required')
    .required('validation:appointmentTime.patients.required'),
})

// only for Reschedule flow (View-Only Appointment)

export type AppointmentTimeRescheduleSchema = {
  startTimes: Array<string> | string // we're stringifying these, allow string for now
  forceScheduling: boolean
  rescheduledReason: RescheduleReasonEnum | null
  resourceRequired: number
  jobType: string
  duration: number
  jobTags: Array<{ id: string }> | string // we're stringifying these, allow string for now
}

export const appointmentTimeRescheduleSchema = Yup.object().shape({
  startTimes: Yup.array()
    .of(Yup.string())
    .min(1, 'validation:appointmentTime.appointment.startTimes.required')
    .required('validation:appointmentTime.appointment.startTimes.required'),
  forceScheduling: Yup.boolean().nullable(),
  rescheduledReason: makeRequired(
    Yup.mixed<RescheduleReasonEnum>().oneOf(Object.values(RescheduleReasonEnum)),
    'appointmentTime.appointment.rescheduleReason.required',
  ),

  // Skedulo Attributes
  resourceRequired: Yup.number(),
  jobType: Yup.string(),
  duration: Yup.number(),
  jobTags: Yup.array().of(Yup.object().shape({ id: Yup.string() })),
})

export default appointmentTimeSchema
