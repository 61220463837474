import { FC } from 'react'

import Tokens from 'config/tokens'
import { useTranslation } from 'react-i18next'

import { Box, Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'

import DescriptionList from 'ui/DescriptionList'
import Spinner from 'ui/Spinner'

import { PatientInsuranceStateStatus } from './constants'
import useDisplayPatientInsuranceFormFieldsFromContext from './useDisplayPatientInsuranceFormFieldsFromContext'
import usePatientInsuranceStateFromContext from './usePatientInsuranceStateFromContext'

const useStyles = makeStyles({
  noInsuranceLabel: {
    color: Tokens.color.neutral.grey[102],
  },
})

const PatientInsuranceCardRead: FC<unknown> = () => {
  const { t } = useTranslation()
  const classes = useStyles()
  const { loading, variant, status } = usePatientInsuranceStateFromContext()
  const displayFields = useDisplayPatientInsuranceFormFieldsFromContext()

  if (loading)
    return (
      <Box width={1} my={8} display="flex" justifyContent="center">
        <Spinner />
      </Box>
    )

  if (status === PatientInsuranceStateStatus.Empty || !displayFields)
    return (
      <Typography className={classes.noInsuranceLabel} variant="body2">
        {t('components:modules.Patient.Insurance.noInsurance', { context: variant })}
      </Typography>
    )

  return <DescriptionList items={displayFields} dense />
}

export default PatientInsuranceCardRead
