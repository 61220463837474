import { CancellationReasonEnum } from 'generated/graphql'
import * as Yup from 'yup'
import makeRequired from 'yupSchemas/helpers/makeRequired'

export type CancelAppointmentSchema = {
  cancellationReason: CancellationReasonEnum | null
  cancellationReasonOther?: string | null
}

const cancelAppointmentSchema = Yup.object().shape({
  cancellationReason: makeRequired(
    Yup.string().oneOf(Object.values(CancellationReasonEnum), 'validation:appointment.cancellationReason.oneOf'),
    'validation:appointment.cancellationReason.required',
  ),
  cancellationReasonOther: Yup.string().when(
    'cancellationReason',
    (cancellationReason: CancelAppointmentSchema['cancellationReason'], schema: Yup.StringSchema) =>
      cancellationReason === CancellationReasonEnum.Other
        ? makeRequired(schema, 'validation:appointment.cancellationReasonOther.required')
        : schema.nullable(),
  ),
})

export default cancelAppointmentSchema
