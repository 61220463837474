import { createContext, useState, useRef } from 'react'

import theme from 'config/theme'

import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles'

import ConfirmModal, { ConfirmModalProps } from 'ui/ConfirmModal/ConfirmModal'

export const ConfirmContext = createContext<(props: ConfirmProps) => Promise<boolean>>(Promise.reject)

export type ConfirmProps = Pick<ConfirmModalProps, 'title' | 'message' | 'confirmText' | 'cancelText' | 'isDestructive'>

export const ConfirmProvider = ({ children }: Readonly<{ children: React.ReactNode }>): JSX.Element => {
  const [confirmProps, setConfirmProps] = useState<ConfirmProps>({})
  const [open, setOpen] = useState(false)

  const confirmPromise = useRef<{
    resolve: (value: boolean) => void
    reject: () => void
  }>()

  const handleCancel = () => {
    if (confirmPromise.current) {
      confirmPromise.current.resolve(false)
    }

    setOpen(false)
  }

  const handleConfirm = () => {
    if (confirmPromise.current) {
      confirmPromise.current.resolve(true)
    }

    setOpen(false)
  }

  const openConfirm = (props: ConfirmProps) => {
    setConfirmProps({ ...props })
    setOpen(true)
    return new Promise<boolean>((resolve, reject) => {
      confirmPromise.current = { resolve, reject }
    })
  }

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <ConfirmContext.Provider value={openConfirm}>
          {children}
          <ConfirmModal {...confirmProps} open={open} handleConfirm={handleConfirm} handleCancel={handleCancel} />
        </ConfirmContext.Provider>
      </ThemeProvider>
    </StyledEngineProvider>
  )
}
