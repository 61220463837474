/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { PatientPatchRecordInput } from 'generated/graphql'

const createUpdatePatientOptions = (patientId: number, patientPatch: Partial<PatientPatchRecordInput>) => {
  const baseOptions = {
    variables: {
      patientId,
      patientPatch: {
        ...patientPatch,
      },
    },
    optimisticResponse: {
      updatePatient: {
        patient: {
          patientId,
          ...patientPatch,
        },
      },
    },
  }
  return baseOptions
}

export default createUpdatePatientOptions
