/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import ZenDeskClient from 'utils/ZenDeskClient'

type RequestOptions = {
  url?: string
  data?: string
  method?: string
  type?: string
}

export const getZendeskLambdaOptions = (overrideOptions: RequestOptions) => ({
  url: '',
  headers: { 'x-api-key': process.env.REACT_APP_ZENDESK_LAMBDA_API_TOKEN || '{{setting.apiToken}}' },
  method: 'GET',
  contentType: 'application/json',
  dataType: 'json',
  secure: true,
  ...overrideOptions,
})

const makeZendeskLambdaRequest = (overrideOptions: RequestOptions) => {
  const options = getZendeskLambdaOptions(overrideOptions)

  return ZenDeskClient && ZenDeskClient.request && ZenDeskClient.request(options)
}

export default makeZendeskLambdaRequest
