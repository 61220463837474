import ControlledField from 'utils/ControlledField/ControlledField'
import safeJsonParse from 'utils/safeJsonParse'

import CommunicationNeedSelect from 'ui/Inputs/CommunicationNeedSelect'
import EmailInput from 'ui/Inputs/EmailInput/EmailInput'
import PhoneNumberInput from 'ui/Inputs/PhoneNumberInput'
import PreferredLanguageSelect from 'ui/Inputs/PreferredLanguageSelect'
import SpacedItems from 'ui/SpacedItems'

const CommunicationsEdit = (): JSX.Element => (
  <SpacedItems direction="column">
    <ControlledField name="preferredPhoneNumber" Component={PhoneNumberInput} />
    <ControlledField name="email" Component={EmailInput} />
    <ControlledField
      name="languages"
      Component={PreferredLanguageSelect}
      onChangeTransform={(val) => JSON.stringify(val.sort())}
      valueTransform={(value) => safeJsonParse(value)}
    />
    <ControlledField
      name="communicationNeed"
      Component={CommunicationNeedSelect}
      onChangeTransform={(val) => JSON.stringify(val.sort())}
      valueTransform={(value) => safeJsonParse(value)}
    />
  </SpacedItems>
)
export default CommunicationsEdit
