import { Appointment, Maybe } from 'generated/graphql'
import { useTranslation } from 'react-i18next'

export type StatusProps = {
  status?: Maybe<Appointment['status']>
}

function AppointmentStatus({ status }: StatusProps): JSX.Element {
  const { t } = useTranslation()
  return <>{(status && t('glossary:appointmentStatus', { context: status })) || '-'}</>
}

export default AppointmentStatus
