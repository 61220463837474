import { FC, useCallback, useEffect, useState } from 'react'

import { useDebounceState } from 'hooks/useDebounceState'

import TextField, { TextFieldProps } from '@mui/material/TextField'

export type FirstNameInputProps = Partial<TextFieldProps>

const ReasonForEncounter: FC<FirstNameInputProps> = ({ onChange = () => {}, value, ...props }) => {
  const [currentValue, setCurrentValue] = useState(value)
  const [debouncedValue, setDebouncedValue] = useDebounceState(value, 300)

  const handleOnChange: TextFieldProps['onChange'] = useCallback(
    (event) => {
      setCurrentValue(event.target.value)
      setDebouncedValue(event.target.value)
    },
    [setCurrentValue, setDebouncedValue],
  )

  useEffect(() => {
    onChange(debouncedValue)
  }, [debouncedValue])

  useEffect(() => {
    // We don't need to update from an external update when:
    // - Current & Debounced value are the same (no update pending)
    // - The Value is already the same as the current value
    if (currentValue === debouncedValue && value !== currentValue) {
      setCurrentValue(value)
      setDebouncedValue(value)
    }
  }, [value])

  return (
    <TextField
      id={`reasonForEncounter`}
      value={currentValue}
      {...props} // Options above this line can be overwritten
      onChange={handleOnChange}
      label="Reason for Encounter"
      multiline
      rows={4}
      inputProps={{ maxLength: 255 }}
    />
  )
}

export default ReasonForEncounter
