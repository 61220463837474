import { yupResolver } from '@hookform/resolvers/yup'
import { generateAppointmentDetailsPath } from 'config/routes'
import { isNotNilOrEmpty } from 'fp/isNilOrEmpty'
import {
  ModuleNameEnum,
  Patient,
  SexEnum,
  SystemOfOriginEnum,
  useCreateSkeduloAppointmentMutation,
  useGetFullAppointmentPreviewLazyQuery,
  useGetPatientQuery,
} from 'generated/graphql'
import useCreateMutatatedDispatch from 'hooks/useCreateMutatedDispatch'
import useInitZendesk from 'hooks/useInitZendesk'
import useToggle from 'hooks/useToggle'
import useUserContext from 'hooks/useUserContext'
import { selectCreateSkeduloPatientInputByEncounter } from 'modules/Appointment/selectors'
import { selectIsAnyEncounterServiceLinesCommunityCareEnrollment } from 'modules/AppointmentPreviewModal/selectors'
import InsuranceRelationshipToPolicyHolder, {
  InsuranceRelationshipToPolicyHolderRCOMap,
} from 'modules/Patient/Insurance/InsuranceRelationshipToPolicyHolder'
import { SubmitHandler, useFieldArray, useForm } from 'react-hook-form'
import { useHistory } from 'react-router-dom'
import isEmblem from 'utils/isEmblem'
import isInZendeskSidebar from 'utils/isInZendeskSidebar'
import { formatUSPhoneNumberToE164 } from 'utils/phone/format'
import safeJsonParse from 'utils/safeJsonParse'
import createInternalNote from 'utils/zendesk/createInternalNote'
import setZendeskTicketAsConverted from 'utils/zendesk/setZendeskTicketAsConverted'
import writeRedshift from 'utils/zendesk/writeRedshift'
import createAppointmentSchema, { createAppointmentSchemaReadyZen } from 'yupSchemas/appointment/createAppointment'
import { isSelf } from 'yupSchemas/insurance'

import AppointmentCreateFormSchema from './AppointmentCreateFormSchema'

const selectPolicyHolderInfoByPatient = (patient?: Pick<Patient, 'firstName' | 'lastName' | 'sex' | 'dob'> | null) => {
  if (!patient) {
    return null
  }
  const { firstName, lastName, sex, dob } = patient
  return {
    policyHolderFirstName: firstName ?? null,
    policyHolderLastName: lastName ?? null,
    policyHolderGender: sex ?? null,
    policyHolderDob: dob ?? null,
  }
}

const useAppointmentCreateState = () => {
  const history = useHistory()
  const userContext = useUserContext()
  const zendeskData = useInitZendesk()

  const [hasGenericServerError, toggleHasGenericServerError] = useToggle(false)

  const [doAppointmentPostCreation] = useGetFullAppointmentPreviewLazyQuery({
    onCompleted: (data) => {
      const { appointment } = data
      createInternalNote(appointment)

      writeRedshift(appointment)
      setZendeskTicketAsConverted()

      const hasCommunityCareVisit = selectIsAnyEncounterServiceLinesCommunityCareEnrollment(data)

      const appointmentId = appointment?.appointmentId
      if (appointmentId) {
        history.push(generateAppointmentDetailsPath(appointmentId), {
          showCreateVisitInHealthCallModal: hasCommunityCareVisit,
        })
      }
    },
  })
  const [createSkeduloAppointment, { loading }] = useCreateSkeduloAppointmentMutation({
    onError() {
      toggleHasGenericServerError(true)
    },
    onCompleted({ createSkeduloAppointment: response }) {
      const appointmentId = response?.appointmentId
      if (appointmentId == null) {
        toggleHasGenericServerError(true)
        return
      }
      if (response && !response.error) {
        // We need to manually fetch the appointment that was created because createSkeduloAppointment only can return an appointment id, not the full appointment payload
        doAppointmentPostCreation({ variables: { appointmentId } })
      }
    },
  })

  const methods = useForm<AppointmentCreateFormSchema>({
    mode: 'onBlur',
    reValidateMode: 'onChange',
    defaultValues: {
      patients: [],

      // Appointment Time
      startTimes: JSON.stringify([]),
      forceScheduling: false,

      // Skedulo Attributes
      resourceRequired: 0,
      jobType: '',
      duration: 0,
      jobTags: JSON.stringify([]),

      // Appointment address
      address: {
        address1: null,
        address2: null,
        city: null,
        state: null,
        zip: null,
      },
      geoLocation: { addressLat: null, addressLng: null },

      unit: null,
      specialInstructions: null,

      marketId: null,
      zoneId: null,
      marketOverride: false,

      // insurances for each patient (added to every encounter for a patient)
      primaryInsurances: {},
      secondaryInsurances: {},
    },
    resolver: yupResolver(isInZendeskSidebar() ? createAppointmentSchemaReadyZen : createAppointmentSchema),
  })

  const { control, handleSubmit } = methods

  const {
    fields: patientFields,
    append: appendPatient,
    remove: removePatient,
  } = useFieldArray({
    control,
    name: 'patients',
    keyName: 'patientId',
  })

  // First Patient is used for Appointment Contact, can be removed when we add Appointment Contact Section
  const firstPatientField = patientFields[0] ?? null
  const { data: firstPatient } = useGetPatientQuery({
    variables: { patientId: firstPatientField?.patientId },
    skip: !firstPatientField,
  })

  const handleCreateAppointment: SubmitHandler<AppointmentCreateFormSchema> = ({
    address,
    geoLocation,

    unit,
    specialInstructions,

    marketId,
    zoneId,
    marketOverride,

    startTimes,
    forceScheduling,
    resourceRequired,
    jobType,
    duration,
    jobTags,

    // patients & encounters,
    primaryInsurances,
    secondaryInsurances,
    patients,
  }) => {
    const { address1, city, state, zip } = address
    const { addressLat, addressLng } = geoLocation

    toggleHasGenericServerError(false)

    if (marketId === null || zoneId === null) {
      return
    }

    const patientData = selectCreateSkeduloPatientInputByEncounter(patients) ?? []

    // AD-HOC Add channelAttributionId to every encounter of a patient
    const patientDataWithAttributionId = patientData.map((patient) => {
      const primaryInsurance = primaryInsurances[patient.patientId]
      const secondaryInsurance = secondaryInsurances[patient.patientId]

      const hasEmblemInsurance =
        isEmblem(Number(primaryInsurance?.packageId) ?? -1) || isEmblem(Number(secondaryInsurance?.packageId) ?? -1)

      // we assign the channelAttributionId of the first patient to all patients
      // in create mode we only have one channelAttributionId dropdown
      // see: src/modules/Appointment/AppointmentPartnership/AppointmentPartnershipEdit.tsx
      const patientField = patients[0]
      if (!patientField) {
        return patient
      }
      return {
        ...patient,
        encounters: patient.encounters.map(({ patientHasSymptoms, patientCloseContactSymptoms, ...encounter }) => {
          let modules = encounter.modules as ModuleNameEnum[]

          if (hasEmblemInsurance && patientHasSymptoms === false && patientCloseContactSymptoms === false) {
            modules = modules.filter((moduleName) => moduleName !== ModuleNameEnum.Clinician)
          }

          return {
            ...encounter,
            modules,
            channelAttributionId: patientField.channelAttributionId,
          }
        }),
      }
    })

    // TEMPORARY IMPLEMENTATION, should be refactored with SKED-572
    // the current form shape is temporary, this all needs to become part of patients
    // preferably wrapped in a controlledField
    const patientDataWithAttributionIdAndInsurances = patientDataWithAttributionId.map(
      ({ patientId, encounters: patientEncounters, ...restPatient }) => {
        const { insuranceRecordId: ignoredValue1, ...primaryInsurance } = primaryInsurances[patientId] || {}
        const { insuranceRecordId: ignoredValue2, ...secondaryInsurance } = secondaryInsurances[patientId] || {}
        const hasPrimaryInsurance = primaryInsurance && Object.values(primaryInsurance).some(isNotNilOrEmpty)
        const hasSecondaryInsurance = secondaryInsurance && Object.values(secondaryInsurance).some(isNotNilOrEmpty)

        // if relationshipToPolicyholder is "SELF" we need to pass patient info from patient data
        const isPrimarySelfRelationship = isSelf(primaryInsurance?.relationshipToPolicyHolder)
        const isSecondarySelfRelationship = isSelf(secondaryInsurance?.relationshipToPolicyHolder)
        const currentPatient = patientFields.find((patient) => patient.patientId === patientId)
        const currentPatientPolicyHolderInfo = selectPolicyHolderInfoByPatient(currentPatient)

        // map our frontend Enums to RCO values
        // we format them as frontend enums in: src/modules/Patient/Insurance/usePatientInsuranceStateFromContext.tsx
        const primaryRelationshipToPolicyHolderRCOValue = InsuranceRelationshipToPolicyHolderRCOMap.get(
          primaryInsurance?.relationshipToPolicyHolder as InsuranceRelationshipToPolicyHolder,
        )
        const secondaryRelationshipToPolicyHolderRCOValue = InsuranceRelationshipToPolicyHolderRCOMap.get(
          secondaryInsurance?.relationshipToPolicyHolder as InsuranceRelationshipToPolicyHolder,
        )

        return {
          patientId,
          encounters: patientEncounters.map(
            (encounter: {
              serviceLineId: number
              modules: ModuleNameEnum[]
              genderRequirement: SexEnum | null
              reasonForEncounter: string
              channelAttributionId?: number
            }) => ({
              ...encounter,
              ...(hasPrimaryInsurance && {
                primaryInsurance: {
                  ...primaryInsurance,
                  ...(primaryRelationshipToPolicyHolderRCOValue && {
                    relationshipToPolicyHolder: primaryRelationshipToPolicyHolderRCOValue,
                  }),
                  ...(isPrimarySelfRelationship && currentPatientPolicyHolderInfo),
                },
              }),
              ...(hasSecondaryInsurance && {
                secondaryInsurance: {
                  ...secondaryInsurance,
                  ...(secondaryRelationshipToPolicyHolderRCOValue && {
                    relationshipToPolicyHolder: secondaryRelationshipToPolicyHolderRCOValue,
                  }),
                  ...(isSecondarySelfRelationship && currentPatientPolicyHolderInfo),
                },
              }),
            }),
          ),
          ...restPatient,
        }
      },
    )

    const patient = firstPatient?.patient

    const parsedStartTimes = safeJsonParse(startTimes)
    const createAppointmentPayload = {
      appointment: {
        address1,
        city,
        state,
        addressLat,
        addressLng,
        unit,
        contactName: `${patient?.firstName ?? ''} ${patient?.lastName ?? ''}`,
        // temporary fix, set to +18888888888, replaced by SKED-590, SKED-186, SKED-48.
        primaryPhone: formatUSPhoneNumberToE164(`${patient?.preferredPhoneNumber ?? '+18888888888'}`),
        marketId,
        zoneId,
        zipCode: zip,
        marketOverride,
        forceScheduling,
        systemOfOrigin: isInZendeskSidebar() ? SystemOfOriginEnum.ReadyZen : SystemOfOriginEnum.ReadyOps,
        specialInstructions,
        createdBy: userContext?.user?.userId,
        startTimes: !forceScheduling ? parsedStartTimes : [parsedStartTimes[0]],
        duration,
        jobType,
        jobTags: safeJsonParse(jobTags),
        resourceRequired,
        ...(isInZendeskSidebar() &&
          zendeskData && { callRailNumber: zendeskData?.callRail?.tracking_phone_number ?? null }),
      },
      patients: patientDataWithAttributionIdAndInsurances,
    }

    createSkeduloAppointment({
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      variables: createAppointmentPayload,
    })
  }

  const submit = useCreateMutatatedDispatch(
    handleSubmit(handleCreateAppointment, (e) =>
      // eslint-disable-next-line no-console
      console.log('client-side validation Errors:', e),
    ),
  )

  return { methods, submit, loading, hasGenericServerError, patientFields, appendPatient, removePatient }
}

export default useAppointmentCreateState
