import { FC, useCallback } from 'react'

import { FetchResult } from '@apollo/client'
import Tokens from 'config/tokens'
import { CreatePatientNoteMutation } from 'generated/graphql'
import useFormValidation from 'hooks/useFormValidation'
import { PaperPlaneTilt } from 'phosphor-react'
import { useTranslation } from 'react-i18next'
import { useToggle } from 'react-use'
import createPatientNoteSchema from 'yupSchemas/patientNote/createPatientNote'

import { Alert, TextField } from '@mui/material'
import Button from '@mui/material/Button'
import Box from '@mui/system/Box'

import Modal from 'ui/Modal'
import Spinner from 'ui/Spinner'

type AddPatientNoteDialogProps = {
  open: boolean
  onClose: () => void
  onSubmit: ({
    note,
    isHighPriority,
  }: {
    note: string
    isHighPriority: boolean
  }) => Promise<FetchResult<CreatePatientNoteMutation> | null>
  loading: boolean
  isHighPriority: boolean
}

const initialState = {
  note: '',
}

const AddPatientNoteDialog: FC<AddPatientNoteDialogProps> = ({
  open,
  onClose,
  onSubmit,
  loading,
  isHighPriority = false,
}) => {
  const { t } = useTranslation()
  const [showGenericError, toggleShowGenericError] = useToggle(false)
  const { values, handleSetValue, handleReset, hasErrors } = useFormValidation({
    initialState,
    dataSchema: createPatientNoteSchema,
  })

  const handleClose = useCallback(() => {
    onClose()
    handleReset()
    toggleShowGenericError(false)
  }, [onClose, handleReset])

  const handleSubmit = useCallback(async () => {
    toggleShowGenericError(false)

    let fetchResult
    try {
      fetchResult = await onSubmit({ ...values, isHighPriority })
      // eslint-disable-next-line no-empty
    } catch (error) {
      // we don't need this as we are only showing a generic error, which is handled by fetchResult being nil
    }

    if (!fetchResult) {
      toggleShowGenericError(true)
      return
    }

    handleReset()
    onClose()
  }, [values, onSubmit, handleReset])

  return (
    <Modal
      fullWidth={true}
      maxWidth="xs"
      open={open}
      onClose={handleClose}
      title={isHighPriority ? 'New Safety Notification' : 'New Note'}
      actions={
        <>
          <Button variant="outlined" color="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button
            onClick={handleSubmit}
            type="submit"
            disabled={loading || hasErrors}
            style={{ width: '6rem' }}
            startIcon={
              !loading ? (
                <PaperPlaneTilt
                  size={14}
                  color={hasErrors ? Tokens.color.neutral.grey[82] : Tokens.color.neutral.white.base}
                  style={{ position: 'relative', top: 1 }}
                />
              ) : (
                <Spinner size="small" foregroundColor={loading ? Tokens.color.neutral.black.base : undefined} />
              )
            }
          >
            Save
          </Button>
        </>
      }
    >
      <form noValidate>
        <TextField
          fullWidth
          multiline
          rows={10}
          placeholder={
            isHighPriority
              ? 'Write your Responder safety notification here. Please only add important safety information here.'
              : 'Write your note here. Please keep general information and Responder safety information separate.'
          }
          id="PatientNote-note"
          disabled={loading}
          onChange={(event) => handleSetValue('note', event.target.value)}
        />
      </form>

      {showGenericError && (
        <Box mt={4}>
          <Alert severity="error">{t('components:modules.Patient.EncountersCancelDialog.genericError')}</Alert>
        </Box>
      )}
    </Modal>
  )
}

export default AddPatientNoteDialog
