enum InsuranceRelationshipToPolicyHolder {
  Self = 'SELF',
  Spouse = 'SPOUSE',
  Child = 'CHILD',
  Mother = 'MOTHER',
  Father = 'FATHER',
  Grandparent = 'GRANDPARENT',
  Grandchild = 'GRANDCHILD',
  NephewOrNiece = 'NEPHEW_OR_NIECE',
  AdoptedChild = 'ADOPTED_CHILD',
  FosterChild = 'FOSTER_CHILD',
  Ward = 'WARD',
  StepSonOrDaughter = 'STEPSON_OR_DAUGHTER',
  Employee = 'EMPLOYEE',
  Unknown = 'UNKNOWN',
  HandicappedDependent = 'HANDICAPPED_DEPENDENT',
  SponsoredDependent = 'SPONSORED_DEPENDENT',
  DependentOfAMinorDependent = 'DEPENDENT_OF_A_MINOR_DEPENDENT',
  SignificantOther = 'SIGNIFICANT_OTHER',
  Other = 'OTHER',
  OtherAdult = 'OTHER_ADULT',
  EmancipatedMinor = 'EMANCIPATED_MINOR',
  OrganDonor = 'ORGAN_DONOR',
  CadaverDonor = 'CADAVER_DONOR',
  InjuredPlaintiff = 'INJURED_PLAINTIFF',
  ChildInsNotFinanciallyRespons = 'CHILD_INS_NOT_FINANCIALLY_RESPONS',
  LifePartner = 'LIFE_PARTNER',
  ChildMothersInsurance = 'CHILD_MOTHERS_INSURANCE',
  ChildFathersInsurance = 'CHILD_FATHERS_INSURANCE',
  ChildMothersInsInsNotFinanciallyRespons = 'CHILD_MOTHERS_INS_INS_NOT_FINANCIALLY_RESPONS',
  ChildFathersInsInsNotFinanciallyRespons = 'CHILD_FATHERS_INS_INS_NOT_FINANCIALLY_RESPONS',
  StepsonOrStepDaughterStepmothersInsurance = 'STEPSON_OR_STEPDAUGHTER_STEPMOTHERS_INSURANCE',
  StepsonOrStepDaughterStepfathersInsurance = 'STEPSON_OR_STEPDAUGHTER_STEPFATHERS_INSURANCE',
}

export const InsuranceRelationshipToPolicyHolderRCOMap = new Map<
  InsuranceRelationshipToPolicyHolder | null | undefined,
  string
>([
  [InsuranceRelationshipToPolicyHolder.Self, 'self'],
  [InsuranceRelationshipToPolicyHolder.Spouse, 'spouse'],
  [InsuranceRelationshipToPolicyHolder.Child, 'child'],
  [InsuranceRelationshipToPolicyHolder.Mother, 'mother'],
  [InsuranceRelationshipToPolicyHolder.Father, 'father'],
  [InsuranceRelationshipToPolicyHolder.Grandparent, 'grandparent'],
  [InsuranceRelationshipToPolicyHolder.Grandchild, 'grandchild'],
  [InsuranceRelationshipToPolicyHolder.NephewOrNiece, 'nephewOrNiece'],
  [InsuranceRelationshipToPolicyHolder.AdoptedChild, 'adoptedChild'],
  [InsuranceRelationshipToPolicyHolder.FosterChild, 'fosterChild'],
  [InsuranceRelationshipToPolicyHolder.Ward, 'ward'],
  [InsuranceRelationshipToPolicyHolder.StepSonOrDaughter, 'stepsonOrStepDaughter'],
  [InsuranceRelationshipToPolicyHolder.Employee, 'employee'],
  [InsuranceRelationshipToPolicyHolder.Unknown, 'unknown'],
  [InsuranceRelationshipToPolicyHolder.HandicappedDependent, 'handicappedDependent'],
  [InsuranceRelationshipToPolicyHolder.SponsoredDependent, 'sponsoredDependent'],
  [InsuranceRelationshipToPolicyHolder.DependentOfAMinorDependent, 'dependentOfAMinorDependent'],
  [InsuranceRelationshipToPolicyHolder.SignificantOther, 'significantOther'],
  [InsuranceRelationshipToPolicyHolder.Other, 'other'],
  [InsuranceRelationshipToPolicyHolder.OtherAdult, 'otherAdult'],
  [InsuranceRelationshipToPolicyHolder.EmancipatedMinor, 'emancipatedMinor'],
  [InsuranceRelationshipToPolicyHolder.OrganDonor, 'organDonor'],
  [InsuranceRelationshipToPolicyHolder.CadaverDonor, 'cadaverDonor'],
  [InsuranceRelationshipToPolicyHolder.InjuredPlaintiff, 'injuredPlaintiff'],
  [InsuranceRelationshipToPolicyHolder.ChildInsNotFinanciallyRespons, 'childInsNotFinanciallyResponsible'],
  [InsuranceRelationshipToPolicyHolder.LifePartner, 'lifePartner'],
  [InsuranceRelationshipToPolicyHolder.ChildMothersInsurance, 'childMothersInsurance'],
  [InsuranceRelationshipToPolicyHolder.ChildFathersInsurance, 'childFathersInsurance'],
  [
    InsuranceRelationshipToPolicyHolder.ChildMothersInsInsNotFinanciallyRespons,
    'childMothersInsuranceNotFinanciallyResponsible',
  ],
  [
    InsuranceRelationshipToPolicyHolder.ChildFathersInsInsNotFinanciallyRespons,
    'childFathersInsuranceNotFinanciallyResponsible',
  ],
  [
    InsuranceRelationshipToPolicyHolder.StepsonOrStepDaughterStepmothersInsurance,
    'stepsonOrStepDaughterStepMothersInsurance',
  ],
  [
    InsuranceRelationshipToPolicyHolder.StepsonOrStepDaughterStepfathersInsurance,
    'stepsonOrStepDaughterStepFathersInsurance',
  ],
])

export default InsuranceRelationshipToPolicyHolder
