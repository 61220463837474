import { FC, memo } from 'react'

import useToggle from 'hooks/useToggle'
import NameAndDemographicsEdit from 'modules/Patient/NameAndDemographics/NameAndDemographicsEdit'
import NameAndDemographicsRead from 'modules/Patient/NameAndDemographics/NameAndDemographicsRead'
import { FormProvider } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import CardLabeled from 'ui/CardLabeled'

import useNameAndDemographicsState from './useNameAndDemographicsState'

const NameAndDemographicsCard: FC<{ patientId: number }> = ({ patientId }) => {
  const { t } = useTranslation()
  const [isEditing, , setEditing, unsetEditing] = useToggle(false)
  const { loading, methods, reset, set } = useNameAndDemographicsState({
    patientId,
    onSet: unsetEditing,
    onReset: unsetEditing,
  })

  const {
    formState: { isDirty, isValid },
  } = methods

  if (loading) return null

  return (
    <FormProvider {...methods}>
      <CardLabeled
        title={t('components:modules.Patient.NameAndDemographicsCard.title')}
        testId="NameAndDemographicsCard"
        editing={isEditing}
        onEdit={setEditing}
        onCancel={reset}
        onAccept={set}
        acceptDisabled={!isDirty || !isValid}
      >
        {!isEditing ? <NameAndDemographicsRead /> : <NameAndDemographicsEdit />}
      </CardLabeled>
    </FormProvider>
  )
}

export default memo(NameAndDemographicsCard)
