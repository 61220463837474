import { FC, useCallback, useMemo } from 'react'

import { FetchResult } from '@apollo/client'
import { yupResolver } from '@hookform/resolvers/yup'
import Tokens from 'config/tokens'
import { CancelEncountersMutation, CancellationReasonEnum } from 'generated/graphql'
import useGetAppointmentQueryContext from 'modules/Appointment/useGetAppointmentQueryContext'
import { ProhibitInset } from 'phosphor-react'
import { useForm, FormProvider, useWatch } from 'react-hook-form'
import { Trans, useTranslation } from 'react-i18next'
import { useToggle } from 'react-use'
import ControlledField from 'utils/ControlledField/ControlledField'
import hasFeatureFlag from 'utils/featureFlagsUtilities'
import cancelEncountersSchema, { CancelEncountersSchema } from 'yupSchemas/encounters/cancelEncounters'

import { Alert, Typography, FormControl, FormGroup, FormControlLabel, Checkbox } from '@mui/material'
import Button from '@mui/material/Button'
import Box from '@mui/system/Box'

import CancellationReasonSelect from 'ui/Inputs/CancellationReasonSelect'
import Modal from 'ui/Modal'
import SpacedItems from 'ui/SpacedItems'
import Spinner from 'ui/Spinner'

import EncountersCancellationReasonOtherInput from './EncountersCancellationReasonOtherInput'
import { createEncounterIdsByCheckboxIdMap, selectCancellableEncounters } from './selectors'

const initialState = {
  cancellationReason: null,
  cancellationReasonOther: null,
  encounterIds: [],
}

type EncountersCancelDialogProps = {
  patientId: number
  open: boolean
  onClose: () => void
  onSubmit: ({
    cancellationReason,
    cancellationReasonOther,
    encounterIds,
  }: CancelEncountersSchema) => Promise<FetchResult<CancelEncountersMutation> | null>
  loading: boolean
}

const useCancellableEncounters = (patientId: number) => {
  const { data } = useGetAppointmentQueryContext()
  const encounters = selectCancellableEncounters(data, { patientId })
  const encounterIds = useMemo(
    () => (encounters ? encounters.map(({ encounterId }) => encounterId) : null),
    [encounters],
  )

  return { encounters, encounterIds, isSingleEncounter: encounters?.length === 1 }
}

const isDescriptionNeeded = (reason: CancellationReasonEnum | null) => {
  if (reason === CancellationReasonEnum.Other) {
    return true
  }
  if (hasFeatureFlag('showUpdatedCancellationReasons')) {
    if (
      reason === CancellationReasonEnum.ServicesNotAvailable ||
      reason === CancellationReasonEnum.SoughtCareElsewhere ||
      reason === CancellationReasonEnum.CapacityRelated
    ) {
      return true
    }
  }
  return false
}

const EncountersCancelDialog: FC<EncountersCancelDialogProps> = ({ patientId, open, onClose, onSubmit, loading }) => {
  const { t } = useTranslation()
  const [showGenericError, toggleShowGenericError] = useToggle(false)
  const { encounters, encounterIds, isSingleEncounter } = useCancellableEncounters(patientId)

  const methods = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: { ...initialState, ...(isSingleEncounter && { encounterIds }) },
    resolver: yupResolver(cancelEncountersSchema),
  })

  // there's an issue with this form (related to the checkboxes),
  // where the component doesn't rerender after every formstate change
  // this watch forces re-renders
  // the re-renders are necessary as the "cancellationReasonOther" relies on 'cancellationReason' to be shown or hidden
  useWatch({
    control: methods.control,
    name: 'cancellationReason',
  })

  const {
    reset,
    watch,
    handleSubmit,
    formState: { isValid, isDirty },
    register,
  } = methods

  const handleClose = useCallback(() => {
    onClose()
    reset()
    toggleShowGenericError(false)
  }, [onClose, reset])

  const handleSubmitCancel = useCallback(
    async ({ encounterIds: encounterIdOrCheckboxIdMap, cancellationReason, cancellationReasonOther }) => {
      if (!encounterIds) {
        toggleShowGenericError(true)
        return
      }

      toggleShowGenericError(false)

      const inputEncounterIds = isSingleEncounter
        ? encounterIdOrCheckboxIdMap
        : createEncounterIdsByCheckboxIdMap(encounterIdOrCheckboxIdMap, encounterIds)

      let fetchResult
      try {
        fetchResult = await onSubmit({
          cancellationReason,
          cancellationReasonOther,
          encounterIds: inputEncounterIds,
        })
        // eslint-disable-next-line no-empty
      } catch (error) {
        // we don't need this as we are only showing a generic error, which is handled by fetchResult being nil
      }

      if (!fetchResult) {
        toggleShowGenericError(true)
        return
      }

      reset()
      onClose()
    },
    [encounterIds, isSingleEncounter, handleSubmit],
  )

  const cancellationReason = watch('cancellationReason')

  return (
    <Modal
      open={open}
      fullWidth={true}
      maxWidth="sm"
      onClose={handleClose}
      title={t('components:modules.Patient.EncountersCancelDialog.heading')}
      actions={
        <>
          <Button variant="outlined" color="secondary" onClick={handleClose}>
            {t('components:modules.Patient.EncountersCancelDialog.cancelButton')}
          </Button>

          <Button
            color="error"
            onClick={handleSubmit(handleSubmitCancel)}
            disabled={loading || !isValid || !isDirty}
            endIcon={
              <>
                {loading ? (
                  <Spinner size="small" foregroundColor={Tokens.color.neutral.black.base} />
                ) : (
                  <ProhibitInset
                    size={14}
                    color={!isValid ? Tokens.color.neutral.grey[82] : Tokens.color.neutral.white.base}
                  />
                )}
              </>
            }
          >
            {t('components:modules.Patient.EncountersCancelDialog.submitButton')}
          </Button>
        </>
      }
    >
      <Box mb={4}>
        <Typography>
          <Trans
            t={t}
            i18nKey="components:modules.Patient.EncountersCancelDialog.body"
            tOptions={isSingleEncounter ? undefined : { context: 'multiple' }}
          />
        </Typography>
      </Box>

      <FormProvider {...methods}>
        {isSingleEncounter ? (
          <input type="hidden" {...register('encounterIds.0')} />
        ) : (
          <Box mt={8} mb={12} ml={8}>
            <FormControl component="fieldset">
              <FormGroup>
                {encounters?.map(({ encounterId, serviceLine }, key) => (
                  <FormControlLabel
                    label={serviceLine?.friendlyName ?? encounterId}
                    control={<ControlledField name={`encounterIds.${key}`} Component={Checkbox} />}
                  />
                ))}
              </FormGroup>
            </FormControl>
          </Box>
        )}

        <SpacedItems direction="column">
          <ControlledField
            name="cancellationReason"
            Component={CancellationReasonSelect}
            ComponentProps={{ fullWidth: true, showNone: false }}
          />
          {isDescriptionNeeded(cancellationReason) && (
            <ControlledField name="cancellationReasonOther" Component={EncountersCancellationReasonOtherInput} />
          )}
        </SpacedItems>
      </FormProvider>

      {showGenericError && (
        <Box mt={4}>
          <Alert severity="error">{t('components:modules.Patient.EncountersCancelDialog.genericError')}</Alert>
        </Box>
      )}
    </Modal>
  )
}

export default EncountersCancelDialog
