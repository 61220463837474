"use strict";
/**
 * --------------------------------------------------------
 * ReadyCore custom error codes.
 * --------------------------------------------------------
 */
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.ReadyCoreErrorMessages = exports.ReadyCoreErrorCodesEnum = void 0;
var ReadyCoreErrorCodesEnum;
(function (ReadyCoreErrorCodesEnum) {
    ReadyCoreErrorCodesEnum["OperationSuccessful"] = "OPERATION_SUCCESSFUL";
    ReadyCoreErrorCodesEnum["DuplicateResourceFound"] = "DUPLICATE_RESOURCE_FOUND";
    ReadyCoreErrorCodesEnum["ResourceNotImplemented"] = "RESOURCE_NOT_IMPLEMENTED";
    ReadyCoreErrorCodesEnum["BadRequest"] = "BAD_REQUEST";
    ReadyCoreErrorCodesEnum["DataValidationFailed"] = "DATA_VALIDATION_FAILED";
})(ReadyCoreErrorCodesEnum = exports.ReadyCoreErrorCodesEnum || (exports.ReadyCoreErrorCodesEnum = {}));
;
/**
 * --------------------------------------------------------
 * ReadyCore custom Error message map.
 * --------------------------------------------------------
 */
exports.ReadyCoreErrorMessages = (_a = {},
    _a[ReadyCoreErrorCodesEnum.OperationSuccessful] = 'Operation completed successfully.',
    _a[ReadyCoreErrorCodesEnum.DuplicateResourceFound] = 'A duplicate resource was found.',
    _a[ReadyCoreErrorCodesEnum.ResourceNotImplemented] = 'The requested resource is not implemented.',
    _a[ReadyCoreErrorCodesEnum.BadRequest] = 'The request is invalid and cannot be processed.',
    _a[ReadyCoreErrorCodesEnum.DataValidationFailed] = 'The request input data is invalid.',
    _a);
