import { RaceEnum } from 'generated/graphql'

import Checkbox from '@mui/material/Checkbox'
import MenuItem from '@mui/material/MenuItem'
import TextField, { OutlinedTextFieldProps } from '@mui/material/TextField'

import { formatRace, RaceNullable } from 'ui/TextFormatters/Races/Races'

export type RacesSelectProps = Partial<Omit<OutlinedTextFieldProps, 'onChange'>> & {
  value?: RaceNullable[]
  onChange: (newValue: RaceEnum[]) => void
}

const removeNullValues = (values: RaceNullable[]) => values.filter((value) => value !== null)

const RacesSelect = ({ onChange, SelectProps, value, ...props }: RacesSelectProps): JSX.Element => {
  const handleOnChange = (e: React.ChangeEvent<{ value: unknown }>) => {
    const newValue = (e.target.value as RaceEnum[]) ?? []
    onChange(newValue)
  }

  const valuesWithoutNull = removeNullValues(value ?? [])

  return (
    <TextField
      fullWidth
      name="race_n_ethnicity"
      {...props}
      value={valuesWithoutNull}
      select
      label="Races & Ethnicity"
      SelectProps={{
        // ...SelectProps,
        multiple: true,
        renderValue: (selected) => {
          const selectedArr = selected as RaceEnum[]
          return selectedArr.map((item) => formatRace[item]).join(', ')
        },
      }}
      onChange={handleOnChange}
    >
      {Object.values(RaceEnum).map((option) => (
        <MenuItem key={option} value={option}>
          <Checkbox
            style={{
              paddingRight: '5px', // TODO better padding via theme
            }}
            checked={(valuesWithoutNull?.indexOf(option) ?? -1) > -1}
          />
          {formatRace[option]}
        </MenuItem>
      ))}
    </TextField>
  )
}

export default RacesSelect
