import { FC, useMemo } from 'react'

import { ModuleNameEnum, SexEnum } from 'generated/graphql'
import { uniq, without } from 'lodash'
import { selectFirstEncounterIdHasGenderRequirement } from 'modules/Appointment/selectors'
import useGetAppointmentQueryContext from 'modules/Appointment/useGetAppointmentQueryContext'
import { selectAllpatientFieldsEncounterWithPatientId } from 'modules/Appointments/Create/selectors/generic'
import useIsCreateModeContext from 'modules/Appointments/useIsCreateModeContext'
import { Controller, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import ControlledField from 'utils/ControlledField/ControlledField'
import hasFeatureFlag from 'utils/featureFlagsUtilities'
import safeJsonParse from 'utils/safeJsonParse'

import { FormGroup, Grid } from '@mui/material'
import FormLabel from '@mui/material/FormLabel'
import makeStyles from '@mui/styles/makeStyles'
import Box from '@mui/system/Box'

import GenderRequirementsSelect from 'ui/Inputs/GenderRequirementsSelect/GenderRequirementsSelect'
import ReasonForEncounter from 'ui/Inputs/ReasonForEnconter/ReasonForEncounter'
import ServiceLineSelect, { ServiceLineSelectProps } from 'ui/Inputs/ServiceLineSelect/ServiceLineSelect'
import Checkbox from 'ui/MaterialUI/Checkbox/Checkbox'
import SpacedItems from 'ui/SpacedItems'
import YesNoInput from 'ui/YesNoInput'

const READY_AT_HOME_BASIC = 1
const READY_AT_HOME_COVID = 2
const READY_AT_HOME_VACCINE = 3
const READY_AT_HOME_HEDIS = 4
const READY_AT_HOME_WELLNESS = 36
const READY_AT_DISCHARGE = 5
const COMMUNITY_CARE_ENROLLMENT = 6
const COMMUNITY_CARE_FOLLOWUP = 7
const READY_AT_DISCHARGE_BEHAVIORAL_HEALTH = 20

const parseModulesString = (modulesString?: string): ModuleNameEnum[] => {
  if (!modulesString) return []
  return safeJsonParse(modulesString)
}

type EncounterDetailsEditProps = {
  encounterId?: number

  // index of patient in createMode form state
  patientFieldKey?: number

  enableCovidQuestions?: boolean
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'space-evenly',
    paddingBottom: '1rem',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
    },
  },
}))

const EncounterDetailsEdit: FC<EncounterDetailsEditProps> = ({
  encounterId,
  patientFieldKey,
  enableCovidQuestions = false,
}) => {
  const { t } = useTranslation()
  const classes = useStyles()

  const { data } = useGetAppointmentQueryContext()
  const isCreateMode = useIsCreateModeContext()

  // TODO: support multiple encounters per patient
  // in createMode we only support one encounter, currently
  const baseId = isCreateMode && patientFieldKey != null ? `patients.${patientFieldKey}.encounters.0.` : ''

  const { watch, getValues, setValue } = useFormContext()
  const serviceLineId = watch(`${baseId}serviceLineId`)
  const modules = parseModulesString(watch(`${baseId}modules`))

  // only used in createMode
  const patients = watch('patients')
  const currentPatient = patientFieldKey != null ? patients?.[patientFieldKey]?.patientId ?? null : null

  const genderRequirementIsDisabled = useMemo(() => {
    if (isCreateMode && currentPatient != null) {
      const encounters = selectAllpatientFieldsEncounterWithPatientId(patients)
      if (!(encounters && encounters.length)) return false

      const patientIdHasGenderRequirement = encounters.find((encounter) => {
        if (!encounter) return false
        const { genderRequirement } = encounter
        return genderRequirement != null && genderRequirement !== SexEnum.Other
      })

      return patientIdHasGenderRequirement != null && patientIdHasGenderRequirement.patientId !== currentPatient
    }

    if (!data) return false

    const encounterIdHasGenderRequirement = selectFirstEncounterIdHasGenderRequirement(data)

    return (
      !(encounterId == null || encounterIdHasGenderRequirement == null) &&
      encounterIdHasGenderRequirement !== encounterId
    )
  }, [encounterId, isCreateMode, patients, currentPatient, data])

  const handleServiceLineChange: ServiceLineSelectProps['onChange'] = (newServiceLine) => {
    const excludedModules: ModuleNameEnum[] = []

    // if service line is not selected or blank, no need to run this check
    if (newServiceLine != null) {
      //
      // If service line is not in this list, exclude Clinician Module
      const enableClinicianList: number[] = [
        READY_AT_HOME_BASIC,
        READY_AT_HOME_COVID,
        READY_AT_HOME_HEDIS,
        READY_AT_HOME_WELLNESS,
        READY_AT_DISCHARGE,
        COMMUNITY_CARE_ENROLLMENT,
      ]

      if (!enableClinicianList.includes(newServiceLine)) {
        excludedModules.push(ModuleNameEnum.Clinician)
      }

      //
      // If service line is not in this list, exclude Vitals Module
      const enableVitalsList: number[] = [
        READY_AT_HOME_BASIC,
        READY_AT_HOME_COVID,
        READY_AT_HOME_HEDIS,
        READY_AT_HOME_WELLNESS,
        READY_AT_DISCHARGE,
        COMMUNITY_CARE_ENROLLMENT,
        READY_AT_DISCHARGE_BEHAVIORAL_HEALTH,
      ]

      if (!enableVitalsList.includes(newServiceLine)) {
        excludedModules.push(ModuleNameEnum.Vitals)
      }
    }

    const currentModules = uniq([
      // add clinician and vitals, should be filtered out through excludedModules if the serviceLine doesn't contain it
      ...[ModuleNameEnum.Clinician, ModuleNameEnum.Vitals],
      // add testCovid only for `Ready At Home - Covid`
      ...(newServiceLine === READY_AT_HOME_COVID ? [ModuleNameEnum.TestCovid] : []),
      ...(newServiceLine === COMMUNITY_CARE_ENROLLMENT ? [ModuleNameEnum.Note] : []),
      ...(newServiceLine === COMMUNITY_CARE_FOLLOWUP ? [ModuleNameEnum.Note] : []),
      // Add vitals for READY_AT_DISCHARGE_BEHAVIORAL_HEALTH
      ...(newServiceLine === READY_AT_DISCHARGE_BEHAVIORAL_HEALTH ? [ModuleNameEnum.Note] : []),
    ])

    const newModules = without(currentModules, ...excludedModules)

    setValue(`${baseId}modules`, JSON.stringify(newModules), { shouldDirty: true })
    setValue(`${baseId}serviceLineId`, newServiceLine, { shouldDirty: true, shouldValidate: true })
  }

  const handleModulesChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const moduleName = event.target.name as ModuleNameEnum
    const isNowChecked = event.target.checked
    const currentModules = parseModulesString(getValues(`${baseId}modules`))

    if (isNowChecked) {
      // Add module to the array
      setValue(`${baseId}modules`, JSON.stringify([...(currentModules ?? []), moduleName]), {
        shouldDirty: true,
        shouldValidate: true,
      })
    } else if (!isNowChecked) {
      // Remove module from array
      setValue(`${baseId}modules`, JSON.stringify(without(currentModules, moduleName)), {
        shouldDirty: true,
        shouldValidate: true,
      })
    }
  }

  return (
    <SpacedItems direction="column">
      <Controller
        name={`${baseId}reasonForEncounter`}
        render={({ field: { onChange, onBlur, value, ref }, fieldState: { invalid, error } }) => (
          <ReasonForEncounter
            id={`${baseId}reasonForEncounter`}
            value={value}
            onChange={onChange}
            onBlur={onBlur}
            inputRef={ref}
            error={invalid}
            helperText={error?.message ? t(error.message) : null}
          />
        )}
      />
      <Controller
        name={`${baseId}serviceLineId`}
        render={({ field: { onBlur, value, ref, name }, fieldState: { invalid, error } }) => (
          <ServiceLineSelect
            name={name}
            id={name}
            value={value}
            onChange={handleServiceLineChange}
            onBlur={onBlur}
            inputRef={ref}
            error={invalid}
            helperText={error?.message ? t(error.message) : null}
            fullWidth
          />
        )}
      />
      {hasFeatureFlag('showEmblemCovidQuestions') && enableCovidQuestions && serviceLineId === READY_AT_HOME_COVID && (
        <div style={{ paddingBottom: '1rem' }}>
          <Grid container direction="row">
            <Grid item container justifyContent="space-between" alignItems="flex-start">
              <Grid item maxWidth="70%">
                {t('components:modules.Patient.EncounterDetailsCard.patientHasSymptoms')}
              </Grid>
              <Grid item>
                <ControlledField
                  Component={YesNoInput}
                  name={`${baseId}patientHasSymptoms`}
                  ComponentProps={{ row: true }}
                />
              </Grid>
            </Grid>
            <Grid item container justifyContent="space-between" alignItems="flex-start">
              <Grid item maxWidth="70%">
                {t('components:modules.Patient.EncounterDetailsCard.patientCloseContactSymptoms')}
              </Grid>
              <Grid item>
                <ControlledField
                  Component={YesNoInput}
                  name={`${baseId}patientCloseContactSymptoms`}
                  ComponentProps={{ row: true }}
                />
              </Grid>
            </Grid>
          </Grid>
        </div>
      )}

      <Box
        sx={{
          paddingBottom: '1rem',
          ...(!(serviceLineId === READY_AT_HOME_VACCINE || serviceLineId === READY_AT_HOME_HEDIS) && {
            display: 'none',
          }),
        }}
      >
        <FormLabel component="legend">Modules</FormLabel>
        <Controller
          name={`${baseId}modules`}
          render={({ field: { value } }) => (
            <FormGroup onChange={handleModulesChange} defaultValue={value}>
              <div className={classes.root}>
                <Checkbox
                  label="Flu Vaccine"
                  name={ModuleNameEnum.VaccineFlu}
                  checked={modules?.includes(ModuleNameEnum.VaccineFlu)}
                />
                <Checkbox
                  label="COVID Vaccine"
                  name={ModuleNameEnum.VaccineCovid}
                  checked={modules?.includes(ModuleNameEnum.VaccineCovid)}
                />
                {serviceLineId === READY_AT_HOME_HEDIS && (
                  <Checkbox
                    label="Test - A1C"
                    name={ModuleNameEnum.TestA1C}
                    checked={modules?.includes(ModuleNameEnum.TestA1C)}
                  />
                )}
              </div>
            </FormGroup>
          )}
        />
      </Box>

      <ControlledField
        ComponentProps={{ disabled: genderRequirementIsDisabled }}
        name={`${baseId}genderRequirement`}
        Component={GenderRequirementsSelect}
      />
    </SpacedItems>
  )
}
export default EncounterDetailsEdit
