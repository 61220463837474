import { PatientNoteFragment } from 'generated/graphql'
import { DateTime } from 'luxon'
import { Flag } from 'phosphor-react'

import Grid from '@mui/material/Grid'
import Box from '@mui/system/Box'

import FormattedDate from 'ui/TextFormatters/FormattedDate'

const Note = ({ note }: { note: PatientNoteFragment }): JSX.Element => (
  <Grid container key={note?.patientNoteId} style={{ flexWrap: 'nowrap' }}>
    <Box pr={2}>
      <Flag size={32} color="#dc1818" weight="duotone" />
    </Box>
    <Box display="flex" flexGrow={1} flexDirection="column" pb="2">
      <Box pb={1}>{note?.note}</Box>
      <Box>
        {note?.author || 'Anonymous'}
        {' on '}
        <FormattedDate dateTime={DateTime.fromISO(note?.createdAt)} />
      </Box>
    </Box>
  </Grid>
)

export default Note
