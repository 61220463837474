import { TypePolicy, FieldPolicy } from '@apollo/client'
import { differenceInYears } from 'date-fns'
import { formatUSPhoneNumberToE164 } from 'utils/phone/format'
import { US_PHONE_NUMBER_REGEXP } from 'utils/phone/regexp'

const Patient: TypePolicy = {
  keyFields: ['patientId'],
  fields: {
    // Adds new @client field `age`
    age: {
      read(value, { readField }) {
        const dob = readField('dob') as string

        if (dob) {
          return differenceInYears(new Date(), new Date(dob))
        }

        return null
      },
    } as FieldPolicy,

    // Adds new @client field `fullName`
    fullName: {
      read(value, { readField }) {
        const first = readField('firstName')
        const last = readField('lastName')

        return `${first ?? ''} ${last ?? ''}`
      },
    } as FieldPolicy,

    // Formats existing field `preferredPhoneNumber`
    preferredPhoneNumber: {
      read(value) {
        if (!value) return null

        if (US_PHONE_NUMBER_REGEXP.test(value)) {
          return formatUSPhoneNumberToE164(value)
        }

        return value
      },
    } as FieldPolicy,
  },
}

export default Patient
