import { isPlainObject } from 'lodash'

// eslint-disable-next-line no-self-compare
const isNilOrNaN = <T>(input: T): boolean => input == null || input !== input

/**
 * Check if the given input is strictly empty.
 * Strict means it only checks for `arrays`, `strings` and `objects`,
 * The following types are never empty: `null` | `undefined` | `boolean` | `number`
 *
 * @note: this does not behave the same as lodash's isEmpty, it is stricter on purpose.
 *
 * @example
 *  isEmpty({}) // -> true
 *  isEmpty('') // -> true
 *  isEmpty([]) // -> true
 *  isEmpty(null) // -> false
 *  isEmpty(false) // -> false
 *  isEmpty(NaN) // -> false
 *  isEmpty(0) // -> false
 */
const isEmpty = <T>(input: T): boolean => {
  // we do not consider nil or NaN values to be empty
  if (isNilOrNaN(input)) return false

  if (Array.isArray(input) || typeof input === 'string') return input.length === 0

  if (isPlainObject(input)) return Object.keys(input).length === 0

  return false
}

export default isEmpty
