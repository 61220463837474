import { FC } from 'react'

import { useGetPatientQuery } from 'generated/graphql'

import PatientDetails, { PatientDetailsProps } from './PatientDetails'

type PatientDetailsByIdProps = { patientId?: number | null } & Pick<
  PatientDetailsProps,
  'isExpanded' | 'onExpand' | 'onRemove' | 'EncountersComponent'
>

const PatientDetailsById: FC<PatientDetailsByIdProps> = ({
  patientId,
  isExpanded,
  onExpand,
  onRemove,
  EncountersComponent,
}) => {
  const patientIdIsNil = patientId == null
  const { data: { patient } = {}, loading } = useGetPatientQuery({
    ...(patientId != null && { variables: { patientId } }),
    skip: patientIdIsNil,
  })
  if (loading || patientIdIsNil || !patient) return null

  return (
    <PatientDetails
      specialInstructions={patient?.specialInstructions}
      firstName={patient?.firstName}
      lastName={patient?.lastName}
      preferredName={patient?.preferredName}
      preferredPhoneNumber={patient?.preferredPhoneNumber}
      patientId={patient?.patientId}
      dob={patient?.dob}
      sex={patient?.sex}
      email={patient?.email}
      languages={patient?.languages}
      communicationNeed={patient?.communicationNeed}
      genderIdentity={patient?.genderIdentity}
      globalHumanId={patient?.globalHumanId}
      genderPronoun={patient?.genderPronoun}
      races={patient?.races}
      address1={patient?.address1}
      address2={patient?.address2}
      city={patient?.city}
      state={patient?.state}
      addressLat={patient?.addressLat}
      addressLng={patient?.addressLng}
      unit={patient?.unit}
      zip={patient?.zip}
      isExpanded={isExpanded}
      onExpand={onExpand}
      onRemove={onRemove}
      EncountersComponent={EncountersComponent}
    />
  )
}

export default PatientDetailsById
