import { FC, useCallback } from 'react'

import { GetAppointmentDocument, useCancelEncountersMutation } from 'generated/graphql'
import { selectEncountersByPatientId } from 'modules/Appointment/selectors'
import useGetAppointmentQueryContext from 'modules/Appointment/useGetAppointmentQueryContext'
import { useTranslation } from 'react-i18next'
import { useToggle } from 'react-use'

import { Button, Hidden } from '@mui/material'

import EncountersCancelDialog from './EncountersCancelDialog'
import { assertEncountersAreCancellable } from './selectors'

const EncountersCancellation: FC<{ patientId: number }> = ({ patientId }) => {
  const { t } = useTranslation()
  const [showCancellationModal, toggleShowCancellationModal] = useToggle(false)
  const { data } = useGetAppointmentQueryContext()
  const [cancelEncounters, { loading: loadingCancelEncounters }] = useCancelEncountersMutation()
  const submitCancelEncounters = useCallback(
    async ({ encounterIds, cancellationReason, cancellationReasonOther }) => {
      const { appointmentId } = data?.appointment || {}

      if (!(encounterIds && encounterIds.length && appointmentId)) return Promise.resolve(null)

      return cancelEncounters({
        variables: {
          cancellationReason,
          cancellationReasonOther,
          encounterIds,
        },
        refetchQueries: [{ query: GetAppointmentDocument, variables: { appointmentId } }],
      })
    },
    [cancelEncounters, data],
  )

  const encounters = selectEncountersByPatientId(data, { patientId })

  if (!encounters) return null

  const isCancellable = assertEncountersAreCancellable(encounters)

  if (!isCancellable) return null

  return (
    <>
      <Hidden smDown>
        <Button color="error" onClick={toggleShowCancellationModal}>
          {t('components:modules.Patient.PatientHeader.cancelEncounterButton.label')}
        </Button>
      </Hidden>

      {showCancellationModal && (
        <EncountersCancelDialog
          patientId={patientId}
          open={showCancellationModal}
          onClose={() => toggleShowCancellationModal(false)}
          onSubmit={submitCancelEncounters}
          loading={loadingCancelEncounters}
        />
      )}
    </>
  )
}

export default EncountersCancellation
