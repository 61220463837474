import { isNotNilOrEmpty } from './isNilOrEmpty'
import shallowFlatten from './shallowFlatten'

type PathType = string | number | symbol | null | undefined

const PATH_SEPARATOR = '.'

/**
 * Create a "path" (or "dot" notated) string from an array of elements
 *
 * @example
 *  joinPath([ 'foo', 'bar' ])
 *  // -> "foo.bar"
 */
// TODO: we should deal with numbers by enclosing them in brackets, e.g. "foo[2].bar[3]"
const joinPath = (input: (PathType | PathType[])[]): string =>
  shallowFlatten<PathType>(input).filter(isNotNilOrEmpty).join(PATH_SEPARATOR)

export default joinPath
