import { uniqBy } from 'lodash'

import FullName, { FullNameProps } from 'ui/TextFormatters/FullName/FullName'

export type FullNameList = FullNameProps[] | null

export type NameListProps = {
  displayCount?: number
  listNames: FullNameList
}

function NameList({ listNames, displayCount = 1 }: NameListProps): JSX.Element {
  if (!listNames || listNames.length < 1) {
    return <>-</>
  }
  let shownCount = displayCount

  if (displayCount <= 0) {
    shownCount = 1
  }
  const namesToShow = listNames.slice(0, shownCount)
  const remainderCount = listNames.length - shownCount

  const Names = uniqBy(namesToShow, 'id').map((name, index) => (
    <span key={name?.id}>
      <FullName key={name?.id} firstName={name?.firstName || ''} lastName={name?.lastName || ''} />
      {index + 1 < namesToShow.length && ', '}
    </span>
  ))

  return (
    <>
      {Names}
      <span key="remainderCount">{remainderCount >= 1 && <strong> +{remainderCount}</strong>}</span>
    </>
  )
}

export default NameList
