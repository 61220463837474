import { useMemo, FC } from 'react'

import { AppointmentStatusEnum } from 'generated/graphql'
import { isEmpty } from 'lodash'
import { useTranslation } from 'react-i18next'

import MenuItem from '@mui/material/MenuItem'
import TextField, { OutlinedTextFieldProps } from '@mui/material/TextField'
import makeStyles from '@mui/styles/makeStyles'

const useStyles = makeStyles({
  container: {
    minWidth: 200,
    maxWidth: ({ fullWidth = false }: Pick<AppointmentStatusSelectProps, 'fullWidth'>) => (fullWidth ? 'none' : 300),
  },
})

const LIST_STATUS = Object.values(AppointmentStatusEnum)

// similar to following MarketsListSelect selector: assertMarketHasMarketId()
export const assertStatusHasStatusKey = (statuses: AppointmentStatusEnum[] | AppointmentStatusEnum | null): boolean => {
  if (statuses == null) {
    return false
  }
  if (Array.isArray(statuses)) {
    return statuses.every((status) => LIST_STATUS.includes(status))
  }
  return LIST_STATUS.includes(statuses)
}

const VALUE_NONE = '__none__'
const DEFAULT_I18N_KEY = 'components:ui.Inputs.AppointmentStatusSelect'
const I18N_OPTION_KEY = 'glossary:appointmentStatus'

// https://github.com/microsoft/TypeScript/issues/30581
type Multiple = true | false
type MultipleValue<T extends Multiple> = T extends true
  ? AppointmentStatusEnum[]
  : T extends false
  ? AppointmentStatusEnum
  : never
type MultipleValueOnChange<T extends Multiple> = (newValue: MultipleValue<T> | null) => void
type MultipleValueRecord<T extends Multiple> = {
  /**
   * Specify whether it is a single select or multi select dropdown.
   * @required
   */
  multiple: T
  value?: MultipleValue<T> | null
  onChange: MultipleValueOnChange<T>
}

export type AppointmentStatusSelectProps = Partial<Omit<OutlinedTextFieldProps, 'onChange' | 'value'>> & {
  i18nKey?: string
  showNone?: boolean
  fullWidth?: boolean
} & (MultipleValueRecord<true> | MultipleValueRecord<false>)

const AppointmentStatusSelect: FC<AppointmentStatusSelectProps> = ({
  value,
  onChange,
  i18nKey = DEFAULT_I18N_KEY,
  showNone = true,
  multiple,
  fullWidth,
  ...textFieldProps
}) => {
  const { t } = useTranslation()
  const classes = useStyles({ fullWidth })

  function handleOnChange(event: { target: { value: string[] } }): void
  function handleOnChange(event: { target: { value: string } }): void
  function handleOnChange(event: { target: { value: string[] | string } }): void {
    const {
      target: { value: optionValue },
    } = event
    const onChangeAs = onChange as MultipleValueOnChange<typeof multiple>

    if (Array.isArray(optionValue)) {
      const multipleOptionValue = optionValue as MultipleValue<true>
      onChangeAs(multipleOptionValue.length ? (multipleOptionValue as AppointmentStatusEnum[]) : null)
      return
    }
    onChangeAs(optionValue === VALUE_NONE ? null : (optionValue as AppointmentStatusEnum))
  }

  const existingValue = useMemo(() => {
    if (!value) return VALUE_NONE
    if (multiple && Array.isArray(value) && !value.length) return VALUE_NONE
    return (assertStatusHasStatusKey(value as AppointmentStatusEnum) && value) || VALUE_NONE
  }, [value, LIST_STATUS])

  const textFieldLabel = t([`${i18nKey}.label`, 'components:ui.Inputs.generic.select.label'])
  const noneLabel = t([`${i18nKey}.option.none.label`, 'components:ui.Inputs.generic.option.none.label'])
  const nonePlaceholder = t([
    `${i18nKey}.option.none.placeholder`,
    'components:ui.Inputs.generic.option.none.placeholder',
  ])

  return (
    <div className={classes.container}>
      <TextField
        fullWidth
        name="appointmentStatusEnum"
        value={multiple && existingValue === VALUE_NONE ? [] : existingValue}
        {...textFieldProps}
        select
        onChange={handleOnChange}
        SelectProps={{
          multiple,
          ...(multiple && { displayEmpty: true }),
          renderValue: (optionValue) => {
            if (Array.isArray(optionValue) && isEmpty(optionValue)) {
              return nonePlaceholder
            }
            if (Array.isArray(optionValue)) {
              return optionValue.map((status) => t('glossary:appointmentStatus', { context: status })).join(', ')
            }
            return optionValue === VALUE_NONE
              ? nonePlaceholder
              : t('glossary:appointmentStatus', { context: optionValue }) ?? (optionValue as AppointmentStatusEnum)
          },
        }}
        label={textFieldLabel}
      >
        {/* the VALUE_NONE MenuItem always has to be present to avoid MUI throwing an out-of-range value warning, we just hide it so it's not selectable */}
        <MenuItem value={VALUE_NONE} disabled={multiple} {...(!showNone && { style: { display: 'none' } })}>
          {noneLabel}
        </MenuItem>

        {LIST_STATUS?.map((status) => (
          <MenuItem key={status} value={status}>
            {t(I18N_OPTION_KEY, { context: status })}
          </MenuItem>
        ))}
      </TextField>
    </div>
  )
}

export default AppointmentStatusSelect
