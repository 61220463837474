import {
  PatientNoteFragment,
  GetAppointmentPreviewQuery,
  AppointmentPreviewEncountersFragment,
  GetFullAppointmentPreviewQuery,
} from 'generated/graphql'
import { filterPatientNotesNotIsDeleted, sortPatientNotes } from 'modules/Appointment/PatientNotes/selectors'
import { createSelector, Selector } from 'reselect'
import { responderNamesFromDispatches } from 'utils/nameListHelpers/nameListHelpers'

const getEncounters = (data?: GetAppointmentPreviewQuery) => data?.appointment?.encountersList ?? null
const getAppointmentDispatches = (data?: GetAppointmentPreviewQuery) =>
  data?.appointment?.appointmentDispatches?.nodes ?? null
const getAvailabilityOverride = (data?: GetAppointmentPreviewQuery) => data?.appointment?.availabilityOverride ?? null

const getFullEncounters = (data?: GetFullAppointmentPreviewQuery) => data?.appointment?.encountersList ?? null

const selectPatientNotesFromEncounters: Selector<
  AppointmentPreviewEncountersFragment | null,
  PatientNoteFragment[] | null
> = (encounter) => (encounter?.patient?.patientNotesList as PatientNoteFragment[]) ?? null

export const selectAvailabilityOverride = createSelector(getAvailabilityOverride, (x) => x)

/**
 * @selector
 * @state GetAppointmentPreviewQuery
 */
export const selectEncounters = createSelector(getEncounters, (x) => x)

/**
 * @selector
 * @state Encounter
 * Select PatientNotes,
 *  - not `isDeleted`
 */
export const selectExistingPatientNotes = createSelector(
  selectPatientNotesFromEncounters,
  filterPatientNotesNotIsDeleted,
)

/**
 * @selector
 * @state Encounter
 * Select PatientNotes,
 *  - not `isDeleted`,
 *  - sorted by `isHighPriority`,
 *  - sorted by `createdAt` (DESCENDING)
 */
export const selectSortedExistingPatientNotes = createSelector(selectExistingPatientNotes, sortPatientNotes)

/**
 * get full name of dispatched responders
 */
export const selectDispatchedResponderNames = createSelector(getAppointmentDispatches, (appointmentDispatches) => {
  if (appointmentDispatches == null) return null
  return responderNamesFromDispatches(appointmentDispatches)
})

const getIsAnyEncounterServiceLinesCommunityCareEnrollment = (
  encounters?: AppointmentPreviewEncountersFragment[] | null,
) => encounters?.some((encounter) => encounter.serviceLine?.serviceLineId === 6)

export const selectIsAnyEncounterServiceLinesCommunityCareEnrollment = createSelector(
  getFullEncounters,
  getIsAnyEncounterServiceLinesCommunityCareEnrollment,
)
