import { createContext, useState, Dispatch, SetStateAction } from 'react'

export type Context =
  | {
      sidebarIsOpen: boolean
      setSidebarIsOpen: Dispatch<SetStateAction<boolean>>
    }
  | undefined
type Props = { children: React.ReactNode }

export const SidebarContext = createContext<Context>(undefined)

export const SidebarContextProvider = ({ children }: Readonly<Props>): JSX.Element => {
  const [sidebarIsOpen, setSidebarIsOpen] = useState<boolean>(false)

  const context = {
    sidebarIsOpen,
    setSidebarIsOpen,
  }

  return <SidebarContext.Provider value={context}>{children}</SidebarContext.Provider>
}
