import { isLocal } from 'config/featureFlags'

import useIsOpsAdmin from './useIsOpsAdmin'

const useHasMarkets = (): boolean => {
  const isOpsAdmin = useIsOpsAdmin()
  const local = isLocal()

  if (local) {
    // Running as local, true
    return true
  }

  if (!local) {
    // Not local, doing perm check
    return isOpsAdmin
  }

  // Defaulting to false
  return false
}

export default useHasMarkets
