import { FC, useState, useCallback, useEffect } from 'react'

import {
  CancellationReasonEnum,
  useUpdateAppointmentMutation,
  useGetAppointmentQuery,
  useUpdateEncounterMutation,
} from 'generated/graphql'
import { useTranslation } from 'react-i18next'

import { Button } from '@mui/material'
import TextField from '@mui/material/TextField'

import AppointmentStatusSelect from 'ui/Inputs/CancellationReasonSelect'
import Modal from 'ui/Modal/Modal'

import { isDescriptionNeeded } from './AppointmentCancellation/AppointmentCancelDialog'

type CancellationReasonModalProps = {
  appointmentId: number
  open: boolean
  onClose: () => void
}

const CancellationReasonModal: FC<CancellationReasonModalProps> = ({ appointmentId, open, onClose }) => {
  const { t } = useTranslation()
  const [updateAppointment] = useUpdateAppointmentMutation()
  const [cancellationReason, setCancelReason] = useState<CancellationReasonEnum | null>(null)
  const [cancellationReasonOther, setCancelReasonOther] = useState<string | undefined | null>(null)
  const { data, loading } = useGetAppointmentQuery({
    variables: {
      appointmentId,
    },
  })

  const [updateEncounterMutation] = useUpdateEncounterMutation()

  const handleClose = useCallback(() => {
    if (data?.appointment?.cancelledReason) {
      setCancelReason(data?.appointment?.cancelledReason)
    }
    if (data?.appointment?.cancelledReasonOther) {
      setCancelReasonOther(data?.appointment?.cancelledReasonOther)
    }
    onClose()
  }, [onClose, data])

  const handleUpdateReason = useCallback(async () => {
    const encounterUpdates = data?.appointment?.encountersList.map((encounter) => {
      return updateEncounterMutation({
        variables: {
          encounterId: encounter.encounterId,
          encounter: {
            cancellationReason,
            cancellationReasonOther: isDescriptionNeeded(cancellationReason) ? cancellationReasonOther : null,
          },
        },
      })
    })

    await updateAppointment({
      variables: {
        appointmentId,
        appointmentPatch: {
          cancelledReason: cancellationReason,
          cancelledReasonOther: isDescriptionNeeded(cancellationReason) ? cancellationReasonOther : null,
        },
      },
    })

    if (encounterUpdates) {
      await Promise.all(encounterUpdates)
    }

    onClose()
  }, [appointmentId, onClose, cancellationReason, cancellationReasonOther, updateAppointment])

  useEffect(() => {
    if (data?.appointment?.cancelledReason) {
      setCancelReason(data.appointment.cancelledReason)
    }
    if (data?.appointment?.cancelledReasonOther && isDescriptionNeeded(cancellationReason)) {
      setCancelReasonOther(data?.appointment?.cancelledReasonOther)
    } else {
      setCancelReasonOther(null)
    }
  }, [data])

  return (
    <Modal
      title="Update Cancellation Reason"
      maxWidth="xs"
      fullWidth
      open={open}
      onClose={handleClose}
      actions={
        <>
          <Button onClick={handleClose} variant="outlined" color="secondary">
            Cancel
          </Button>
          <Button
            disabled={
              loading ||
              (isDescriptionNeeded(cancellationReason) && cancellationReasonOther?.length === 0) ||
              (isDescriptionNeeded(cancellationReason) &&
                cancellationReasonOther === data?.appointment?.cancelledReasonOther) ||
              (!isDescriptionNeeded(cancellationReason) && cancellationReason === data?.appointment?.cancelledReason)
            }
            onClick={handleUpdateReason}
          >
            {t('components:modules.Appointment.UpdateReasonModal.button')}
          </Button>
        </>
      }
    >
      <AppointmentStatusSelect value={cancellationReason} onChange={setCancelReason} fullWidth showNone={false} />
      {isDescriptionNeeded(cancellationReason) && (
        <TextField
          id="appointment-cancelled-reason-other"
          name="cancellationReasonOther"
          fullWidth
          multiline
          rows={4}
          label={t('components:ui.Inputs.AppointmentCancellationOtherInput.label')}
          placeholder={t('components:ui.Inputs.AppointmentCancellationOtherInput.placeholder')}
          value={isDescriptionNeeded(cancellationReason) ? cancellationReasonOther : null}
          onChange={(e) => setCancelReasonOther(e.target.value)}
        />
      )}
    </Modal>
  )
}

export default CancellationReasonModal
