import { ApolloClient, ApolloProvider, createHttpLink, InMemoryCache } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { useAuth0 } from '@auth0/auth0-react'
import Config from 'config/config'

import typePolicies from './typePolicies'

type Props = {
  children: React.ReactNode
}

export const cache = new InMemoryCache({
  typePolicies,
})

const ApolloProviderComponent = ({ children }: Readonly<Props>): JSX.Element | null => {
  const { isAuthenticated, getAccessTokenSilently } = useAuth0()

  const httpLink = createHttpLink({
    uri: Config.graphqlUrl,
  })

  const authLink = setContext(async (_, { headers }) => {
    const accessToken = await getAccessTokenSilently()

    return {
      headers: {
        ...headers,
        authorization: accessToken ? `Bearer ${accessToken}` : '',
      },
    }
  })

  const client = new ApolloClient({
    link: authLink.concat(httpLink),
    cache,
  })

  if (process.env.NODE_ENV === 'development' && window.devDebug) {
    window.devDebug.apolloClient = client
  }

  return isAuthenticated ? <ApolloProvider client={client}>{children}</ApolloProvider> : null
}

export default ApolloProviderComponent
