/* eslint-disable no-param-reassign */
import { useEffect, useState, memo } from 'react'

import { yupResolver } from '@hookform/resolvers/yup'
import {
  useUpdateEncounterMutation,
  Encounter,
  ServiceLine,
  Patient,
  EncounterResponderStatusEnum,
  useGetEncounterInsuranceRecordsQuery,
  ModuleNameEnum,
} from 'generated/graphql'
import createUpdateEncounterOptions from 'graphql/mutations/UpdateEncounterOptions'
import { omit, pickBy } from 'lodash'
import useGetAppointmentQueryContext from 'modules/Appointment/useGetAppointmentQueryContext'
import EncounterDetailsEdit from 'modules/Patient/EncounterDetails/EncounterDetailsEdit'
import EncounterDetailsRead from 'modules/Patient/EncounterDetails/EncounterDetailsRead'
import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import isEmblem from 'utils/isEmblem'
import safeJsonParse from 'utils/safeJsonParse'
import { encounterWithEmblemSchema } from 'yupSchemas/encounters'

import CardLabeled from 'ui/CardLabeled'

import { selectPrimaryInsurance, selectSecondaryInsurance } from '../selectors'

import CancelledStatusChip from './CancelledStatusChip'

type EncounterDetailsCardProps = Pick<
  Encounter,
  | 'encounterId'
  | 'reasonForEncounter'
  | 'genderRequirement'
  | 'modules'
  | 'modulesFriendly'
  | 'responderStatus'
  | 'cancellationReason'
  | 'cancellationReasonOther'
  | 'cancelledAt'
> &
  Pick<Patient, 'patientId'> & {
    serviceLineName?: ServiceLine['friendlyName']
    serviceLine?: ServiceLine['serviceLineId']
    responderStatus?: EncounterResponderStatusEnum | null
  }

const EncounterDetailsCard = ({
  patientId,
  encounterId,
  reasonForEncounter,
  serviceLine: serviceLineId,
  serviceLineName,
  genderRequirement,
  modules,
  modulesFriendly,
  cancellationReason,
  cancellationReasonOther,
  cancelledAt,
  responderStatus,
}: EncounterDetailsCardProps): JSX.Element => {
  const { t } = useTranslation()
  const [isEditing, setEditing] = useState(false)

  const [updateEncounter] = useUpdateEncounterMutation()
  const { id: appointmentId } = useGetAppointmentQueryContext()

  const DEFAULT_VALUES_EMBLEM = {
    patientHasSymptoms: null,
    patientCloseContactSymptoms: null,
  }

  /**
   * we need to fetch patient insurances to know whether the emblem questions are required
   */
  const { data, loading: insuranceLoading } = useGetEncounterInsuranceRecordsQuery({
    ...(patientId && appointmentId && { variables: { patientId, appointmentId } }),
    skip: !appointmentId || appointmentId < 1 || !patientId || patientId < 1,
  })
  const primaryInsurance = selectPrimaryInsurance(data)
  const secondaryInsurance = selectSecondaryInsurance(data)

  const defaultValues = {
    reasonForEncounter,
    serviceLineId,
    genderRequirement,
    modules: JSON.stringify([...(modules ?? [])].sort()),
  }

  const methods = useForm({
    mode: 'onBlur',
    reValidateMode: 'onChange',
    defaultValues: {
      ...defaultValues,
      ...DEFAULT_VALUES_EMBLEM,
    },
    resolver: yupResolver(encounterWithEmblemSchema),
  })

  const {
    reset,
    getValues,
    handleSubmit,
    formState: { isValid, isDirty, dirtyFields },
  } = methods

  useEffect(() => {
    reset({ ...defaultValues, ...DEFAULT_VALUES_EMBLEM })
  }, Object.values(defaultValues))

  const setEditingTrue = () => setEditing(true)
  const handleCancel = () => {
    setEditing(false)
    reset()
  }

  const handleSave = () => {
    const allValues = getValues()

    const dirtyKeys = Object.keys(dirtyFields)
    const newValues = pickBy(allValues, (value, key) => dirtyKeys.includes(key))

    // we need insurance to do the emblem check
    const hasEmblemInsurance =
      (primaryInsurance && isEmblem(Number(primaryInsurance?.packageId) ?? -1)) ||
      (secondaryInsurance && isEmblem(Number(secondaryInsurance?.packageId) ?? -1))

    // do an emblem check and remove "CLINICIAN" if we pass the check
    if (
      hasEmblemInsurance &&
      (allValues.patientHasSymptoms === false || allValues.patientHasSymptoms === 'false') &&
      (allValues.patientCloseContactSymptoms === false || allValues.patientCloseContactSymptoms === 'false')
    ) {
      const currentModules: string[] | string = safeJsonParse(allValues.modules)
      const nextModules = Array.isArray(currentModules)
        ? currentModules.filter((moduleName) => moduleName !== ModuleNameEnum.Clinician)
        : currentModules
      newValues.modules = JSON.stringify([...(nextModules ?? [])].sort())
    }

    // turn the modules string into an array
    if (newValues.modules) {
      newValues.modules = safeJsonParse(newValues.modules as string)
    }

    // we need to remove emblem questions from payload as RCO doesn't accept them
    const valuesDelta = omit(newValues, ['patientCloseContactSymptoms', 'patientHasSymptoms'])

    const baseOptions = createUpdateEncounterOptions(encounterId, patientId, valuesDelta, modulesFriendly)

    updateEncounter(baseOptions)
    reset(undefined, { keepValues: true })
    setEditing(false)
  }

  const isNotStarted = responderStatus === EncounterResponderStatusEnum.NotStarted
  const isCancelled = responderStatus === EncounterResponderStatusEnum.Cancelled

  return (
    <FormProvider {...methods}>
      <CardLabeled
        title={
          <>
            {t('components:modules.Patient.EncounterDetailsCard.title')}
            {isCancelled && <CancelledStatusChip />}
          </>
        }
        testId="EncounterDetailsCard"
        editing={isEditing}
        onEdit={setEditingTrue}
        onCancel={handleCancel}
        onAccept={handleSubmit(handleSave)}
        acceptDisabled={!isDirty || !isValid || insuranceLoading}
        isEditable={isNotStarted}
        editButtonTitle={
          !isNotStarted ? t('components:modules.Patient.EncounterDetailsCard.editButtonTitle_disabled') : undefined
        }
      >
        {isEditing ? (
          <EncounterDetailsEdit encounterId={encounterId} enableCovidQuestions />
        ) : (
          <EncounterDetailsRead
            id={patientId}
            reasonForEncounter={reasonForEncounter}
            serviceLineName={serviceLineName}
            genderRequirement={genderRequirement}
            modulesFriendly={modulesFriendly}
            cancellationReason={cancellationReason}
            cancellationReasonOther={cancellationReasonOther}
            cancelledAt={cancelledAt}
            responderStatus={responderStatus}
          />
        )}
      </CardLabeled>
    </FormProvider>
  )
}

export default memo(EncounterDetailsCard)
