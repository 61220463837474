import { createContext, FC, useCallback, useContext, useEffect, useMemo, useState } from 'react'

import { yupResolver } from '@hookform/resolvers/yup'
import findMapKeyByValue from 'fp/findMapKeyByValue'
import isNilOrEmpty from 'fp/isNilOrEmpty'
import pickValues from 'fp/pickValues'
import { Patient, useGetEncounterInsuranceRecordsQuery, useGetInsuranceRecordQuery } from 'generated/graphql'
import useCreateMutatatedDispatch from 'hooks/useCreateMutatedDispatch'
import { pick } from 'lodash'
import useGetAppointmentQueryContext from 'modules/Appointment/useGetAppointmentQueryContext'
import useIsCreateModeContext from 'modules/Appointments/useIsCreateModeContext'
import { useForm, UseFormReset, UseFormReturn } from 'react-hook-form'
import insuranceSchema, { InsuranceSchema, isSelf } from 'yupSchemas/insurance'

import { selectPatientInsuranceStateByVariant } from '../selectors'

import { KEYS_ADDITIONAL_FIELDS, KEYS_PRIMARY_FIELDS, PatientInsuranceStateStatus } from './constants'
import { InsuranceRelationshipToPolicyHolderRCOMap } from './InsuranceRelationshipToPolicyHolder'
import { InsuranceVariant } from './InsuranceVariant'

const pickPrimaryFieldValues = pickValues(KEYS_PRIMARY_FIELDS)

export const getRelationshipToPolicyHolderEnumByRCOValue = findMapKeyByValue(InsuranceRelationshipToPolicyHolderRCOMap)

type UsePatientInsuranceStateProps = {
  patientId: Patient['patientId']
  variant: InsuranceVariant
  onSet?: (arg0?: InsuranceSchema | null, insuranceRecordId?: number | null) => void
  onUnset?: () => void
}

type UsePatientInsuranceState = {
  patientId: Patient['patientId']
  variant: InsuranceVariant
  loading: boolean
  status: PatientInsuranceStateStatus
  methods: UseFormReturn<InsuranceSchema>

  /**
   * custom form/state methods
   */
  unset: () => void
  set: () => Promise<Partial<InsuranceSchema> | null | undefined>
  reset: UseFormReset<InsuranceSchema>
  setStatus: (status: PatientInsuranceStateStatus) => void
  setInsuranceRecord: (value: number | null) => Promise<void>
  selectedInsuranceRecord: number | null
}

export const DEFAULT_VALUES_PATIENT_INSURANCE: InsuranceSchema = {
  company: null,
  memberId: null,
  groupNumber: null,
  packageId: null,
  relationshipToPolicyHolder: null,
  policyHolderFirstName: null,
  policyHolderLastName: null,
  policyHolderGender: null,
  policyHolderDob: null,
  insuranceRecordId: null,
}

const patientInsuranceStateContext = createContext<UsePatientInsuranceState | undefined>(undefined)

export const usePatientInsuranceState = ({
  patientId,
  variant,
  onSet,
  onUnset,
}: UsePatientInsuranceStateProps): UsePatientInsuranceState => {
  const isCreateMode = useIsCreateModeContext()
  const { id: appointmentId } = useGetAppointmentQueryContext()
  const [selectedInsuranceRecord, setSelectedInsuranceRecord] = useState<number | null>(null)
  const [status, setStatus] = useState<PatientInsuranceStateStatus>(PatientInsuranceStateStatus.PreFetch)
  const methods: UseFormReturn<InsuranceSchema> = useForm({
    mode: 'onBlur',
    reValidateMode: 'onChange',
    defaultValues: DEFAULT_VALUES_PATIENT_INSURANCE,
    resolver: yupResolver(insuranceSchema),
  })

  const { refetch: getInsuranceRecord } = useGetInsuranceRecordQuery({
    skip: true,
  })

  const { loading } = useGetEncounterInsuranceRecordsQuery({
    ...(patientId && appointmentId && { variables: { patientId, appointmentId } }),
    skip: isCreateMode || !appointmentId || appointmentId < 1 || !patientId || patientId < 1,
    onCompleted: (responseData) => {
      const patientInsuranceFields = selectPatientInsuranceStateByVariant(responseData, { variant })

      if (
        !patientInsuranceFields ||
        // if the primary required fields are `nil` or empty, treat the form state as Empty
        pickPrimaryFieldValues(patientInsuranceFields).every(isNilOrEmpty)
      ) {
        setStatus(PatientInsuranceStateStatus.Empty)
        return
      }

      // we don't pre-fill insurances from RCO fetch in Create Mode
      // in Create Mode, insurances have to be filled in by hand every single time
      // this should never be reached, as we skip this fetch on isCreateMode
      // but let's keep it here since it doesn't hurt anything either
      if (isCreateMode) {
        setStatus(PatientInsuranceStateStatus.Empty)
        return
      }

      // map RCO values to our frontend Enum values
      // note: as RCO values are currently strings without constraints, there might not be a key to map to. (should return `null` in this case)
      // we parse them as RCO values in: src/modules/Appointments/AppointmentsCreate.tsx
      const relationshipToPolicyHolderByRCOValue = getRelationshipToPolicyHolderEnumByRCOValue(
        patientInsuranceFields.relationshipToPolicyHolder,
      )

      methods.reset({
        ...patientInsuranceFields,
        ...(relationshipToPolicyHolderByRCOValue && {
          relationshipToPolicyHolder: relationshipToPolicyHolderByRCOValue,
        }),
      })
      if (patientInsuranceFields.insuranceRecordId) {
        setSelectedInsuranceRecord(patientInsuranceFields.insuranceRecordId)
      }
      setStatus(PatientInsuranceStateStatus.Set)
    },
    onError: () => setStatus(PatientInsuranceStateStatus.Empty),
  })

  // in Create Mode we set state to Empty as we are not using RCO data, this should only have to be done once
  useEffect(() => {
    if (isCreateMode && status === PatientInsuranceStateStatus.PreFetch) {
      setStatus(PatientInsuranceStateStatus.Empty)
    }
  }, [isCreateMode])

  // `unset` reverts to the initial defaults (empty state) and sets status to Empty,
  // opposed to `reset`, which reverts to last defaults (which can be changed by calling `methods.reset()`)
  const unset = useCreateMutatatedDispatch(() => {
    methods.reset(DEFAULT_VALUES_PATIENT_INSURANCE)
    setStatus(PatientInsuranceStateStatus.Empty)
    onUnset?.()
  })

  // this sets the status to `Set` if the form is valid
  // and also returns the part of the form that is required
  // it also always calls the onSet callback, returning null if invalid, undefined if pre-fetch/uninitialized
  const set = useCreateMutatatedDispatch(async () => {
    let state

    if (selectedInsuranceRecord) {
      const patientInsuranceState = methods.getValues()
      onSet?.(patientInsuranceState, selectedInsuranceRecord)
      return patientInsuranceState
    }

    await methods.handleSubmit(
      (patientInsuranceState) => {
        setStatus(PatientInsuranceStateStatus.Set)
        // if `relationshipToPolicyHolder` is not self, we need to pass additional fields
        const isNotSelfRelationship = !isSelf(patientInsuranceState.relationshipToPolicyHolder)

        // return field values
        state = pick(patientInsuranceState, [
          ...KEYS_PRIMARY_FIELDS,
          ...(isNotSelfRelationship ? KEYS_ADDITIONAL_FIELDS : []),
        ])

        // Yup coerces values by default, based on the type of the validation schema
        // `policyHolderDob` is validated as a Yup.date() schema and is coerced into a Date object
        // RHF passes the coerced values into the `handleSubmit` callback (first argument of this function)
        // lets keep using the original ISO string instead, as this callback is the only place we have to deal with Yup's transformation
        const originalPolicyHolderDob = methods.getValues('policyHolderDob')
        if (isNotSelfRelationship) {
          state.policyHolderDob = originalPolicyHolderDob
        }

        const nextPatientInsuranceState = { ...patientInsuranceState, policyHolderDob: originalPolicyHolderDob }

        // set saved data as new default, we need to do this, because we have no other source of truth
        // if someone saves, then edits again, then cancels, on cancel it will not reset to the last saved state
        // this can possibly cause other issues though, RHF suggests not putting a reset in an onSubmit
        methods.reset(nextPatientInsuranceState)
      },
      () => {
        // we assume getting RCO records is required for initialization of this state
        // we return undefined if it's still pre-fetching, that way consumers know it's not initialized
        if (status === PatientInsuranceStateStatus.PreFetch) return

        // return null when form is invalid, we don't want to save any state that is invalid
        // this also aligns with keeping the entire field `null` upstream
        state = null
      },
    )()

    // TODO: pass errors object to onSet if INVALID
    // we are not validating, if null is passed here, we should deal with that separately.
    onSet?.(state)

    return state
  })

  const setInsuranceRecord: UsePatientInsuranceState['setInsuranceRecord'] = useCallback(
    async (value) => {
      setSelectedInsuranceRecord(value)
      if (value === null) {
        unset()
        return
      }

      const response = await getInsuranceRecord({ insuranceRecordId: value })

      const { insuranceRecord } = response?.data ?? {}

      const relationshipToPolicyHolderByRCOValue = getRelationshipToPolicyHolderEnumByRCOValue(
        insuranceRecord?.relationshipToPolicyHolder,
      )

      methods.setValue('packageId', insuranceRecord?.packageId)
      methods.setValue('company', insuranceRecord?.company)
      methods.setValue('memberId', insuranceRecord?.memberId)
      methods.setValue('groupNumber', insuranceRecord?.groupNumber)
      methods.setValue('relationshipToPolicyHolder', relationshipToPolicyHolderByRCOValue)
      methods.setValue('policyHolderFirstName', insuranceRecord?.policyHolderFirstName)
      methods.setValue('policyHolderLastName', insuranceRecord?.policyHolderLastName)
      methods.setValue('policyHolderGender', insuranceRecord?.policyHolderGender)
      methods.setValue('policyHolderDob', insuranceRecord?.policyHolderDob)
      methods.trigger([
        'packageId',
        'company',
        'memberId',
        'groupNumber',
        'relationshipToPolicyHolder',
        'policyHolderFirstName',
        'policyHolderLastName',
        'policyHolderGender',
        'policyHolderDob',
      ])
      setStatus(PatientInsuranceStateStatus.Set)
    },
    [setSelectedInsuranceRecord],
  )

  // wrapped reset method, this is because we always need to check any change to status on user interaction
  const reset = useCreateMutatatedDispatch<UseFormReset<InsuranceSchema>>((...xs) => {
    methods.reset(...xs)

    const currentState = methods.getValues()
    const currentStateIsEmpty = !currentState || pickPrimaryFieldValues(currentState).every(isNilOrEmpty)

    if (PatientInsuranceStateStatus.Set && currentStateIsEmpty) {
      setStatus(PatientInsuranceStateStatus.Empty)
    }
    // this should never happen,
    // as we have no actions that can set the state to empty when the fields are populated
    if (PatientInsuranceStateStatus.Empty && !currentStateIsEmpty) {
      setStatus(PatientInsuranceStateStatus.Set)
    }
  })

  const patientInsuranceState = useMemo(
    () => ({
      patientId,
      variant,
      status,
      loading,
      methods,
      unset,
      set,
      reset,
      setStatus,
      setInsuranceRecord,
      selectedInsuranceRecord,
    }),
    [
      patientId,
      variant,
      status,
      loading,
      methods,
      unset,
      set,
      reset,
      setStatus,
      setInsuranceRecord,
      selectedInsuranceRecord,
    ],
  )

  return patientInsuranceState
}

export const PatientInsuranceStateProvider: FC<UsePatientInsuranceStateProps> = ({
  patientId,
  variant,
  children,
  onSet,
  onUnset,
}) => {
  const contextValue = usePatientInsuranceState({ patientId, variant, onSet, onUnset })

  // don't render anything if we have no patientId
  if (!patientId || patientId < 1) {
    return null
  }

  return <patientInsuranceStateContext.Provider value={contextValue}>{children}</patientInsuranceStateContext.Provider>
}

const usePatientInsuranceStateFromContext = (): UsePatientInsuranceState => {
  const context = useContext(patientInsuranceStateContext)
  if (context === undefined) {
    const error = new Error('usePatientInsuranceStateFromContext must be used within a PatientInsuranceStateProvider')
    if (Error.captureStackTrace) Error.captureStackTrace(error, usePatientInsuranceStateFromContext)
    throw error
  }
  return context
}

export default usePatientInsuranceStateFromContext
