import { differenceInYears } from 'date-fns'

export type AgeProps = {
  dob: string
}

function Age({ dob }: AgeProps): JSX.Element {
  if (!dob) {
    return <>-</>
  }

  return <>{String(differenceInYears(new Date(), new Date(dob)))}</>
}

export default Age
