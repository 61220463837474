import { createContext, useEffect, useState, Dispatch, SetStateAction } from 'react'

import { ApolloError } from '@apollo/client'
import { useAuth0 } from '@auth0/auth0-react'
import * as Sentry from '@sentry/react'
import config, { Environment } from 'config/config'
import Tokens from 'config/tokens'
import { useGetCurrentUserQuery, GetCurrentUserQuery } from 'generated/graphql'
import { isSentryEnvironment } from 'initSentry'
import { WarningOctagon } from 'phosphor-react'
import { Trans, useTranslation } from 'react-i18next'

import { Button, Paper, Typography } from '@mui/material'
import Box from '@mui/system/Box'

import Loading from 'ui/Loading'

export type CurrentUser = GetCurrentUserQuery['getCurrentUser']

export enum UserRoles {
  Responder = 'RESPONDER',
  Clinician = 'CLINICIAN',
  It = 'IT',
  Ops = 'OPS',
  OpsAdmin = 'OPS_ADMIN',
}

export type UserContextWithRefetch = {
  user: CurrentUser
  isLoading: boolean
  hasError?: ApolloError
  operatingAs: UserRoles | null
  refetchUser: Dispatch<SetStateAction<boolean>>
  roles: Array<UserRoles> | null
  setOperatingAs: Dispatch<SetStateAction<UserRoles | null>>
}
type Props = { children: React.ReactNode }

export const UserContext = createContext<UserContextWithRefetch | undefined>(undefined)

const isLocal = () => config.environment === Environment.Local

export const UserContextProvider = ({ children }: Readonly<Props>): JSX.Element => {
  const { logout } = useAuth0()
  const { t } = useTranslation()
  const [user, setUser] = useState<CurrentUser>(null)
  const [roles, setRoles] = useState<Array<UserRoles> | null>(null)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [hasError, setHasError] = useState<ApolloError | undefined>(undefined)
  const [refetchUser, setRefetchUser] = useState<boolean>(false)
  const [operatingAs, setOperatingAs] = useState<UserRoles | null>(null)

  const context = {
    user,
    hasError,
    isLoading,
    operatingAs,
    roles,
    setOperatingAs,
    refetchUser: setRefetchUser,
  }

  const { data, error, loading, refetch } = useGetCurrentUserQuery()

  useEffect(() => {
    if (data && data.getCurrentUser) {
      const { employeeId, email, isResponder, isClinician, isOps, isIt, opsRole } = data.getCurrentUser

      const currentRoles = [
        ...(isResponder ? [UserRoles.Responder] : []),
        ...(isClinician ? [UserRoles.Clinician] : []),
        ...(isIt ? [UserRoles.It] : []),
        ...(isOps ? [UserRoles.Ops] : []),
        ...(opsRole?.opsAdmin ? [UserRoles.OpsAdmin] : []),
      ]

      setUser(data.getCurrentUser)
      setRoles(currentRoles)

      if (isSentryEnvironment()) {
        Sentry.setUser({
          id: employeeId ?? undefined,
          email: email ?? undefined,
          roles: currentRoles.join(','),
        })
      }
    }
  }, [data])

  useEffect(() => {
    setIsLoading(loading)
  }, [loading])

  useEffect(() => {
    setHasError(error)
  }, [error])

  useEffect(() => {
    setOperatingAs((state) => (state ? (state[0] as UserRoles) : null))
  }, [roles])

  useEffect(() => {
    if (refetchUser) {
      refetch()
      setRefetchUser(false)
    }
  }, [refetchUser])

  const isOps = data?.getCurrentUser?.isOps ?? null

  if (loading || isOps == null) return <Loading />

  if (!(isLocal() || isOps)) {
    return (
      <Box
        minHeight={576}
        height="100vh"
        width="100vw"
        display="flex"
        justifyContent="center"
        alignItems="center"
        bgcolor={Tokens.color.ui.steel.base}
      >
        <Paper>
          <Box display="flex" p={12} flexDirection="column" alignItems="center">
            <Box p={4}>
              <WarningOctagon size={72} color={Tokens.color.brand.red.light} weight="duotone" />
            </Box>

            <Box textAlign="center">
              <Typography variant="h2">
                <Trans t={t} i18nKey="components:userContextProvider.noAccess.body" />
              </Typography>
            </Box>

            <Box p={4} pt={8} width={276}>
              <Button fullWidth variant="outlined" onClick={() => logout({ returnTo: window.location.origin })}>
                {t('actions:logOut')}
              </Button>
            </Box>
          </Box>
        </Paper>
      </Box>
    )
  }

  return <UserContext.Provider value={context}>{children}</UserContext.Provider>
}
