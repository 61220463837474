import { TypePolicy, FieldPolicy } from '@apollo/client'

const ServiceLine: TypePolicy = {
  keyFields: ['serviceLineId'],

  fields: {
    // Adds new @client field `friendlyName`
    friendlyName: {
      read(value, { readField }) {
        const name = readField('name') as string
        const subType = readField('subType') as string

        if (name && subType) {
          return [name, subType].join(' - ')
        }

        return '-'
      },
    } as FieldPolicy,
  },
}

export default ServiceLine
