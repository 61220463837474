import { memo, Dispatch, SetStateAction, useEffect } from 'react'

import { LogoutOptions } from '@auth0/auth0-react'
import Tokens from 'config/tokens'
import { CaretDown, CaretUp } from 'phosphor-react'
import { CurrentUser } from 'providers/UserContextProvider'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import Avatar from 'ui/Avatar'
import FullName from 'ui/TextFormatters/FullName'

export type Props = {
  logout: (arg?: LogoutOptions) => void
  overlayIsVisible: boolean
  setOverlayIsVisible: Dispatch<SetStateAction<boolean>>
  sidebarIsOpen?: boolean
  user?: Partial<CurrentUser>
}

const StyledFooter = styled.div`
  & > header {
    align-items: center;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    padding: calc(${Tokens.rhythm} * 2);

    &:hover {
      background-color: ${Tokens.color.neutral.black.transparent[10]};
    }

    .user {
      align-items: center;
      display: flex;

      .details {
        p {
          font-weight: ${Tokens.font.weight.normal};
          margin: 0;
          padding: 0 0 0 calc(${Tokens.rhythm} * 2);
        }

        p.small {
          color: ${Tokens.color.neutral.grey[191]};
          font-size: ${Tokens.font.size.paragraph.small};
        }
      }
    }
  }

  & > .actions {
    & > ul {
      border-top: 1px solid ${Tokens.color.neutral.white.transparent[10]};
      list-style: none;
      margin: 0 ${Tokens.rhythm};
      padding: ${Tokens.rhythm} 0;

      li {
        border-radius: 2px;
        color: ${Tokens.color.neutral.white.base};
        cursor: pointer;
        display: block;
        font-size: ${Tokens.font.size.paragraph.small};
        padding: ${Tokens.rhythm};
        text-decoration: none;

        &:hover {
          background-color: ${Tokens.color.neutral.white.transparent[10]};
        }
      }
    }
  }
`

const Footer = ({
  logout = () => {},
  overlayIsVisible,
  setOverlayIsVisible,
  sidebarIsOpen = false,
  user = {},
}: Readonly<Props>): JSX.Element => {
  const { t } = useTranslation()
  useEffect(() => {
    if (!sidebarIsOpen) setOverlayIsVisible(false)
  }, [sidebarIsOpen])

  return (
    <StyledFooter>
      <header onClick={() => setOverlayIsVisible((state) => !state)}>
        <div className="user">
          <Avatar size="lg" src={user?.profilePictureUrl ?? undefined} />
          <div className="details">
            <p>
              <FullName firstName={user?.firstName ?? ''} lastName={user?.lastName ?? ''} />
            </p>
          </div>
        </div>

        {overlayIsVisible ? (
          <CaretDown color={Tokens.color.neutral.white.base} />
        ) : (
          <CaretUp color={Tokens.color.neutral.white.base} />
        )}
      </header>

      {overlayIsVisible ? (
        <div className="actions">
          <ul>
            <li onClick={() => logout({ returnTo: window.location.origin })}>{t('actions:logOut')}</li>
          </ul>
        </div>
      ) : null}
    </StyledFooter>
  )
}

export default memo(Footer)
