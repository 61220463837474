import { GetAppointmentsQuery } from 'generated/graphql'
import { createSelector } from 'reselect'
import ArrayValueType from 'types/ArrayValueType'
import { responderNamesFromDispatches } from 'utils/nameListHelpers/nameListHelpers'

const getAppointmentDispatches = (appointment: ArrayValueType<GetAppointmentsQuery['appointmentsList']>) =>
  appointment?.appointmentDispatches?.nodes ?? null

/**
 * get full name of dispatched responders
 */
export const selectDispatchedResponderNames = createSelector(getAppointmentDispatches, (appointmentDispatches) => {
  if (appointmentDispatches == null) return null
  return responderNamesFromDispatches(appointmentDispatches)
})

const getPatientIds = (patients: Array<{ patientId: number }>): Array<number> | null =>
  patients?.map(({ patientId }) => patientId) ?? null

export const selectPatientIds = createSelector(getPatientIds, (x) => x)
