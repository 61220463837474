import { FC } from 'react'

import Tokens from 'config/tokens'
import { AppointmentDispatchesFragment } from 'generated/graphql'
import { useTranslation } from 'react-i18next'

import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import { Box } from '@mui/system'

import TableHeadCell from 'ui/MaterialUI/Table/TableHeadCell'
import FormattedDate from 'ui/TextFormatters/FormattedDate'

const DispatchesTable: FC<{
  dispatches: Partial<AppointmentDispatchesFragment['appointmentDispatches']['nodes']> | null
}> = ({ dispatches }) => {
  const { t } = useTranslation()

  return (
    <TableContainer style={{ maxHeight: '60vh' }}>
      <Table aria-label="Dispatches Table" stickyHeader>
        <TableHead>
          <TableRow>
            <TableHeadCell>{t('glossary:responder')}</TableHeadCell>
            <TableHeadCell>{t('glossary:AppointmentDispatchStatus_DISPATCHED')}</TableHeadCell>
            <TableHeadCell>{t('glossary:AppointmentDispatchStatus_EN_ROUTE')}</TableHeadCell>
            <TableHeadCell>{t('glossary:AppointmentDispatchStatus_ON_SCENE')}</TableHeadCell>
            <TableHeadCell>{t('glossary:AppointmentDispatchStatus_AT_PATIENT')}</TableHeadCell>
            <TableHeadCell>{t('glossary:AppointmentDispatchStatus_COMPLETED')}</TableHeadCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {dispatches
            ? dispatches.map((dispatch) =>
                dispatch ? (
                  <TableRow key={dispatch?.appointmentDispatchId}>
                    <TableCell>{dispatch?.responder?.user?.fullName ?? t('common:emptyValue')}</TableCell>

                    <TableCell>
                      <FormattedDate formatPreset={'DATETIME_MED_WITHOUT_YEAR'} dateTime={dispatch?.dispatchedAt} />
                    </TableCell>

                    <TableCell>
                      <FormattedDate formatPreset={'DATETIME_MED_WITHOUT_YEAR'} dateTime={dispatch?.enRouteAt} />
                    </TableCell>

                    <TableCell>
                      <FormattedDate formatPreset={'DATETIME_MED_WITHOUT_YEAR'} dateTime={dispatch?.onSceneAt} />
                    </TableCell>

                    <TableCell>
                      <FormattedDate formatPreset={'DATETIME_MED_WITHOUT_YEAR'} dateTime={dispatch?.atPatientAt} />
                    </TableCell>

                    <TableCell>
                      {dispatch?.removedAt ? (
                        <Box sx={{ color: Tokens.color.brand.red.base }}>
                          <span>{t('components:modules.Appointment.Dispatches.removedAt')}</span>{' '}
                          <FormattedDate formatPreset={'DATETIME_MED_WITHOUT_YEAR'} dateTime={dispatch?.removedAt} />
                        </Box>
                      ) : (
                        <FormattedDate formatPreset={'DATETIME_MED_WITHOUT_YEAR'} dateTime={dispatch?.completedAt} />
                      )}
                    </TableCell>
                  </TableRow>
                ) : null,
              )
            : null}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default DispatchesTable
