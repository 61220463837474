import Tokens from 'config/tokens'
import { useGetServiceLinesQuery } from 'generated/graphql'

import { ListSubheader, Typography } from '@mui/material'
import MenuItem from '@mui/material/MenuItem'
import TextField, { OutlinedTextFieldProps } from '@mui/material/TextField'
import Box from '@mui/system/Box'

import Spinner from 'ui/Spinner'

import { selectGroupedServiceLinesWithoutNycha } from './selectors'

const VALUE_NONE = '__none__'

export type ServiceLineSelectProps = Partial<Omit<OutlinedTextFieldProps, 'onChange' | 'value'>> & {
  showNone?: boolean
  fullWidth?: boolean

  value?: number | null
  onChange: (newValue: number | null) => void
}

const ServiceLineSelect = ({
  showNone = false,
  fullWidth,
  onChange,
  value,
  ...props
}: ServiceLineSelectProps): JSX.Element => {
  const handleOnChange = (e: React.ChangeEvent<{ value: string | typeof VALUE_NONE }>) => {
    const newValue = e.target.value
    onChange(newValue === VALUE_NONE ? null : parseFloat(newValue))
  }

  const { data, loading } = useGetServiceLinesQuery()

  const groupedServiceLines = selectGroupedServiceLinesWithoutNycha(data)

  const existingValue = value ?? VALUE_NONE

  return (
    <Box sx={{ position: 'relative', minWidth: 200, maxWidth: fullWidth ? 'none' : 300 }}>
      <TextField fullWidth {...props} value={existingValue} select label="Service Line" onChange={handleOnChange}>
        {loading && (
          <Box
            component="li"
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            px={16}
            py={8}
            bgcolor={Tokens.color.ui.steel.base}
          >
            <Spinner size="large" foregroundColor={Tokens.color.ui.blue.base} />
            <Typography sx={{ color: Tokens.color.neutral.grey[102] }}>Loading...</Typography>
          </Box>
        )}
        <MenuItem value={VALUE_NONE} {...(!showNone && { style: { display: 'none' } })} />
        {Object.keys(groupedServiceLines).reduce((result: JSX.Element[], serviceLineName) => {
          result.push(
            <ListSubheader key={serviceLineName} disableSticky>
              {serviceLineName}
            </ListSubheader>,
          )
          const options = groupedServiceLines[serviceLineName].map((item) => (
            <MenuItem key={item.serviceLineId} value={item.serviceLineId}>
              {item.friendlyName}
            </MenuItem>
          ))
          result.push(...options)
          return result
        }, [])}
      </TextField>
    </Box>
  )
}

export default ServiceLineSelect
