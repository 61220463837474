import zonedTimeToUtc from 'date-fns-tz/zonedTimeToUtc'
import endOfDay from 'date-fns/endOfDay'
import format from 'date-fns/format'
import parse from 'date-fns/parse'
import startOfDay from 'date-fns/startOfDay'
import { DateTime } from 'luxon'

export const YMDtoDate = (date: string): Date => parse(date, 'yyyy-MM-dd', new Date())

export const YMDtoDateSafe = (date?: string): Date => {
  if (!date) {
    return new Date()
  }
  return YMDtoDate(date)
}

export const DateToYMD = (date: Date): string => format(date, 'yyyy-MM-dd')

export const getStartEndDatesForTimeZone = (
  date: string,
  timezone: string,
): {
  dayStart: Date
  dayEnd: Date
} => {
  const dateObj = YMDtoDate(date)

  const dayStart = zonedTimeToUtc(startOfDay(dateObj), timezone)
  const dayEnd = zonedTimeToUtc(endOfDay(dateObj), timezone)
  return { dayStart, dayEnd }
}

/* Example: isPastDate('2021-02-12 01:00', 'America/Los_Angeles'); */
export const isPastDate = (dateTime: string, zone: string): boolean =>
  DateTime.fromISO(dateTime, { zone }).diffNow('seconds').seconds < 0
