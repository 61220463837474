import { FC } from 'react'

import Tokens from 'config/tokens'
import { ProhibitInset } from 'phosphor-react'
import { Trans, useTranslation } from 'react-i18next'

import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import Box from '@mui/system/Box'

import Modal from 'ui/Modal'

type PatientInsuranceDeleteDialogProps = {
  open: boolean
  onClose: () => void
  onSubmit: () => void
}

const PatientInsuranceDeleteDialog: FC<PatientInsuranceDeleteDialogProps> = ({ open, onClose, onSubmit }) => {
  const { t } = useTranslation()

  return (
    <Modal
      open={open}
      fullWidth={true}
      maxWidth="sm"
      onClose={onClose}
      title={t('components:modules.Patient.Insurance.DeleteDialog.heading')}
      actions={
        <>
          <Button variant="outlined" color="secondary" onClick={onClose}>
            {t('components:modules.Patient.Insurance.DeleteDialog.cancelButton')}
          </Button>

          <Button
            color="error"
            onClick={onSubmit}
            endIcon={<ProhibitInset size={14} color={Tokens.color.neutral.white.base} />}
          >
            {t('components:modules.Patient.Insurance.DeleteDialog.submitButton')}
          </Button>
        </>
      }
    >
      <Box mb={4}>
        <Typography>
          <Trans t={t} i18nKey="components:modules.Patient.Insurance.DeleteDialog.body" />
        </Typography>
      </Box>
    </Modal>
  )
}

export default PatientInsuranceDeleteDialog
