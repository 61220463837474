import { StrictMode, Suspense } from 'react'

// eslint-disable-next-line import/no-extraneous-dependencies
import { createBrowserHistory } from 'history'
import App from 'modules/App/App'
import ReactDOM from 'react-dom'
import { Router } from 'react-router-dom'
import 'index.css'
import { initFeatureFlagOverrides, isDevelopment } from 'utils/featureFlagsUtilities'

import initSentry from './initSentry'
// init i18n
import './initI18next'

const history = createBrowserHistory()

// init sentry
initSentry()

if (isDevelopment) {
  ;(async () => {
    const { default: initDevDebug } = await import('initDevDebug')
    initFeatureFlagOverrides()
    initDevDebug()
  })()
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const renderApp = () => {
  ReactDOM.render(
    <StrictMode>
      <Suspense fallback="">
        <Router history={history}>
          <App />
        </Router>
      </Suspense>
    </StrictMode>,
    // some testing init issue where it couldn't find #root
    document.getElementById('root') || document.createElement('div'),
  )
}

renderApp()
