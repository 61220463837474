import { useEffect, useState } from 'react'

import { yupResolver } from '@hookform/resolvers/yup'
import { useAddEncounterMutation, GetAppointmentDocument, ModuleNameEnum } from 'generated/graphql'
import useCreateMutatatedDispatch from 'hooks/useCreateMutatedDispatch'
import useToggle from 'hooks/useToggle'
import { PatientLookupType } from 'modules/Appointments/Create/AppointmentCreateFormSchema'
import PatientLookup from 'modules/Appointments/Create/PatientLookup'
import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import hasFeatureFlag from 'utils/featureFlagsUtilities'

import { Alert, Grid } from '@mui/material'
import Box from '@mui/system/Box'

import LabeledSection from 'ui/LabeledSection'

import { selectFirstEncounterChannelAttributionId, selectPatients } from '../selectors'
import useGetAppointmentQueryContext from '../useGetAppointmentQueryContext'

import AddPatientActions from './AddPatientActions'
import AddPatientButton from './AddPatientButton'
import AddPatientDetails from './AddPatientDetails'
import AddPatientFormSchema, { addPatientFormSchema } from './AddPatientFormSchema'
import useAddPatientModeContext from './useAddPatientModeContext'

const DEFAULT_VALUES_ADD_PATIENT_FORM = {
  // TODO: reset form when a patient is selected
  patientId: null,

  reasonForEncounter: '',
  modules: '[]',
  serviceLineId: null,
  genderRequirement: null,

  channelAttributionId: null,
}

const AddPatient = () => {
  const { t } = useTranslation()

  const [hasGenericServerError, , setGenericServerError, unsetGenericServerError] = useToggle(false)
  const [isAddPatientMode, , setIsAddPatientMode, unsetIsAddPatientMode] = useAddPatientModeContext()
  const [selectedPatient, setSelectedPatient] = useState<PatientLookupType | null>(null)

  const [addEncounterMutation, { loading: loadingAddEncounter }] = useAddEncounterMutation()
  const { data, id: appointmentId } = useGetAppointmentQueryContext()
  const patients = selectPatients(data)
  const currentChannelAttributionId = selectFirstEncounterChannelAttributionId(data)

  const defaultValuesWithChannelAttribution = {
    ...DEFAULT_VALUES_ADD_PATIENT_FORM,
    channelAttributionId: currentChannelAttributionId,
  }

  const methods = useForm<AddPatientFormSchema>({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    defaultValues: defaultValuesWithChannelAttribution,
    resolver: yupResolver(addPatientFormSchema),
  })

  const cancel = useCreateMutatatedDispatch(() => {
    unsetGenericServerError()

    // unset entire state
    unsetIsAddPatientMode()
    setSelectedPatient(null)
    methods.reset(defaultValuesWithChannelAttribution)
  })

  const submit = useCreateMutatatedDispatch(async () => {
    unsetGenericServerError()

    let result

    try {
      await methods.handleSubmit(
        async ({ patientId, reasonForEncounter, serviceLineId, genderRequirement, modules, channelAttributionId }) => {
          // useless typeguards
          if (appointmentId == null || patientId == null || serviceLineId == null || channelAttributionId == null)
            return

          result = await addEncounterMutation({
            variables: {
              appointmentId,
              patientId,

              reasonForEncounter,
              serviceLineId,
              genderRequirement,
              modules: modules ? (modules as ModuleNameEnum) : [],

              channelAttributionId,
            },
            refetchQueries: [{ query: GetAppointmentDocument, variables: { appointmentId } }],
          })
        },
        (e) =>
          // eslint-disable-next-line no-console
          console.log('client-side validation Errors:', e),
      )()

      // unset entire state
      unsetIsAddPatientMode()
      setSelectedPatient(null)
      methods.reset(defaultValuesWithChannelAttribution)
    } catch (e) {
      // eslint-disable-next-line no-empty
    }

    if (!result) {
      setGenericServerError()
    }
  })

  // reset form when a patient is (de)selected
  useEffect(() => {
    unsetGenericServerError()
    // defaultValues could be stale here, but I'm taking a bet that the chance is negligible
    // we can remove any doubt by resetting when calling something like onSelectedPatient
    // then we also need to reset on a callback for cancel and confirm (when we deselect patient)
    if (selectedPatient == null) {
      methods.reset(defaultValuesWithChannelAttribution)
      return
    }
    methods.reset({ ...defaultValuesWithChannelAttribution, patientId: selectedPatient.patientId })
  }, [selectedPatient])

  if (!hasFeatureFlag('showAddPatientInAppointmentDetails')) {
    return null
  }

  return (
    <>
      {isAddPatientMode ? (
        <LabeledSection
          label={t('components:modules.Appointment.AddPatient.heading')}
          as={'h2'}
          style={{ paddingTop: '2rem' }}
        >
          {selectedPatient == null ? (
            <PatientLookup patients={patients} onPatientSelected={setSelectedPatient} />
          ) : (
            <FormProvider {...methods}>
              <AddPatientDetails patient={selectedPatient} />
            </FormProvider>
          )}

          {hasGenericServerError ? (
            <Grid item xs={12} sm={12}>
              <Box mt={4}>
                <Alert severity="error">{t('components:modules.Appointment.PatientCreateDialog.genericError')}</Alert>
              </Box>
            </Grid>
          ) : null}

          <AddPatientActions
            onCancel={cancel}
            onConfirm={submit}
            loading={loadingAddEncounter}
            isValid={selectedPatient != null && methods.formState.isValid}
          />
        </LabeledSection>
      ) : (
        <AddPatientButton status={data?.appointment?.status} onClick={setIsAddPatientMode} />
      )}
    </>
  )
}

export default AddPatient
