import { FC } from 'react'

import { DateTime } from 'luxon'
import { useFormContext } from 'react-hook-form'
import safeJsonParse from 'utils/safeJsonParse'

import GlobalHumanId from 'ui/GlobalHumanId'
import LabeledItem from 'ui/LabeledItem'
import Age from 'ui/TextFormatters/Age'
import FormattedDate from 'ui/TextFormatters/FormattedDate'
import FullName from 'ui/TextFormatters/FullName'
import GenderIdentity from 'ui/TextFormatters/GenderIdentity/GenderIdentity'
import GenderPronoun from 'ui/TextFormatters/GenderPronoun'
import Races from 'ui/TextFormatters/Races'
import Sex from 'ui/TextFormatters/Sex/Sex'

const NameAndDemographicsRead: FC<unknown> = () => {
  const { getValues } = useFormContext()
  const { firstName, lastName, dob, sex, preferredName, genderIdentity, genderPronoun, races, globalHumanId } =
    getValues()

  return (
    <div>
      <LabeledItem label="Preferred First Name:" inline>
        {preferredName}
      </LabeledItem>

      <LabeledItem label="Legal Full Name:" inline>
        <FullName firstName={firstName ?? ''} lastName={lastName ?? ''} />
      </LabeledItem>

      <LabeledItem label="Date of Birth:" inline>
        <FormattedDate dateTime={DateTime.fromISO(dob)} withTime={false} withDayOfWeek={false} /> (<Age dob={dob} />{' '}
        years old)
      </LabeledItem>

      <LabeledItem label="Sex at Birth:" inline>
        <Sex sex={sex} />
      </LabeledItem>

      <LabeledItem label="Preferred Gender Identity:" inline>
        <GenderIdentity genderIdentity={genderIdentity} />
      </LabeledItem>

      <LabeledItem label="Preferred Gender Pronouns:" inline>
        <GenderPronoun genderPronoun={genderPronoun} />
      </LabeledItem>

      <LabeledItem label="Race & Ethnicity:" inline>
        <Races races={safeJsonParse(races) ?? []} />
      </LabeledItem>

      <LabeledItem label="Patient ID:" inline>
        <GlobalHumanId id={globalHumanId} />
      </LabeledItem>
    </div>
  )
}

export default NameAndDemographicsRead
