import { useMemo, FC, memo } from 'react'

import Tokens from 'config/tokens'
import { useGetServiceLinesQuery } from 'generated/graphql'
import { isEmpty } from 'lodash'
import { useTranslation } from 'react-i18next'

import { ListSubheader } from '@mui/material'
import MenuItem from '@mui/material/MenuItem'
import TextField, { OutlinedTextFieldProps } from '@mui/material/TextField'
import { Box } from '@mui/system'

import Spinner from 'ui/Spinner'

import {
  assertServiceLineHasServiceLineId,
  selectGroupedServiceLinesWithoutNycha,
  selectServiceLineById,
  selectServiceLines,
} from './selectors'

// TODO: migrate to i18nKey, see AppointmentStatusSelect for reference

const VALUE_NONE = '__none__'
const DEFAULT_I18N_KEY = 'components:ui.Inputs.ServiceLineSelectMultiple'

export type ServiceLineSelectMultipleProps = Partial<Omit<OutlinedTextFieldProps, 'onChange' | 'value'>> & {
  showNone?: boolean
  fullWidth?: boolean
  i18nKey?: string

  /**
   * Although this select has an internal loading state,
   * this prop triggers the small loading spinner.
   * This is useful when an external state is controlling the value and has its own loading state
   * If the external state will trigger a refetch of markets, it's best to use this prop in unison with `disabled` prop
   */
  loading?: boolean

  value?: number[] | null
  onChange: (newValue: number[] | null) => void
}

const ServiceLineSelectMultiple: FC<ServiceLineSelectMultipleProps> = ({
  value,
  onChange,
  placeholder,
  fullWidth,
  i18nKey = DEFAULT_I18N_KEY,
  showNone = false,
  loading: externalLoading = false,
  ...textFieldProps
}) => {
  const { t } = useTranslation()

  function handleOnChange(event: { target: { value: number[] | string } }): void {
    const {
      target: { value: optionValue },
    } = event

    onChange(optionValue !== VALUE_NONE ? (optionValue as number[]) : null)
  }

  const { data, loading } = useGetServiceLinesQuery()
  const serviceLines = selectServiceLines(data)
  const groupedServiceLines = selectGroupedServiceLinesWithoutNycha(data)

  const existingValue = useMemo(() => {
    if (!(value && value.length && serviceLines)) return VALUE_NONE
    if (!serviceLines.length) return VALUE_NONE
    return (assertServiceLineHasServiceLineId(serviceLines, value) && value) || VALUE_NONE
  }, [value, serviceLines])

  const textFieldLabel = t([
    `${i18nKey}.label`,
    `${DEFAULT_I18N_KEY}.label`,
    'components:ui.Inputs.generic.select.label',
  ])
  const noneLabel = t([
    `${i18nKey}.option.none.label`,
    `${DEFAULT_I18N_KEY}.option.none.label`,
    'components:ui.Inputs.generic.option.none.label',
  ])
  const nonePlaceholder = t([
    `${i18nKey}.option.none.placeholder`,
    `${DEFAULT_I18N_KEY}.option.none.placeholder`,
    'components:ui.Inputs.generic.option.none.placeholder',
  ])

  return (
    <Box
      sx={{
        position: 'relative',
        minWidth: 200,
        maxWidth: fullWidth ? 'none' : 300,
      }}
    >
      <TextField
        fullWidth
        name="marketsList"
        label={textFieldLabel}
        value={existingValue === VALUE_NONE ? [] : existingValue}
        {...textFieldProps}
        select
        onChange={handleOnChange}
        SelectProps={{
          multiple: true,
          displayEmpty: true,
          renderValue: (optionValue) => {
            if (Array.isArray(optionValue) && isEmpty(optionValue)) {
              return nonePlaceholder
            }
            return optionValue === VALUE_NONE
              ? nonePlaceholder
              : (optionValue as number[]).map(selectServiceLineById(data)).join(', ')
          },
        }}
      >
        <MenuItem value={VALUE_NONE} disabled {...(!showNone && { style: { display: 'none' } })}>
          {noneLabel}
        </MenuItem>

        {loading && (
          <Box
            component="li"
            sx={{
              pointerEvents: 'none',

              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',

              padding: '32px 16px',
              backgroundColor: Tokens.color.ui.steel.base,
            }}
          >
            <Spinner size="large" foregroundColor={Tokens.color.ui.blue.base} />
            <Box component="p" sx={{ color: Tokens.color.neutral.grey[102] }}>
              {t('components:ui.Inputs.AppointmentCancellationReasonSelect.text.fetching')}
            </Box>
          </Box>
        )}

        {!(loading || serviceLines?.length) ? (
          <MenuItem disabled>
            {t('components:ui.Inputs.AppointmentCancellationReasonSelect.text.notAvailable')}
          </MenuItem>
        ) : (
          Object.keys(groupedServiceLines).reduce((result: JSX.Element[], serviceLineName) => {
            result.push(
              <ListSubheader key={serviceLineName} disableSticky>
                {serviceLineName}
              </ListSubheader>,
            )

            const options = groupedServiceLines[serviceLineName].map((item) => (
              <MenuItem key={item.serviceLineId} value={item.serviceLineId}>
                {item.friendlyName}
              </MenuItem>
            ))
            result.push(...options)
            return result
          }, [])
        )}
      </TextField>

      {(loading || externalLoading) && (
        <Box sx={{ pointerEvents: 'none', position: 'absolute', top: 20, right: 32 }}>
          <Spinner size="small" foregroundColor={Tokens.color.ui.blue.base} />
        </Box>
      )}
    </Box>
  )
}

export default memo(ServiceLineSelectMultiple)
