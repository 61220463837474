import { Auth0Provider } from '@auth0/auth0-react'

import LoginProvider from './LoginProvider'

type Props = {
  children: React.ReactNode
}

const AuthProvider = ({ children }: Readonly<Props>): JSX.Element => {
  const domain = process.env.REACT_APP_AUTH0_DOMAIN
  const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID
  const audience = process.env.REACT_APP_AUTH0_AUDIENCE

  return (
    <Auth0Provider
      domain={domain!}
      clientId={clientId!}
      redirectUri={window.location.origin}
      useRefreshTokens={true}
      audience={audience!}
    >
      <LoginProvider>{children}</LoginProvider>
    </Auth0Provider>
  )
}

export default AuthProvider
