import { FC } from 'react'

import { useTranslation } from 'react-i18next'

import Button from '@mui/material/Button'
import Box from '@mui/system/Box'

import ConfirmPatientButton from './ConfirmPatientButton'

type AddPatientActionsProps = {
  onCancel: () => void
  onConfirm: () => void

  loading: boolean
  isValid: boolean
}

const AddPatientActions: FC<AddPatientActionsProps> = ({ onCancel, onConfirm, loading, isValid }) => {
  const { t } = useTranslation()
  return (
    <Box sx={{ display: 'flex', justifyContent: 'flex-end', paddingTop: 8 }}>
      <Box sx={{ '& > * + *': { marginLeft: 8 } }}>
        <Button variant="outlined" color="secondary" onClick={onCancel}>
          {t('actions:cancel')}
        </Button>

        <Button type="submit" onClick={onConfirm} disabled={!isValid}>
          <ConfirmPatientButton loading={loading} isValid={isValid} />
        </Button>
      </Box>
    </Box>
  )
}

export default AddPatientActions
