import FormGroup from '@mui/material/FormGroup'

import TimeSlot from 'ui/TimeSlot'

type TimeSlotType = {
  start: number
  end: number
  respondersAvailable: number
  label: string
  isPast: boolean
}

type TimeSlotRadiosProps = {
  availabilityData: { [key: string]: TimeSlotType }
  value: string
  onChange: (newValue: string) => void
  override?: boolean
  disabled?: boolean
}

const TimeSlotRadios = ({
  availabilityData,
  value,
  onChange,
  override,
  disabled,
}: TimeSlotRadiosProps): JSX.Element => (
  <FormGroup onChange={(event: React.ChangeEvent<HTMLInputElement>) => onChange(event.target.value)}>
    {Object.keys(availabilityData).map((key) => (
      <TimeSlot
        name="startTimes"
        value={key}
        key={key}
        checked={value.includes(key)}
        disabled={
          availabilityData[key].isPast || (!override && (disabled || availabilityData[key].respondersAvailable <= 0))
        }
      >
        {availabilityData[key].label}
      </TimeSlot>
    ))}
  </FormGroup>
)

export default TimeSlotRadios
