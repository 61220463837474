import { FC, useMemo } from 'react'

import { GetChannelAttributionsQuery, useGetChannelAttributionsQuery } from 'generated/graphql'
import { useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { AppointmentPartnershipCardEditFormSchema } from 'yupSchemas/partnership'

import { Typography } from '@mui/material'

import LabeledItem from 'ui/LabeledItem'

const selectChannelNameByChannelId = (data?: GetChannelAttributionsQuery) => (channelAttributionId: number | null) => {
  if (!(data && channelAttributionId)) return null

  const channelAttribution =
    data?.channelAttributionsList?.find((item) => item.channelAttributionId === channelAttributionId) ?? null

  if (!channelAttribution) return null

  return channelAttribution?.channel ?? null
}

const AppointmentPartnershipRead: FC<unknown> = () => {
  const { t } = useTranslation()
  const { data } = useGetChannelAttributionsQuery()
  const patients = useWatch<AppointmentPartnershipCardEditFormSchema, 'patients'>({ name: 'patients' })

  const selectChannelNamesByChannelIdFromAllChannelAttributions = useMemo(
    () => selectChannelNameByChannelId(data),
    [data],
  )

  if (!(data && patients)) return null

  return (
    <>
      {patients.map(({ firstName, lastName, channelAttributionId, patientId }) => (
        <div key={patientId}>
          <Typography>
            {firstName} {lastName}
          </Typography>
          <LabeledItem label={t('components:modules.Appointments.channelAttribution')} inline>
            {selectChannelNamesByChannelIdFromAllChannelAttributions(channelAttributionId) ?? t('common:emptyValue')}
          </LabeledItem>
        </div>
      ))}
    </>
  )
}

export default AppointmentPartnershipRead
