import { FC } from 'react'

import { AppointmentStatusEnum } from 'generated/graphql'
import { useTranslation } from 'react-i18next'

import Button from '@mui/material/Button'
import Box from '@mui/system/Box'

type AddPatientButtonProps = {
  onClick: () => void
  status?: AppointmentStatusEnum | null
}

const AddPatientButton: FC<AddPatientButtonProps> = ({ status, onClick }) => {
  const { t } = useTranslation()

  const disableAddPatientButton = Boolean(
    status === AppointmentStatusEnum.Cancelled ||
      status === AppointmentStatusEnum.Completed ||
      status === AppointmentStatusEnum.Rescheduled,
  )

  return (
    <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end' }} paddingTop={4}>
      <Button disabled={disableAddPatientButton} onClick={onClick}>
        {t('actions:appointment.addPatient')}
      </Button>
    </Box>
  )
}

export default AddPatientButton
