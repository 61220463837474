import { useEffect } from 'react'

import theme from 'config/theme'
import Tokens from 'config/tokens'
import useSidebarContext from 'hooks/useSidebarContext'
import { Sidebar } from 'modules/Sidebar'
import { List, X } from 'phosphor-react'
import { Link as RouterLink } from 'react-router-dom'
import isInZendeskSidebar from 'utils/isInZendeskSidebar'

import { Container, IconButton, Breadcrumbs, Box, Link } from '@mui/material'
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles'
import makeStyles from '@mui/styles/makeStyles'

import { LinkTo } from 'ui/Breadcrumbs/types'

import StyledLayout from './StyledLayout'

type BreadcrumbsProps = {
  title: string
  href?: string
  to?: LinkTo
}

type Props = {
  children?: React.ReactNode
  headerActions?: React.ReactNode
  title?: string
  breadcrumbsProps?: BreadcrumbsProps[]
}

const useStyles = makeStyles({
  main: {
    minHeight: '100vh',
  },
})

const Layout = ({ children, title = 'Page Title', breadcrumbsProps, headerActions }: Props): JSX.Element => {
  const classes = useStyles()
  const sidebarContext = useSidebarContext()
  const asideClasses = ['sidebar']
  const overlayClasses = ['overlay']

  document.title = `ReadyOps | ${title}`

  if (sidebarContext?.sidebarIsOpen) {
    asideClasses.push('visible')
    overlayClasses.push('visible')
  }

  useEffect(() => {
    if (sidebarContext?.sidebarIsOpen) {
      sidebarContext?.setSidebarIsOpen(false)
    }
  }, [])

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <StyledLayout>
          <div className={overlayClasses.join(' ')} onClick={() => sidebarContext?.setSidebarIsOpen(false)} />

          <aside className={asideClasses.join(' ')}>
            <Sidebar />
          </aside>

          <main className={classes.main}>
            {!isInZendeskSidebar() && (
              <header style={{ marginBottom: '1rem', borderBottom: `1px solid ${Tokens.color.neutral.grey[219]}` }}>
                <Container maxWidth={false}>
                  <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                    <IconButton
                      onClick={() => sidebarContext?.setSidebarIsOpen(true)}
                      size="large"
                      data-testid="sidebar-menu-button"
                    >
                      {!sidebarContext?.sidebarIsOpen ?? false ? (
                        <List size={24} color={Tokens.color.ui.charcoal.base} />
                      ) : (
                        <X size={24} color={Tokens.color.ui.charcoal.base} />
                      )}
                    </IconButton>

                    {breadcrumbsProps && (
                      <Breadcrumbs>
                        <span style={{ color: 'rgb(82, 94, 125, 0.7)' }}>ReadyOps</span>
                        {breadcrumbsProps.map(({ title: breadcrumbTitle, href }: BreadcrumbsProps, index) =>
                          index === breadcrumbsProps.length - 1 ? (
                            <Link component={RouterLink} to={href || '/'} underline="hover" key={breadcrumbTitle}>
                              {breadcrumbTitle}
                            </Link>
                          ) : (
                            <Link
                              component={RouterLink}
                              to={href || '/'}
                              underline="hover"
                              color="secondary"
                              key={breadcrumbTitle}
                            >
                              {breadcrumbTitle}
                            </Link>
                          ),
                        )}
                      </Breadcrumbs>
                    )}
                    <Box sx={{ flexGrow: 1 }} />
                    <Box sx={{ paddingRight: '1rem' }}>{headerActions}</Box>
                  </Box>
                </Container>
              </header>
            )}
            {isInZendeskSidebar() && (
              <header>
                <Container>{''}</Container>
              </header>
            )}

            {children}
          </main>
        </StyledLayout>
      </ThemeProvider>
    </StyledEngineProvider>
  )
}

export default Layout
