import React from 'react'

import Tokens from 'config/tokens'
import { ProhibitInset } from 'phosphor-react'

import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'

import Modal from 'ui/Modal/Modal'

export type ConfirmModalProps = {
  open?: boolean
  title?: string
  confirmText?: React.ReactNode
  cancelText?: React.ReactNode
  message?: string
  isDestructive?: boolean
  handleConfirm: () => void | undefined
  handleCancel: () => void | undefined
}

const ConfirmModal = ({
  open = false,
  title,
  cancelText,
  confirmText,
  handleCancel = () => {},
  handleConfirm = () => {},
  message,
  isDestructive,
  ...props
}: ConfirmModalProps): JSX.Element => (
  <Modal
    {...props}
    open={open}
    title={title}
    container={document.getElementById('root')}
    actions={
      <>
        <Button autoFocus onClick={handleCancel} variant="outlined" color="secondary">
          {cancelText || 'Cancel'}
        </Button>
        <Button
          onClick={handleConfirm}
          color={isDestructive ? 'error' : undefined}
          startIcon={isDestructive ? <ProhibitInset size={14} color={Tokens.color.neutral.white.base} /> : undefined}
        >
          {confirmText || 'Ok'}
        </Button>
      </>
    }
  >
    <Typography>{message}</Typography>
  </Modal>
)

export default ConfirmModal
