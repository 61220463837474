import { FC, useCallback } from 'react'

import { GetAppointmentDocument, GetAppointmentQuery, useCancelAppointmentMutation } from 'generated/graphql'
import { useTranslation } from 'react-i18next'
import { useToggle } from 'react-use'

import Button from '@mui/material/Button'
import Box from '@mui/system/Box'

import AppointmentCancelDialog from './AppointmentCancelDialog'
import assertAppointmentIsCancellable from './assertAppointmentisCancellable'

type AppointmentCancellationProps = {
  appointment: GetAppointmentQuery['appointment'] | null | undefined
}

const AppointmentCancellation: FC<AppointmentCancellationProps> = ({ appointment }) => {
  const { t } = useTranslation()
  const [showCancellationModal, toggleShowCancellationModal] = useToggle(false)
  const [cancelAppointment, { loading: loadingCancelAppointment }] = useCancelAppointmentMutation()
  const submitCancelAppointment = useCallback(
    async ({ cancellationReason, cancellationReasonOther }) => {
      if (!(appointment && appointment?.appointmentId)) return Promise.resolve(null)

      const { appointmentId } = appointment

      return cancelAppointment({
        variables: {
          cancellationReason,
          cancellationReasonOther,
          appointmentId,
        },
        refetchQueries: [{ query: GetAppointmentDocument, variables: { appointmentId } }],
      })
    },
    [appointment, cancelAppointment],
  )

  if (!appointment) return null

  const isCancellable = assertAppointmentIsCancellable(appointment)

  if (!isCancellable) return null

  return (
    <>
      <Box py={4}>
        <Button color="error" onClick={toggleShowCancellationModal}>
          {t('actions:appointment.cancelAppointment')}
        </Button>
      </Box>

      <AppointmentCancelDialog
        open={showCancellationModal}
        onClose={() => toggleShowCancellationModal(false)}
        onSubmit={submitCancelAppointment}
        loading={loadingCancelAppointment}
      />
    </>
  )
}

export default AppointmentCancellation
