import { useMemo, FC } from 'react'

import InsuranceRelationshipToPolicyHolder from 'modules/Patient/Insurance/InsuranceRelationshipToPolicyHolder'
import { useTranslation } from 'react-i18next'

import MenuItem from '@mui/material/MenuItem'
import TextField, { OutlinedTextFieldProps } from '@mui/material/TextField'
import makeStyles from '@mui/styles/makeStyles'

const OPTIONS = Object.values(InsuranceRelationshipToPolicyHolder)

export type OptionType = InsuranceRelationshipToPolicyHolder | null

const VALUE_NONE = '__none__'
const DEFAULT_I18N_KEY = 'components:ui.Inputs.InsuranceRelationshipToPolicyHolder'
const I18N_OPTION_KEY = 'glossary:InsuranceRelationshipToPolicyHolder'

export type InsuranceRelationshipToPolicyHolderSelectProps = Partial<
  Omit<OutlinedTextFieldProps, 'onChange' | 'value'>
> & {
  i18nKey?: string
  showNone?: boolean
  fullWidth?: boolean

  value?: OptionType
  onChange: (newValue: OptionType) => void
}

const useStyles = makeStyles({
  container: {
    minWidth: 200,
    maxWidth: ({ fullWidth }: Pick<InsuranceRelationshipToPolicyHolderSelectProps, 'fullWidth'>) =>
      fullWidth ? 'none' : 300,
  },
  policyHolderRelationship: {
    whiteSpace: 'normal',
  },
})

export const assertOptionExists = (option: OptionType): boolean => {
  if (option == null) {
    return false
  }
  return OPTIONS.includes(option)
}

const InsuranceRelationshipToPolicyHolderSelect: FC<InsuranceRelationshipToPolicyHolderSelectProps> = ({
  value,
  onChange,
  i18nKey = DEFAULT_I18N_KEY,
  showNone = true,
  fullWidth = false,
  ...textFieldProps
}) => {
  const { t } = useTranslation()
  const classes = useStyles({ fullWidth })

  function handleOnChange(event: { target: { value: OptionType | string } }): void {
    const {
      target: { value: optionValue },
    } = event

    onChange(optionValue === VALUE_NONE ? null : (optionValue as OptionType))
  }

  const existingValue = useMemo(() => {
    if (!value) return VALUE_NONE
    return (assertOptionExists(value) && value) || VALUE_NONE
  }, [value, OPTIONS])

  const textFieldLabel = t(`${i18nKey}.label`)
  const noneLabel = t(`${i18nKey}.option.none.label`)
  const nonePlaceholder = t(`${i18nKey}.option.none.placeholder`)

  return (
    <div className={classes.container}>
      <TextField
        fullWidth
        name="insuranceRelationshipToPolicyHolder"
        value={existingValue ?? ''}
        {...textFieldProps}
        select
        onChange={handleOnChange}
        SelectProps={{
          renderValue: (optionValue) =>
            optionValue === VALUE_NONE
              ? nonePlaceholder
              : t(I18N_OPTION_KEY, { context: optionValue }) ?? (optionValue as OptionType),
        }}
        label={textFieldLabel}
      >
        {showNone && <MenuItem value={VALUE_NONE}>{noneLabel ?? noneLabel}</MenuItem>}

        {OPTIONS?.map((option) => (
          <MenuItem key={option} value={option} className={classes.policyHolderRelationship}>
            {t(I18N_OPTION_KEY, { context: option })}
          </MenuItem>
        ))}
      </TextField>
    </div>
  )
}

export default InsuranceRelationshipToPolicyHolderSelect
