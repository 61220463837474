"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.DataValidationFailedError = exports.ResourceNotImplementedError = exports.DuplicateResourceFoundError = exports.BadRequestError = exports.ReadyCoreError = void 0;
var codes_1 = require("./codes");
/**
 * ----------------------------------------------------
 * ReadyCoreError
 * Extends from Error and represents a type of error
 * that is created in a ReadyCore application and safe
 * to send to the client.
 * ----------------------------------------------------
 */
var ReadyCoreError = /** @class */ (function (_super) {
    __extends(ReadyCoreError, _super);
    function ReadyCoreError(message) {
        var _this = _super.call(this, message) || this;
        Object.setPrototypeOf(_this, ReadyCoreError.prototype);
        return _this;
    }
    return ReadyCoreError;
}(Error));
exports.ReadyCoreError = ReadyCoreError;
/**
 * ----------------------------------------------------
 * BadRequestError
 * Represents a type of error thrown when the client
 * request is malformed, or contains invalid syntax.
 * ----------------------------------------------------
 */
var BadRequestError = /** @class */ (function (_super) {
    __extends(BadRequestError, _super);
    function BadRequestError(msg) {
        var _this = _super.call(this, msg || codes_1.ReadyCoreErrorMessages[codes_1.ReadyCoreErrorCodesEnum.BadRequest]) || this;
        _this.code = codes_1.ReadyCoreErrorCodesEnum.BadRequest;
        Object.setPrototypeOf(_this, BadRequestError.prototype);
        return _this;
    }
    BadRequestError.prototype.serializeErrors = function () {
        return [{ message: this.message }];
    };
    return BadRequestError;
}(ReadyCoreError));
exports.BadRequestError = BadRequestError;
/**
 * ----------------------------------------------------
 * DuplicateResourceFoundError
 * Represents a type of error thrown when the client
 * request conflicts with a duplicate resource.
 * ----------------------------------------------------
 */
var DuplicateResourceFoundError = /** @class */ (function (_super) {
    __extends(DuplicateResourceFoundError, _super);
    function DuplicateResourceFoundError(msg) {
        var _this = _super.call(this, msg || codes_1.ReadyCoreErrorMessages[codes_1.ReadyCoreErrorCodesEnum.DuplicateResourceFound]) || this;
        _this.code = codes_1.ReadyCoreErrorCodesEnum.DuplicateResourceFound;
        Object.setPrototypeOf(_this, DuplicateResourceFoundError.prototype);
        return _this;
    }
    DuplicateResourceFoundError.prototype.serializeErrors = function () {
        return [{ message: this.message }];
    };
    return DuplicateResourceFoundError;
}(ReadyCoreError));
exports.DuplicateResourceFoundError = DuplicateResourceFoundError;
/**
 * ----------------------------------------------------
 * ResourceNotImplementedError
 * Represents a type of error thrown when the client
 * requested resource is not implemented on the server.
 * ----------------------------------------------------
 */
var ResourceNotImplementedError = /** @class */ (function (_super) {
    __extends(ResourceNotImplementedError, _super);
    function ResourceNotImplementedError(msg) {
        var _this = _super.call(this, msg || codes_1.ReadyCoreErrorMessages[codes_1.ReadyCoreErrorCodesEnum.ResourceNotImplemented]) || this;
        _this.code = codes_1.ReadyCoreErrorCodesEnum.ResourceNotImplemented;
        Object.setPrototypeOf(_this, ResourceNotImplementedError.prototype);
        return _this;
    }
    ResourceNotImplementedError.prototype.serializeErrors = function () {
        return [{ message: this.message }];
    };
    return ResourceNotImplementedError;
}(ReadyCoreError));
exports.ResourceNotImplementedError = ResourceNotImplementedError;
/**
 * ----------------------------------------------------
 * DataValidationFailedError
 * Represents a type of error thrown when the client
 * input request data is invalid.
 * ----------------------------------------------------
 */
var DataValidationFailedError = /** @class */ (function (_super) {
    __extends(DataValidationFailedError, _super);
    function DataValidationFailedError(msg) {
        var _this = _super.call(this, msg || codes_1.ReadyCoreErrorMessages[codes_1.ReadyCoreErrorCodesEnum.DataValidationFailed]) || this;
        _this.code = codes_1.ReadyCoreErrorCodesEnum.DataValidationFailed;
        Object.setPrototypeOf(_this, DataValidationFailedError.prototype);
        return _this;
    }
    DataValidationFailedError.prototype.serializeErrors = function () {
        return [{ message: this.message }];
    };
    return DataValidationFailedError;
}(ReadyCoreError));
exports.DataValidationFailedError = DataValidationFailedError;
