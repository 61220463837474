/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react'

import { Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

const identity = <T,>(x: T): T => x

export type ControlledFieldProps = {
  name: string
  defaultValue?: any
  Component: React.FunctionComponent<any>
  ComponentProps?: Record<string, unknown>
  onChangeTransform?: (x: any) => any
  valueTransform?: (x: any) => any
}

const ControlledField = ({
  name,
  defaultValue,
  Component,
  ComponentProps,
  onChangeTransform = identity,
  valueTransform = identity,
}: ControlledFieldProps): JSX.Element => {
  const { t } = useTranslation()
  return (
    <Controller
      name={name}
      {...(defaultValue && { defaultValue })}
      render={({ field: { onChange, onBlur, value, ref }, fieldState: { invalid, error } }) => (
        <Component
          {...ComponentProps}
          onChange={(x: unknown) => onChange(onChangeTransform(x))}
          onBlur={onBlur}
          value={valueTransform(value)}
          inputRef={ref}
          error={invalid}
          helperText={error?.message ? t(error.message) : null}
        />
      )}
    />
  )
}

export default ControlledField
