import { FC } from 'react'

import { useParams } from 'react-router-dom'
import { RouteParams } from 'types/router'

import Appointment from './Appointment'
import { GetAppointmentQueryProvider } from './useGetAppointmentQueryContext'

const AppointmentRoute: FC<unknown> = () => {
  const { id } = useParams<RouteParams>()

  // we handle a non valid appointmentId within the GetAppointmentQueryProvider
  // preferably we would like to structure it so that this "Route" component deals with "Page not Found" situations

  return (
    <GetAppointmentQueryProvider id={Number(id)}>
      <Appointment />
    </GetAppointmentQueryProvider>
  )
}

export default AppointmentRoute
