import { GetServiceLinesQuery, ServiceLine } from 'generated/graphql'
import { isEmpty, isNumber, memoize } from 'lodash'
import groupBy from 'lodash/groupBy'
import { createSelector } from 'reselect'

const READY_AT_HOME_NYCHA_ID = 8

const getServiceLines = (data?: GetServiceLinesQuery) => data?.serviceLines?.nodes ?? null
export const selectServiceLines = createSelector(getServiceLines, (x) => x)

export const selectServiceLineById = createSelector(selectServiceLines, (serviceLines) =>
  memoize((id: number | null) => {
    if (!serviceLines || id == null) {
      return null
    }
    return serviceLines.find(({ serviceLineId }) => id === serviceLineId)?.name ?? null
  }),
)

export const selectGroupedServiceLinesWithoutNycha = createSelector(selectServiceLines, (serviceLines) => {
  if (serviceLines == null) return {}
  const serviceLinesWithoutNycha = serviceLines.filter((service) => service.serviceLineId !== READY_AT_HOME_NYCHA_ID)
  const serviceLinesGrouped = groupBy(serviceLinesWithoutNycha, 'name')
  return serviceLinesGrouped
})

export const assertServiceLineHasServiceLineId = (
  serviceLines: Partial<ServiceLine>[] | null,
  ids: number[] | number | null,
): boolean => {
  if (!serviceLines || isEmpty(serviceLines)) {
    return false
  }
  if (!isNumber(ids) && isEmpty(ids)) {
    return false
  }
  if (Array.isArray(ids)) {
    return ids.every((id) => Boolean(serviceLines.find(({ serviceLineId }) => id === serviceLineId)))
  }
  return Boolean(serviceLines.find(({ serviceLineId }) => ids === serviceLineId))
}
