import {
  GetEncounterInsuranceRecordsQuery,
  GetPatientQuery,
  GetPatientsQuery,
  PatientFragment,
  SearchPatientsQuery,
} from 'generated/graphql'
import { compact } from 'lodash'
import { createSelector } from 'reselect'
import { AddressValues } from 'types/AddressValues'
import { InsuranceSchema } from 'yupSchemas/insurance'

import { InsuranceVariant } from './Insurance/InsuranceVariant'

export const selectPatients = createSelector(
  (data?: SearchPatientsQuery) => data?.searchPatients ?? null,
  (x) => x,
)

export const findElementEqualsPatientId = <T extends { patientId: number }>(
  patients?: Array<T> | null,
  patientId?: number | null,
): T | null => (patients && patientId ? patients.find((patient) => patient.patientId === patientId) ?? null : null)

export const findExistingIndexElementEqualsPatientId = <T extends { patientId: number }>(
  patients?: Array<T> | null,
  patientId?: number | null,
): number | null => {
  if (!patients) return null
  const patientIndex = patients.findIndex((patient) => patient.patientId === patientId)
  if (patientIndex === -1) return null
  return patientIndex
}

// do a minimum check, where if these specific fields are null, it's invalid
export const addressIsValid = ({ address1, city, state, zip }: AddressValues): boolean =>
  Boolean(address1 && city && state && zip)

export const createAddressLine = ({ address1, address2, city, state, zip }: AddressValues): string =>
  compact([address1, address2, city, [state, zip].filter(Boolean).join(' ')])
    .filter(Boolean)
    .join(', ')

const getPatientIdFromPatientFragment = (patient?: PatientFragment | null) => patient?.patientId ?? null
const getPatientIdFromPatientFragments = (patients?: PatientFragment[] | null) => {
  if (!patients) return null
  return patients.map(getPatientIdFromPatientFragment)
}
const getAddressFromPatientFragment = (patient?: PatientFragment | null) => {
  if (!patient) return null
  if (!addressIsValid(patient)) return null
  const { address1, address2, city, state, zip, unit, specialInstructions, addressLat, addressLng } = patient
  return { address1, address2, city, state, zip, unit, specialInstructions, addressLat, addressLng }
}
const getFullNameFromPatientFragment = (patient?: PatientFragment | null) => {
  if (!patient) return null
  // taken from local field typePolicy for Patient
  // TODO: move this to a local field and only get the field here
  return `${patient?.firstName ?? ''} ${patient?.lastName ?? ''}`
}

const getPatientIds = (data?: GetPatientsQuery) => {
  const patients = data?.patients?.nodes
  if (!patients) return null
  return getPatientIdFromPatientFragments(patients)
}
const getAddress = (data?: GetPatientQuery) => getAddressFromPatientFragment(data?.patient)
const getAddresses = (data?: GetPatientsQuery) => {
  const patients = data?.patients?.nodes
  if (!patients) return null
  return patients.map(getAddressFromPatientFragment)
}
const getFullNames = (data?: GetPatientsQuery) => {
  const patients = data?.patients?.nodes
  if (!patients) return null
  return patients.map(getFullNameFromPatientFragment)
}

const getPrimaryInsurance = (data?: GetEncounterInsuranceRecordsQuery) =>
  data?.appointment?.insuranceRecordsByEncounterAppointmentIdAndPrimaryInsurance?.nodes?.[0] ?? null

const getSecondaryInsurance = (data?: GetEncounterInsuranceRecordsQuery) =>
  data?.appointment?.insuranceRecordsByEncounterAppointmentIdAndSecondaryInsurance?.nodes?.[0] ?? null

/**
 * generic selectors
 */
export const selectPatientIdFromFragments = createSelector(getPatientIdFromPatientFragments, (x) => x)
export const selectPatientIds = createSelector(getPatientIds, (x) => x)
export const selectAddress = createSelector(getAddress, (x) => x)
export const selectAddresses = createSelector(getAddresses, (x) => x)
export const selectFullNames = createSelector(getFullNames, (x) => x)

export const selectPrimaryInsurance = createSelector(getPrimaryInsurance, (x) => x)
export const selectSecondaryInsurance = createSelector(getSecondaryInsurance, (x) => x)

export const selectInsuranceByVariant = createSelector(
  [getPrimaryInsurance, getSecondaryInsurance, (state: unknown, { variant }: { variant: InsuranceVariant }) => variant],
  (primaryInsurance, secondaryInsurance, variant) => {
    if (variant === InsuranceVariant.Primary) {
      return primaryInsurance ?? null
    }
    if (variant === InsuranceVariant.Secondary) {
      return secondaryInsurance ?? null
    }
    return null
  },
)

export const selectPatientInsuranceStateByVariant = createSelector(
  selectInsuranceByVariant,
  (insuranceRecord): InsuranceSchema | null => {
    if (!insuranceRecord) return null

    const {
      company,
      memberId,
      groupNumber,
      relationshipToPolicyHolder,
      policyHolderFirstName,
      policyHolderLastName,
      policyHolderGender,
      policyHolderDob,
      packageId,
      insuranceRecordId,
    } = insuranceRecord

    return {
      company,
      memberId,
      groupNumber,
      relationshipToPolicyHolder,
      policyHolderFirstName,
      policyHolderLastName,
      policyHolderGender,
      policyHolderDob,
      packageId,
      insuranceRecordId,
    }
  },
)

/**
 * select the home address line
 * ex. "1233 York Avenue, New York, NY 10065"
 */
export const selectAddressLines = createSelector(selectAddresses, (addresses) => {
  if (!addresses) return null
  return addresses.map((address) => {
    if (!address) return null

    // TODO: move this to a local field and only get the field here
    return createAddressLine(address)
  })
})

/**
 * select address select option values
 */
export const selectPatientHomeAddressOptions = createSelector(
  [selectPatientIds, selectAddresses, selectAddressLines, selectFullNames],
  (patientIds, addresses, addressLines, fullNames) => {
    if (!(patientIds && addresses && addressLines && fullNames)) return null

    return patientIds
      .map((patientId, key) => {
        const address = addresses[key]
        const addressLine = addressLines[key]
        const fullName = fullNames[key]
        if (!(address && addressLine && fullName)) return null

        return {
          patientId,
          addressLine,
          fullName,
          ...address,
        }
      })
      .filter(Boolean)
  },
)
