import { FC } from 'react'

import { useGetZoneQuery, Zone } from 'generated/graphql'
import { useTranslation } from 'react-i18next'

type ZoneProps = {
  zoneId?: Zone['zoneId'] | null
}

const ZoneName: FC<ZoneProps> = ({ zoneId }) => {
  const { t } = useTranslation()
  const { data, loading } = useGetZoneQuery({ ...(zoneId && { variables: { zoneId } }), skip: zoneId == null })

  if (loading) return null

  return <>{data?.zone?.name ?? t('common:emptyValue')}</>
}

export default ZoneName
