import { DateTime } from 'luxon'
import { DateToYMD } from 'utils/dates'

import DatePicker, { DatePickerProps } from './DatePicker'

export type AppointmentDateSelectProps = Omit<DatePickerProps, 'value'> & {
  value: string
}

const AppointmentDateSelect = ({ value, ...props }: AppointmentDateSelectProps): JSX.Element => {
  const currentValue = DateTime.fromISO(value)
  return (
    <DatePicker
      id="appointment-date"
      name="appointment-date"
      fullWidth={false}
      {...props}
      value={currentValue}
      required
      label="Select Date"
      InputProps={{
        inputProps: {
          min: DateToYMD(new Date()),
        },
      }}
    />
  )
}

export default AppointmentDateSelect
