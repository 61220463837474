export enum Environment {
  Local = 'local',
  Test = 'test',
  Development = 'development',
  Staging = 'staging',
  Production = 'production',
}

export enum LogLevel {
  debug = 0,
  info = 1,
  warn = 2,
  error = 3,
}

type Config = {
  environment: Environment
  graphqlUrl: string
  skeduloUrl: string
  version: string
  sentryDsn?: string
  logLevel: number
}

const baseConfig = {
  version: process.env.REACT_APP_VERSION || 'unknown',
  sentryDsn: 'https://51dd430430d94c79b756f9799a54f3d3@o411821.ingest.sentry.io/5752548',
  logLevel: LogLevel.debug,
}

const configs: Record<Environment, Config> = {
  [Environment.Local]: {
    ...baseConfig,
    environment: Environment.Local,
    version: 'local',
    graphqlUrl: 'https://dev.ready.dev/graphql',
    skeduloUrl: 'https://rr-uat.my.skedulo.com',
  },
  [Environment.Test]: {
    ...baseConfig,
    environment: Environment.Test,
    version: 'test',
    graphqlUrl: 'https://dev.ready.dev/graphql',
    skeduloUrl: 'https://rr-uat.my.skedulo.com',
  },
  [Environment.Development]: {
    ...baseConfig,
    environment: Environment.Development,
    graphqlUrl: 'https://dev.ready.dev/graphql',
    skeduloUrl: 'https://rr-uat.my.skedulo.com',
  },
  [Environment.Staging]: {
    ...baseConfig,
    environment: Environment.Staging,
    graphqlUrl: 'https://stag.ready.dev/graphql',
    skeduloUrl: 'https://rr-uat.my.skedulo.com',
  },
  [Environment.Production]: {
    ...baseConfig,
    environment: Environment.Production,
    graphqlUrl: 'https://core.getready.com/graphql',
    skeduloUrl: 'https://ready.my.skedulo.com',
    logLevel: LogLevel.warn,
  },
}

const getConfig = (): Config => {
  if (process.env.STORYBOOK) {
    return configs[Environment.Local]
  }
  const env: Environment = process.env.REACT_APP_ENVIRONMENT as Environment
  if (!Object.values(Environment).includes(env)) {
    throw Error(`Cannot find app configuration for environment named "${env}"`)
  }
  return configs[env]
}

export default getConfig()
