/**
 * Find a Map key by its value
 * Usage example: ?
 *
 * const ourMap = {
 *    123: ['key', 'value1'],
 *    234: ['key', 'value2'],
 * }
 *
 * const findFunction = findMapKeyByValue(ourMap)
 *
 * findFunction('value2)
 *
 * Returns:
 * ['key', 'value2']
 */
const findMapKeyByValue =
  <K, V>(map: Map<K, V>) =>
  (byValue?: V | null): K | null => {
    if (map && byValue) {
      const entries = map ? [...map.entries()] : []

      const matchingValues = entries.find(([, value]) => value === byValue)

      return matchingValues?.[0] ?? null
    }
    return null
  }

export default findMapKeyByValue
