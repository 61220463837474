import { FC } from 'react'

import { defaultTimezone } from 'config/defaults'
import { useGetMarketQuery } from 'generated/graphql'
import { useDeepMemoDeps } from 'hooks/useDeepMemo'
import AppointmentCreateFormSchema from 'modules/Appointments/Create/AppointmentCreateFormSchema'
import { useWatch } from 'react-hook-form'

import { selectEncountersForAppointmentTime } from '../selectors'

import AppointmentTimeEdit from './AppointmentTimeEdit'

const AppointmentTimeEditCreateConnected: FC = () => {
  const [patients, address, geoLocation, marketId, zoneId] = useWatch<
    AppointmentCreateFormSchema,
    ['patients', 'address', 'geoLocation', 'marketId', 'zoneId', 'patientIds']
  >({
    name: ['patients', 'address', 'geoLocation', 'marketId', 'zoneId', 'patientIds'],
  })
  const { address1, city, state, zip } = address
  const { addressLat, addressLng } = geoLocation

  const { data: marketData } = useGetMarketQuery({
    ...(marketId && { variables: { marketId } }),
    skip: !marketId || marketId === -1,
  })

  const patientData = useDeepMemoDeps(() => selectEncountersForAppointmentTime(patients), [patients])

  return (
    <AppointmentTimeEdit
      scheduledFor={''}
      timeZone={marketData?.market?.timezone ?? defaultTimezone}
      patients={patientData}
      // following also exist in the form
      address1={address1}
      city={city}
      state={state}
      zip={zip}
      addressLat={addressLat}
      addressLng={addressLng}
      marketId={marketId}
      zoneId={zoneId}
    />
  )
}

export default AppointmentTimeEditCreateConnected
