import TextField, { OutlinedTextFieldProps } from '@mui/material/TextField'

export type UnitNumberInputProps = Partial<OutlinedTextFieldProps>

const UnitNumberInput = ({ value, ...props }: UnitNumberInputProps): JSX.Element => (
  <TextField
    id="unitNumber"
    name="unitNumber"
    fullWidth
    {...props} // Options above this line can be overwritten
    label="Apt, Suite, Unit, Bldg # (Optional)"
    value={value ?? ''}
  />
)

export default UnitNumberInput
