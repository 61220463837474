import { FC } from 'react'

import Tokens from 'config/tokens'
import { useTranslation } from 'react-i18next'

import { Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'

const useStyles = makeStyles(({ spacing }) => ({
  root: {
    marginLeft: spacing(2),
    marginRight: spacing(2),

    color: Tokens.color.ui.error.base,

    fontWeight: Tokens.font.weight.semiBold,
    textTransform: 'uppercase',
  },
}))

const CancelledStatusChip: FC<unknown> = () => {
  const { t } = useTranslation()
  const classes = useStyles()

  return (
    <Typography component="span" className={classes.root}>
      {t('glossary:encounterResponderStatus_CANCELLED')}
    </Typography>
  )
}

export default CancelledStatusChip
