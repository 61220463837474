import { FC } from 'react'

import { useGetMarketQuery, Market } from 'generated/graphql'
import { useTranslation } from 'react-i18next'

type MarketProps = {
  marketId?: Market['marketId'] | null
}

const MarketName: FC<MarketProps> = ({ marketId }) => {
  const { t } = useTranslation()
  const { data, loading } = useGetMarketQuery({ ...(marketId && { variables: { marketId } }), skip: marketId == null })

  if (loading) return null

  return <>{data?.market?.name ?? t('common:emptyValue')}</>
}

export default MarketName
