import { DateTime } from 'luxon'

const MAP_FORMAT_DATETIME = {
  // custom formats
  DATE_SHORT_WITH_SHORT_DAY: {
    ...DateTime.DATE_SHORT,
    weekday: 'short' as const,
  },
  TIME_SIMPLE_WITH_SHORT_TIMEZONE: {
    ...DateTime.TIME_SIMPLE,
    timeZoneName: 'short' as const,
  },
  DATETIME_MED_WITHOUT_YEAR: {
    month: 'short' as const,
    day: '2-digit' as const,
    hour: '2-digit' as const,
    minute: '2-digit' as const,
  },

  // defaults from DateTime
  DATE_SHORT: DateTime.DATE_SHORT,
  DATE_MED: DateTime.DATE_MED,
  DATE_MED_WITH_WEEKDAY: DateTime.DATE_MED_WITH_WEEKDAY,
  DATE_FULL: DateTime.DATE_FULL,
  DATE_HUGE: DateTime.DATE_HUGE,
  TIME_SIMPLE: DateTime.TIME_SIMPLE,
  TIME_WITH_SECONDS: DateTime.TIME_WITH_SECONDS,
  TIME_WITH_SHORT_OFFSET: DateTime.TIME_WITH_SHORT_OFFSET,
  TIME_WITH_LONG_OFFSET: DateTime.TIME_WITH_LONG_OFFSET,
  TIME_24_SIMPLE: DateTime.TIME_24_SIMPLE,
  TIME_24_WITH_SECONDS: DateTime.TIME_24_WITH_SECONDS,
  TIME_24_WITH_SHORT_OFFSET: DateTime.TIME_24_WITH_SHORT_OFFSET,
  TIME_24_WITH_LONG_OFFSET: DateTime.TIME_24_WITH_LONG_OFFSET,
  DATETIME_SHORT: DateTime.DATETIME_SHORT,
  DATETIME_SHORT_WITH_SECONDS: DateTime.DATETIME_SHORT_WITH_SECONDS,
  DATETIME_MED: DateTime.DATETIME_MED,
  DATETIME_MED_WITH_SECONDS: DateTime.DATETIME_MED_WITH_SECONDS,
  DATETIME_MED_WITH_WEEKDAY: DateTime.DATETIME_MED_WITH_WEEKDAY,
  DATETIME_FULL: DateTime.DATETIME_FULL,
  DATETIME_FULL_WITH_SECONDS: DateTime.DATETIME_FULL_WITH_SECONDS,
  DATETIME_HUGE: DateTime.DATETIME_HUGE,
  DATETIME_HUGE_WITH_SECONDS: DateTime.DATETIME_HUGE_WITH_SECONDS,
}
const DEFAULT_FORMAT_DATETIME = {
  ...MAP_FORMAT_DATETIME.DATE_SHORT_WITH_SHORT_DAY,
  ...MAP_FORMAT_DATETIME.TIME_SIMPLE_WITH_SHORT_TIMEZONE,
}

export type DateTimeFormatKeys = keyof typeof MAP_FORMAT_DATETIME

export const KEY_FORMAT_DATETIME = 'DATE '

const datetime = (dateTime: DateTime, format?: DateTimeFormatKeys | null): string => {
  const dateTimeFormat = format ? MAP_FORMAT_DATETIME[format] : DEFAULT_FORMAT_DATETIME

  return dateTime.toLocaleString(dateTimeFormat)
}

export default datetime
