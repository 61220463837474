import { useState, useMemo, useCallback, FC } from 'react'

import { useGetZoneAndMarketByZipCodeQuery } from 'generated/graphql'
import useCreateMutatatedDispatch from 'hooks/useCreateMutatedDispatch'
import useToggle from 'hooks/useToggle'
import { createAddressLine } from 'modules/Patient/selectors'
import { Controller, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useMountedState } from 'react-use'
import { AddressValues } from 'types/AddressValues'
import ControlledField from 'utils/ControlledField/ControlledField'

import { Alert } from '@mui/material'
import { Box } from '@mui/system'

import HiddenInput from 'ui/Inputs/HiddenInput'
import MarketsListSelect from 'ui/Inputs/MarketsListSelect'
import { selectFirstActiveZoneByZipCode } from 'ui/Inputs/MarketsListSelect/selectors'
import PatientHomeAddressSelect from 'ui/Inputs/PatientHomeAddressSelect'
import SpecialInstructionsInput from 'ui/Inputs/SpecialInstructionsInput/SpecialInstructionsInput'
import UnitNumberInput from 'ui/Inputs/UnitNumberInput/UnitNumberInput'
import ZoneListSelect from 'ui/Inputs/ZoneListSelect'
import SpacedItems from 'ui/SpacedItems'

import GoogleMapsAutoComplete from '../GoogleMapsAutoComplete'

import useGoogleMapsApiContext, { selectGeoLocation } from './useGoogleMapsApiContext'

const AppointmentAddressEdit: FC<{ patientIds?: number[] | null }> = ({ patientIds }) => {
  const isMounted = useMountedState()
  const { fetchGeocodeComponents } = useGoogleMapsApiContext()
  // useLazyQuery does not return a promise, we're using refetch intentionally here
  const { refetch: fetchMarketAndZone, loading: loadingMarketAndZone } = useGetZoneAndMarketByZipCodeQuery({
    skip: true,
  })
  const [hasGeocodeError, , setGeocodeError, unsetGeocodeError] = useToggle(false)
  const [isOutOfMarket, setIsOutOfMarket] = useToggle(false)
  const [marketIsDisabled, setMarketIsDisabled] = useToggle(true)
  const [patientHomeAddressId, setPatientHomeAddressId] = useState<number | null>(null)
  const { t } = useTranslation()

  const {
    setValue,
    watch,
    trigger,
    formState: { errors },
  } = useFormContext()

  const [address, marketId] = watch(['address', 'marketId'])

  const unsetPatientHomeAddress = () => setPatientHomeAddressId(null)
  const handleChangeTransform = <T,>(optionValue: T): T => {
    unsetPatientHomeAddress()
    return optionValue
  }

  const setMarketAndZoneByZip = useCallback(
    async (zipCode: AddressValues['zip']): Promise<void> => {
      setMarketIsDisabled(true)
      setIsOutOfMarket(false)
      setValue('marketOverride', false, { shouldTouch: true })

      if (!zipCode) {
        setValue('marketId', null, { shouldTouch: true })
        setValue('zoneId', null, { shouldTouch: true })
        return
      }

      // TODO: error handling, wrap it in a try catch
      // if response is nil, we can display a generic error
      const { data } = await fetchMarketAndZone({ zipCode })

      if (!isMounted()) return

      const zone = selectFirstActiveZoneByZipCode(data)
      const { marketId: newMarketId, zoneId: newZoneId } = zone ?? {}

      setMarketIsDisabled(false)

      if (!newMarketId) {
        setIsOutOfMarket(true)
        setValue('marketOverride', true, { shouldTouch: true })
        setValue('marketId', null, { shouldTouch: true })
        setValue('zoneId', null, { shouldTouch: true })
        return
      }

      if (newMarketId) {
        setValue('marketId', newMarketId, {
          shouldDirty: true,
          shouldTouch: true,
          shouldValidate: true,
        })
      }
      if (newZoneId) {
        setValue('zoneId', newZoneId, {
          shouldDirty: true,
          shouldTouch: true,
          shouldValidate: true,
        })
      }
    },
    [setValue, fetchMarketAndZone, setMarketIsDisabled],
  )

  const setAddressValue = async (addressValuesObject: AddressValues | null) => {
    unsetGeocodeError()
    if (!addressValuesObject) return

    // useless typeguard, we don't render component if it doesn't exist
    if (!fetchGeocodeComponents) return

    if (!isMounted()) return

    const { address1, address2, city, state, zip, unit, specialInstructions, addressLat, addressLng } =
      addressValuesObject

    setMarketAndZoneByZip(zip)

    const addressValues = {
      address1: address1?.trim() ?? null,
      address2: address2?.trim() ?? null,
      city,
      state,
      zip,
    }

    setValue('address', addressValues, { shouldDirty: true, shouldTouch: true })

    if (addressLat && addressLng) {
      setValue('geoLocation', { addressLat, addressLng }, { shouldDirty: true, shouldTouch: true })
    } else {
      try {
        const { geocoderResult } = await fetchGeocodeComponents({
          address: createAddressLine({ address1, address2, city, state, zip }),
        })
        const { addressLat: nextAddressLat, addressLng: nextAddressLng } = selectGeoLocation(geocoderResult)

        if (!isMounted) return

        setValue('geoLocation', { addressLat: nextAddressLat, addressLng: nextAddressLng })
      } catch (e) {
        setGeocodeError()
        setValue('geoLocation', { addressLat: null, addressLng: null })
      }
    }

    // we don't want to unset these fields if the change is coming from the autocomplete field,
    // as the user might decide to fill out the fields in a different order, or correct a mistake in the address at the end
    // however, when selecting a patient's home address, all fields should be set to that of the home address
    if ('unit' in addressValuesObject) {
      setValue('unit', unit, {
        shouldDirty: true,
        shouldTouch: true,
      })
    }
    if ('specialInstructions' in addressValuesObject) {
      setValue('specialInstructions', specialInstructions, {
        shouldDirty: true,
        shouldTouch: true,
      })
    }

    trigger(['address1', 'address2', 'city', 'state', 'zip', 'addressLat', 'addressLng', 'unit', 'specialInstructions'])
  }

  const clearAddressValue = useCallback(() => {
    unsetPatientHomeAddress()

    setValue('address', {
      address1: null,
      address2: null,
      city: null,
      state: null,
      zip: null,
    })
    setValue('geoLocation', {
      addressLat: null,
      addressLng: null,
    })

    setValue('marketId', null, { shouldTouch: true })
    setValue('zoneId', null, { shouldTouch: true })

    trigger(['address'])
  }, [unsetPatientHomeAddress, setValue, trigger])

  const hasAddressError = useMemo(() => Object.keys(errors).some((key) => ['address'].includes(key)), [errors])

  const setPatientHomeAddressValues = useCreateMutatatedDispatch((patientAddressValues) => {
    setPatientHomeAddressId(patientAddressValues?.patientId ?? null)
    if (!patientAddressValues) return
    setAddressValue(patientAddressValues)
  })

  const setGoogleMapsAutoCompleteValues = useCreateMutatatedDispatch((addressValues) => {
    unsetPatientHomeAddress()
    setAddressValue(addressValues)
  })

  if (!fetchGeocodeComponents) return null

  return (
    <SpacedItems direction="column">
      {patientIds && (
        <PatientHomeAddressSelect
          patientIds={patientIds}
          value={patientHomeAddressId}
          onChange={setPatientHomeAddressValues}
          fullWidth
        />
      )}

      <GoogleMapsAutoComplete
        setAddressValue={setGoogleMapsAutoCompleteValues}
        clearAddressValue={clearAddressValue}
        address={address}
        error={hasAddressError}
        helperText={hasAddressError ? 'Address is required.' : undefined}
      />

      {isOutOfMarket ? (
        <Box mt={4}>
          <Alert severity="info">{t('components:modules.Appointments.appointmentAddress.notInMarket')}</Alert>
        </Box>
      ) : null}

      {hasGeocodeError ? (
        <Box mt={4}>
          <Alert severity="error">{t('components:modules.Appointments.appointmentAddress.geocodeError.message')}</Alert>
        </Box>
      ) : null}

      <ControlledField name="unit" Component={UnitNumberInput} onChangeTransform={handleChangeTransform} />

      <ControlledField
        name="specialInstructions"
        Component={SpecialInstructionsInput}
        onChangeTransform={handleChangeTransform}
      />

      <ControlledField name="geoLocation" Component={HiddenInput} />

      <Controller
        name="marketId"
        render={({ field: { onChange, onBlur, value, ref }, fieldState: { invalid, error } }) => (
          <MarketsListSelect
            multiple={false}
            value={value}
            onChange={onChange}
            onBlur={onBlur}
            inputRef={ref}
            error={invalid}
            helperText={error?.message ? t(error.message) : null}
            label="Markets"
            placeholder=""
            noneLabel="All"
            disabled={marketIsDisabled}
            showIsActive={true}
            fullWidth
            loading={loadingMarketAndZone}
          />
        )}
      />

      <Controller
        name="zoneId"
        render={({ field: { onChange, onBlur, value, ref }, fieldState: { invalid, error } }) => (
          <ZoneListSelect
            value={value}
            onChange={onChange}
            onBlur={onBlur}
            inputRef={ref}
            error={invalid}
            helperText={error?.message ? t(error.message) : null}
            marketId={marketId}
            disabled={marketIsDisabled}
            showIsActive={true}
            fullWidth
            loading={loadingMarketAndZone}
          />
        )}
      />
    </SpacedItems>
  )
}

export default AppointmentAddressEdit
