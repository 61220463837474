import { FC } from 'react'

import Tokens from 'config/tokens'

import Box from '@mui/system/Box'

const NotePriorityDisplay: FC<unknown> = () => (
  <Box
    sx={{
      position: 'absolute',
      height: '100%',
      backgroundColor: Tokens.color.brand.red.light,
      width: 4,
      borderRadius: 4,
      left: -12,
    }}
  />
)
export default NotePriorityDisplay
