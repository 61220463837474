import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

import Accordion from './Accordion'
import AccordionDetails from './AccordionDetails'
import AccordionSummary from './AccordionSummary'

export type PrimaryAccordionProps = {
  children: JSX.Element
  menu?: JSX.Element | null
  title: JSX.Element | string
  id: string
  expanded: boolean
  onChange: (id: string) => void
  disabled?: boolean
}

const PrimaryAccordion = ({
  children,
  menu = null,
  title,
  id,
  expanded = false,
  onChange,
  disabled = false,
}: PrimaryAccordionProps): JSX.Element => (
  <Accordion square expanded={expanded} onChange={() => onChange(id)} disabled={disabled}>
    <AccordionSummary aria-controls={`${id}-content`} id={`${id}-header`} expandIcon={<ExpandMoreIcon />}>
      {menu}
      {title}
    </AccordionSummary>
    <AccordionDetails>{children}</AccordionDetails>
  </Accordion>
)

export default PrimaryAccordion
