import { memo, FC } from 'react'

import { appointmentsRoutes, attributionRoutes, marketsRoutes } from 'config/routes'
import Tokens from 'config/tokens'
import useHasMarkets from 'hooks/useHasMarkets'
import useIsOpsAdmin from 'hooks/useIsOpsAdmin'
import { CirclesFour, MapPin, Tag } from 'phosphor-react'
import { useTranslation } from 'react-i18next'
import { NavLink } from 'react-router-dom'
import hasFeatureFlag from 'utils/featureFlagsUtilities'

import { Box } from '@mui/system'

const Navigation: FC<unknown> = () => {
  const { t } = useTranslation()
  const hasMarkets = useHasMarkets()
  const isOpsAdmin = useIsOpsAdmin()

  return (
    <Box
      component="ul"
      sx={{
        padding: `${Tokens.rhythm}`,

        listStyle: 'none',

        a: {
          display: 'flex',
          padding: `calc(${Tokens.rhythm} * 2)`,
          alignItems: 'center',

          fontWeight: `${Tokens.font.weight.normal}`,
          lineHeight: 1,
          textDecoration: 'none',

          borderRadius: 1,
          color: `${Tokens.color.neutral.white.base}`,

          svg: {
            marginRight: `calc(${Tokens.rhythm} * 2)`,
          },

          '&:hover': {
            backgroundColor: `${Tokens.color.neutral.black.transparent[10]}`,
          },
          '&.active': {
            backgroundColor: `${Tokens.color.neutral.black.transparent[25]}`,
            fontWeight: `${Tokens.font.weight.semiBold}`,
          },
        },
      }}
    >
      <li>
        <NavLink exact to={appointmentsRoutes.overview}>
          <CirclesFour weight="fill" color={Tokens.color.neutral.white.base} />

          {t('common:breadcrumb.appointments.overview')}
        </NavLink>
      </li>

      {hasMarkets && (
        <li>
          <NavLink exact to={marketsRoutes.overview}>
            <MapPin weight="fill" color={Tokens.color.neutral.white.base} />

            {t('common:breadcrumb.markets.overview')}
          </NavLink>
        </li>
      )}

      {hasFeatureFlag('showAttributionController') && isOpsAdmin ? (
        <li>
          <NavLink exact to={attributionRoutes.overview}>
            <Tag weight="fill" color={Tokens.color.neutral.white.base} />

            {t('common:breadcrumb.attribution.overview')}
          </NavLink>
        </li>
      ) : null}
    </Box>
  )
}

export default memo(Navigation)
