import { FC } from 'react'

import makeStyles from '@mui/styles/makeStyles'

export type GlobalHumanIdProps = {
  id?: string | null
}

const useStyles = makeStyles({
  root: {
    textTransform: 'uppercase',
  },
})

const GlobalHumanId: FC<GlobalHumanIdProps> = ({ id }) => {
  const classes = useStyles()

  if (!id) return null

  return <span className={classes.root}>{id}</span>
}

export default GlobalHumanId
