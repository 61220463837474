import { UserRoles } from 'providers/UserContextProvider'

import useUserContext from './useUserContext'

const useIsOpsAdmin = () => {
  const { roles } = useUserContext()

  return roles?.includes(UserRoles.OpsAdmin) ?? false
}

export default useIsOpsAdmin
