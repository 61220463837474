import { SexEnum } from 'generated/graphql'
import { useTranslation } from 'react-i18next'

import MenuItem from '@mui/material/MenuItem'
import TextField, { OutlinedTextFieldProps } from '@mui/material/TextField'

// Because this select is optional and we have no actual "none" option that is part of the enum
// RHF seems to take the value directly from the element onBlur (might be getting it directly from the inputRef)
// this direct value is `__none__` or VALUE_NONE, and neither is `null`!
// thus, we have no way of transforming `__none__` into `null` before it is stored into the form
// again, this is a problem because this select is optional, and on blur
// we can't return `null`, instead RHF directly stored `__none__`, which is messing up our validation

const VALUE_NONE = SexEnum.Other

export type GenderRequirementsSelectProps = Partial<Omit<OutlinedTextFieldProps, 'onChange' | 'helperText'>> & {
  value: SexEnum | null
  helperText?: string
  onChange: (newSex: SexEnum) => void
  hasGenderRequirement?: boolean
}

type SexEnumWithoutOtherType = Exclude<SexEnum, SexEnum.Other>
const { Other, ...SexEnumWithoutOther } = SexEnum

const GenderRequirementsSelect = ({
  onChange,
  helperText,
  value,
  ...props
}: GenderRequirementsSelectProps): JSX.Element => {
  const { t } = useTranslation()

  const handleOnChange = (e: React.ChangeEvent<{ value: SexEnum | string }>) => {
    const newSex = e.target.value as SexEnum
    onChange(newSex)
  }
  const existingValue = value == null ? VALUE_NONE : value

  return (
    <TextField
      fullWidth
      name="gender-requirements"
      {...props}
      value={existingValue}
      helperText={helperText || 'Require Specific Gender Responder'}
      select
      label="Gender Requirements (optional)"
      onChange={handleOnChange}
    >
      <MenuItem value={VALUE_NONE}>{t('glossary:genderRequirements', { context: 'NONE' })}</MenuItem>

      {Object.values(SexEnumWithoutOther).map((option: SexEnumWithoutOtherType) => (
        <MenuItem key={option} value={option}>
          {t('glossary:genderRequirements', { context: option })}
        </MenuItem>
      ))}
    </TextField>
  )
}

export default GenderRequirementsSelect
