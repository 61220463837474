import { CancelEncountersInput, EncounterFieldsFragment, EncounterResponderStatusEnum } from 'generated/graphql'
import { selectEncountersByPatientId } from 'modules/Appointment/selectors'
import { createSelector } from 'reselect'

const predicateEncounterIsCancelled = ({ responderStatus }: EncounterFieldsFragment): boolean =>
  responderStatus === EncounterResponderStatusEnum.Cancelled

const predicateEncounterIsCancellable = ({ responderStatus }: EncounterFieldsFragment): boolean =>
  !(
    responderStatus === EncounterResponderStatusEnum.Cancelled ||
    responderStatus === EncounterResponderStatusEnum.Completed
  )

const filterEncountersAreCancellable = (
  encounters?: EncounterFieldsFragment[] | null,
): EncounterFieldsFragment[] | null => {
  if (!encounters) return null
  return encounters.filter(predicateEncounterIsCancellable)
}

const assertAllEncountersAreCancelled = (encounters?: EncounterFieldsFragment[] | null): boolean => {
  if (!encounters) return false
  return encounters.every(predicateEncounterIsCancelled)
}

export const assertEncountersAreCancellable = (encounters?: EncounterFieldsFragment[] | null): boolean => {
  if (!encounters) return false
  return encounters.some(predicateEncounterIsCancellable)
}

/**
 * used to transform the checkbox list of encounters into the encounterIds list
 */
export const createEncounterIdsByCheckboxIdMap = (
  checkboxIdMap: boolean[],
  encounterIds: number[],
): CancelEncountersInput['encounterIds'] =>
  // ts is having a hard time figuring out this filter, it infers that it returns (number|null)[] which is clearly impossible
  checkboxIdMap.map((isChecked: boolean, key) => (isChecked ? encounterIds[key] : null)).filter(Boolean) as number[]

/**
 * @selector
 * @state GetAppointmentQuery
 * @props patientId
 * select cancellable encounters by a single patientId
 *
 * @example
 *  const encounters = selectCancellableEncounters(data, { patientId })
 */
export const selectCancellableEncounters = createSelector(selectEncountersByPatientId, filterEncountersAreCancellable)

/**
 * @selector
 * @state GetAppointmentQuery
 * @props patientId
 * assert whether all encounters by single patientId are cancelled
 *
 * @example
 *  const areCancelled = selectAllPatientEncountersAreCancelled(data, { patientId })
 */
export const selectAllPatientEncountersAreCancelled = createSelector(
  selectEncountersByPatientId,
  assertAllEncountersAreCancelled,
)
