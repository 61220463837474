import { FC, memo, useCallback } from 'react'

import { FormProvider } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useToggle } from 'react-use'

import CardLabeled from 'ui/CardLabeled'

import PatientInsuranceCardEdit from './PatientInsuranceCardEdit'
import PatientInsuranceCardRead from './PatientInsuranceCardRead'
import PatientInsuranceDeleteDialog from './PatientInsuranceDeleteDialog'
import usePatientInsuranceStateFromContext from './usePatientInsuranceStateFromContext'

export type PatientInsuranceCardProps = {
  isEditable?: boolean
  testId?: string
}

const PatientInsuranceCard: FC<PatientInsuranceCardProps> = ({ isEditable = false, testId }) => {
  const { t } = useTranslation()
  const [isEditing, setIsEditing] = useToggle(false)
  const { loading, variant, methods, unset, set, reset, selectedInsuranceRecord } =
    usePatientInsuranceStateFromContext()
  const [showDeleteModal, setShowDeleteModal] = useToggle(false)

  const handleOnSubmitDeleteDialog = useCallback(() => {
    unset()
    setShowDeleteModal(false)
    setIsEditing(false)
  }, [])

  const handleOnSave = useCallback(async () => {
    await set()
    setIsEditing(false)
  }, [])

  const handleOnCancel = useCallback(() => {
    reset()
    setIsEditing(false)
  }, [])

  return (
    <FormProvider {...methods}>
      <CardLabeled
        title={t('components:modules.Patient.Insurance.title', { context: variant })}
        editing={isEditing}
        onEdit={() => setIsEditing(true)}
        onCancel={handleOnCancel}
        onAccept={handleOnSave}
        acceptDisabled={!(methods.formState.isValid || selectedInsuranceRecord !== null)}
        isEditable={isEditable && !loading}
        isDeletable
        deleteText={t('components:modules.Patient.Insurance.deleteButton')}
        onDelete={setShowDeleteModal}
        testId={testId}
      >
        {isEditing ? <PatientInsuranceCardEdit /> : <PatientInsuranceCardRead />}
      </CardLabeled>
      <PatientInsuranceDeleteDialog
        open={showDeleteModal}
        onClose={setShowDeleteModal}
        onSubmit={handleOnSubmitDeleteDialog}
      />
    </FormProvider>
  )
}

export default memo(PatientInsuranceCard)
