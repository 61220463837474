import TextField, { OutlinedTextFieldProps } from '@mui/material/TextField'

export type SpecialInstructionsInputProps = Partial<OutlinedTextFieldProps>

const SpecialInstructionsInput = ({ value, ...props }: SpecialInstructionsInputProps): JSX.Element => (
  <TextField
    id="specialInstructions"
    name="specialInstructions"
    fullWidth
    {...props} // Options above this line can be overwritten
    multiline={true}
    rows={2}
    label="Special Instructions (Optional)"
    value={value ?? ''}
    inputProps={{ maxLength: 255 }}
  />
)

export default SpecialInstructionsInput
