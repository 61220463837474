/**
 * Should be kept in sync with:
 * https://github.com/Ready-Responders/ready-patient/blob/dev/src/util/isEmblem.js
 */

const isEmblem = (packageId: number) =>
  [
    10887, 91071, 29126, 125998, 142528, 43954, 34746, 33073, 273950, 495477, 157853, 15741, 86165, 8002, 68027, 33930,
    26460, 16299, 51988, 300875, 384084, 96824, 138121, 630821, 417457, 26579, 615559, 615560,
  ].includes(packageId)

export default isEmblem
