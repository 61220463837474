import i18n from 'i18next'
import { DateTime } from 'luxon'
import { initReactI18next } from 'react-i18next'

// default lng resources (en-US)
import actions from './locales/en-US/actions.json'
import common from './locales/en-US/common.json'
import components from './locales/en-US/components.json'
import glossary from './locales/en-US/glossary.json'
import validation from './locales/en-US/validation.json'
import datetime, { KEY_FORMAT_DATETIME, DateTimeFormatKeys } from './utils/dates/datetime'
import capitalize, { KEY_FORMAT_CAPITALIZE } from './utils/i18nFormatters/capitalize'

export const DEFAULT_NS = 'common'
export const LANGUAGE_EN_US = 'en-US'

export const resources = {
  [LANGUAGE_EN_US]: {
    actions,
    common,
    components,
    glossary,
    validation,
  },
}

i18n.use(initReactI18next).init({
  resources,
  lng: LANGUAGE_EN_US,
  fallbackLng: LANGUAGE_EN_US,
  defaultNS: DEFAULT_NS,

  keySeparator: false, // disables nested objects in JSON

  interpolation: {
    format: (value, format) => {
      switch (format) {
        case KEY_FORMAT_CAPITALIZE: {
          return capitalize(value)
        }
        default:
      }

      // date formatting
      if (format?.startsWith(KEY_FORMAT_DATETIME)) {
        return datetime(value, format.substring(KEY_FORMAT_DATETIME.length) as DateTimeFormatKeys)
      }
      if (value instanceof DateTime) return datetime(value, null)

      return value
    },
    escapeValue: false, // react already safes from xss
  },

  react: {
    transSupportBasicHtmlNodes: true,
    transKeepBasicHtmlNodesFor: ['br', 'strong', 'i', 'p', 'em', 'span'],
  },

  debug: process.env.NODE_ENV === 'development',
})

export default i18n
