import { FC } from 'react'

import { useFormContext } from 'react-hook-form'
import { AddressSchema } from 'yupSchemas/address/items'

import LabeledItem from 'ui/LabeledItem'
import Address from 'ui/TextFormatters/Address'
import MarketName from 'ui/TextFormatters/MarketName'
import ZoneName from 'ui/TextFormatters/ZoneName'

const AppointmentAddressRead: FC<unknown> = () => {
  const { getValues } = useFormContext<AddressSchema>()
  const { address, unit, specialInstructions, marketId, zoneId } = getValues()
  const { address1, address2, city, state, zip } = address

  return (
    <>
      <LabeledItem label="Address:" inline>
        <Address
          address1={address1 ?? ''}
          address2={address2 ?? ''}
          city={city ?? ''}
          state={state ?? ''}
          zip={zip ?? ''}
        />
      </LabeledItem>

      <LabeledItem label="Apt, Suite, Unit, Bldg #:" inline>
        {unit ?? ''}
      </LabeledItem>

      <LabeledItem label="Special Instructions:" inline>
        {specialInstructions ?? ''}
      </LabeledItem>

      <LabeledItem label="Market" inline>
        <MarketName marketId={marketId} />
      </LabeledItem>

      <LabeledItem label="Zone" inline>
        <ZoneName zoneId={zoneId} />
      </LabeledItem>
    </>
  )
}

export default AppointmentAddressRead
