import { FC } from 'react'

import { ApolloQueryResult } from '@apollo/client'
import { appointmentsRoutes } from 'config/routes'
import Tokens from 'config/tokens'
import { GetAppointmentQuery } from 'generated/graphql'
import { LinkBreak } from 'phosphor-react'
import { Trans, useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import { Alert, Button, Grid, Paper, Typography } from '@mui/material'
import { Box } from '@mui/system'

const AppointmentNotFound: FC<{
  refetch?: () => Promise<ApolloQueryResult<GetAppointmentQuery>> | null
  error?: Error | null
}> = ({ refetch, error }) => {
  const { t } = useTranslation()

  return (
    <Box
      minHeight={576}
      height="100vh"
      width="100vw"
      display="flex"
      justifyContent="center"
      alignItems="center"
      bgcolor={Tokens.color.ui.steel.base}
    >
      <Paper>
        <Box display="flex" p={12} flexDirection="column" alignItems="center" maxWidth={640}>
          <Box p={4}>
            <LinkBreak size={72} color={Tokens.color.brand.red.light} weight="duotone" />
          </Box>

          <Box textAlign="center">
            <Typography variant="h2">
              <Grid item xs={12} sm={12}>
                <Trans t={t} i18nKey="components:modules.Appointment.AppointmentNotFound.body" />
              </Grid>

              {error ? (
                <Box pt={6}>
                  <Grid item xs={12} sm={12}>
                    {t('components:modules.Appointment.AppointmentNotFound.serverError.title')}
                  </Grid>

                  <Grid item xs={12} sm={12}>
                    <Box mt={4}>
                      <Alert severity="error">{error.message}</Alert>
                    </Box>
                  </Grid>
                </Box>
              ) : null}
            </Typography>
          </Box>

          <Box sx={{ p: 4, pt: 8, display: 'flex' }}>
            <Button variant="outlined" component={Link} to={appointmentsRoutes.root} sx={{ mx: 2 }}>
              {t('components:modules.Appointment.AppointmentNotFound.toAppointmentOverviewButton.label')}
            </Button>

            {refetch != null ? (
              <Button variant="outlined" onClick={() => refetch()} sx={{ mx: 2 }}>
                {t('components:modules.Appointment.AppointmentNotFound.refetchButton.label')}
              </Button>
            ) : null}
          </Box>
        </Box>
      </Paper>
    </Box>
  )
}

export default AppointmentNotFound
