import { useMemo, FC } from 'react'

import { RescheduleReasonEnum } from 'generated/graphql'
import { useTranslation } from 'react-i18next'
import { AppointmentTimeRescheduleSchema } from 'yupSchemas/appointmentTime/index'

import MenuItem from '@mui/material/MenuItem'
import TextField, { OutlinedTextFieldProps } from '@mui/material/TextField'
import makeStyles from '@mui/styles/makeStyles'

//
// this Select component is a near complete duplication of AppointmentStatusSelect
//

const OPTIONS_BY_ENUM_VALUES = Object.values(RescheduleReasonEnum)

const OPTIONS_BY_ENUM_VALUES_WITHOUT = OPTIONS_BY_ENUM_VALUES.filter(
  (rescheduleReason) => rescheduleReason !== RescheduleReasonEnum.LackOfResourcesReadyInitiated,
)

const VALUE_NONE = '__none__'
const DEFAULT_I18N_KEY = 'components:ui.Inputs.RescheduleReasonSelect'
const I18N_OPTION_KEY = 'glossary:rescheduleReason'

export type OptionValue = AppointmentTimeRescheduleSchema['rescheduledReason']

export type RescheduleReasonSelectProps = Partial<Omit<OutlinedTextFieldProps, 'onChange' | 'value'>> & {
  i18nKey?: string
  showNone?: boolean
  fullWidth?: boolean

  value?: OptionValue
  onChange: (newValue: OptionValue) => void
}

const useStyles = makeStyles({
  container: {
    minWidth: 200,
    maxWidth: ({ fullWidth }: Pick<RescheduleReasonSelectProps, 'fullWidth'>) => (fullWidth ? 'none' : 300),
  },
})

export const assertOptionValueExists = (optionValue: OptionValue): boolean => {
  if (optionValue == null) {
    return false
  }
  return OPTIONS_BY_ENUM_VALUES.includes(optionValue)
}

const RescheduleReasonSelect: FC<RescheduleReasonSelectProps> = ({
  value,
  onChange,
  i18nKey = DEFAULT_I18N_KEY,
  showNone = true,
  fullWidth = false,
  ...textFieldProps
}) => {
  const { t } = useTranslation()
  const classes = useStyles({ fullWidth })

  function handleOnChange(event: { target: { value: OptionValue | string } }): void {
    const {
      target: { value: optionValue },
    } = event

    onChange(optionValue === VALUE_NONE ? null : (optionValue as OptionValue))
  }

  const existingValue = useMemo(() => {
    if (!value) return VALUE_NONE
    return (assertOptionValueExists(value) && value) || VALUE_NONE
  }, [value, OPTIONS_BY_ENUM_VALUES])

  const textFieldLabel = t([`${i18nKey}.label`, 'components:ui.Inputs.generic.select.label'])
  const noneLabel = t([`${i18nKey}.option.none.label`, 'components:ui.Inputs.generic.option.none.label'])
  const nonePlaceholder = t([
    `${i18nKey}.option.none.placeholder`,
    'components:ui.Inputs.generic.option.none.placeholder',
  ])

  const rescheduleReasonsList = OPTIONS_BY_ENUM_VALUES_WITHOUT

  return (
    <div className={classes.container}>
      <TextField
        fullWidth
        name="appointmentRescheduleReason"
        value={existingValue ?? ''}
        {...textFieldProps}
        select
        onChange={handleOnChange}
        SelectProps={{
          renderValue: (optionValue) =>
            optionValue === VALUE_NONE
              ? nonePlaceholder
              : t(I18N_OPTION_KEY, { context: optionValue }) ?? (optionValue as OptionValue),
        }}
        label={textFieldLabel}
      >
        <MenuItem value={VALUE_NONE} {...(!showNone && { style: { display: 'none' } })}>
          {noneLabel}
        </MenuItem>

        {rescheduleReasonsList?.map((status) => (
          <MenuItem key={status} value={status}>
            {t(I18N_OPTION_KEY, { context: status })}
          </MenuItem>
        ))}
      </TextField>
    </div>
  )
}

export default RescheduleReasonSelect
