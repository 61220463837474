import { TypePolicy, FieldPolicy } from '@apollo/client'
import { ModuleNameEnum } from 'generated/graphql'

const Encounter: TypePolicy = {
  keyFields: ['encounterId'],
  fields: {
    // Returns the `visitId` from `migrationSource` field
    visitId: {
      read(value, { readField }) {
        const migrationSource = readField('migrationSource') as string

        if (migrationSource) {
          const regex = /^readyhealth:visit:([\w|-]+)$/
          const match = migrationSource?.match(regex)
          return match && match.length > 1 ? match[1] : ''
        }

        return null
      },
    } as FieldPolicy,
    // Adds new @client field `modulesFriendly`
    modulesFriendly: {
      read(value, { readField }) {
        const allModules = readField('modules') as string[]

        if (!allModules?.length) return 'None'

        const modulesWeShow = [ModuleNameEnum.VaccineFlu, ModuleNameEnum.VaccineCovid, ModuleNameEnum.TestA1C] as const

        const formattedModules = {
          [ModuleNameEnum.VaccineFlu]: 'Vaccine - Flu',
          [ModuleNameEnum.VaccineCovid]: 'Vaccine - COVID',
          [ModuleNameEnum.TestA1C]: 'Test - A1C',
        }

        const modulesToShow = modulesWeShow.filter((item) => allModules.includes(item))

        if (modulesToShow.length > 0) {
          return modulesToShow.map((key) => formattedModules[key]).join(', ')
        }

        return 'None'
      },
    } as FieldPolicy,
  },
}

export default Encounter
