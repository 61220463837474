import { useMemo } from 'react'

import joinPath from 'fp/joinPath'
import { DateTime } from 'luxon'
import { useFormContext, useWatch } from 'react-hook-form'
import { TFunction, useTranslation } from 'react-i18next'
import ArrayElementType from 'types/ArrayElementType'
import MapValueType from 'types/MapValueType'
import { isSelf } from 'yupSchemas/insurance'

import {
  InsuranceRecordFieldEnums,
  KEYS_ADDITIONAL_FIELDS,
  KEYS_PRIMARY_FIELDS,
  MAP_INSURANCE_RECORD_FIELD_KEY_TO_ASSOCIATED,
  PatientInsuranceStateStatus,
} from './constants'
import usePatientInsuranceStateFromContext from './usePatientInsuranceStateFromContext'

const LIST_FIELD_DEPENDENDIES = [...MAP_INSURANCE_RECORD_FIELD_KEY_TO_ASSOCIATED.keys()]

// we need to get a translation key for specific fields, this is an attempt to find an abstraction pattern
const translateInsuranceRecordEntry = (
  t: TFunction<'translation'>,
  [key, value]: [
    keyof InsuranceRecordFieldEnums | string,
    null | ArrayElementType<MapValueType<typeof MAP_INSURANCE_RECORD_FIELD_KEY_TO_ASSOCIATED>['enumValues']>,
  ],
): [string, string | number | null] => {
  const translatedKey = t(joinPath(['glossary:InsuranceRecord', key]))

  if (!value) return [translatedKey, null]

  if (MAP_INSURANCE_RECORD_FIELD_KEY_TO_ASSOCIATED.has(key)) {
    // we know at this point that key is in the map so we can cast it, as I can't figure out how to type-guard it
    const mapKey = key as keyof InsuranceRecordFieldEnums

    const fieldMap = MAP_INSURANCE_RECORD_FIELD_KEY_TO_ASSOCIATED.get(key)

    // for some reason TS doesn't believe it exists, event though we typeguarded with .has()
    if (!fieldMap) return [translatedKey, value]

    // TS prefers this typing over the inferred one,
    // the inferred type for `enumValues` types the .includes() first argument as `never`
    const values: InsuranceRecordFieldEnums[typeof mapKey][] = fieldMap.enumValues

    const translatedValue = values.includes(value) ? t(fieldMap.i18nKey, { context: value }) : value
    return [translatedKey, translatedValue]
  }

  // we need to account for non enum fields that need to be translated in MAP_INSURANCE_RECORD_FIELD_KEY_TO_ASSOCIATED
  // following is ad-hoc for Date field
  if (key === 'policyHolderDob') {
    const dobValue = value as unknown as Date | string

    const formattedDob = t('common:formattedDate.short', {
      dateTime: dobValue instanceof Date ? DateTime.fromJSDate(dobValue) : DateTime.fromISO(dobValue),
    })

    return [translatedKey, formattedDob ?? null]
  }

  return [translatedKey, value]
}

const useDisplayPatientInsuranceFormFieldsFromContext = (): Array<[string, string | number | null]> | null => {
  const { t } = useTranslation()
  const { getValues, control } = useFormContext()
  const fieldDependencies = useWatch({ control, name: LIST_FIELD_DEPENDENDIES })
  const { status } = usePatientInsuranceStateFromContext()

  const displayFields = useMemo(() => {
    if (status === PatientInsuranceStateStatus.Empty || status === PatientInsuranceStateStatus.PreFetch) return null

    const allFields = getValues()
    const { relationshipToPolicyHolder } = allFields
    const fieldEntries: Array<[string, string | number | null]> = [
      ...KEYS_PRIMARY_FIELDS,
      ...(isSelf(relationshipToPolicyHolder) ? [] : KEYS_ADDITIONAL_FIELDS),
    ].map((key: string) => translateInsuranceRecordEntry(t, [key, allFields[key]]))

    return fieldEntries
  }, [status, ...fieldDependencies])

  return displayFields
}

export default useDisplayPatientInsuranceFormFieldsFromContext
