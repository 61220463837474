import { useEffect, useState } from 'react'

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { get } from 'lodash'
import callerInfo from 'utils/zendesk/callerInfo'
import makeZendeskLambdaRequest from 'utils/zendesk/makeZendeskLambdaRequest'
import ZenDeskClient from 'utils/ZenDeskClient'

const useInitZendesk = () => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [data, setData] = useState<any>(null)

  useEffect(() => {
    const fetchInitData = async () => {
      if (!ZenDeskClient) return

      const callInNumber = await callerInfo()

      const initData = await makeZendeskLambdaRequest({
        url: `${process.env.REACT_APP_READY_ZENDESK_LAMBDA_BASE_URL}/init?callInNumber=${callInNumber}`,
      })

      setData(initData)

      const referralSource = await ZenDeskClient.get(process.env.REACT_APP_ZENDESK_REFERRAL_SOURCE)
      const existingReferralSource = get(referralSource, [process.env.REACT_APP_ZENDESK_REFERRAL_SOURCE ?? ''])

      if (!existingReferralSource) {
        const sourceName = get(initData, ['callRail', 'source_name'], '')
        const callRailId = get(initData, ['callRail', 'id'], '')
        // check to see the call rail ID value
        const callRailCallID = await ZenDeskClient.get(process.env.REACT_APP_ZENDESK_CALL_RAIL_ID)
        const existingCallRailCallID = get(callRailCallID, [process.env.REACT_APP_ZENDESK_CALL_RAIL_ID ?? ''])

        if (!existingCallRailCallID && callRailId) {
          ZenDeskClient.set(process.env.REACT_APP_ZENDESK_CALL_RAIL_ID, `${callRailId}`)
        }

        if (sourceName) {
          ZenDeskClient.set(process.env.REACT_APP_ZENDESK_REFERRAL_SOURCE, sourceName)
        }
      }
    }

    fetchInitData()
  }, [])

  return data
}

export default useInitZendesk
