import { FC } from 'react'

import { defaultTimezone } from 'config/defaults'
import { DateTime } from 'luxon'

import LabeledItem from 'ui/LabeledItem'
import FormattedDate from 'ui/TextFormatters/FormattedDate'

import { selectDefaultedTimezone, selectGetScheduledFor } from '../selectors'
import useGetAppointmentQueryContext from '../useGetAppointmentQueryContext'

const AppointmentTimeRead: FC<unknown> = () => {
  const { data: appointmentData } = useGetAppointmentQueryContext()
  const timeZone = selectDefaultedTimezone(appointmentData)
  const scheduledFor = selectGetScheduledFor(appointmentData)

  if (!scheduledFor) return null

  return (
    <div>
      <LabeledItem label="Scheduled For:" inline>
        <FormattedDate dateTime={DateTime.fromISO(scheduledFor, { zone: timeZone ?? defaultTimezone })} />
      </LabeledItem>
    </div>
  )
}

export default AppointmentTimeRead
