import { memo, useState } from 'react'

import { useApolloClient } from '@apollo/client'
import { SearchAppointmentsOrderByEnum, GetExportAppointmentsDocument } from 'generated/graphql'
import useIsOpsAdmin from 'hooks//useIsOpsAdmin'
import { json2csvAsync } from 'json-2-csv'
import { uniqBy } from 'lodash'
import { DateTime } from 'luxon'

import { AlertColor } from '@mui/material/Alert'
import Button from '@mui/material/Button'
import makeStyles from '@mui/styles/makeStyles'

import logger from '../../../utils/logger'
import useAppointmentFilterContext from '../useAppointmentFiltersContext'

export type ExportButtonUpdateParams = {
  text: string
  severity: AlertColor
  show: boolean
}
export type ExportButtonProps = {
  handleUpdate: (params: ExportButtonUpdateParams) => void
}

const ExportButton = ({ handleUpdate }: ExportButtonProps) => {
  const useStyles = makeStyles(({ spacing }) => ({
    exportButton: {
      whiteSpace: 'nowrap',
      // align with select margins
      flexGrow: 1,
      marginTop: spacing(2),
      marginBottom: spacing(1),
    },
  }))

  const isOpsAdmin = useIsOpsAdmin() as boolean

  const [exportLoading, setExportLoading] = useState(false)

  let exportSeverity = 'warning' as AlertColor

  const classes = useStyles()

  const { query, marketId, status, startDate, endDate, serviceLines } = useAppointmentFilterContext()

  const client = useApolloClient()

  // TODO move this to a util function
  const handleExport = async () => {
    if (!isOpsAdmin) {
      logger.error('Only ops admins can export appointments')
      exportSeverity = 'error'
      handleUpdate({ text: 'Only ops admins can export appointments', severity: 'error', show: true })
      return
    }

    const limitDate: number = new Date().valueOf() - 1000 * 60 * 60 * 24 * 365
    if (limitDate.valueOf() > startDate.valueOf()) {
      logger.warn('Export is not allowed past 365 days')
      exportSeverity = 'error'
      handleUpdate({
        text: 'Export cannot exceed 1 year, please use a shorter date range',
        severity: 'error',
        show: true,
      })
      return
    }

    try {
      setExportLoading(true)
      exportSeverity = 'warning'
      handleUpdate({
        text: 'Exporting appointments, please wait as this may take a few moments',
        severity: 'warning',
        show: true,
      })
      const { data } = await client.query({
        fetchPolicy: 'no-cache',
        query: GetExportAppointmentsDocument,
        variables: {
          limit: 10000,
          offset: 0,
          orderBy: SearchAppointmentsOrderByEnum.ScheduledForDesc,
          query,
          markets: marketId,
          status,
          serviceLines,
          scheduledAfter: startDate?.toISO(),
          scheduledBefore: endDate?.toISO(),
        },
      })

      // eslint-disable-next-line  @typescript-eslint/no-explicit-any
      const appointments = uniqBy(data.searchAppointments, 'appointmentId').map((appointment: any) => {
        return {
          Market: appointment?.market?.name,
          Status: appointment.status,
          'Service Line': `${appointment?.encountersList[0]?.serviceLine?.name} - ${appointment?.encountersList[0]?.serviceLine?.subType}`,
          'Encounter Start Date': appointment.encountersList[0].inProgressAt,
          'Appointment Time': appointment.scheduledFor,
          'Encounter End Date': appointment.encountersList[0].encounterSignaturesList.length
            ? DateTime.fromJSDate(
                new Date(
                  Math.max(
                    ...appointment.encountersList[0].encounterSignaturesList.map(
                      // eslint-disable-next-line  @typescript-eslint/no-explicit-any
                      (signature: any) => new Date(signature.createdAt),
                    ),
                  ),
                ),
              )
                .toUTC()
                .toISO()
            : null,
          'Responder(s)': appointment.appointmentDispatches.nodes
            // eslint-disable-next-line  @typescript-eslint/no-explicit-any
            .map((dispatch: any) => {
              return `${dispatch.responder.user.firstName} ${dispatch.responder.user.lastName}`
            })
            .join(', '),
          'Patient Name': [
            appointment?.encountersList[0]?.patient?.firstName,
            appointment?.encountersList[0]?.patient?.lastName,
          ].join(' '),
          Platform: appointment.systemOfOrigin,
          'Partnership/Attribution': appointment?.encountersList[0]?.channelAttribution?.channel,
          'Appointment ID': appointment.appointmentId,
        }
      })

      const csv = await json2csvAsync(appointments)

      // Download CSV
      // eslint-disable-next-line  @typescript-eslint/no-explicit-any
      const nav = window.navigator as any
      if (nav.msSaveOrOpenBlob) {
        const blob = new Blob([csv])
        nav.msSaveOrOpenBlob(blob, 'appointment_exports.csv')
      } else {
        const a = document.createElement('a')
        a.href = `data:attachment/csv,${encodeURIComponent(csv)}`
        a.target = '_blank'
        a.download = 'appointment_exports.csv'
        document.body.appendChild(a)
        a.click()
      }
      exportSeverity = 'success'
      handleUpdate({
        text: 'Export completed',
        severity: 'success',
        show: true,
      })
    } catch (e) {
      exportSeverity = 'error'
      handleUpdate({
        text: 'The export failed due to a timeout, please select a shorter date range',
        severity: 'error',
        show: true,
      })
    } finally {
      setExportLoading(false)
      setTimeout(() => {
        handleUpdate({ text: '', severity: exportSeverity, show: false })
      }, 4000)
    }
  }

  return (
    <>
      {isOpsAdmin && (
        <Button color="secondary" disabled={exportLoading} className={classes.exportButton} onClick={handleExport}>
          Export
        </Button>
      )}
    </>
  )
}
export default memo(ExportButton)
