import { FC, useMemo } from 'react'

import Avatar from '@mui/material/Avatar'

const createStringHash = (str: string) =>
  // eslint-disable-next-line no-bitwise
  Array.from(str).reduce((hash, char) => char.charCodeAt(0) + ((hash << 5) - hash), 0)

const SEPARATOR_INITIALS = ''
const createInitials = (fullName: string): string | null =>
  fullName
    .trim()
    .split(' ')
    .filter(Boolean)
    .map((subName: string) => subName.charAt(0).toUpperCase())
    .join(SEPARATOR_INITIALS) ?? null

type PatientAvatarProps = {
  firstName?: string | null
  lastName?: string | null

  fullName?: string | null

  profilePictureUrl?: string | null
}

/**
 * Can either pass `fullName` or `firstName` & `lastName`
 */
const PatientAvatar: FC<PatientAvatarProps> = ({ firstName, lastName, fullName, profilePictureUrl }) => {
  const { displayName, hash, initials } = useMemo((): {
    displayName: string | null
    hash?: number
    initials?: string | null
  } => {
    // use fullName if available
    if (fullName != null) {
      return {
        displayName: fullName,
        hash: createStringHash(fullName),
        initials: createInitials(fullName),
      }
    }

    // shortcircuit if nothing is passed
    if (firstName == null && lastName == null) {
      return {
        displayName: null,
      }
    }

    const joinedFullName = [firstName, lastName].filter((str) => str != null).join(' ')

    return {
      displayName: joinedFullName,
      hash: createStringHash(joinedFullName),
      initials: createInitials(joinedFullName),
    }
  }, [firstName, lastName, fullName])

  // fallback to generic icon
  if (displayName == null && profilePictureUrl == null) {
    return <Avatar />
  }
  // fallback to only use profilePictureUrl
  if (displayName == null && profilePictureUrl != null) {
    return <Avatar src={profilePictureUrl} alt={profilePictureUrl} />
  }

  return (
    <Avatar
      {...(profilePictureUrl != null && { src: profilePictureUrl })}
      {...(displayName && {
        alt: displayName,
        sx: { bgcolor: `hsla(${hash}, 95%, 40%, .8)` },
        children: initials,
      })}
    />
  )
}

export default PatientAvatar
