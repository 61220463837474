/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { defaultTimezone } from 'config/defaults'
import isNilOrEmpty from 'fp/isNilOrEmpty'
import { AppointmentDispatchesFragment, EncounterFieldsFragment, GetAppointmentQuery, SexEnum } from 'generated/graphql'
import { filter, groupBy } from 'lodash'
import { EncounterData, PatientFieldType } from 'modules/Appointments/Create/AppointmentCreateFormSchema'
import { findElementEqualsPatientId } from 'modules/Patient/selectors'
import { createSelector } from 'reselect'
import { responderNamesFromDispatches } from 'utils/nameListHelpers/nameListHelpers'
import safeJsonParse from 'utils/safeJsonParse'

const getDispatches = (data?: GetAppointmentQuery) => {
  const dispatches = data?.appointment?.appointmentDispatches?.nodes
  if (!dispatches || dispatches.length === 0) return null
  return dispatches
}
const getSkeduloJobId = (data?: GetAppointmentQuery) => data?.appointment?.skeduloJobId ?? null
const getPatients = (data?: GetAppointmentQuery) =>
  data?.appointment?.patientsByEncounterAppointmentIdAndPatientIdList ?? null
const getEncounters = (data?: GetAppointmentQuery) => data?.appointment?.encountersList ?? null
const getTimezone = (data?: GetAppointmentQuery) => data?.appointment?.market?.timezone ?? null
const getMarketId = (data?: GetAppointmentQuery) => data?.appointment?.marketId ?? null
const getZoneId = (data?: GetAppointmentQuery) => data?.appointment?.zoneId ?? null
const getScheduledFor = (data?: GetAppointmentQuery) => data?.appointment?.scheduledFor ?? null
const getAddress = (data?: GetAppointmentQuery) => {
  if (!data?.appointment) return null
  const { address1, address2, city, state, zip, addressLat, addressLng } = data?.appointment ?? {}
  return { address1, address2, city, state, zip, addressLat, addressLng }
}
const getAvailabilityOverride = (data?: GetAppointmentQuery) => data?.appointment?.availabilityOverride ?? null
const getFirstEncounterChannelAttributionId = (data?: GetAppointmentQuery) =>
  data?.appointment?.encountersList?.[0]?.channelAttributionId ?? null

const mapResponderNamesFromDispatches = (
  dispatches?: AppointmentDispatchesFragment['appointmentDispatches']['nodes'] | null,
) => {
  if (!dispatches) return null
  return responderNamesFromDispatches(dispatches)
}

const groupEncountersByPatientId = (encounters?: EncounterFieldsFragment[] | null) =>
  encounters ? groupBy(encounters, 'patientId') : null

/**
 * @selector
 * @state GetAppointmentQuery
 * simple state selectors
 */
export const selectDispatches = createSelector(getDispatches, (x) => x)
export const selectSkeduloJobId = createSelector(getSkeduloJobId, (x) => x)
export const selectPatients = createSelector(getPatients, (x) => x)
export const selectEncounters = createSelector(getEncounters, (x) => x)
export const selectDefaultedTimezone = createSelector(getTimezone, (timezone) => timezone ?? defaultTimezone)
export const selectMarketId = createSelector(getMarketId, (x) => x)
export const selectZoneId = createSelector(getZoneId, (x) => x)
export const selectGetScheduledFor = createSelector(getScheduledFor, (x) => x)
export const selectAddress = createSelector(getAddress, (x) => x)
export const selectAvailabilityOverride = createSelector(getAvailabilityOverride, (x) => x)
export const selectFirstEncounterChannelAttributionId = createSelector(getFirstEncounterChannelAttributionId, (x) => x)

/**
 * @selector
 * @state GetAppointmentQuery
 * Select responder names from dispatches
 */
export const selectResponderNamesFromDispatches = createSelector(selectDispatches, mapResponderNamesFromDispatches)

/**
 * @selector
 * @state GetAppointmentQuery
 * select group of encounters by PatientId
 */
export const selectGroupedEncountersByPatientId = createSelector(selectEncounters, groupEncountersByPatientId)

/**
 * @selector
 * @state GetAppointmentQuery
 * @props patientId
 * select encounters by a single patientId
 *
 * @example
 *  const encounters = selectEncountersByPatientId(data, { patientId })
 */
export const selectEncountersByPatientId = createSelector(
  [selectGroupedEncountersByPatientId, (state: unknown, { patientId }: { patientId: number }) => patientId],
  (groupedEncounters, patientId) => {
    if (!groupedEncounters) return null
    const encountersByPatientId = groupedEncounters?.[patientId]
    return encountersByPatientId ?? null
  },
)

/**
 * @selector
 * @state GetAppointmentQuery
 * select patient encounter details (specificity for timecards)
 */
export const selectPatientEncounterDetails = createSelector(
  selectEncounters,
  (
    encounters,
  ): Array<{
    serviceLineId: number
    modules: string[]
    responderGender: SexEnum
  }> | null => {
    if (!encounters) return null
    return encounters?.map((encounter) => ({
      serviceLineId: encounter.serviceLine?.serviceLineId ?? -1,
      modules: (filter(encounter.modules) ?? []) as string[],
      responderGender: encounter.genderRequirement ?? SexEnum.Other,
    }))
  },
)

// SPECIFIC to AppointmentCreate
export const selectEncountersForAppointmentTime = createSelector(
  (patients: Array<PatientFieldType>) => {
    if (isNilOrEmpty(patients)) return null
    // useless typeguard
    if (patients == null) return null

    return patients
      .map(({ encounters }) => {
        if (encounters == null || encounters.length === 0) {
          return {
            serviceLineId: undefined,
            modules: undefined,
            responderGender: undefined,
          }
        }

        // TODO: support multiple encounters per patient
        // only one encounter per patient
        const firstPatientEncounter = encounters[0]

        const { genderRequirement } = firstPatientEncounter
        const nonNoneGenderRequirement = genderRequirement === SexEnum.Other ? null : genderRequirement

        return {
          serviceLineId: firstPatientEncounter.serviceLineId,
          modules: safeJsonParse(firstPatientEncounter.modules ?? '[]'),
          responderGender: nonNoneGenderRequirement,
        }
      })
      .filter(Boolean)
  },
  (x) => x,
)

// SPECIFIC to AppointmentCreate
export const selectCreateSkeduloPatientInputByEncounter = createSelector(
  (patients: Array<PatientFieldType>) => {
    if (isNilOrEmpty(patients)) return null
    return patients.map(({ patientId, encounters }) => {
      if (encounters == null)
        return {
          patientId,
          encounters: [],
        }

      const patientEncounters = encounters.map((encounter) => ({
        ...encounter,
        modules: safeJsonParse(encounter.modules ?? '[]'),
        genderRequirement: encounter.genderRequirement === SexEnum.Other ? null : encounter.genderRequirement,
      }))

      return {
        patientId,
        encounters: patientEncounters,
      }
    })
  },
  (x) => x,
)

export const selectFirstEncounterIdHasGenderRequirement = createSelector(selectEncounters, (encounters) => {
  if (!encounters) return null
  const firstEncounter = encounters.find(
    (encounter) => encounter.genderRequirement && encounter.genderRequirement !== SexEnum.Other,
  )
  return firstEncounter ? firstEncounter.encounterId : null
})

export const selectEncountersAsString = createSelector(
  (encounters: EncounterData[]): string => JSON.stringify(encounters.filter(Boolean)),
  (x) => x,
)

// PartnershipEdit selectors

export const selectPartnershipPatient = createSelector([selectPatients, selectEncounters], (patients, encounters) => {
  if (!(patients && encounters)) {
    return null
  }

  return patients.map(({ firstName, lastName, patientId }) => {
    // all encounters share the same channelAttributionId
    const firstEncounterByPatientId = findElementEqualsPatientId(encounters, patientId)

    return {
      firstName,
      lastName,
      patientId,
      channelAttributionId: firstEncounterByPatientId?.channelAttributionId ?? null,
    }
  })
})

export const selectPartnershipEncounters = createSelector(selectEncounters, (encounters) => {
  if (!encounters) {
    return null
  }
  return encounters.map(({ encounterId, patientId }) => ({ encounterId, patientId }))
})
