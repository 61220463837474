import { CommunicationNeedEnum, LanguagesEnum, Maybe } from 'generated/graphql'
import { formatE164ToUSPhoneNumber } from 'utils/phone/format'

import LabeledItem from 'ui/LabeledItem'
import Languages from 'ui/TextFormatters/Languages/Languages'
import SpecialCommunicationNeeds from 'ui/TextFormatters/SpecialCommunicationNeeds'

type CommunicationsReadProps = {
  preferredPhoneNumber?: string | null
  email?: string | null
  languages?: Maybe<Maybe<LanguagesEnum>[]>
  communicationNeed?: Array<CommunicationNeedEnum | null> | null
}

const CommunicationsRead = ({
  preferredPhoneNumber,
  email,
  languages,
  communicationNeed,
}: CommunicationsReadProps): JSX.Element => (
  <div>
    <LabeledItem label="Preferred Phone Number" inline>
      {preferredPhoneNumber ? formatE164ToUSPhoneNumber(preferredPhoneNumber) : null}
    </LabeledItem>
    <LabeledItem label="Email Address" inline>
      {email}
    </LabeledItem>
    <LabeledItem label="Languages" inline>
      <Languages languages={languages} />
    </LabeledItem>
    <LabeledItem label="Special Communication Needs" inline>
      <SpecialCommunicationNeeds specialCommunicationNeed={communicationNeed ?? []} />
    </LabeledItem>
  </div>
)

export default CommunicationsRead
