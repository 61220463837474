import Tokens from 'config/tokens'
import { EncounterResponderStatusEnum } from 'generated/graphql'
import { useTranslation } from 'react-i18next'

import Box from '@mui/system/Box'

export type StatusProps = {
  responderStatus?: EncounterResponderStatusEnum | null
}

function EncounterStatus({ responderStatus }: StatusProps): JSX.Element {
  const { t } = useTranslation()
  return (
    <Box
      component="span"
      sx={{
        ...(responderStatus === EncounterResponderStatusEnum.Cancelled && {
          color: Tokens.color.ui.error.base,
          fontWeight: Tokens.font.weight.semiBold,
        }),
      }}
    >
      {(responderStatus && t('glossary:encounterResponderStatus', { context: responderStatus })) || '-'}
    </Box>
  )
}

export default EncounterStatus
