import { useCallback, useRef } from 'react'

import GenericFunction from 'types/GenericFunction'

/**
 * Creates a callback function of which the reference never changes.
 * This is similar to `dispatch` like functions,
 * and are most useful for operations initiated by user interaction
 */
const useCreateMutatatedDispatch = <F extends GenericFunction>(fn: F): ((...args: Parameters<F>) => ReturnType<F>) => {
  const mutatedDispatchRef = useRef<F>(fn)
  mutatedDispatchRef.current = fn
  return useCallback((...xs) => mutatedDispatchRef.current(...xs), [])
}

export default useCreateMutatatedDispatch
