import { FC, memo, useState } from 'react'

import Config from 'config/config'
import { appointmentsRoutes, generateAppointmentDetailsPath } from 'config/routes'
import useToggle from 'hooks/useToggle'
import get from 'lodash/get'
import without from 'lodash/without'
import AppointmentOverview from 'modules/AppointmentOverview/AppointmentOverview'
import { Layout } from 'modules/Layout'
import PatientDetails from 'modules/Patient/PatientDetails'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import hasFeatureFlag from 'utils/featureFlagsUtilities'

import Button from '@mui/material/Button'
import Container from '@mui/material/Container'
import Paper from '@mui/material/Paper'

import LabeledSection from 'ui/LabeledSection'

import AddPatient from './AddPatient/AddPatient'
import { AddPatientModeContextProvider } from './AddPatient/useAddPatientModeContext'
import { GoogleMapsApiContextProvider } from './AppointmentAddress/useGoogleMapsApiContext'
import AppointmentDetails from './AppointmentDetails/AppointmentDetails'
import CreateVisitInHealthCallModal from './CreateVisitInHealthCallModal/CreateVisitInHealthCallModal'
import Dispatches from './Dispatches/Dispatches'
import {
  selectAvailabilityOverride,
  selectGroupedEncountersByPatientId,
  selectPatients,
  selectSkeduloJobId,
} from './selectors'
import useGetAppointmentQueryContext from './useGetAppointmentQueryContext'

const Appointment: FC<unknown> = () => {
  const { t } = useTranslation()
  const [expandedPatients, setExpandedPatients] = useState<number[]>([])
  const history = useHistory()
  const [showCreateVisitInHealthCallModal, toggleCreateVisitInHealthCallModal] = useToggle(
    Boolean(get(history, 'location.state.showCreateVisitInHealthCallModal', false)),
  )
  const { data, id } = useGetAppointmentQueryContext()

  const handleExpandPatient = (patientId: number) => {
    if (expandedPatients.includes(patientId)) {
      setExpandedPatients((current) => without(current, patientId))
    } else {
      setExpandedPatients((current) => [...current, patientId])
    }
  }

  const patients = selectPatients(data)
  const skeduloJobId = selectSkeduloJobId(data)
  const encountersByPatientId = selectGroupedEncountersByPatientId(data)
  const availabilityOverride = selectAvailabilityOverride(data)

  return (
    <GoogleMapsApiContextProvider>
      <Layout
        title={t('glossary:appointment')}
        headerActions={
          skeduloJobId != null ? (
            <Button
              color="primary"
              size="large"
              href={`${Config.skeduloUrl}/job/${skeduloJobId}`}
              target="_blank"
              rel="noopener"
            >
              {t('actions:appointment.openSkedulo')}
            </Button>
          ) : null
        }
        breadcrumbsProps={[
          {
            title: t('common:breadcrumb.appointments.overview'),
            href: appointmentsRoutes.overview,
          },
          {
            title: t('common:breadcrumb.appointments.details'),
            href: generateAppointmentDetailsPath(id),
          },
        ]}
      >
        {hasFeatureFlag('removeCommunityCareModal') ? null : (
          <CreateVisitInHealthCallModal
            open={showCreateVisitInHealthCallModal}
            onClose={toggleCreateVisitInHealthCallModal}
            appointmentId={data?.appointment?.appointmentId}
          />
        )}

        <Container>
          {/* Appointment Overview */}
          <LabeledSection label="Overview" as={'h2'}>
            <Paper style={{ padding: '16px' }}>
              <AppointmentOverview
                availabilityOverride={availabilityOverride}
                scheduledFor={data?.appointment?.scheduledFor}
                createdAt={data?.appointment?.createdAt}
                createdBy={data?.appointment?.userByCreatedBy}
                status={data?.appointment?.status}
                marketName={data?.appointment?.market?.name ?? ''}
                timeZone={data?.appointment?.market?.timezone ?? ''}
                address1={data?.appointment?.address1 ?? ''}
                address2={data?.appointment?.address2 ?? ''}
                city={data?.appointment?.city ?? ''}
                state={data?.appointment?.state ?? ''}
                zip={data?.appointment?.zip ?? ''}
                systemOfOrigin={data?.appointment?.systemOfOrigin}
                appointmentId={data?.appointment?.appointmentId ?? -1}
                appointmentCancellation={data?.appointment?.cancelledReason}
                appointmentReschedule={data?.appointment?.rescheduledReason}
                cancelledReasonOther={data?.appointment?.cancelledReasonOther}
                rescheduledFrom={data?.appointment?.rescheduledFrom}
                rescheduledAt={data?.appointment?.rescheduledAt}
                rescheduledToAppointmentId={data?.appointment?.rescheduledTo?.appointmentId}
                cancelledBy={data?.appointment?.cancelledBy?.name}
              />
            </Paper>
          </LabeledSection>

          {/* Dispatches Section */}
          {hasFeatureFlag('showDispatchesSectionInAppointment') && (
            <LabeledSection
              label={t('components:modules.Appointment.Dispatches.title')}
              as={'h2'}
              style={{ paddingTop: '2rem' }}
            >
              <Dispatches />
            </LabeledSection>
          )}

          {/* Patients Accordion */}
          <LabeledSection label="Patients" as={'h2'} style={{ paddingTop: '2rem' }}>
            {patients &&
              patients.map((patient) => (
                <PatientDetails
                  key={patient?.patientId}
                  specialInstructions={patient?.specialInstructions}
                  firstName={patient?.firstName}
                  lastName={patient?.lastName}
                  preferredName={patient?.preferredName}
                  preferredPhoneNumber={patient?.preferredPhoneNumber}
                  patientId={patient?.patientId}
                  dob={patient?.dob}
                  sex={patient?.sex}
                  email={patient?.email}
                  languages={patient?.languages}
                  communicationNeed={patient?.communicationNeed}
                  genderIdentity={patient?.genderIdentity}
                  globalHumanId={patient?.globalHumanId}
                  genderPronoun={patient?.genderPronoun}
                  races={patient?.races}
                  address1={patient?.address1}
                  address2={patient?.address2}
                  city={patient?.city}
                  state={patient?.state}
                  addressLat={patient?.addressLat}
                  addressLng={patient?.addressLng}
                  unit={patient?.unit}
                  zip={patient?.zip}
                  isExpanded={expandedPatients.includes(patient.patientId ?? -1)}
                  onExpand={() => handleExpandPatient(patient.patientId ?? -1)}
                  encounters={encountersByPatientId?.[patient.patientId]}
                  // the assumption is that all encounters have the same status
                  // not sure whether this is true, but this is how the previous code was written
                  responderStatus={encountersByPatientId?.[patient.patientId]?.[0].responderStatus}
                />
              ))}
          </LabeledSection>

          <AddPatientModeContextProvider>
            <AddPatient />

            <AppointmentDetails />
          </AddPatientModeContextProvider>
        </Container>
      </Layout>
    </GoogleMapsApiContextProvider>
  )
}

export default memo(Appointment)
