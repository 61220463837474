import { FC } from 'react'

import { useTranslation } from 'react-i18next'

import {
  FormControlLabel,
  FormHelperText,
  FormHelperTextProps,
  Radio,
  RadioGroup,
  RadioGroupProps,
} from '@mui/material'

type YesNoInputProps = RadioGroupProps & {
  helperText: FormHelperTextProps['children']
  error: FormHelperTextProps['error']
}
const YesNoInput: FC<YesNoInputProps> = ({ helperText, error, ...props }) => {
  const { t } = useTranslation()
  return (
    <div>
      <RadioGroup {...props}>
        <FormControlLabel value={true} control={<Radio size="small" />} label={t('glossary:yes')} />
        <FormControlLabel value={false} control={<Radio size="small" />} label={t('glossary:no')} />
      </RadioGroup>
      <FormHelperText error={error}>{helperText}</FormHelperText>
    </div>
  )
}

export default YesNoInput
