// import { ApolloProvider } from '@apollo/client';
import * as Sentry from '@sentry/react'
import ApolloProviderComponent from 'providers/ApolloProvider'
import AuthProvider from 'providers/AuthProvider'
import { ConfirmProvider } from 'providers/ConfirmProvider'
import IconProvider from 'providers/IconProvider'
import { SidebarContextProvider } from 'providers/SidebarContextProvider'
import { UserContextProvider } from 'providers/UserContextProvider'
import isInZendeskSidebar from 'utils/isInZendeskSidebar'
import ZenDeskClient from 'utils/ZenDeskClient'

import ErrorFallback from 'ui/ErrorFallback'

import AppCssBaseline from './AppCssBaseline'
import AppRoutes from './AppRoutes'

if (isInZendeskSidebar()) {
  if (window.outerHeight > 1200) {
    ZenDeskClient.invoke('resize', { width: '100%', height: '79vh' })
  } else {
    ZenDeskClient.invoke('resize', { width: '100%', height: '72vh' })
  }
}

const App = (): JSX.Element => (
  <Sentry.ErrorBoundary fallback={ErrorFallback} showDialog>
    <AppCssBaseline />
    <AuthProvider>
      <ApolloProviderComponent>
        <ConfirmProvider>
          <UserContextProvider>
            <SidebarContextProvider>
              <IconProvider>
                <AppRoutes />
              </IconProvider>
            </SidebarContextProvider>
          </UserContextProvider>
        </ConfirmProvider>
      </ApolloProviderComponent>
    </AuthProvider>
  </Sentry.ErrorBoundary>
)

export default App
