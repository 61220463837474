import { FC } from 'react'

import Tokens from 'config/tokens'
import { PatientNoteLabel } from 'generated/graphql'
import { capitalize } from 'lodash'

import Box from '@mui/system/Box'

const MAP_LABEL_COLOR = {
  [PatientNoteLabel.Safety]: 'khaki',
  [PatientNoteLabel.Clinical]: 'lavender',
}

const NoteLabel: FC<{
  label: PatientNoteLabel
  friendlyLabel: string | null
}> = ({ label, friendlyLabel }) => (
  <Box py={0.5} px={1} borderRadius={1} bgcolor={MAP_LABEL_COLOR[label] ?? Tokens.color.neutral.grey[219]}>
    {friendlyLabel ?? capitalize(label)}
  </Box>
)

export default NoteLabel
