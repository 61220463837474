import MuiAccordionSummary from '@mui/material/AccordionSummary'
import withStyles from '@mui/styles/withStyles'

const AccordionSummary = withStyles((theme) => ({
  root: {
    '&$expanded': {
      minHeight: 'inherit',
    },
  },
  content: {
    '&$expanded': {
      margin: theme.spacing(3, 0),
    },
    alignItems: 'center',
    '& > * + *': {
      paddingLeft: theme.spacing(3, 0),
    },
  },
  expanded: {},
}))(MuiAccordionSummary)

export default AccordionSummary
