import Tokens from 'config/tokens'

import TableRow from '@mui/material/TableRow'
import withStyles from '@mui/styles/withStyles'

const TableRowAlternating = withStyles(() => ({
  root: {
    cursor: 'pointer',
    '&:nth-of-type(even)': {
      backgroundColor: Tokens.color.neutral.grey[250],
    },
    '&:hover': {
      backgroundColor: Tokens.color.neutral.grey[240],
    },
  },
}))(TableRow)

export default TableRowAlternating
