import { FC } from 'react'

import Tokens from 'config/tokens'
import { UserCirclePlus } from 'phosphor-react'
import { useTranslation } from 'react-i18next'

import Box from '@mui/system/Box'

import Spinner from 'ui/Spinner'

const ConfirmPatientButton: FC<{ loading: boolean; isValid: boolean }> = ({ loading, isValid }) => {
  const { t } = useTranslation()
  return (
    <Box width={175} display="flex" alignItems="center" justifyContent="center">
      {t('components:modules.Appointment.AddPatient.confirmButton.label')}

      <Box ml={1}>
        {!loading ? (
          <UserCirclePlus
            size={14}
            color={!isValid ? Tokens.color.neutral.grey[82] : Tokens.color.neutral.white.base}
            style={{ position: 'relative', top: 2 }}
          />
        ) : (
          <Spinner size="small" foregroundColor={loading ? Tokens.color.neutral.black.base : undefined} />
        )}
      </Box>
    </Box>
  )
}

export default ConfirmPatientButton
