import { GetMarketsQuery, GetZoneAndMarketByZipCodeQuery, Market, MarketFragment, Zone } from 'generated/graphql'
import { isEmpty, isNumber, memoize } from 'lodash'
import { createSelector, Selector } from 'reselect'

type DynamicSelector<S, R1, T> = Selector<S, (arg0: R1) => T>

const getMarketsList = (data?: GetMarketsQuery) => data?.marketsList ?? null
export const selectMarketsList = createSelector(getMarketsList, (x) => x)

const filterActiveMarkets = (markets: MarketFragment[] | null) => {
  if (!markets) return null
  return markets.filter((market) => market.isActive)
}

export const selectActiveMarketsList = createSelector(selectMarketsList, filterActiveMarkets)

export const selectMarketNameById: DynamicSelector<GetMarketsQuery | undefined, number | null, string | null> =
  createSelector(selectMarketsList, (markets) =>
    memoize((id: number | null) => {
      if (!markets || id == null) {
        return null
      }
      return markets.find(({ marketId }) => id === marketId)?.name ?? null
    }),
  )

export const assertMarketHasMarketId = (markets: Partial<Market>[] | null, ids: number[] | number | null): boolean => {
  if (!markets || isEmpty(markets)) {
    return false
  }
  if (!isNumber(ids) && isEmpty(ids)) {
    return false
  }
  if (Array.isArray(ids)) {
    return ids.every((id) => Boolean(markets.find(({ marketId }) => id === marketId)))
  }
  return Boolean(markets.find(({ marketId }) => ids === marketId))
}

const getZonesByZipCode = (data: GetZoneAndMarketByZipCodeQuery) => data?.zipcode?.zones?.nodes ?? null
const selectZonesByZipCode = createSelector(getZonesByZipCode, (x) => x)
export const selectFirstActiveZoneByZipCode = createSelector(
  selectZonesByZipCode,
  (zones: Array<Pick<Zone, 'isActive' | 'zoneId' | 'marketId'>> | null) =>
    zones?.filter((zone) => zone.isActive)[0] ?? null,
)
