import { FC, useCallback, useEffect, useMemo, useState } from 'react'

import Tokens from 'config/tokens'
import {
  SexEnum,
  useGetAppointmentAvailabilitiesLazyQuery,
  useGetMarketQuery,
  Appointment,
  Maybe,
  GetAppointmentAvailabilitiesQueryVariables,
} from 'generated/graphql'
import useFormState from 'hooks/useFormState'
import useToggle from 'hooks/useToggle'
import { LazyYupValidationError, useLazyAsyncYupValidation } from 'hooks/useYupValidation'
import compact from 'lodash/compact'
import { DateTime } from 'luxon'
import useIsCreateModeContext from 'modules/Appointments/useIsCreateModeContext'
import { createAddressLine } from 'modules/Patient/selectors'
import { Controller, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { usePrevious } from 'react-use'
import ControlledField from 'utils/ControlledField/ControlledField'
import { getStartEndDatesForTimeZone } from 'utils/dates'
import appointmentTimeSchema from 'yupSchemas/appointmentTime'

import { Alert, AlertTitle } from '@mui/material'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import Box from '@mui/system/Box'

import RescheduleReasonSelect from 'ui/Inputs/RescheduleReasonSelect/RescheduleReasonSelect'
import TimeSlotRadios from 'ui/Inputs/TimeSlotRadios/TimeSlotRadios'
import Checkbox from 'ui/MaterialUI/Checkbox/Checkbox'
import AppointmentDateSelect from 'ui/MaterialUI/DatePicker/AppointmentDateSelect'
import Spinner from 'ui/Spinner'

import useGoogleMapsApiContext, {
  GeocoderError,
  selectGeoLocation,
} from '../AppointmentAddress/useGoogleMapsApiContext'

import useAvailabilityTimeslots from './useAvailabilityTimeslots'

// TODO: use generated type instead
type PayloadType = {
  start: string
  end: string
  appointment: {
    address?: string | null
    zipcode?: string | null
    marketId?: number | null
    zoneId?: number | null
    address_lat?: number | null
    address_lng?: number | null
  }
  patients:
    | {
        serviceLineId?: number
        modules?: string[]
        responderGender?: Maybe<SexEnum>
      }[]
    | null
}

type AppointmentTimeEditProps = {
  scheduledFor: Appointment['scheduledFor']
  timeZone: string

  address1?: string | null
  city?: string | null
  state?: string | null
  zip?: string | null

  addressLat: Appointment['addressLat']
  addressLng: Appointment['addressLng']

  marketId?: number | null
  zoneId?: number | null

  patients:
    | {
        serviceLineId?: number
        modules?: string[]
        responderGender?: Maybe<SexEnum>
      }[]
    | null
  // AppointmentCreate keeps track of patients using this field
  patientIds?: string | number[] | null
}

const AppointmentTimeEdit: FC<AppointmentTimeEditProps> = ({
  scheduledFor,
  timeZone,
  address1,
  city,
  state,
  zip,
  marketId,
  zoneId,
  patients,
  patientIds,
  addressLat,
  addressLng,
}) => {
  const { t } = useTranslation()
  const [showTimeSlots, setShowTimeSlots] = useState(false)
  const [payloadErrors, setPayloadErrors] = useState<null | LazyYupValidationError<PayloadType>['errors']>(null)
  const isCreateMode = useIsCreateModeContext()
  const { fetchGeocodeComponents } = useGoogleMapsApiContext()
  // showErrors is used for both client and server side errors
  const [showErrors, setShowErrors] = useState(false)
  const [hasGeocoderError, , setGeocoderError, unsetGeocoderError] = useToggle(false)
  const [selectedTimeSlot, setSelectedTimeSlot] = useState('')

  const { data: marketData, refetch: refetchGetMarket } = useGetMarketQuery({
    variables:
      marketId == null
        ? undefined
        : {
            marketId,
          },
    skip: marketId == null,
  })

  const marketIsFrozen = marketData?.market?.isFrozen ?? false

  const initialDate = scheduledFor ? DateTime.fromISO(scheduledFor, { zone: timeZone }) : DateTime.now()
  const initialState = {
    availabilityDate: initialDate.toFormat('yyyy-MM-dd'),
  }
  const { values, handleSetValue } = useFormState({
    initialState,
  })

  const { watch, setValue } = useFormContext()

  const [getAvailability, { loading, data, error: serverError }] = useGetAppointmentAvailabilitiesLazyQuery({
    fetchPolicy: 'no-cache',
    onCompleted: () => {
      setShowTimeSlots(true)
      setShowErrors(false)
    },
    onError: () => {
      setShowTimeSlots(false)
      setShowErrors(true)
    },
  })

  const { validate } = useLazyAsyncYupValidation<PayloadType>({ schema: appointmentTimeSchema })

  const datetime = useMemo(
    () => DateTime.fromISO(values.availabilityDate, { zone: timeZone }),
    [values.availabilityDate, timeZone],
  )

  const { availabilityData, dispatchAvailabilityData, resetAvailability } = useAvailabilityTimeslots(datetime)

  const handleFetchAvailability = async () => {
    setShowErrors(false)
    setPayloadErrors(null)
    unsetGeocoderError()

    if (marketId != null) {
      await refetchGetMarket()
    }

    const { dayStart, dayEnd } = getStartEndDatesForTimeZone(values.availabilityDate, timeZone)
    const payload = {
      start: dayStart.toISOString(),
      end: dayEnd.toISOString(),
      appointment: {
        address: compact([address1, city, state]).join(', '),
        zipcode: zip,

        marketId,
        zoneId,

        address_lat: addressLat,
        address_lng: addressLng,
      },
      patients,
    }

    try {
      await validate(payload)

      // fetch lat/lng if not available
      if (!addressLat && !addressLng && fetchGeocodeComponents) {
        const { geocoderResult } = await fetchGeocodeComponents({
          address: createAddressLine({ address1, city, state, zip }),
        })
        const { addressLat: geocoderAddressLat, addressLng: geocoderAddressLng } = selectGeoLocation(geocoderResult)
        payload.appointment.address_lat = geocoderAddressLat
        payload.appointment.address_lng = geocoderAddressLng
      }

      getAvailability({ variables: payload as GetAppointmentAvailabilitiesQueryVariables })
    } catch (validationError) {
      setShowErrors(true)
      if (validationError instanceof LazyYupValidationError) {
        setPayloadErrors(validationError.errors)
      }
      if (validationError instanceof GeocoderError) {
        setGeocoderError()
      }
    }
  }

  const resetSelectedTimeslot = useCallback(() => {
    setValue('startTimes', JSON.stringify([]), { shouldDirty: false })
    setValue('resourceRequired', 0, { shouldDirty: false })
    setValue('jobType', '', { shouldDirty: false })
    setValue('duration', 0, { shouldDirty: false })
    setValue('jobTags', JSON.stringify([]), { shouldDirty: false })
    setSelectedTimeSlot('')
  }, [setValue])

  const handleTimeslotChange = useCallback(
    (newValue) => {
      setSelectedTimeSlot(newValue)
      if (availabilityData[newValue]) {
        // We have an array of start times if there is availability data
        const { startTimes } = availabilityData[newValue]
        setValue('startTimes', JSON.stringify(startTimes))
      } else {
        // When overriding availability we use just the first timeslot
        setValue('startTimes', JSON.stringify([newValue]))
      }
    },
    [setValue, availabilityData],
  )

  // this has a dependency on datetime
  useEffect(() => {
    if (loading) resetAvailability()
  }, [loading])

  useEffect(() => {
    setShowTimeSlots(false)
    resetSelectedTimeslot()
    resetAvailability()
  }, [patients, address1, city, state, zip, marketId, zoneId, patientIds])

  useEffect(() => {
    resetSelectedTimeslot()
    resetAvailability()

    // user must have selected a different date after already having fetched timeslots, as we are already showing timeslots
    // in this case let's try refetching availability without user interaction
    if (showTimeSlots && !payloadErrors) {
      handleFetchAvailability()
    }
  }, [values.availabilityDate])

  const forceScheduling = watch('forceScheduling')
  const lastData = usePrevious(data)

  useEffect(() => {
    if (!data) return
    if (data !== lastData) {
      const { getAppointmentAvailabilities: { jobType, jobTags, requiredResponders, jobDuration } = {} } = data
      setValue('resourceRequired', requiredResponders, { shouldDirty: true })
      setValue('jobType', jobType, { shouldDirty: true })
      setValue('duration', jobDuration, { shouldDirty: true })
      // Stringify Array so RHF doesn't get confused

      const jobTagsIdsOnly = jobTags?.map((tag) => ({ id: tag?.id }))

      setValue('jobTags', JSON.stringify(jobTagsIdsOnly), { shouldDirty: true })
    }

    dispatchAvailabilityData({ type: 'UPDATE', data, timeZone, forceScheduling })
  }, [data, timeZone, forceScheduling])

  if (!fetchGeocodeComponents) return null

  return (
    <div>
      <Grid container direction="row" alignItems="center" justifyContent="space-between">
        <Grid item>
          <Grid container alignItems="center">
            <Controller
              name="startTimes"
              render={({ fieldState: { invalid, error } }) => (
                <AppointmentDateSelect
                  value={values.availabilityDate}
                  onChange={(event: { target: { value: string } }) =>
                    handleSetValue('availabilityDate', event.target.value)
                  }
                  error={invalid}
                  helperText={error?.message ? t(error.message) : null}
                />
              )}
            />
            <Grid item style={{ paddingLeft: '1rem' }}>
              {loading && <Spinner />}{' '}
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Button variant="outlined" onClick={handleFetchAvailability} disabled={loading}>
            {t('components:modules.Appointments.fetchAvailability')}
          </Button>
        </Grid>
      </Grid>

      {/* notification market isFrozen */}
      {marketIsFrozen && showTimeSlots && (
        <Box color={Tokens.color.ui.error.base} mb={2}>
          {t('components:modules.Appointments.fetchAvailability.marketIsFrozen')}
        </Box>
      )}

      {/* timeslot radio button group */}
      {showTimeSlots && (
        <>
          <TimeSlotRadios
            availabilityData={availabilityData}
            // value={safeJsonParse(value)?.[0]}
            value={selectedTimeSlot}
            onChange={handleTimeslotChange}
            override={forceScheduling}
            disabled={marketIsFrozen}
          />

          <Box component="span" m={2} style={{ whiteSpace: 'nowrap' }}>
            <Controller
              name="forceScheduling"
              render={({ field: { onChange, value } }) => (
                <Checkbox
                  checked={value}
                  onChange={(event: { target: { value: string } }, checked: boolean) => {
                    if (checked === false) {
                      // unselect the timeslot when we uncheck this override
                      // ideally we do a check to see if the selected timeslot is available when override is unchecked
                      setValue('startTimes', JSON.stringify([]), { shouldDirty: false })
                      setSelectedTimeSlot('')
                    }
                    onChange(checked)
                  }}
                  label={t('components:modules.Appointments.fetchAvailability.override.label')}
                />
              )}
            />
          </Box>

          {!isCreateMode && (
            <Box mt={6}>
              <ControlledField
                name="rescheduledReason"
                Component={RescheduleReasonSelect}
                ComponentProps={{ fullWidth: true, showNone: false }}
              />
            </Box>
          )}
        </>
      )}

      <Grid
        container
        direction="row"
        alignItems="center"
        justifyContent="space-around"
        alignContent="center"
        style={{ marginTop: '1rem' }}
      >
        <Grid item xs={12} sm={8} md={9}>
          {/* empty state */}
          {!showTimeSlots &&
            !showErrors &&
            (isCreateMode
              ? 'Enter all Encounter Details and Appointment Details. Then click Fetch Availability to view open time slots.'
              : t('components:modules.Appointments.fetchAvailability.instructions'))}

          {showErrors && (payloadErrors || serverError) && (
            <Alert severity="error">
              {/* server error (400 etc) */}
              {serverError && (
                <AlertTitle>{t('components:modules.Appointments.fetchAvailability.serverError')}</AlertTitle>
              )}

              {/* client-side validation errors */}
              {payloadErrors && (
                <>
                  <AlertTitle>{t('components:modules.Appointments.fetchAvailability.errors')}</AlertTitle>
                  <ul>
                    {Object.keys(payloadErrors).map((key) => (
                      <li key={key}>{t(payloadErrors[key as keyof typeof payloadErrors]?.message ?? '')}</li>
                    ))}
                  </ul>
                </>
              )}
              {hasGeocoderError ? (
                <>{t('components:modules.Appointments.appointmentAddress.geocodeError.message')}</>
              ) : null}
            </Alert>
          )}
        </Grid>
      </Grid>
    </div>
  )
}

export default AppointmentTimeEdit
