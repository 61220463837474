import { FC } from 'react'

import { PatientNoteFragment } from 'generated/graphql'
import isInZendeskSidebar from 'utils/isInZendeskSidebar'

import { Theme } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import Box from '@mui/system/Box'

import Note from './Note'

const useStyles = makeStyles(({ spacing }: Theme) => {
  if (!isInZendeskSidebar()) {
    return {
      container: {
        // loosely based this maxHeight off of the left container height with our eyes
        maxHeight: 1340,
        overflowY: 'auto',
        '& > * + *': {
          marginTop: spacing(4),
        },
      },
    }
  }
  return {
    container: {
      '& > * + *': {
        marginTop: spacing(4),
      },
    },
  }
})

const NoteList: FC<{
  notes: PatientNoteFragment[]
  isDeletable: boolean | null
}> = ({ notes, isDeletable }) => {
  const classes = useStyles()

  return (
    <Box component="section" display="flex" flexDirection="column" className={classes.container}>
      {notes.map((note, key) => (
        <Note
          key={key}
          patientNoteId={note.patientNoteId}
          isDeletable={isDeletable ?? false}
          isPriority={note.isHighPriority ?? false}
          date={new Date(note.createdAt)}
          author={note.author}
          note={note.note ?? null}
          label={note.label ?? null}
          friendlyLabel={null}
        />
      ))}
    </Box>
  )
}

export default NoteList
