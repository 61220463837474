import MuiAccordion from '@mui/material/Accordion'
import withStyles from '@mui/styles/withStyles'

const Accordion = withStyles({
  root: {
    boxShadow: 'none',
    margin: 0,
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 0,
    },
  },
  expanded: {},
})(MuiAccordion)

export default Accordion
