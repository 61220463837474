import TextField, { OutlinedTextFieldProps } from '@mui/material/TextField'

export type FirstNameInputProps = Partial<OutlinedTextFieldProps>

const FirstNameInput = ({ value, ...props }: FirstNameInputProps): JSX.Element => (
  <TextField
    id="firstName"
    name="firstName"
    required
    fullWidth
    {...props} // Options above this line can be overwritten
    label="Legal First Name"
    value={value ?? ''}
  />
)

export default FirstNameInput
