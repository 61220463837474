import { useMemo, FC, useCallback } from 'react'

import { CancellationReasonEnum } from 'generated/graphql'
import { useTranslation } from 'react-i18next'
import hasFeatureFlag from 'utils/featureFlagsUtilities'
import { CancelAppointmentSchema } from 'yupSchemas/appointment/cancelAppointment'

import MenuItem from '@mui/material/MenuItem'
import TextField, { OutlinedTextFieldProps } from '@mui/material/TextField'
import makeStyles from '@mui/styles/makeStyles'

const LIST_CANCELLATION_REASONS = Object.values(CancellationReasonEnum)

const LIST_CANCELLATION_REASONS_WITHOUT = LIST_CANCELLATION_REASONS.filter(
  (cancellationReason) => cancellationReason !== CancellationReasonEnum.RefusedRescheduling,
)

const VALUE_NONE = '__none__'
const DEFAULT_I18N_KEY = 'components:ui.Inputs.AppointmentCancellationReasonSelect'
const I18N_OPTION_KEY = 'glossary:appointmentCancellationReason'

export type CancellationReason = CancelAppointmentSchema['cancellationReason']

export type CancellationReasonProps = Partial<Omit<OutlinedTextFieldProps, 'onChange' | 'value'>> & {
  i18nKey?: string
  showNone?: boolean
  fullWidth?: boolean

  value?: CancellationReason
  onChange: (newValue: CancellationReason) => void
}

const useStyles = makeStyles({
  container: {
    minWidth: 200,
    maxWidth: ({ fullWidth }: Pick<CancellationReasonProps, 'fullWidth'>) => (fullWidth ? 'none' : 300),
  },
})

export const assertCancellationReasonExists = (cancellationReason: CancellationReason): boolean => {
  if (cancellationReason == null) {
    return false
  }
  return LIST_CANCELLATION_REASONS.includes(cancellationReason)
}

const CancellationReasonSelect: FC<CancellationReasonProps> = ({
  value,
  onChange,
  i18nKey = DEFAULT_I18N_KEY,
  showNone = true,
  fullWidth = false,
  ...textFieldProps
}) => {
  const { t } = useTranslation()
  const classes = useStyles({ fullWidth })

  const handleOnChange = useCallback(
    (event: { target: { value: CancellationReason | string } }): void => {
      const {
        target: { value: optionValue },
      } = event

      onChange(optionValue === VALUE_NONE ? null : (optionValue as CancellationReason))
    },
    [onChange],
  )

  const existingValue = useMemo(() => {
    if (!value) return VALUE_NONE
    return (assertCancellationReasonExists(value) && value) || VALUE_NONE
  }, [value, LIST_CANCELLATION_REASONS])

  const textFieldLabel = t(`${i18nKey}.label`)
  const noneLabel = t(`${i18nKey}.option.none.label`)
  const nonePlaceholder = t(`${i18nKey}.option.none.placeholder`)

  const cancellationReasonList = hasFeatureFlag('showUpdatedCancellationReasons')
    ? LIST_CANCELLATION_REASONS_WITHOUT
    : LIST_CANCELLATION_REASONS

  return (
    <div className={classes.container}>
      <TextField
        fullWidth
        name="appointmentCancellationReason"
        value={existingValue ?? ''}
        {...textFieldProps}
        select
        onChange={handleOnChange}
        SelectProps={{
          renderValue: (optionValue) =>
            optionValue === VALUE_NONE
              ? nonePlaceholder
              : t(I18N_OPTION_KEY, { context: optionValue }) ?? (optionValue as CancellationReason),
        }}
        label={textFieldLabel}
      >
        {showNone && <MenuItem value={VALUE_NONE}>{noneLabel ?? noneLabel}</MenuItem>}

        {cancellationReasonList?.map((status) => (
          <MenuItem key={status} value={status}>
            {hasFeatureFlag('showUpdatedCancellationReasons') &&
            (status === CancellationReasonEnum.ServicesNotAvailable ||
              status === CancellationReasonEnum.SoughtCareElsewhere ||
              status === CancellationReasonEnum.CapacityRelated)
              ? t(`${I18N_OPTION_KEY}WithSpecify`, { context: status })
              : t(I18N_OPTION_KEY, { context: status })}
          </MenuItem>
        ))}
      </TextField>
    </div>
  )
}

export default CancellationReasonSelect
