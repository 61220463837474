import Tokens from 'config/tokens'

import MuiTableHead from '@mui/material/TableHead'
import withStyles from '@mui/styles/withStyles'

const TableHead = withStyles(() => ({
  root: {
    backgroundColor: Tokens.color.neutral.grey[219],
  },
}))(MuiTableHead)

export default TableHead
