import { selectPatientIdFromFragments } from 'modules/Patient/selectors'

import { Hidden } from '@mui/material'
import Grid from '@mui/material/Grid'
import Box from '@mui/system/Box'

import LabeledSection from 'ui/LabeledSection'
import SpacedItems from 'ui/SpacedItems'

import useAddPatientModeContext from '../AddPatient/useAddPatientModeContext'
import AppointmentAddressCard from '../AppointmentAddress/AppointmentAddressCard'
import AppointmentCancellation from '../AppointmentCancellation/AppointmentCancellation'
import AppointmentPartnershipCard from '../AppointmentPartnership/AppointmentPartnershipCard'
import AppointmentTimeCard from '../AppointmentTime/AppointmentTimeCard'
import { selectPatients } from '../selectors'
import useGetAppointmentQueryContext from '../useGetAppointmentQueryContext'

const AppointmentDetails = () => {
  const [isAddPatientMode] = useAddPatientModeContext()

  const { data, id } = useGetAppointmentQueryContext()
  const patients = selectPatients(data)
  const patientIds = selectPatientIdFromFragments(patients)

  if (isAddPatientMode) {
    return null
  }

  return (
    <Hidden smDown>
      <LabeledSection label="Appointment Details" as={'h2'} style={{ paddingTop: '2rem' }}>
        <Grid
          container
          spacing={6}
          style={{
            paddingTop: '1rem',
          }}
        >
          <Grid item xs={12} md={7}>
            <SpacedItems direction="column">
              <AppointmentAddressCard
                patientIds={patientIds?.filter(Boolean) as number[] | null}
                appointmentId={id ? Number(id) : null}
                marketId={data?.appointment?.marketId}
                zoneId={data?.appointment?.zoneId}
                address1={data?.appointment?.address1}
                address2={data?.appointment?.address2}
                city={data?.appointment?.city}
                state={data?.appointment?.state}
                zip={data?.appointment?.zip}
                addressLat={data?.appointment?.addressLat}
                addressLng={data?.appointment?.addressLng}
                unit={data?.appointment?.unit}
                specialInstructions={data?.appointment?.specialInstructions}
              />

              <AppointmentPartnershipCard />

              <AppointmentTimeCard />
            </SpacedItems>
          </Grid>

          <Grid item xs={12} md={5}>
            {/** Empty */}
          </Grid>
        </Grid>
      </LabeledSection>

      <Box paddingTop={4}>
        <AppointmentCancellation appointment={data?.appointment} />
      </Box>
    </Hidden>
  )
}

export default AppointmentDetails
