import React, { memo, MouseEventHandler, useCallback, useEffect, useState } from 'react'

import { NetworkStatus } from '@apollo/client'
import { generateAppointmentDetailsPath } from 'config/routes'
import Tokens from 'config/tokens'
import {
  GetAppointmentsQuery,
  useGetAppointmentLazyQuery,
  useGetSearchAppointmentsQuery,
  SearchAppointmentsOrderByEnum,
} from 'generated/graphql'
import debounce from 'lodash/debounce'
import uniqBy from 'lodash/uniqBy'
import { DateTime } from 'luxon'
import { CalendarX, Flag, Pencil } from 'phosphor-react'
import { useTranslation } from 'react-i18next'
import useInfiniteScroll from 'react-infinite-scroll-hook'
import { Link } from 'react-router-dom'
import { patientNamesFromEncounters } from 'utils/nameListHelpers/nameListHelpers'

import CircleIcon from '@mui/icons-material/Circle'
import { Skeleton } from '@mui/material'
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'
import Paper from '@mui/material/Paper'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableRow from '@mui/material/TableRow'
import TableSortLabel from '@mui/material/TableSortLabel'
import Box from '@mui/system/Box'

import EmptyState from 'ui/EmptyState'
import TableHead from 'ui/MaterialUI/Table/TableHead'
import TableHeadCell from 'ui/MaterialUI/Table/TableHeadCell'
import TableRowAlternating from 'ui/MaterialUI/Table/TableRow'
import Spinner from 'ui/Spinner'
import AppointmentStatus from 'ui/TextFormatters/AppointmentStatus'
import FormattedDate from 'ui/TextFormatters/FormattedDate'
import NameList from 'ui/TextFormatters/NameList'

import AppointmentPreviewModal from '../AppointmentPreviewModal/AppointmentPreviewModal'

import { selectDispatchedResponderNames } from './selectors'
import useAppointmentFilterContext from './useAppointmentFiltersContext'

export type AppointmentsList = NonNullable<GetAppointmentsQuery['appointmentsList']>
export type Encounters = NonNullable<AppointmentsList[number]['encounters']['nodes']>

function hasAnyHighPriority(encounters: Encounters = []) {
  return encounters.some((encounter) => (encounter?.patient?.patientNotes?.totalCount ?? 0) >= 1)
}

const TOTAL_ITEM_PER_PAGE = 25
const MAX_POLL_ITEM_PER_PAGE = 250
const MS_POLLING_INTERVAL = 15000

function AppointmentTable(): JSX.Element {
  const { t } = useTranslation()
  const [page, setPage] = useState(0)
  const filterContext = useAppointmentFilterContext()
  const { data, loading, fetchMore, networkStatus } = useGetSearchAppointmentsQuery({
    variables: {
      first: Math.min(page + TOTAL_ITEM_PER_PAGE, MAX_POLL_ITEM_PER_PAGE),
      offset: 0,
      orderBy: SearchAppointmentsOrderByEnum.ScheduledForDesc,
      query: filterContext?.query,
      markets: filterContext?.marketId,
      status: filterContext?.status,
      serviceLines: filterContext?.serviceLines,
      scheduledAfter: filterContext?.startDate?.toISO(),
      scheduledBefore: filterContext?.endDate?.toISO(),
    },
    // we use `undefined` state for persisting filters to guarantee that they have been loaded from localStorage
    skip: filterContext.marketId === undefined && filterContext.status === undefined,
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    pollInterval: MS_POLLING_INTERVAL,
  })

  const [fetchAppointment] = useGetAppointmentLazyQuery()
  const preloadAppointment = React.useMemo(
    () =>
      debounce((appointmentId) => {
        fetchAppointment({ variables: { appointmentId } })
      }, 250),
    [],
  )

  const [modalState, setModalState] = useState({
    open: false,
    id: -1,
  })

  useEffect(() => {
    // short-circuit this effect if fields haven't been loaded from local storage yet
    if (typeof filterContext?.status === 'undefined' || typeof filterContext?.marketId === 'undefined') return
    if (page === 0) return
    setPage(0)
  }, [filterContext])

  const appointments = data?.searchAppointments || []
  const hasNextPage = !(appointments.length < page)

  // due to a bug in the endpoint we need to filter out duplicates,
  const appointmentsList = uniqBy(appointments, 'appointmentId')

  const handleLoadMore = useCallback(() => {
    if (modalState.open) return
    if (loading) return
    if (page >= MAX_POLL_ITEM_PER_PAGE - TOTAL_ITEM_PER_PAGE) return
    // for some reason there is an edge-case where fetchMore is undefined, breaking the app
    // could be caused by tab suspension, or some other memory optimization in v8 engine
    // a quick look into seemed to suggest this is an issue with infintescroll's use of the IntersectionObserver
    if (!fetchMore) return

    fetchMore({
      variables: { first: TOTAL_ITEM_PER_PAGE, offset: page + TOTAL_ITEM_PER_PAGE },
    })
    setPage(() => Math.min(page + TOTAL_ITEM_PER_PAGE, MAX_POLL_ITEM_PER_PAGE))
  }, [loading, modalState.open, page, fetchMore, setPage])

  const [sentryRef] = useInfiniteScroll({
    loading,
    hasNextPage,
    onLoadMore: handleLoadMore,
    rootMargin: '0px 0px 400px 0px',
  })

  const handleClose = () => {
    setModalState({
      open: false,
      id: modalState.id,
    })
  }

  const handleEditClick: MouseEventHandler<HTMLAnchorElement> = (event) => {
    event.stopPropagation()
  }

  return (
    <>
      <TableContainer component={Paper} style={{ maxHeight: 'calc(100vh - 233px - 1rem)' }}>
        <Table aria-label="simple table" stickyHeader>
          <TableHead>
            <TableRow>
              <TableHeadCell>
                <TableSortLabel active={true} direction={'desc'}>
                  Scheduled For
                </TableSortLabel>
              </TableHeadCell>
              <TableHeadCell>Responder(s)</TableHeadCell>
              <TableHeadCell>Patient(s)</TableHeadCell>
              <TableHeadCell>Source</TableHeadCell>
              <TableHeadCell align="right">Market</TableHeadCell>
              <TableHeadCell align="right">Status</TableHeadCell>
              <TableHeadCell align="right"></TableHeadCell>
              {/** Note column */}
              <TableHeadCell align="right"></TableHeadCell>
              {/** Edit column */}
            </TableRow>
          </TableHead>

          <TableBody>
            {appointmentsList.map((appointment) => {
              const encounters = appointment?.encounters?.nodes
              const patientNames = patientNamesFromEncounters(encounters)

              const responderNames = selectDispatchedResponderNames(appointment)

              return (
                <TableRowAlternating
                  key={`AppointmentTableRow-${appointment.appointmentId}`}
                  onClick={() => {
                    setModalState({
                      open: true,
                      id: appointment.appointmentId,
                    })
                  }}
                >
                  <TableCell component="th" scope="row" width={180}>
                    <Box sx={{ display: 'flex', placeContent: 'space-between' }}>
                      <FormattedDate
                        splitLine={true}
                        dateTime={DateTime.fromISO(appointment?.scheduledFor, {
                          zone: appointment?.market?.timezone ?? '',
                        })}
                      />

                      {appointment?.availabilityOverride ? (
                        <Box
                          title={t('actions:appointment.availabilityOverride')}
                          sx={{ alignSelf: 'center', marginLeft: 3, marginRight: 3 }}
                        >
                          <CircleIcon
                            sx={{
                              fontSize: 12,
                              color: Tokens.color.brand.red.base,
                            }}
                          />
                        </Box>
                      ) : null}
                    </Box>
                  </TableCell>
                  <TableCell width={342}>
                    <NameList listNames={responderNames} displayCount={3} />
                  </TableCell>
                  <TableCell width={342}>
                    <NameList listNames={patientNames} displayCount={1} />
                  </TableCell>
                  <TableCell>{encounters[0]?.channelAttribution?.channel}</TableCell>
                  <TableCell align="right" width={130}>
                    {appointment?.market?.name}
                  </TableCell>
                  <TableCell align="right" width={125}>
                    <AppointmentStatus status={appointment?.status} />
                  </TableCell>
                  <TableCell align="right" width={56}>
                    {hasAnyHighPriority(appointment.encounters.nodes) && (
                      <Flag size={24} color="#dc1818" weight="duotone" />
                    )}
                  </TableCell>
                  <TableCell align="right" width={56}>
                    <IconButton
                      size="small"
                      aria-label="view & edit appointment"
                      component={Link}
                      to={generateAppointmentDetailsPath(appointment?.appointmentId)}
                      onClick={handleEditClick}
                      onMouseOver={() => {
                        preloadAppointment(appointment?.appointmentId)
                      }}
                    >
                      <Pencil size={24} weight="light" />
                    </IconButton>
                  </TableCell>
                </TableRowAlternating>
              )
            })}

            {/* Load More button */}
            {!loading &&
              appointmentsList.length > 0 &&
              hasNextPage &&
              !(page >= MAX_POLL_ITEM_PER_PAGE - TOTAL_ITEM_PER_PAGE) && (
                <TableRow>
                  <TableCell colSpan={42}>
                    <div ref={sentryRef}>
                      <Box
                        display="flex"
                        flexDirection="column"
                        alignItems="center"
                        justifyContent="center"
                        textAlign="center"
                      >
                        <Button onClick={handleLoadMore}>{t('actions:loadMore')}</Button>
                      </Box>
                    </div>
                  </TableCell>
                </TableRow>
              )}

            {!loading && page >= MAX_POLL_ITEM_PER_PAGE - TOTAL_ITEM_PER_PAGE && (
              <TableRow>
                <TableCell colSpan={42}>
                  <div ref={sentryRef}>
                    <Box
                      display="flex"
                      flexDirection="column"
                      alignItems="center"
                      justifyContent="center"
                      textAlign="center"
                    >
                      <p>{t('components:modules.Appointments.AppointmentTable.maximumAppointmentsReachedInfo')}</p>
                    </Box>
                  </div>
                </TableCell>
              </TableRow>
            )}

            {/* loading state */}
            {loading && networkStatus !== NetworkStatus.poll && (
              <TableRow>
                <TableCell colSpan={42}>
                  <div ref={sentryRef}>
                    <Box
                      display="flex"
                      flexDirection="column"
                      alignItems="center"
                      justifyContent="center"
                      textAlign="center"
                      {...(appointmentsList.length === 0 && { minHeight: '30vh' })}
                    >
                      {appointmentsList.length === 0 && loading ? (
                        <>
                          <Skeleton
                            variant="rectangular"
                            animation="wave"
                            height={73}
                            width={'100%'}
                            sx={{ margin: 2 }}
                          />
                          <Skeleton
                            variant="rectangular"
                            animation="wave"
                            height={73}
                            width={'100%'}
                            sx={{ margin: 2 }}
                          />
                          <Skeleton
                            variant="rectangular"
                            animation="wave"
                            height={73}
                            width={'100%'}
                            sx={{ margin: 2 }}
                          />
                          <Skeleton
                            variant="rectangular"
                            animation="wave"
                            height={73}
                            width={'100%'}
                            sx={{ margin: 2 }}
                          />
                          <Skeleton
                            variant="rectangular"
                            animation="wave"
                            height={73}
                            width={'100%'}
                            sx={{ margin: 2 }}
                          />
                          <Skeleton
                            variant="rectangular"
                            animation="wave"
                            height={73}
                            width={'100%'}
                            sx={{ margin: 2 }}
                          />
                          <Skeleton
                            variant="rectangular"
                            animation="wave"
                            height={73}
                            width={'100%'}
                            sx={{ margin: 2 }}
                          />
                          <Skeleton
                            variant="rectangular"
                            animation="wave"
                            height={73}
                            width={'100%'}
                            sx={{ margin: 2 }}
                          />
                        </>
                      ) : null}

                      {loading && appointmentsList.length > 0 ? (
                        <>
                          <span>{t('actions:loading')}</span>
                          <Spinner />
                        </>
                      ) : null}
                    </Box>
                  </div>
                </TableCell>
              </TableRow>
            )}

            {/* empty state */}
            {!loading && appointmentsList.length === 0 && (
              <TableRow>
                <TableCell colSpan={42}>
                  <EmptyState icon={CalendarX} message="No appointments matched your search and/or filters." />
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      <AppointmentPreviewModal open={modalState.open} id={modalState.id} onClose={handleClose} />
    </>
  )
}

export default memo(AppointmentTable)
