import { FC } from 'react'

import Tokens from 'config/tokens'

import { Box } from '@mui/system'

const inlineStyle = {
  flexDirection: 'row',
  rowGap: 2,
  padding: `calc(${Tokens.rhythm} * 1.5) 0`,
  '& .label': {
    color: Tokens.color.neutral.grey[102],
    flexBasis: `40%`,
    '&.warning': {
      color: Tokens.color.brand.red.base,
      fontWeight: 600,
    },
  },
  '& .children': {
    flexBasis: '60%',
    paddingLeft: Tokens.rhythm,
    '&.warning': {
      color: Tokens.color.brand.red.base,
      fontWeight: 600,
    },
  },
} as const

const notInlineStyle = {
  flexDirection: 'column',
  '& .label': {
    paddingBottom: `calc(${Tokens.rhythm} * 2)`,
    fontWeight: Tokens.font.weight.bold,
  },
} as const

export type LabeledItemProps = {
  label: string
  labelAction?: React.ReactNode
  children: React.ReactNode
  inline?: boolean
  // display key as red
  warning?: boolean
  // due to our inconsistent design, we have a case where the value should be displayed as a warning,
  // so here we are
  valueWarning?: boolean
}

const LabeledItem: FC<LabeledItemProps> = ({
  label,
  labelAction,
  children,
  inline = false,
  warning = false,
  valueWarning = false,
}) => (
  <Box display="flex" sx={inline ? inlineStyle : notInlineStyle}>
    <div className={['label', warning && 'warning'].filter(Boolean).join(' ')}>
      {label}
      {labelAction}
    </div>

    <div
      className={['children', valueWarning && 'warning'].filter(Boolean).join(' ')}
      style={{ overflowWrap: 'break-word', wordBreak: 'break-word' }}
    >
      {children}
    </div>
  </Box>
)

export default LabeledItem
