import { DateTime } from 'luxon'
import { Trans, useTranslation } from 'react-i18next'

import makeStyles from '@mui/styles/makeStyles'

const useStyles = makeStyles({
  // avoid wrapping text within time format
  time: {
    whiteSpace: 'nowrap',
  },
})

export type FormattedDateProps = {
  dateTime?: DateTime | string | null

  // use a formatting preset directly
  // see: https://moment.github.io/luxon/#/formatting
  formatPreset?: string

  // or alternatively pick date options
  splitLine?: boolean
  withTime?: boolean
  withDayOfWeek?: boolean
}

const FormattedDate = ({
  dateTime,

  formatPreset,

  splitLine = false,
  withTime = true,
  withDayOfWeek = true,
}: FormattedDateProps): JSX.Element => {
  const classes = useStyles()
  const { t } = useTranslation()

  if (dateTime == null) {
    return <>{t('common:emptyValue')}</>
  }

  const displayDateTime = dateTime instanceof DateTime ? dateTime : DateTime.fromISO(dateTime)

  let i18nKey
  if (formatPreset) {
    i18nKey = [`common:formattedDate.formatPreset_${formatPreset}`, 'common:formattedDate.simple']
  } else if (withTime) {
    i18nKey = [`common:formattedDate.withTime.${splitLine ? 'splitLine' : 'singleLine'}`, 'common:formattedDate.simple']
  } else if (!withDayOfWeek) {
    i18nKey = 'common:formattedDate.short'
  } else {
    i18nKey = 'common:formattedDate.simple'
  }

  return (
    <span title={t('common:formattedDate.tooltip', { dateTime: displayDateTime.toLocal() })}>
      <Trans
        t={t}
        i18nKey={i18nKey}
        values={{ dateTime: displayDateTime }}
        // this is an example of passing a child with default value, however, self-closing should be the preferred option
        // -> { time: <span /> }
        components={{ time: <span className={classes.time}> time </span> }}
      />
    </span>
  )
}

FormattedDate.defaultProps = {
  splitLine: false,
}

export default FormattedDate
