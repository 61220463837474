import { FC, useCallback } from 'react'

import { Check, ClipboardText } from 'phosphor-react'
import { useTranslation, Trans } from 'react-i18next'
import { useCopyToClipboard, useToggle } from 'react-use'

import { Grid, IconButton, InputAdornment, TextField, useTheme } from '@mui/material'
import Button from '@mui/material/Button'
import Box from '@mui/system/Box'

import Modal from 'ui/Modal'

type CreateVisitInHealthCallModalProps = {
  open: boolean
  appointmentId?: number
  onClose: () => void
}

const CreateVisitInHealthCallModal: FC<CreateVisitInHealthCallModalProps> = ({ open, appointmentId, onClose }) => {
  const { t } = useTranslation()
  const [showSuccess, setShowSuccess] = useToggle(false)
  const [, copyToClipboard] = useCopyToClipboard()
  const theme = useTheme()

  const handleCopyAppointmentIdToClipboard = useCallback(() => {
    copyToClipboard(String(appointmentId))
    setShowSuccess(true)
  }, [appointmentId, useCopyToClipboard])

  const handleResetIcon = useCallback(() => {
    setShowSuccess(false)
  }, [setShowSuccess])

  const handleInputFocus = useCallback(
    (event) => {
      if (event?.target?.select) event.target.select()
    },
    [setShowSuccess],
  )

  return (
    <Modal
      open={open}
      title={t('components:modules.Appointment.CreateVisitInHealthCallModal.heading')}
      actions={<Button onClick={onClose}>{t('common:okay')}</Button>}
    >
      <Box style={{ paddingTop: '1em', paddingBottom: '1em' }}>
        <Trans t={t} i18nKey="components:modules.Appointment.CreateVisitInHealthCallModal.body" />
      </Box>
      {appointmentId && (
        <>
          <Grid container direction="column" alignItems="center" style={{ paddingTop: '1em', paddingBottom: '1em' }}>
            <Grid item>
              <strong>{t('glossary:appointment.id')}</strong>
            </Grid>
            <Grid item>
              <TextField
                value={appointmentId}
                inputProps={{
                  size: String(appointmentId).length,
                }}
                InputProps={{
                  onClick: handleInputFocus,
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={handleCopyAppointmentIdToClipboard}
                        onBlur={handleResetIcon}
                        edge="end"
                        size="small"
                      >
                        {showSuccess ? (
                          <Check color={theme.palette.success.main} size={22} />
                        ) : (
                          <ClipboardText size={22} />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          </Grid>
        </>
      )}
    </Modal>
  )
}

export default CreateVisitInHealthCallModal
