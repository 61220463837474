import { ReactNode } from 'react'

import Tokens from 'config/tokens'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import Button from '@mui/material/Button'

export const StyledSection = styled.div`
  background-color: ${Tokens.color.neutral.white.base};
  font-family: ${Tokens.font.family};
  font-size: ${Tokens.font.size.paragraph.base};
  line-height: ${Tokens.lineHeight.paragraph.base};
  .bodyContainer {
    padding: calc(${Tokens.rhythm} * 2);
  }
  .body {
    &.editing {
      padding: calc(${Tokens.rhythm} * 2);
      background-color: ${Tokens.color.neutral.grey[250]};
    }
  }
  button {
    :hover {
      text-decoration: none;
    }
  }
  .deleteButton {
    color: ${Tokens.color.ui.error.base};
  }
  .heading {
    display: flex;
    justify-content: space-between;
    border-bottom: ${Tokens.border.size} solid ${Tokens.color.neutral.black.transparent[10]};
    padding: 0 calc(${Tokens.rhythm} * 2);
    align-items: center;
    p {
      font-weight: ${Tokens.font.weight.semiBold};
    }
  }
  .buttonContainer {
    display: flex;
    justify-content: flex-end;
    padding-top: calc(${Tokens.rhythm} * 4);
    > * + * {
      margin-left: calc(${Tokens.rhythm} * 4);
    }
  }
`

export type CardLabeledProps = {
  children: React.ReactNode

  title: ReactNode

  /**
   * enter Edit mode
   */
  editText?: string
  editButtonTitle?: string // title attribute for the edit button
  editing?: boolean
  isEditable?: boolean
  onEdit?: () => void

  /**
   * actions when in Edit mode
   */
  cancelText?: string
  acceptText?: ReactNode
  acceptDisabled?: boolean
  cancelDisabled?: boolean
  onCancel?: () => void
  onAccept?: () => void

  /**
   * show Delete button when in Edit mode
   */
  deleteText?: string
  isDeletable?: boolean
  deleteDisabled?: boolean
  onDelete?: () => void
  testId?: string
}

function CardLabeled({
  title,
  editButtonTitle,
  editing = false,
  children,
  editText,
  deleteText,
  onEdit,
  cancelText,
  onCancel,
  acceptText,
  onAccept,
  acceptDisabled = false,
  cancelDisabled = false,
  isEditable = true,
  isDeletable = false,
  deleteDisabled = false,
  onDelete,
  testId,
}: CardLabeledProps): JSX.Element {
  const { t } = useTranslation()
  const contentClassNames = ['body', editing ? 'editing' : ''].join(' ')

  return (
    <StyledSection {...(testId && { 'data-testid': `Card-${testId}` })}>
      <div className="heading">
        <p {...(testId && { 'data-testid': `Card-Title-${testId}` })}>{title}</p>
        {!editing && onEdit && (
          <Button
            variant="text"
            color="info"
            onClick={onEdit}
            disabled={!isEditable}
            title={editButtonTitle ?? t('actions:edit')}
            {...(testId && { 'data-testid': `Card-EditButton-${testId}` })}
          >
            {editText ?? t('actions:edit')}
          </Button>
        )}
        {editing && isDeletable && onDelete && (
          <Button
            className="deleteButton"
            variant="text"
            onClick={onDelete}
            disabled={deleteDisabled}
            title={t('actions:deleteByTitle', { title })}
            {...(testId && { 'data-testid': `Card-DeleteButton-${testId}` })}
          >
            {deleteText ?? t('actions:delete')}
          </Button>
        )}
      </div>
      <div className="bodyContainer">
        <div className={contentClassNames}>
          <div className="content">{children}</div>
          {editing && (onCancel || onAccept) && (
            <div className="buttonContainer">
              {onCancel && (
                <Button
                  variant="outlined"
                  color="secondary"
                  className="cancelButton"
                  onClick={onCancel}
                  disabled={cancelDisabled}
                  {...(testId && { 'data-testid': `Card-CancelButton-${testId}` })}
                >
                  {cancelText ?? t('actions:cancel')}
                </Button>
              )}
              {onAccept && (
                <Button
                  className="saveButton"
                  onClick={onAccept}
                  type="submit"
                  disabled={acceptDisabled}
                  {...(testId && { 'data-testid': `Card-SaveButton-${testId}` })}
                >
                  {acceptText ?? t('actions:save')}
                </Button>
              )}
            </div>
          )}
        </div>
      </div>
    </StyledSection>
  )
}

export default CardLabeled
