import TextField, { OutlinedTextFieldProps } from '@mui/material/TextField'

export type EmailInputProps = Partial<OutlinedTextFieldProps>

const EmailInput = ({ value, ...props }: EmailInputProps): JSX.Element => (
  <TextField
    id="email"
    name="email"
    fullWidth
    {...props} // Options above this line can be overwritten
    label="Email Address (Optional)"
    value={value ?? ''}
  />
)

export default EmailInput
