import { FC, useCallback, useMemo, useState } from 'react'

import { NetworkStatus } from '@apollo/client'
import Tokens from 'config/tokens'
import {
  useGetPatientNotesQuery,
  PatientNoteFragment,
  useGetCurrentUserQuery,
  useCreatePatientNoteMutation,
  GetPatientNotesDocument,
  GetAppointmentPreviewDocument,
} from 'generated/graphql'
import { useToggle } from 'react-use'

import { Divider } from '@mui/material'
import Button from '@mui/material/Button'
import Box from '@mui/system/Box'

import { NoteList } from 'ui/Note'
import Spinner from 'ui/Spinner'

import useGetAppointmentQueryContext from '../useGetAppointmentQueryContext'

import AddPatientNoteDialog from './AddPatientNoteDialog'
import { selectSortedExistingPatientNotes } from './selectors'

const PatientNotesListSpinner: FC<unknown> = () => (
  <Box width={1} my={8} display="flex" justifyContent="center">
    <Spinner />
  </Box>
)

const PatientNotesListEmptyState: FC<unknown> = () => (
  <Box component="p" my={8} textAlign="center" color={Tokens.color.neutral.grey[134]}>
    (There are no notes on this patient, yet.)
  </Box>
)

const PatientNotesListContainer: FC<{
  loading: boolean
  notes?: PatientNoteFragment[] | null
}> = ({ loading, notes }) => {
  if (loading) return <PatientNotesListSpinner />
  if (!notes?.length) return <PatientNotesListEmptyState />
  return <NoteList notes={notes} isDeletable={true} />
}

const PatientNotes: FC<{ patientId?: number }> = ({ patientId }) => {
  const [isSafetyNote, setIsSafetyNote] = useState(false)
  const [showAddNoteModal, toggleShowAddNoteModal] = useToggle(false)
  const [createPatientNote, { loading: loadingCreatePatientNote }] = useCreatePatientNoteMutation()
  const { id: appointmentId } = useGetAppointmentQueryContext()
  const { data: currentUser, loading: loadingCurrentUser } = useGetCurrentUserQuery()

  const openNoteModal = () => {
    setIsSafetyNote(false)
    toggleShowAddNoteModal()
  }

  const openSafetyModal = () => {
    setIsSafetyNote(true)
    toggleShowAddNoteModal()
  }

  const {
    data,
    loading: loadingGetPatientNote,
    networkStatus,
  } = useGetPatientNotesQuery({
    variables:
      patientId == null
        ? undefined
        : {
            patientId,
          },
    skip: patientId == null,
    notifyOnNetworkStatusChange: true,
  })
  const notes = useMemo(() => data && selectSortedExistingPatientNotes(data), [data])
  const isFetching = useMemo(
    () =>
      loadingGetPatientNote ||
      loadingCreatePatientNote ||
      loadingCurrentUser ||
      networkStatus === NetworkStatus.refetch,
    [loadingGetPatientNote, loadingCreatePatientNote, loadingCurrentUser, networkStatus],
  )

  const createNewPatientNote = useCallback(
    async ({ note, isHighPriority }) => {
      if (!(patientId && currentUser?.getCurrentUser?.userId)) return null

      return createPatientNote({
        variables: {
          patientNote: {
            patientId,
            // createdBy: currentUser.getCurrentUser.userId,
            note,
            isHighPriority,
          },
        },
        refetchQueries: [
          { query: GetPatientNotesDocument, variables: { patientId } },
          // refetch preview as well if we aren't creating a new appointment
          // appointmentId should be `undefined` in isCreateMode
          ...(appointmentId == null ? [] : [{ query: GetAppointmentPreviewDocument, variables: { appointmentId } }]),
        ],
      })
    },
    [patientId, currentUser],
  )

  if (patientId == null) {
    return null
  }

  return (
    <>
      <article>
        <div>
          <Box
            component="div"
            my={0}
            py={0}
            px={1}
            fontWeight={600}
            display="flex"
            alignItems={'center'}
            justifyContent="space-between"
          >
            <Box component="p" my={'14px'}>
              Patient Notes
            </Box>
            {!isFetching && (
              <Box display="flex" justifyContent="center" gap="0.5rem">
                <Button variant="text" color="info" onClick={openNoteModal}>
                  Add Note
                </Button>
                <Button variant="text" color="error" onClick={openSafetyModal}>
                  Add Safety Notification
                </Button>
              </Box>
            )}
          </Box>
          <Divider />
        </div>
        <Box mt={4}>
          <PatientNotesListContainer loading={isFetching} notes={notes} />
        </Box>
      </article>
      <AddPatientNoteDialog
        open={showAddNoteModal}
        onClose={() => toggleShowAddNoteModal(false)}
        onSubmit={createNewPatientNote}
        loading={loadingCreatePatientNote}
        isHighPriority={isSafetyNote}
      />
    </>
  )
}

export default PatientNotes
