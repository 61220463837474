import { User, Patient } from 'generated/graphql'

import { FullNameList } from 'ui/TextFormatters/NameList/NameList'

type UserIdentity = Pick<User, 'firstName' | 'lastName' | 'userId' | 'fullName'>
type PartialDispatch = {
  responder?: {
    user?: Partial<UserIdentity> | null
  } | null
  dispatchedAt?: string | null
  removedAt?: string | null
}

export const responderNamesFromDispatches = (dispatches: PartialDispatch[]): FullNameList =>
  dispatches
    .filter(({ dispatchedAt, removedAt }) => dispatchedAt != null && removedAt == null)
    .map((dispatch) => ({
      id: dispatch?.responder?.user?.userId,
      firstName: dispatch?.responder?.user?.firstName ?? '',
      lastName: dispatch?.responder?.user?.lastName ?? '',
      fullName: dispatch?.responder?.user?.fullName ?? '',
    }))

type PatientIdentity = Pick<Patient, 'firstName' | 'lastName' | 'patientId'>
type PartialEncounter = {
  patient?: Partial<PatientIdentity> | null
}

export const patientNamesFromEncounters = (encounters: PartialEncounter[]): FullNameList =>
  encounters.map((encounter) => {
    const { patient } = encounter
    const patientId = patient?.patientId
    const firstName = patient?.firstName ?? ''
    const lastName = patient?.lastName ?? ''
    return { firstName, lastName, id: patientId }
  })
