import { withAuthenticationRequired } from '@auth0/auth0-react'

type LoginWithRedirectProps = {
  children: React.ReactNode
}

const LoginWithRedirect = withAuthenticationRequired(
  ({ children }: Readonly<LoginWithRedirectProps>): JSX.Element => <>{children}</>,
)

export default LoginWithRedirect
