/* eslint-disable no-param-reassign */
import { FC, useCallback, useEffect, useMemo, useState } from 'react'

import parse from 'autosuggest-highlight/parse'
import debounce from 'lodash/debounce'
import { createAddressLine } from 'modules/Patient/selectors'
import { AddressValues } from 'types/AddressValues'

import LocationOnIcon from '@mui/icons-material/LocationOn'
import Autocomplete from '@mui/material/Autocomplete'
import Grid from '@mui/material/Grid'
import TextField, { TextFieldProps } from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import makeStyles from '@mui/styles/makeStyles'

import useGoogleMapsApiContext, {
  selectGeocoderAddressValues,
  selectGeoLocation,
} from './AppointmentAddress/useGoogleMapsApiContext'

const useStyles = makeStyles((theme) => ({
  icon: {
    color: theme.palette.text.secondary,
    marginRight: theme.spacing(2),
  },
}))

type PlaceType = google.maps.places.AutocompletePrediction

type PlaceTypePartial = Partial<PlaceType>

type PlaceTypeEither = PlaceType | PlaceTypePartial

type GoogleMapsAutoCompleteProps = {
  setAddressValue: (addressValueObject: Partial<AddressValues>) => void
  clearAddressValue: () => void
  address: {
    address1: string | null
    address2: string | null
    city: string | null
    state: string | null
    zip: string | null
  }
} & Pick<TextFieldProps, 'error' | 'helperText'>

const GoogleMapsAutoComplete: FC<GoogleMapsAutoCompleteProps> = ({
  setAddressValue,
  clearAddressValue,
  address,
  error,
  helperText,
}) => {
  const classes = useStyles()
  const [inputValue, setInputValue] = useState('')
  const [options, setOptions] = useState<PlaceType[]>([])
  const { autocompleteService, fetchGeocodeComponents } = useGoogleMapsApiContext()
  const { address1, address2, city, state, zip } = address

  const fetch = useMemo(
    () =>
      autocompleteService
        ? debounce(
            (request: google.maps.places.AutocompletionRequest, callback) => {
              autocompleteService.getPlacePredictions(request, callback)
            },
            500,
            { maxWait: 3000 },
          )
        : null,
    [autocompleteService],
  )

  useEffect(() => {
    let active = true

    if (inputValue !== '' && fetch) {
      fetch({ input: inputValue }, (results?: PlaceType[]) => {
        if (active) {
          let newOptions = [] as PlaceType[]

          if (results) {
            newOptions = [...newOptions, ...results]
          }
          setOptions(newOptions)
        }
      })
    }

    return () => {
      active = false
    }
  }, [inputValue, fetch])

  const handlePlaceSelected = useCallback(
    async (placeId: string | null) => {
      if (placeId === null) {
        clearAddressValue()
        return
      }

      // useless typeguard, should not occur because we aren't rendering component if it doesn't exist
      if (!fetchGeocodeComponents) return

      const { geocoderResult } = await fetchGeocodeComponents({ placeId })

      const addressValues = selectGeocoderAddressValues(geocoderResult)
      const geoLocation = selectGeoLocation(geocoderResult)

      setAddressValue({ ...addressValues, ...geoLocation })
    },
    [fetchGeocodeComponents],
  )

  const formatAddressForDefaultValue = createAddressLine({ address1, address2, city, state, zip })

  if (!fetchGeocodeComponents) return null

  return (
    <Autocomplete
      id="google-map-demo"
      key={`google-map-demo ${formatAddressForDefaultValue}`}
      getOptionLabel={(option) => (typeof option === 'string' ? option : option.description)}
      filterOptions={(x) => x}
      options={options}
      autoComplete
      includeInputInList
      defaultValue={{ description: formatAddressForDefaultValue } as PlaceType}
      filterSelectedOptions
      onChange={(event, newValue: PlaceTypeEither | null): void => {
        handlePlaceSelected(newValue?.place_id ?? null)
      }}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue)
      }}
      renderInput={(params) => (
        <TextField {...params} error={error} helperText={helperText} label="Address" variant="outlined" fullWidth />
      )}
      renderOption={(props, option) => {
        if (!option.structured_formatting || !option.structured_formatting.main_text_matched_substrings) {
          return (
            <li {...props} key={option.description}>
              {option.description}
            </li>
          )
        }
        // highlight parts of the address that were in the user input,
        // seems that if there is no user input, this prop won't exist, the typing from google suggests otherwise
        const matches = option.structured_formatting?.main_text_matched_substrings
        const parts = parse(
          option.structured_formatting.main_text,
          matches.map((match) => [match.offset, match.offset + match.length]),
        )

        return (
          <li {...props} key={option.description}>
            <Grid container alignItems="center">
              <Grid item>
                <LocationOnIcon className={classes.icon} />
              </Grid>

              <Grid item xs>
                {parts.map((part, index) => (
                  <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
                    {part.text}
                  </span>
                ))}

                <Typography variant="body2" color="textSecondary">
                  {option.structured_formatting.secondary_text}
                </Typography>
              </Grid>
            </Grid>
          </li>
        )
      }}
    />
  )
}

export default GoogleMapsAutoComplete
