import type { DateTime } from 'luxon'

import TextField, { OutlinedTextFieldProps } from '@mui/material/TextField'

export type DatePickerProps = Partial<Omit<OutlinedTextFieldProps, 'value'>> & {
  value: DateTime
}

const DatePicker = ({ value, ...props }: DatePickerProps): JSX.Element => (
  <TextField
    fullWidth
    id="date"
    label="Select Date"
    {...props}
    value={value.toFormat('yyyy-MM-dd')}
    onChange={(event) => {
      // We can ignore the leading 0 when typing 09 for day & month
      // When only the 0 of the 09 is typed, the change event doesn't return a date it's an empty string
      if (event.target.value !== '') props?.onChange?.(event)
    }}
    type="date"
  />
)
export default DatePicker
