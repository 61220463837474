import { FC } from 'react'

import isNilOrEmpty from 'fp/isNilOrEmpty'
import { useGetChannelAttributionsByMarketQuery } from 'generated/graphql'
import { sortBy } from 'lodash'
import AppointmentCreateFormSchema from 'modules/Appointments/Create/AppointmentCreateFormSchema'
import useIsCreateModeContext from 'modules/Appointments/useIsCreateModeContext'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import ControlledField from 'utils/ControlledField/ControlledField'

import MenuItem from '@mui/material/MenuItem'
import TextField, { OutlinedTextFieldProps } from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import Box from '@mui/system/Box'

import HiddenInput from 'ui/Inputs/HiddenInput'

export type AppointmentPartnershipEditProps = Partial<Omit<OutlinedTextFieldProps, 'onChange' | 'value'>> & {
  value?: number | null
  onChange: (newValue: number | null) => void
  marketId: number
}

const VALUE_NONE = '__none__'

export const ChannelAttributionsSelect = ({
  onChange,
  value,
  marketId,
  fullWidth,
  ...textFieldProps
}: AppointmentPartnershipEditProps): JSX.Element => {
  const { t } = useTranslation()
  const { data } = useGetChannelAttributionsByMarketQuery({
    variables: { marketId },
    skip: !marketId || marketId === -1,
  })
  const sortedChannelAttributionsList = sortBy(data?.channelAttributionsList, 'channel')

  const handleOnChange = (event: React.ChangeEvent<{ value: string }>) => {
    const { value: eventValue } = event.target
    return onChange(eventValue !== VALUE_NONE ? parseFloat(eventValue) : null)
  }

  // TODO: SKED-535 assert that value exists
  // TODO: SKED-535 we need to fetch all attribution channels seperately, so that we can assert that the given validation exists,
  // and add it as MenuItem list so MUI doesn't complain
  const existingValue = value ?? VALUE_NONE

  // inputRef causes a bug in RHF where it calls .focus() on `null` element ref
  const { inputRef, ...remainingTextFieldProps } = textFieldProps

  return (
    <div>
      <TextField
        fullWidth
        name="partnershipAttribution"
        {...remainingTextFieldProps}
        select
        value={existingValue}
        label={t('components:modules.Patient.ChannelAttribution.label')}
        disabled={!(marketId && data)}
        onChange={handleOnChange}
      >
        <MenuItem value={VALUE_NONE} style={{ display: 'none' }}>
          {t('common:noneValue')}
        </MenuItem>

        {sortedChannelAttributionsList.map((item) => (
          <MenuItem key={item.channelAttributionId} value={item.channelAttributionId}>
            {item.channel}
          </MenuItem>
        ))}
      </TextField>
    </div>
  )
}

const AppointmentPartnershipEdit: FC<unknown> = () => {
  const isCreateMode = useIsCreateModeContext()
  // useWatch doesn't seem to sync correctly when creating a new patient
  const { watch } = useFormContext<AppointmentCreateFormSchema>()
  const [patients, marketId] = watch<['patients', 'marketId']>(['patients', 'marketId'])

  if (isNilOrEmpty(patients)) return null

  if (isCreateMode) {
    const [, ...otherPatients] = patients

    return (
      <Box>
        <ControlledField
          name={'patients.0.channelAttributionId' as const}
          Component={ChannelAttributionsSelect}
          ComponentProps={{ fullWidth: true, marketId }}
        />
        {/*
          we only use the first patient's channelAttributionId and assign that to all patients,
          however for yup validation we require the other patients to have a value for channelAttributionId
         */}
        {otherPatients.map((patient, key) => (
          <ControlledField
            key={key}
            // key+1 as we sliced the first element out of this array
            name={`patients.${key + 1}.channelAttributionId` as const}
            Component={HiddenInput}
            defaultValue={-1}
          />
        ))}
      </Box>
    )
  }

  return (
    <>
      {patients.map((field, key) => (
        <Box key={field.patientId}>
          <Typography>
            {field.firstName} {field.lastName}
          </Typography>
          <ControlledField
            name={`patients.${key}.channelAttributionId`}
            Component={ChannelAttributionsSelect}
            ComponentProps={{ fullWidth: true, marketId }}
          />
        </Box>
      ))}
    </>
  )
}

export default AppointmentPartnershipEdit
