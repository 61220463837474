export type InsurancePlan = {
  packageId: number
  company: string
}

const insurancePlans: Array<InsurancePlan> = [
  {
    company: '1199SEIU Benefit and Pension Funds',
    packageId: 981,
  },
  {
    company: '1199SEIU Benefit and Pension Funds - Aetna (PPO)',
    packageId: 108757,
  },
  {
    company: '1199SEIU Benefit and Pension Funds (PPO)',
    packageId: 49875,
  },
  {
    company: 'AARP',
    packageId: 58664,
  },
  {
    company: 'AARP (Medicare Supplement)',
    packageId: 1910,
  },
  {
    company: 'Access Health (HMO)',
    packageId: 159184,
  },
  {
    company: 'Administrative Concepts',
    packageId: 4901,
  },
  {
    company: 'Administrative Concepts - First Health (PPO)',
    packageId: 249343,
  },
  {
    company: 'Administrative Concepts - MultiPlan (PPO)',
    packageId: 38430,
  },
  {
    company: 'Aetna',
    packageId: 1352,
  },
  {
    company: 'Aetna - Beech Street (EPO)',
    packageId: 128341,
  },
  {
    company: 'Aetna - Beech Street (POS II)',
    packageId: 109575,
  },
  {
    company: 'Aetna - Dual Complete (Medicare Replacement/Advantage - HMO)',
    packageId: 587584,
  },
  {
    company: 'Aetna - HTH Worldwide (PPO)',
    packageId: 66640,
  },
  {
    company: 'Aetna - Innovation Health (POS)',
    packageId: 278385,
  },
  {
    company: 'Aetna - International Claims Services (PPO)',
    packageId: 286535,
  },
  {
    company: 'Aetna - Mail Handlers Benefit Plan (POS II)',
    packageId: 476168,
  },
  {
    company: 'Aetna - Medical Mutual (PPO)',
    packageId: 529705,
  },
  {
    company: 'Aetna - Prime (Medicare Replacement/Advantage - HMO)',
    packageId: 302975,
  },
  {
    company: 'Aetna - Prudential',
    packageId: 9,
  },
  {
    company: 'Aetna (EPO)',
    packageId: 3150,
  },
  {
    company: 'Aetna (HMO)',
    packageId: 37821,
  },
  {
    company: 'Aetna (Indemnity)',
    packageId: 22055,
  },
  {
    company: 'Aetna (Medicare Replacement/Advantage - HMO)',
    packageId: 38982,
  },
  {
    company: 'Aetna (Medicare Replacement/Advantage - PPO)',
    packageId: 74229,
  },
  {
    company: 'Aetna (POS II)',
    packageId: 38684,
  },
  {
    company: 'Aetna (POS)',
    packageId: 16991,
  },
  {
    company: 'Aetna (PPO)',
    packageId: 6293,
  },
  {
    company: 'Aetna Better Health of FL (Medicaid Replacement - HMO)',
    packageId: 412369,
  },
  {
    company: 'Aetna Better Health of LA (Medicaid Replacement - HMO)',
    packageId: 299540,
  },
  {
    company: 'Aetna Better Health of MD (Medicaid Replacement - HMO)',
    packageId: 467223,
  },
  {
    company: 'Aetna Better Health of VA (Medicaid Replacement - HMO)',
    packageId: 31360,
  },
  {
    company: 'Aetna Signature Administrators - GEHA - GEHA (PPO)',
    packageId: 295937,
  },
  {
    company: 'Aetna Student Health (PPO)',
    packageId: 122002,
  },
  {
    company: 'Aetna Voluntary (Indemnity)',
    packageId: 123648,
  },
  {
    company: 'Affinity Health Plan',
    packageId: 10886,
  },
  {
    company: 'Affinity Health Plan (HMO)',
    packageId: 41363,
  },
  {
    company: 'Affinity Health Plan (Medicaid Replacement - HMO)',
    packageId: 37702,
  },
  {
    company: 'Affordable Benefit Administrators (PPO)',
    packageId: 106470,
  },
  {
    company: 'Aflac',
    packageId: 116614,
  },
  {
    company: 'AFSA - Tricare (TRICARE Supplement)',
    packageId: 148224,
  },
  {
    company: 'AG Administrators',
    packageId: 20086,
  },
  {
    company: 'AIG',
    packageId: 112011,
  },
  {
    company: 'AIG - Foreign Payer',
    packageId: 336551,
  },
  {
    company: 'Alignment Health Plan - Full Dual Eligible (Medicare Replacement/Advantage - HMO)',
    packageId: 626488,
  },
  {
    company: 'All Savers - United Healthcare (PPO)',
    packageId: 249229,
  },
  {
    company: 'Allegeant - BCBS-MD (PPO)',
    packageId: 152420,
  },
  {
    company: 'Allegiance Benefit Plan Management',
    packageId: 12227,
  },
  {
    company: 'Allegiance Benefit Plan Management - Cigna - Montana Providers Only (PPO)',
    packageId: 538754,
  },
  {
    company: 'Allegiance Benefit Plan Management - Cigna (PPO)',
    packageId: 211513,
  },
  {
    company: 'ALLEGIANCE BENEFIT PLAN MANAGEMENT - WELLPATH',
    packageId: 442977,
  },
  {
    company: 'Allied',
    packageId: 7146,
  },
  {
    company: 'Allied - Aetna Signature Administrators (PPO)',
    packageId: 109670,
  },
  {
    company: 'Allied National',
    packageId: 37966,
  },
  {
    company: 'Allied National - MultiPlan (PPO)',
    packageId: 121833,
  },
  {
    company: 'Allstate',
    packageId: 127903,
  },
  {
    company: 'AllWays Health Partners',
    packageId: 102840,
  },
  {
    company: 'AllWays Health Partners (HMO)',
    packageId: 69450,
  },
  {
    company: 'AllWays Health Partners (PPO)',
    packageId: 526767,
  },
  {
    company: 'AltaMed - Health Net (Medicaid Replacement - HMO)',
    packageId: 23894,
  },
  {
    company: 'AmeriBen - BCBS-AZ',
    packageId: 26523,
  },
  {
    company: 'Amerigroup - Amerigroup Arizona (HMO)',
    packageId: 586416,
  },
  {
    company: 'Amerigroup - Amerigroup Iowa (Medicaid Replacement - HMO)',
    packageId: 356740,
  },
  {
    company: 'Amerigroup - Amerigroup Maryland (Medicaid Replacement - HMO)',
    packageId: 15087,
  },
  {
    company: 'Amerigroup - Amerigroup Maryland (Medicare Replacement/Advantage - HMO)',
    packageId: 81039,
  },
  {
    company: 'Amerigroup - Amerigroup New Jersey (Medicaid Replacement - HMO)',
    packageId: 9095,
  },
  {
    company: 'Amerigroup - Amerigroup South Carolina',
    packageId: 69312,
  },
  {
    company: 'Amerigroup - Amerigroup Texas - Houston-based providers (Medicaid Replacement - HMO)',
    packageId: 15070,
  },
  {
    company: 'Amerigroup - Amerigroup Texas - STAR (Medicaid Replacement - HMO)',
    packageId: 2768,
  },
  {
    company: 'Amerigroup - Amerigroup Washington - ABD (Medicaid Replacement - HMO)',
    packageId: 350663,
  },
  {
    company: 'Amerigroup DC (Medicare Replacement/Advantage - HMO)',
    packageId: 556318,
  },
  {
    company: 'AmeriHealth (EPO)',
    packageId: 337890,
  },
  {
    company: 'AmeriHealth (POS)',
    packageId: 16923,
  },
  {
    company: 'AmeriHealth Administrators (PPO)',
    packageId: 136783,
  },
  {
    company: 'AmeriHealth Caritas FL - CHIP',
    packageId: 323165,
  },
  {
    company: 'AmeriHealth Caritas-LA (Medicaid Replacement - HMO)',
    packageId: 159572,
  },
  {
    company: 'AmeriHealth DC (Medicaid Replacement - HMO)',
    packageId: 35518,
  },
  {
    company: 'AmeriHealth DC (PPO)',
    packageId: 34094,
  },
  {
    company: 'AmeriHealth New Jersey (EPO)',
    packageId: 222581,
  },
  {
    company: 'Amida Care (Medicaid Replacement - HMO)',
    packageId: 287084,
  },
  {
    company: 'Amida Care (Medicare Replacement/Advantage - HMO)',
    packageId: 286995,
  },
  {
    company: 'ASR Health Benefits',
    packageId: 1488,
  },
  {
    company: 'Associated Hispanic Physicians - MedPoint Management - Health Net-CA (Medicaid Replacement - HMO)',
    packageId: 526550,
  },
  {
    company: 'AvMed',
    packageId: 12920,
  },
  {
    company: 'AvMed (Medicare Replacement/Advantage - HMO)',
    packageId: 10011,
  },
  {
    company: 'Axminster Medical Group - Humana - Gold Plus (Medicare Replacement/Advantage - HMO)',
    packageId: 490846,
  },
  {
    company: 'Bankers Life And Casualty Company (Medicare Supplement)',
    packageId: 112,
  },
  {
    company: 'BCBS-AK',
    packageId: 16443,
  },
  {
    company: 'BCBS-AK (EPO)',
    packageId: 566223,
  },
  {
    company: 'BCBS-AL',
    packageId: 1619,
  },
  {
    company: 'BCBS-AL (PPO)',
    packageId: 19996,
  },
  {
    company: 'BCBS-AR',
    packageId: 6259,
  },
  {
    company: 'BCBS-AR - Blue Advantage',
    packageId: 18591,
  },
  {
    company: 'BCBS-AR (PPO)',
    packageId: 25908,
  },
  {
    company: 'BCBS-AZ',
    packageId: 38417,
  },
  {
    company: 'BCBS-AZ - AmeriBen (EPO)',
    packageId: 199140,
  },
  {
    company: 'BCBS-AZ (PPO)',
    packageId: 38340,
  },
  {
    company: 'BCBS-CA Blue Cross of California',
    packageId: 15310,
  },
  {
    company: 'BCBS-CA Blue Cross of California - CalPERS (PPO)',
    packageId: 3156,
  },
  {
    company: 'BCBS-CA Blue Cross of California - Coastal TPA',
    packageId: 9036,
  },
  {
    company: 'BCBS-CA Blue Cross of California - Keenan (PPO)',
    packageId: 136914,
  },
  {
    company: 'BCBS-CA Blue Cross of California (EPO)',
    packageId: 41728,
  },
  {
    company: 'BCBS-CA Blue Cross of California (HMO)',
    packageId: 1389,
  },
  {
    company: 'BCBS-CA Blue Cross of California (Medicaid Replacement - HMO)',
    packageId: 15890,
  },
  {
    company: 'BCBS-CA Blue Cross of California (Medicare Replacement/Advantage - HMO)',
    packageId: 65420,
  },
  {
    company: 'BCBS-CA Blue Cross of California (Medicare Replacement/Advantage - PPO)',
    packageId: 46707,
  },
  {
    company: 'BCBS-CA Blue Cross of California (PPO)',
    packageId: 5512,
  },
  {
    company: 'BCBS-CA Blue Shield of California',
    packageId: 2150,
  },
  {
    company: 'BCBS-CA Blue Shield of California - FEP',
    packageId: 579,
  },
  {
    company: 'BCBS-CA Blue Shield of California - FEP (PPO)',
    packageId: 63373,
  },
  {
    company: 'BCBS-CA BLUE SHIELD OF CALIFORNIA - HEALTHCOMP (PPO)',
    packageId: 210069,
  },
  {
    company: 'BCBS-CA BLUE SHIELD OF CALIFORNIA (HMO)',
    packageId: 19500,
  },
  {
    company: 'BCBS-CA BLUE SHIELD OF CALIFORNIA (MEDICARE REPLACEMENT/ADVANTAGE - HMO)',
    packageId: 3810,
  },
  {
    company: 'BCBS-CA Blue Shield of California (POS)',
    packageId: 6489,
  },
  {
    company: 'BCBS-CA Blue Shield of California (PPO)',
    packageId: 52689,
  },
  {
    company: 'BCBS-CO',
    packageId: 12059,
  },
  {
    company: 'BCBS-CO - FEP',
    packageId: 42862,
  },
  {
    company: 'BCBS-CO (HMO)',
    packageId: 19224,
  },
  {
    company: 'BCBS-CO (Indemnity)',
    packageId: 10579,
  },
  {
    company: 'BCBS-CO (Medicare Replacement/Advantage - HMO)',
    packageId: 113953,
  },
  {
    company: 'BCBS-CO (PPO)',
    packageId: 57919,
  },
  {
    company: 'BCBS-CT',
    packageId: 2559,
  },
  {
    company: 'BCBS-CT (EPO)',
    packageId: 548504,
  },
  {
    company: 'BCBS-CT (HMO)',
    packageId: 12092,
  },
  {
    company: 'BCBS-CT (Medicare Replacement/Advantage - HMO)',
    packageId: 58094,
  },
  {
    company: 'BCBS-CT (PPO)',
    packageId: 24687,
  },
  {
    company: 'BCBS-DC',
    packageId: 103735,
  },
  {
    company: 'BCBS-DC - BCBS-MD - FEP (PPO)',
    packageId: 93133,
  },
  {
    company: 'BCBS-DC - BlueChoice (HMO)',
    packageId: 499678,
  },
  {
    company: 'BCBS-DC - FEP',
    packageId: 32186,
  },
  {
    company: 'BCBS-DC (HMO)',
    packageId: 32183,
  },
  {
    company: 'BCBS-DC (POS)',
    packageId: 57968,
  },
  {
    company: 'BCBS-DC (PPO)',
    packageId: 34749,
  },
  {
    company: 'BCBS-DE',
    packageId: 2889,
  },
  {
    company: 'BCBS-DE (PPO)',
    packageId: 33844,
  },
  {
    company: 'BCBS-FL',
    packageId: 80900,
  },
  {
    company: 'BCBS-FL - BlueOptions (PPO)',
    packageId: 54306,
  },
  {
    company: 'BCBS-FL - BlueSelect (PPO)',
    packageId: 155033,
  },
  {
    company: 'BCBS-FL - FEP (PPO)',
    packageId: 12810,
  },
  {
    company: 'BCBS-FL - MyBlue (HMO)',
    packageId: 344739,
  },
  {
    company: 'BCBS-FL - Network Blue (PPO)',
    packageId: 146737,
  },
  {
    company: 'BCBS-FL (HMO)',
    packageId: 73939,
  },
  {
    company: 'BCBS-FL (PPO)',
    packageId: 37452,
  },
  {
    company: 'BCBS-GA',
    packageId: 76917,
  },
  {
    company: 'BCBS-GA - AmeriBen (PPO)',
    packageId: 537304,
  },
  {
    company: 'BCBS-GA (EPO)',
    packageId: 62930,
  },
  {
    company: 'BCBS-GA (HMO)',
    packageId: 15357,
  },
  {
    company: 'BCBS-GA (Medicare Supplement)',
    packageId: 55338,
  },
  {
    company: 'BCBS-GA (POS)',
    packageId: 55796,
  },
  {
    company: 'BCBS-GA (PPO)',
    packageId: 61596,
  },
  {
    company: 'BCBS-HI',
    packageId: 4210,
  },
  {
    company: 'BCBS-HI - ABD (Medicaid Replacement - HMO)',
    packageId: 346307,
  },
  {
    company: 'BCBS-HI (Medicaid Replacement - HMO)',
    packageId: 149192,
  },
  {
    company: 'BCBS-HI (Medicare Replacement/Advantage - PPO)',
    packageId: 149663,
  },
  {
    company: 'BCBS-HI (PPO)',
    packageId: 10374,
  },
  {
    company: 'BCBS-ID Blue Cross',
    packageId: 7184,
  },
  {
    company: 'BCBS-ID Blue Cross - FEP',
    packageId: 75420,
  },
  {
    company: 'BCBS-ID Blue Cross (Medicare Replacement/Advantage - HMO)',
    packageId: 22568,
  },
  {
    company: 'BCBS-ID Blue Cross (POS)',
    packageId: 92854,
  },
  {
    company: 'BCBS-ID Blue Cross (PPO)',
    packageId: 53033,
  },
  {
    company: 'BCBS-ID Blue Shield',
    packageId: 10223,
  },
  {
    company: 'BCBS-ID Blue Shield - FEP',
    packageId: 75419,
  },
  {
    company: 'BCBS-ID Blue Shield (PPO)',
    packageId: 85083,
  },
  {
    company: 'BCBS-IL',
    packageId: 556,
  },
  {
    company: 'BCBS-IL - Central States Health and Welfare Fund (PPO)',
    packageId: 41965,
  },
  {
    company: 'BCBS-IL - FEP (PPO)',
    packageId: 24253,
  },
  {
    company: 'BCBS-IL (EPO)',
    packageId: 21832,
  },
  {
    company: 'BCBS-IL (HMO)',
    packageId: 20762,
  },
  {
    company: 'BCBS-IL (POS)',
    packageId: 50188,
  },
  {
    company: 'BCBS-IL (PPO)',
    packageId: 21719,
  },
  {
    company: 'BCBS-IN (PPO)',
    packageId: 19002,
  },
  {
    company: 'BCBS-Kansas City (PPO)',
    packageId: 15754,
  },
  {
    company: 'BCBS-KY',
    packageId: 85784,
  },
  {
    company: 'BCBS-KY (HMO)',
    packageId: 25200,
  },
  {
    company: 'BCBS-KY (PPO)',
    packageId: 25198,
  },
  {
    company: 'BCBS-LA',
    packageId: 2160,
  },
  {
    company: 'BCBS-LA - DOS on or after 1/1/2021 (Medicare Replacement/Advantage - PPO)',
    packageId: 622396,
  },
  {
    company: 'BCBS-LA - DOS prior to 1/1/2021 (Medicare Replacement/Advantage - HMO)',
    packageId: 526664,
  },
  {
    company: 'BCBS-LA - FEP',
    packageId: 2339,
  },
  {
    company: 'BCBS-LA - FEP (PPO)',
    packageId: 90764,
  },
  {
    company: 'BCBS-LA (EPO)',
    packageId: 484617,
  },
  {
    company: 'BCBS-LA (HMO)',
    packageId: 121550,
  },
  {
    company: 'BCBS-LA (Medicare Replacement/Advantage - HMO)',
    packageId: 622398,
  },
  {
    company: 'BCBS-LA (Medicare Supplement)',
    packageId: 12370,
  },
  {
    company: 'BCBS-LA (POS)',
    packageId: 287369,
  },
  {
    company: 'BCBS-LA (PPO)',
    packageId: 53119,
  },
  {
    company: 'BCBS-MA',
    packageId: 1424,
  },
  {
    company: 'BCBS-MA (HMO)',
    packageId: 1283,
  },
  {
    company: 'BCBS-MA (PPO)',
    packageId: 8477,
  },
  {
    company: 'BCBS-MD',
    packageId: 1505,
  },
  {
    company: 'BCBS-MD - BeneSys - Union Plan (PPO)',
    packageId: 137037,
  },
  {
    company: 'BCBS-MD - BlueChoice (HMO)',
    packageId: 499680,
  },
  {
    company: 'BCBS-MD - FELRA and UFCW Health and Welfare Fund',
    packageId: 174990,
  },
  {
    company: 'BCBS-MD - FEP',
    packageId: 15152,
  },
  {
    company: 'BCBS-MD - Plumbers and Pipefitters - Union Plan (PPO)',
    packageId: 555518,
  },
  {
    company: 'BCBS-MD - Teamsters Local 922 - Union Plan (PPO)',
    packageId: 400678,
  },
  {
    company: 'BCBS-MD - Union Plan (PPO)',
    packageId: 111989,
  },
  {
    company: 'BCBS-MD - Washington Wholesalers Health and Welfare Fund (PPO)',
    packageId: 164046,
  },
  {
    company: 'BCBS-MD (EPO)',
    packageId: 120926,
  },
  {
    company: 'BCBS-MD (HMO)',
    packageId: 125612,
  },
  {
    company: 'BCBS-MD (Indemnity)',
    packageId: 104447,
  },
  {
    company: 'BCBS-MD (Medicare Supplement)',
    packageId: 54047,
  },
  {
    company: 'BCBS-MD (POS)',
    packageId: 44552,
  },
  {
    company: 'BCBS-MD (PPO)',
    packageId: 44486,
  },
  {
    company: 'BCBS-ME (HMO)',
    packageId: 1663,
  },
  {
    company: 'BCBS-MI (Indemnity)',
    packageId: 56863,
  },
  {
    company: 'BCBS-MI (Medicare Replacement/Advantage - PPO)',
    packageId: 110425,
  },
  {
    company: 'BCBS-MI (PPO)',
    packageId: 110491,
  },
  {
    company: 'BCBS-MI Blue Care Network',
    packageId: 64105,
  },
  {
    company: 'BCBS-MI Blue Care Network (HMO)',
    packageId: 15051,
  },
  {
    company: 'BCBS-MN',
    packageId: 1132,
  },
  {
    company: 'BCBS-MN (Indemnity)',
    packageId: 57117,
  },
  {
    company: 'BCBS-MN (PPO)',
    packageId: 21448,
  },
  {
    company: 'BCBS-MO (PPO)',
    packageId: 22957,
  },
  {
    company: 'BCBS-MS',
    packageId: 73375,
  },
  {
    company: 'BCBS-MS - FEP',
    packageId: 70733,
  },
  {
    company: 'BCBS-MS (PPO)',
    packageId: 57718,
  },
  {
    company: 'BCBS-MT (PPO)',
    packageId: 50772,
  },
  {
    company: 'BCBS-NC',
    packageId: 85791,
  },
  {
    company: 'BCBS-NC (HMO)',
    packageId: 23458,
  },
  {
    company: 'BCBS-NC (Indemnity)',
    packageId: 23984,
  },
  {
    company: 'BCBS-NC (PPO)',
    packageId: 38272,
  },
  {
    company: 'BCBS-NE',
    packageId: 1412,
  },
  {
    company: 'BCBS-NE (PPO)',
    packageId: 161797,
  },
  {
    company: 'BCBS-NH (HMO)',
    packageId: 10095,
  },
  {
    company: 'BCBS-NJ',
    packageId: 73681,
  },
  {
    company: 'BCBS-NJ (EPO)',
    packageId: 77192,
  },
  {
    company: 'BCBS-NJ (Medicare Supplement)',
    packageId: 23801,
  },
  {
    company: 'BCBS-NJ (POS)',
    packageId: 51142,
  },
  {
    company: 'BCBS-NJ (PPO)',
    packageId: 105898,
  },
  {
    company: 'BCBS-NM (POS)',
    packageId: 10594,
  },
  {
    company: 'BCBS-NV',
    packageId: 85795,
  },
  {
    company: 'BCBS-NV - Community Care (Medicaid Replacement - HMO)',
    packageId: 87288,
  },
  {
    company: 'BCBS-NV - FEP',
    packageId: 39586,
  },
  {
    company: 'BCBS-NV - Zenith American Solutions (PPO)',
    packageId: 360044,
  },
  {
    company: 'BCBS-NV (HMO)',
    packageId: 253292,
  },
  {
    company: 'BCBS-NV (Medicaid Replacement - HMO)',
    packageId: 75708,
  },
  {
    company: 'BCBS-NV (Medicare Replacement/Advantage - PPO)',
    packageId: 136902,
  },
  {
    company: 'BCBS-NV (Medicare Supplement)',
    packageId: 334901,
  },
  {
    company: 'BCBS-NV (PPO)',
    packageId: 10475,
  },
  {
    company: 'BCBS-NY Blue Shield of Northeastern NY',
    packageId: 16962,
  },
  {
    company: 'BCBS-NY Blue Shield of Northeastern NY - FEP - DOS prior to 11/1/21',
    packageId: 85641,
  },
  {
    company: 'BCBS-NY Blue Shield of Northeastern NY (EPO)',
    packageId: 43947,
  },
  {
    company: 'BCBS-NY Blue Shield of Northeastern NY (HMO)',
    packageId: 29206,
  },
  {
    company: 'BCBS-NY Blue Shield of Northeastern NY (POS)',
    packageId: 29205,
  },
  {
    company: 'BCBS-NY Blue Shield of Northeastern NY (PPO)',
    packageId: 28204,
  },
  {
    company: 'BCBS-NY Central NY',
    packageId: 1256,
  },
  {
    company: 'BCBS-NY Empire',
    packageId: 16808,
  },
  {
    company: 'BCBS-NY Empire - FEP',
    packageId: 11177,
  },
  {
    company: 'BCBS-NY Empire - FEP (PPO)',
    packageId: 84806,
  },
  {
    company: 'BCBS-NY Empire (EPO)',
    packageId: 19237,
  },
  {
    company: 'BCBS-NY Empire (HMO)',
    packageId: 53156,
  },
  {
    company: 'BCBS-NY Empire (Indemnity)',
    packageId: 15718,
  },
  {
    company: 'BCBS-NY Empire (Medicare Replacement/Advantage - HMO)',
    packageId: 30207,
  },
  {
    company: 'BCBS-NY Empire (POS)',
    packageId: 28865,
  },
  {
    company: 'BCBS-NY Empire (PPO)',
    packageId: 17105,
  },
  {
    company: 'BCBS-NY Rochester - Blue Choice',
    packageId: 18065,
  },
  {
    company: 'BCBS-NY Rochester (Medicaid Replacement - HMO)',
    packageId: 63109,
  },
  {
    company: 'BCBS-NY Rochester (PPO)',
    packageId: 75480,
  },
  {
    company: 'BCBS-NY Western NY',
    packageId: 15932,
  },
  {
    company: 'BCBS-NY Western NY (HMO)',
    packageId: 26140,
  },
  {
    company: 'BCBS-NY Western NY (Medicaid Replacement - HMO)',
    packageId: 113495,
  },
  {
    company: 'BCBS-NY Western NY (POS)',
    packageId: 31960,
  },
  {
    company: 'BCBS-OH',
    packageId: 76368,
  },
  {
    company: 'BCBS-OH - Blue Access (PPO)',
    packageId: 4896,
  },
  {
    company: 'BCBS-OH - Blue Preferred',
    packageId: 4897,
  },
  {
    company: 'BCBS-OH - Bricklayers Health and Welfare Fund (PPO)',
    packageId: 549164,
  },
  {
    company: 'BCBS-OH - FEP',
    packageId: 2604,
  },
  {
    company: 'BCBS-OH - MediBlue (Medicare Replacement/Advantage - HMO)',
    packageId: 3037,
  },
  {
    company: 'BCBS-OH (EPO)',
    packageId: 134508,
  },
  {
    company: 'BCBS-OH (PPO)',
    packageId: 18691,
  },
  {
    company: 'BCBS-OK (PPO)',
    packageId: 11489,
  },
  {
    company: 'BCBS-OR',
    packageId: 5133,
  },
  {
    company: 'BCBS-PA Capital Blue Cross',
    packageId: 17153,
  },
  {
    company: 'BCBS-PA Capital Blue Cross (PPO)',
    packageId: 24110,
  },
  {
    company: 'BCBS-PA Highmark BCBS',
    packageId: 44580,
  },
  {
    company: 'BCBS-PA Highmark BCBS (EPO)',
    packageId: 420261,
  },
  {
    company: 'BCBS-PA Highmark BCBS (HMO)',
    packageId: 78563,
  },
  {
    company: 'BCBS-PA Highmark BCBS (POS)',
    packageId: 10247,
  },
  {
    company: 'BCBS-PA Highmark BCBS (PPO)',
    packageId: 62103,
  },
  {
    company: 'BCBS-PA Highmark Blue Shield - FEP',
    packageId: 11022,
  },
  {
    company: 'BCBS-PA Highmark Blue Shield (EPO)',
    packageId: 57291,
  },
  {
    company: 'BCBS-PA Highmark Blue Shield (PPO)',
    packageId: 19444,
  },
  {
    company: 'BCBS-PA Independence Blue Cross',
    packageId: 90679,
  },
  {
    company: 'BCBS-PA Independence Blue Cross - Personal Choice (PPO)',
    packageId: 22367,
  },
  {
    company: 'BCBS-PA Independence Blue Cross (PPO)',
    packageId: 22709,
  },
  {
    company: 'BCBS-PA Keystone HP East (POS)',
    packageId: 28498,
  },
  {
    company: 'BCBS-PR',
    packageId: 21037,
  },
  {
    company: 'BCBS-PR (PPO)',
    packageId: 55650,
  },
  {
    company: 'BCBS-RI',
    packageId: 1076,
  },
  {
    company: 'BCBS-RI - Classic',
    packageId: 1111,
  },
  {
    company: 'BCBS-SC',
    packageId: 129,
  },
  {
    company: 'BCBS-SC - BlueChoice Health Plan of South Carolina (HMO)',
    packageId: 11063,
  },
  {
    company: 'BCBS-SC - Key Benefit Administrators (PPO)',
    packageId: 55202,
  },
  {
    company: 'BCBS-SC - State Health Plan (Indemnity)',
    packageId: 4927,
  },
  {
    company: 'BCBS-SC (EPO)',
    packageId: 51141,
  },
  {
    company: 'BCBS-SC (Medicare Replacement/Advantage - PPO)',
    packageId: 478179,
  },
  {
    company: 'BCBS-SC (PPO)',
    packageId: 16029,
  },
  {
    company: 'BCBS-TN',
    packageId: 1567,
  },
  {
    company: 'BCBS-TN - Network P (PPO)',
    packageId: 55339,
  },
  {
    company: 'BCBS-TN (PPO)',
    packageId: 9037,
  },
  {
    company: 'BCBS-TX',
    packageId: 52947,
  },
  {
    company: 'BCBS-TX - Blue Choice (PPO)',
    packageId: 2146,
  },
  {
    company: 'BCBS-TX - FEP (PPO)',
    packageId: 23207,
  },
  {
    company: 'BCBS-TX (EPO)',
    packageId: 38581,
  },
  {
    company: 'BCBS-TX (HMO)',
    packageId: 315123,
  },
  {
    company: 'BCBS-TX (PPO)',
    packageId: 11993,
  },
  {
    company: 'BCBS-TX Blue Cross Medicare Advantage (Medicare Replacement/Advantage - PPO)',
    packageId: 404775,
  },
  {
    company: 'BCBS-UT',
    packageId: 18097,
  },
  {
    company: 'BCBS-UT (Medicare Replacement/Advantage - PPO)',
    packageId: 152040,
  },
  {
    company: 'BCBS-UT (PPO)',
    packageId: 30928,
  },
  {
    company: 'BCBS-VA',
    packageId: 18162,
  },
  {
    company: 'BCBS-VA - FEP',
    packageId: 17632,
  },
  {
    company: 'BCBS-VA - FEP (PPO)',
    packageId: 33451,
  },
  {
    company: 'BCBS-VA - HealthKeepers (HMO)',
    packageId: 11457,
  },
  {
    company: 'BCBS-VA - HealthKeepers (Medicaid Replacement - HMO)',
    packageId: 252068,
  },
  {
    company: 'BCBS-VA - HealthKeepers (Medicare Replacement/Advantage - HMO)',
    packageId: 478835,
  },
  {
    company: 'BCBS-VA - HealthKeepers (Medicare Replacement/Advantage - PPO)',
    packageId: 622137,
  },
  {
    company: 'BCBS-VA - HealthKeepers (POS)',
    packageId: 36066,
  },
  {
    company: 'BCBS-VA - Healthkeepers Plus (Medicaid Replacement - HMO)',
    packageId: 22741,
  },
  {
    company: 'BCBS-VA (PPO)',
    packageId: 1742,
  },
  {
    company: 'BCBS-VT - CBA Blue (PPO)',
    packageId: 138531,
  },
  {
    company: 'BCBS-WA Premera',
    packageId: 64896,
  },
  {
    company: 'BCBS-WA Premera (EPO)',
    packageId: 210885,
  },
  {
    company: 'BCBS-WA Premera (PPO)',
    packageId: 10489,
  },
  {
    company: 'BCBS-WA Regence',
    packageId: 5870,
  },
  {
    company: 'BCBS-WA Regence (PPO)',
    packageId: 133641,
  },
  {
    company: 'Benefit & Risk Management Services',
    packageId: 16712,
  },
  {
    company: 'Benefit Administrative Systems',
    packageId: 1552,
  },
  {
    company: 'Benefit Advantage',
    packageId: 70097,
  },
  {
    company: 'BeneSys',
    packageId: 27749,
  },
  {
    company: 'BeneSys - Beech Street (PPO)',
    packageId: 313674,
  },
  {
    company: 'BeneSys - Cement Masons and Plasterers Health and Welfare Fund - Beech Street (PPO)',
    packageId: 266896,
  },
  {
    company: 'BeneSys - IBEW - MultiPlan (PPO)',
    packageId: 319863,
  },
  {
    company: 'BeneSys (Medicare Supplement)',
    packageId: 356084,
  },
  {
    company: 'Benserco - GHI (PPO)',
    packageId: 113026,
  },
  {
    company: 'Bind Insurance - PreferredOne (PPO)',
    packageId: 602156,
  },
  {
    company: 'Bind Insurance - United Healthcare (PPO)',
    packageId: 582356,
  },
  {
    company: 'Bind Insurance - UnitedHealth Shared Services - United Healthcare (PPO)',
    packageId: 521320,
  },
  {
    company: 'Blackhawk Claims Services - MultiPlan (PPO)',
    packageId: 619764,
  },
  {
    company: 'Blackhawk Claims Services - PHCS',
    packageId: 611998,
  },
  {
    company: 'Blue Cross Canada',
    packageId: 33151,
  },
  {
    company: 'Boon-Chapman - Kemper Benefits',
    packageId: 392826,
  },
  {
    company: 'Boon-Chapman (PPO)',
    packageId: 85163,
  },
  {
    company: 'Boston Medical Center HealthNet Plan (Medicaid Replacement - HMO)',
    packageId: 1976,
  },
  {
    company: 'Bravo Health (Medicare Replacement/Advantage - HMO)',
    packageId: 58020,
  },
  {
    company: 'Bright Health (EPO)',
    packageId: 527844,
  },
  {
    company: 'Bupa Global - Foreign Payer',
    packageId: 618428,
  },
  {
    company: 'California Health & Wellness (Medicaid Replacement - HMO)',
    packageId: 239567,
  },
  {
    company: 'Canadian Medical Network (PPO)',
    packageId: 99343,
  },
  {
    company: 'CARE',
    packageId: 6822,
  },
  {
    company: 'Care Factor',
    packageId: 4611,
  },
  {
    company: 'CareFirst - AmeriHealth Administrators (PPO)',
    packageId: 363069,
  },
  {
    company: 'CareFirst - IUOE - BCBS-MD - Union Plan (PPO)',
    packageId: 267257,
  },
  {
    company: 'CareFirst Administrators - NCAS (PPO)',
    packageId: 116535,
  },
  {
    company: 'CareFirst Administrators (EPO)',
    packageId: 567984,
  },
  {
    company: 'CareFirst BCBS Medicare Advantage - Dual (Medicare Replacement/Advantage - HMO)',
    packageId: 463332,
  },
  {
    company: 'CareFirst BCBS Medicare Advantage (Medicare Replacement/Advantage - HMO)',
    packageId: 347712,
  },
  {
    company: 'Carefirst Community Health Plan DC - DC Health Care Alliance (Medicaid Replacement - HMO)',
    packageId: 311906,
  },
  {
    company: 'Carefirst Community Health Plan DC - DC Healthy Families (Medicaid Replacement - HMO)',
    packageId: 223429,
  },
  {
    company: 'CareFirst Community Health Plan Maryland (Medicaid Replacement - HMO)',
    packageId: 480233,
  },
  {
    company: 'CareMore - BCBS-CA Blue Cross of California - Dual Eligible (Medicare Replacement/Advantage - HMO)',
    packageId: 298312,
  },
  {
    company: 'CareMore (Medicare Replacement/Advantage - HMO)',
    packageId: 54340,
  },
  {
    company: 'CarePlus Health Plans (Medicare Replacement/Advantage - HMO)',
    packageId: 18152,
  },
  {
    company: 'CareSource-GA (Medicaid Replacement - HMO)',
    packageId: 441398,
  },
  {
    company: 'CareSource-KY (HMO)',
    packageId: 306017,
  },
  {
    company: 'Carpenters H & W Fund (Medicare Supplement)',
    packageId: 529995,
  },
  {
    company: 'CBA Blue - BCBS-VT (PPO)',
    packageId: 80454,
  },
  {
    company: 'CCMP',
    packageId: 111290,
  },
  {
    company: 'CDPHP (EPO)',
    packageId: 63810,
  },
  {
    company: 'CDPHP (Medicare Replacement/Advantage - HMO)',
    packageId: 28921,
  },
  {
    company: 'CenCal Health (Medicaid Replacement - HMO)',
    packageId: 26599,
  },
  {
    company: 'Centene - Allwell from SilverSummit Health Plan (Medicare Replacement/Advantage - HMO)',
    packageId: 568921,
  },
  {
    company: 'Centene - Allwell Louisiana Healthcare Connections (Medicare Replacement/Advantage - HMO)',
    packageId: 479804,
  },
  {
    company: 'Centene - Ambetter - Coordinated Care (HMO)',
    packageId: 319374,
  },
  {
    company: 'Centene - Ambetter from Managed Health Services - Managed Health Services-IN (HMO)',
    packageId: 295455,
  },
  {
    company: 'Centene - Ambetter from SilverSummit Health Plan (HMO)',
    packageId: 477786,
  },
  {
    company: 'Centene - Ambetter from Sunshine State Health Plan (EPO)',
    packageId: 359511,
  },
  {
    company: 'Centene - Health Net-CA - IFP (EPO)',
    packageId: 477964,
  },
  {
    company: 'Centene - Health Net-CA - IFP (HMO)',
    packageId: 477966,
  },
  {
    company: 'Centene - Health Net-CA - IFP (Medicare Replacement/Advantage - HMO)',
    packageId: 477943,
  },
  {
    company: 'CENTENE - SILVERSUMMIT HEALTHPLAN (MEDICAID REPLACEMENT - HMO)',
    packageId: 447247,
  },
  {
    company: 'Centene - Superior HealthPlan (Medicare Replacement/Advantage - HMO)',
    packageId: 70466,
  },
  {
    company: 'Centerlight Healthcare - Dual Eligible - PACE (Medicare Replacement/Advantage - HMO)',
    packageId: 165402,
  },
  {
    company: 'Centers Plan for Healthy Living',
    packageId: 358812,
  },
  {
    company: 'Centers Plan for Healthy Living (Medicare Replacement/Advantage - HMO)',
    packageId: 329296,
  },
  {
    company: 'Central California Alliance For Health (Medicaid Replacement - HMO)',
    packageId: 6774,
  },
  {
    company: 'CHAMPVA (CHAMPVA)',
    packageId: 35331,
  },
  {
    company: 'Choice Benefits',
    packageId: 4939,
  },
  {
    company: 'Christian Care Ministry',
    packageId: 15288,
  },
  {
    company: 'Christian Care Ministry - PHCS (PPO)',
    packageId: 69536,
  },
  {
    company: 'Cigna',
    packageId: 74,
  },
  {
    company: 'CIGNA - AMERICAN POSTAL WORKERS UNION HEALTH PLAN',
    packageId: 58422,
  },
  {
    company: 'Cigna - Auxiant (PPO)',
    packageId: 516239,
  },
  {
    company: 'Cigna - AZ (Medicare Replacement/Advantage - HMO)',
    packageId: 157235,
  },
  {
    company: 'Cigna - Connect (EPO)',
    packageId: 410129,
  },
  {
    company: 'Cigna - GA MO PA and Midatlantic (Medicare Replacement/Advantage - HMO)',
    packageId: 264985,
  },
  {
    company: 'Cigna - Masters Mates & Pilots Health & Benefit Plan (PPO)',
    packageId: 132584,
  },
  {
    company: 'Cigna - MVP Health Care (PPO)',
    packageId: 285483,
  },
  {
    company: 'Cigna - NALC Health Benefit Plan',
    packageId: 147317,
  },
  {
    company: 'Cigna - NALC Health Benefit Plan (PPO)',
    packageId: 70179,
  },
  {
    company: 'Cigna - S&S HealthCare Strategies (PPO)',
    packageId: 534038,
  },
  {
    company: 'Cigna - The Health Plan (PPO)',
    packageId: 351994,
  },
  {
    company: 'Cigna - Tufts Health Plan',
    packageId: 115133,
  },
  {
    company: 'Cigna (EPO)',
    packageId: 26384,
  },
  {
    company: 'Cigna (HMO)',
    packageId: 12652,
  },
  {
    company: 'Cigna (Indemnity)',
    packageId: 109582,
  },
  {
    company: 'Cigna (PPO)',
    packageId: 2621,
  },
  {
    company: 'Cigna HealthSpring of TX - Renaissance Physician Organization (Medicare Replacement/Advantage - HMO)',
    packageId: 22773,
  },
  {
    company: 'Cigna International',
    packageId: 61406,
  },
  {
    company: 'Cigna Supplemental - Cigna Health and Life Insurance (Medicare Supplement)',
    packageId: 394871,
  },
  {
    company: 'ClaimsBridge - Integra Administrative Group (PPO)',
    packageId: 215361,
  },
  {
    company: 'Clear Spring Health (Medicare Replacement/Advantage - HMO)',
    packageId: 615419,
  },
  {
    company: 'Clover Health (Medicare Replacement/Advantage - PPO)',
    packageId: 293440,
  },
  {
    company: 'CMO',
    packageId: 11191,
  },
  {
    company: 'CMO (HMO)',
    packageId: 81223,
  },
  {
    company: 'CMO (Medicaid Replacement - HMO)',
    packageId: 297890,
  },
  {
    company: 'CMO (Medicare Replacement/Advantage - HMO)',
    packageId: 73940,
  },
  {
    company: 'Co-ordinated Benefit Plans',
    packageId: 61798,
  },
  {
    company: 'Co-ordinated Benefit Plans - First Health - OneCare (PPO)',
    packageId: 561556,
  },
  {
    company: 'Community Care Plan - PPUC',
    packageId: 533924,
  },
  {
    company: 'Community Health Center Network - Alameda Alliance for Health (Medicaid Replacement - HMO)',
    packageId: 99344,
  },
  {
    company: 'Community Health Group (Medicaid Replacement - HMO)',
    packageId: 22,
  },
  {
    company: 'Community Health Plan of California (HMO)',
    packageId: 29433,
  },
  {
    company: 'Community Health Plan of California (Medicaid Replacement - HMO)',
    packageId: 23530,
  },
  {
    company: 'Companion Life - MultiPlan',
    packageId: 197301,
  },
  {
    company: 'Conifer Value Based Care - Alamitos IPA - BCBS-CA Blue Cross of California (HMO)',
    packageId: 593180,
  },
  {
    company: 'Contra Costa Health Plan (Medicaid Replacement - HMO)',
    packageId: 25248,
  },
  {
    company: 'Culinary Health Fund',
    packageId: 19223,
  },
  {
    company: 'Culinary Health Fund (PPO)',
    packageId: 96051,
  },
  {
    company: 'Dean Health Plan',
    packageId: 19099,
  },
  {
    company: 'Delta Health Systems (PPO)',
    packageId: 72039,
  },
  {
    company: 'Denver Health (HMO)',
    packageId: 59381,
  },
  {
    company: 'EBMS',
    packageId: 7994,
  },
  {
    company: 'EBMS - MultiPlan (PPO)',
    packageId: 51862,
  },
  {
    company: 'El Paso Health - Preferred Administrators (PPO)',
    packageId: 44034,
  },
  {
    company: 'Elderplan',
    packageId: 29789,
  },
  {
    company: 'Elderplan - Dual Eligibile (Medicare Replacement/Advantage - HMO)',
    packageId: 325685,
  },
  {
    company: 'Elderplan (Medicare Replacement/Advantage - HMO)',
    packageId: 15666,
  },
  {
    company: 'Electrical Health and Welfare Fund',
    packageId: 97456,
  },
  {
    company: 'Electrical Workers Health and Welfare Fund (PPO)',
    packageId: 418539,
  },
  {
    company: 'EmblemHealth Insurance Company',
    packageId: 10887,
  },
  {
    company: 'EmblemHealth Insurance Company - CHIP (Medicaid Replacement - HMO)',
    packageId: 33073,
  },
  {
    company: 'EmblemHealth Insurance Company - Dual Eligible (Medicare Replacement/Advantage - HMO)',
    packageId: 273950,
  },
  {
    company: 'EmblemHealth Insurance Company - MultiPlan (PPO)',
    packageId: 157853,
  },
  {
    company: 'EmblemHealth Insurance Company (EPO)',
    packageId: 91071,
  },
  {
    company: 'EmblemHealth Insurance Company (HMO)',
    packageId: 29126,
  },
  {
    company: 'EmblemHealth Insurance Company (Medicaid Replacement - HMO)',
    packageId: 125998,
  },
  {
    company: 'EmblemHealth Insurance Company (Medicare Replacement/Advantage - HMO)',
    packageId: 26579,
  },
  {
    company: 'EmblemHealth Insurance Company (PPO)',
    packageId: 34746,
  },
  {
    company: 'EmblemHealth Plan Inc',
    packageId: 15741,
  },
  {
    company: 'EmblemHealth Plan Inc - Dual Eligible (Medicare Replacement/Advantage - HMO)',
    packageId: 300875,
  },
  {
    company: 'EmblemHealth Plan Inc (HMO)',
    packageId: 8002,
  },
  {
    company: 'EmblemHealth Plan Inc (Medicare Replacement/Advantage - HMO)',
    packageId: 68027,
  },
  {
    company: 'EmblemHealth Plan Inc (PPO)',
    packageId: 16299,
  },
  {
    company: 'Empire BCBS HealthPlus - Amerigroup New York',
    packageId: 166934,
  },
  {
    company: 'Empire BCBS HealthPlus (Medicaid Replacement - HMO)',
    packageId: 166927,
  },
  {
    company: 'Fidelis Care - Essential Plan 1 (HMO)',
    packageId: 372089,
  },
  {
    company: 'Fidelis Care - Fidelis Care at Home (Medicaid Replacement)',
    packageId: 359158,
  },
  {
    company: 'Fidelis Care - PCP Only (Medicaid Replacement - HMO)',
    packageId: 103151,
  },
  {
    company: 'Fidelis Care - Specialists (HMO)',
    packageId: 74952,
  },
  {
    company: 'Fidelis Care - Specialists (Medicaid Replacement - HMO)',
    packageId: 79007,
  },
  {
    company: 'Fidelis Care (HMO)',
    packageId: 249758,
  },
  {
    company: 'Fidelis Care (Medicaid Replacement - HMO)',
    packageId: 605561,
  },
  {
    company: 'Fidelis Care (Medicare Replacement/Advantage - HMO)',
    packageId: 46113,
  },
  {
    company: 'First Choice Health',
    packageId: 19158,
  },
  {
    company: 'Florida Health Care Plans (HMO)',
    packageId: 23959,
  },
  {
    company: 'Florida Health Care Plans (Medicare Replacement/Advantage - HMO)',
    packageId: 24026,
  },
  {
    company: 'Fox Everett',
    packageId: 1529,
  },
  {
    company: 'Free Market Administrators - PHCS (PPO)',
    packageId: 567863,
  },
  {
    company: 'Freedom Health (Medicare Replacement/Advantage - HMO)',
    packageId: 43939,
  },
  {
    company: 'Freedom Life Insurance Company of America',
    packageId: 5782,
  },
  {
    company: 'Freedom Life Insurance Company of America - PHCS (PPO)',
    packageId: 41294,
  },
  {
    company: 'Freedom Network - UMR',
    packageId: 20148,
  },
  {
    company: 'Friday Health Plans of Colorado (HMO)',
    packageId: 135096,
  },
  {
    company: 'Friday Health Plans of NV',
    packageId: 613291,
  },
  {
    company: 'Fringe Benefit Group - First Health - The American Worker (PPO)',
    packageId: 525041,
  },
  {
    company: 'Fringe Benefit Group - PHCS (PPO)',
    packageId: 604444,
  },
  {
    company: 'FrontPath - Aetna (PPO)',
    packageId: 60855,
  },
  {
    company: 'GEHA (PPO)',
    packageId: 25,
  },
  {
    company: 'Geisinger Health Plan',
    packageId: 11465,
  },
  {
    company: 'GeoBlue (PPO)',
    packageId: 384699,
  },
  {
    company: 'Gerber Life Insurance (Medicare Supplement)',
    packageId: 110090,
  },
  {
    company: 'Global Care Medical Group IPA - LA Care Health Plan - MedPoint Management (Medicaid Replacement - HMO)',
    packageId: 71564,
  },
  {
    company: 'Global Underwriters',
    packageId: 53670,
  },
  {
    company: 'Group & Pension Administrators',
    packageId: 2481,
  },
  {
    company: 'Group & Pension Administrators - MultiPlan (PPO)',
    packageId: 205047,
  },
  {
    company: 'Group & Pension Administrators - PHCS',
    packageId: 10172,
  },
  {
    company: 'Group & Pension Administrators (PPO)',
    packageId: 11760,
  },
  {
    company: 'Guardian Life of the Caribbean',
    packageId: 54881,
  },
  {
    company: 'Gulf South Risk Services - PHCS (PPO)',
    packageId: 442026,
  },
  {
    company: 'GWH-Cigna - Allegiance Benefit Plan Management - Cigna',
    packageId: 72520,
  },
  {
    company: 'GWH-Cigna - Allegiance Benefit Plan Management - Cigna (PPO)',
    packageId: 111512,
  },
  {
    company: 'GWH-Cigna - American Plan Administrators - Cigna (PPO)',
    packageId: 229618,
  },
  {
    company: 'GWH-Cigna - Benefit Administration Services - Cigna (PPO)',
    packageId: 198200,
  },
  {
    company: 'GWH-Cigna - BeneSys - Cigna (PPO)',
    packageId: 278311,
  },
  {
    company: 'GWH-Cigna - Cigna',
    packageId: 108106,
  },
  {
    company: 'GWH-Cigna - Cigna (EPO)',
    packageId: 40923,
  },
  {
    company: 'GWH-Cigna - Cigna (PPO)',
    packageId: 1826,
  },
  {
    company: 'GWH-Cigna - Continental Benefits - Cigna (PPO)',
    packageId: 322376,
  },
  {
    company: 'GWH-Cigna - Daniel H Cook Associates - Cigna (PPO)',
    packageId: 408798,
  },
  {
    company: 'GWH-Cigna - Gilsbar - Cigna (PPO)',
    packageId: 40725,
  },
  {
    company: 'GWH-Cigna - HealthEZ - Cigna (PPO)',
    packageId: 280889,
  },
  {
    company: 'GWH-CIGNA - NGS TRUSTMARK HEALTH BENEFITS - CIGNA (PPO)',
    packageId: 50191,
  },
  {
    company: 'GWH-Cigna - Performance Health - Cigna (PPO)',
    packageId: 352001,
  },
  {
    company: 'GWH-Cigna - S&S HealthCare Strategies - Cigna (PPO)',
    packageId: 247058,
  },
  {
    company: 'GWH-CIGNA - TRUSTMARK HEALTH BENEFITS - CIGNA (PPO)',
    packageId: 134420,
  },
  {
    company: 'GWH-Cigna - Wellfleet - Cigna (PPO)',
    packageId: 147747,
  },
  {
    company: 'Harrington Health - Kansas (PPO)',
    packageId: 10182,
  },
  {
    company: 'Harvard Pilgrim Health Care (PPO)',
    packageId: 7542,
  },
  {
    company: 'Hawaii Mainland Administrators - First Health (PPO)',
    packageId: 322460,
  },
  {
    company: 'Hawaii Mainland Administrators - First Health Network (EPO)',
    packageId: 527638,
  },
  {
    company: 'Hawaii Mainland Administrators - MultiPlan (EPO)',
    packageId: 562748,
  },
  {
    company: 'Hawaii Mainland Administrators - MultiPlan (PPO)',
    packageId: 60665,
  },
  {
    company: 'Hawaii Mainland Administrators - PHCS (PPO)',
    packageId: 135005,
  },
  {
    company: 'Health Care LA IPA - Health Net-CA - MedPoint Management (Medicaid Replacement - HMO)',
    packageId: 153740,
  },
  {
    company: 'Health Care LA IPA - LA Care Health Plan - MedPoint Management (Medicaid Replacement - HMO)',
    packageId: 69540,
  },
  {
    company: 'Health First Health Plans',
    packageId: 7155,
  },
  {
    company: 'Health Net-CA - DOS on or After 1/1/18 - Calviva (Medicaid Replacement - HMO)',
    packageId: 481038,
  },
  {
    company: 'Health Net-CA - DOS on or After 1/1/18 (Medicaid Replacement - HMO)',
    packageId: 477936,
  },
  {
    company: 'Health Net-CA (Medicaid)',
    packageId: 370925,
  },
  {
    company: 'Health Plan of Nevada - United Healthcare',
    packageId: 157777,
  },
  {
    company: 'Health Plan of Nevada - United Healthcare (POS)',
    packageId: 123384,
  },
  {
    company: 'Health Plan of Nevada (HMO)',
    packageId: 23408,
  },
  {
    company: 'Health Plan of Nevada (Medicaid Replacement - HMO)',
    packageId: 81629,
  },
  {
    company: 'Health Plan of Nevada (POS)',
    packageId: 70174,
  },
  {
    company: 'Health Plan of Nevada (PPO)',
    packageId: 21896,
  },
  {
    company: 'HEALTH PLAN OF SAN JOAQUIN (CHDP)',
    packageId: 393581,
  },
  {
    company: 'Health Plans Inc',
    packageId: 1411,
  },
  {
    company: 'Health Services for Children with Special Needs - Medicaid-DC',
    packageId: 188731,
  },
  {
    company: 'HealthCare Partners IPA - BCBS-NY Empire (Medicare Replacement/Advantage - HMO)',
    packageId: 354713,
  },
  {
    company: 'HealthCare Partners IPA - HIP (HMO)',
    packageId: 138215,
  },
  {
    company: 'HealthCare Partners IPA - HIP (Medicaid Replacement - HMO)',
    packageId: 76672,
  },
  {
    company: 'HealthCare Partners IPA - HIP (Medicare Replacement/Advantage - HMO)',
    packageId: 125031,
  },
  {
    company: 'HealthCare Solutions Group',
    packageId: 11378,
  },
  {
    company: 'HealthComp',
    packageId: 2585,
  },
  {
    company: 'HealthEZ',
    packageId: 18926,
  },
  {
    company: 'HealthEZ - Aetna Signature Administrators (PPO)',
    packageId: 616323,
  },
  {
    company: 'HEALTHEZ - TRUSTMARK HEALTH BENEFITS (PPO)',
    packageId: 128269,
  },
  {
    company: 'HealthEZ (PPO)',
    packageId: 53574,
  },
  {
    company: 'Healthfirst',
    packageId: 2992,
  },
  {
    company: 'Healthfirst - Dual Eligible (Medicare Replacement/Advantage - HMO)',
    packageId: 316723,
  },
  {
    company: 'Healthfirst - Essential Plan 1 (HMO)',
    packageId: 372090,
  },
  {
    company: 'Healthfirst - Essential Plan 2 (HMO)',
    packageId: 372141,
  },
  {
    company: 'Healthfirst - Essential Plan 4 (HMO)',
    packageId: 366840,
  },
  {
    company: 'Healthfirst (HMO)',
    packageId: 31181,
  },
  {
    company: 'Healthfirst (Medicaid Replacement - HMO)',
    packageId: 11327,
  },
  {
    company: 'Healthfirst (Medicare Replacement/Advantage - HMO)',
    packageId: 12476,
  },
  {
    company: 'HealthLink - UMR (PPO)',
    packageId: 31761,
  },
  {
    company: 'HealthPartners',
    packageId: 260581,
  },
  {
    company: 'HealthPartners - Cigna',
    packageId: 127042,
  },
  {
    company: 'HealthPartners - Cigna (PPO)',
    packageId: 85616,
  },
  {
    company: 'HealthPartners (PPO)',
    packageId: 562899,
  },
  {
    company: 'HealthPlan of San Mateo (HMO)',
    packageId: 114110,
  },
  {
    company: 'HealthSCOPE Benefits',
    packageId: 12874,
  },
  {
    company: 'HealthSCOPE Benefits - Aetna Signature Administrators (PPO)',
    packageId: 203836,
  },
  {
    company:
      'HealthSCOPE Benefits - AllWays Health Partners - Aetna Signature Administrators - Out of Network Providers (EPO)',
    packageId: 530304,
  },
  {
    company:
      'HealthSCOPE Benefits - AllWays Health Partners - Aetna Signature Administrators - Out of Network Providers (PPO)',
    packageId: 537135,
  },
  {
    company: 'HealthSCOPE Benefits - Augusta Health (PPO)',
    packageId: 537854,
  },
  {
    company: 'HealthSCOPE Benefits - PHCS',
    packageId: 12453,
  },
  {
    company: 'HealthSCOPE Benefits (PPO)',
    packageId: 134746,
  },
  {
    company: 'HEALTHSMART MSO - NOBLE COMMUNITY MEDICAL ASSOCIATES - BCBS-CA BLUE CROSS OF CALIFORNIA (HMO)',
    packageId: 323924,
  },
  {
    company: 'HealthTexas Medical Group - United Healthcare West (HMO)',
    packageId: 424069,
  },
  {
    company: 'Healthy Blue of LA - CHIP (Medicaid Replacement)',
    packageId: 465549,
  },
  {
    company: 'Healthy Blue of LA (Medicaid Replacement - HMO)',
    packageId: 456018,
  },
  {
    company: 'Healthy Blue of LA (Medicare Replacement/Advantage - HMO)',
    packageId: 578635,
  },
  {
    company: 'Healthy Start Program',
    packageId: 17886,
  },
  {
    company: 'Hill Physicians Medical Group - Western Health Advantage (HMO)',
    packageId: 61541,
  },
  {
    company: 'Hometown Health',
    packageId: 8192,
  },
  {
    company: 'Hometown Health - First Health (PPO)',
    packageId: 83574,
  },
  {
    company: 'Hometown Health - PHCS (PPO)',
    packageId: 60934,
  },
  {
    company: 'Hometown Health (EPO)',
    packageId: 142507,
  },
  {
    company: 'Hometown Health (HMO)',
    packageId: 63602,
  },
  {
    company: 'Hometown Health (Medicare Replacement/Advantage - HMO)',
    packageId: 64640,
  },
  {
    company: 'Hometown Health (PPO)',
    packageId: 77852,
  },
  {
    company: 'Horizon NJ Health (Medicaid Replacement - HMO)',
    packageId: 12567,
  },
  {
    company: 'Humana',
    packageId: 2640,
  },
  {
    company: 'Humana - ChoiceCare (POS)',
    packageId: 20522,
  },
  {
    company: 'Humana - Gold Choice (Medicare Replacement/Advantage - PFFS)',
    packageId: 47362,
  },
  {
    company: 'Humana - Gold Plus (Medicare Replacement/Advantage - HMO)',
    packageId: 20995,
  },
  {
    company: 'Humana - Gold Plus (Medicare Replacement/Advantage - POS)',
    packageId: 133950,
  },
  {
    company: 'Humana (HMO)',
    packageId: 33532,
  },
  {
    company: 'Humana (Medicaid Replacement - HMO)',
    packageId: 17236,
  },
  {
    company: 'Humana (Medicare Replacement/Advantage - HMO)',
    packageId: 60678,
  },
  {
    company: 'Humana (Medicare Replacement/Advantage - PPO)',
    packageId: 47006,
  },
  {
    company: 'Humana (POS)',
    packageId: 73534,
  },
  {
    company: 'Humana (PPO)',
    packageId: 31724,
  },
  {
    company: 'IBA - MultiPlan',
    packageId: 124226,
  },
  {
    company: 'IBA - PHCS - LifeShield National Insurance Company (PPO)',
    packageId: 407787,
  },
  {
    company: 'IBEW (PPO)',
    packageId: 30461,
  },
  {
    company: 'IEHP (HMO)',
    packageId: 18741,
  },
  {
    company: 'IMA',
    packageId: 6203,
  },
  {
    company: 'INDECS (PPO)',
    packageId: 38984,
  },
  {
    company: 'Independence Administrators - BCBS-PA Independence Blue Cross - Personal Choice (PPO)',
    packageId: 96368,
  },
  {
    company: 'Independent Health',
    packageId: 129184,
  },
  {
    company: 'INETICO - Stirling Benefits',
    packageId: 1491,
  },
  {
    company: 'Insurance Programmers - First Health',
    packageId: 1219,
  },
  {
    company: 'Insurance Programmers (Medicare Supplement)',
    packageId: 31590,
  },
  {
    company: 'Insurance TPA - PHCS',
    packageId: 574483,
  },
  {
    company: 'Integrity Administrators (Indemnity)',
    packageId: 26441,
  },
  {
    company: 'Interactive Medical Systems',
    packageId: 1305,
  },
  {
    company: 'Intermountain Healthcare - Humana - Gold Plus (Medicare Replacement/Advantage - HMO)',
    packageId: 89019,
  },
  {
    company: 'Intermountain Healthcare - United Healthcare West (Medicare Replacement/Advantage - HMO)',
    packageId: 35265,
  },
  {
    company: 'International Services',
    packageId: 348334,
  },
  {
    company: 'Intervalley Health Plan',
    packageId: 3675,
  },
  {
    company: 'InterWest Health - HealthEZ (PPO)',
    packageId: 306830,
  },
  {
    company: 'IU Health (PPO)',
    packageId: 575915,
  },
  {
    company: 'IUOE - MultiPlan (PPO)',
    packageId: 34824,
  },
  {
    company: 'JacksonHealth',
    packageId: 15142,
  },
  {
    company: 'Jai Medical Systems',
    packageId: 133644,
  },
  {
    company: 'Jai Medical Systems (Medicaid Replacement - HMO)',
    packageId: 15973,
  },
  {
    company: 'Johns Hopkins - EHP',
    packageId: 15448,
  },
  {
    company: 'Johns Hopkins - EHP (PPO)',
    packageId: 93837,
  },
  {
    company: 'Johns Hopkins - Priority Partners (Medicaid Replacement - HMO)',
    packageId: 15155,
  },
  {
    company: 'Johns Hopkins - USFHP (HMO)',
    packageId: 33843,
  },
  {
    company: 'Johns Hopkins (Medicare Replacement/Advantage - HMO)',
    packageId: 482962,
  },
  {
    company: 'Kaiser Permanente Northwest (EPO)',
    packageId: 574809,
  },
  {
    company: 'Kaiser Permanente Northwest (HMO)',
    packageId: 11082,
  },
  {
    company: 'Kaiser Permanente of Colorado',
    packageId: 12225,
  },
  {
    company: 'Kaiser Permanente of Georgia',
    packageId: 37955,
  },
  {
    company: 'Kaiser Permanente of Georgia (HMO)',
    packageId: 11653,
  },
  {
    company: 'Kaiser Permanente of Hawaii (HMO)',
    packageId: 298669,
  },
  {
    company: 'Kaiser Permanente of Mid-Atlantic States',
    packageId: 16719,
  },
  {
    company: 'Kaiser Permanente of Mid-Atlantic States (HMO)',
    packageId: 353841,
  },
  {
    company: 'Kaiser Permanente of Mid-Atlantic States (Medicaid Replacement - HMO)',
    packageId: 353839,
  },
  {
    company: 'Kaiser Permanente of Mid-Atlantic States (Medicare Replacement/Advantage - HMO)',
    packageId: 353842,
  },
  {
    company: 'Kaiser Permanente of Northern California',
    packageId: 9081,
  },
  {
    company: 'Kaiser Permanente of Northern California (HMO)',
    packageId: 50877,
  },
  {
    company: 'Kaiser Permanente of Northern California (Medicare Replacement/Advantage - HMO)',
    packageId: 71111,
  },
  {
    company: 'Kaiser Permanente of Southern California (HMO)',
    packageId: 6744,
  },
  {
    company: 'Kaiser Permanente of Southern California (Medicaid Replacement - HMO)',
    packageId: 140863,
  },
  {
    company: 'Kaiser Permanente of Southern California (Medicare Replacement/Advantage - HMO)',
    packageId: 43851,
  },
  {
    company: 'Kern Health Systems - Medi-Cal (Medicaid Replacement - HMO)',
    packageId: 27304,
  },
  {
    company: 'Key Benefit Administrators',
    packageId: 20712,
  },
  {
    company: 'Key Solutions - Key Benefit Administrators - MultiPlan',
    packageId: 250802,
  },
  {
    company: 'Kingdom Healthshare - PHCS (PPO)',
    packageId: 526970,
  },
  {
    company: 'LA Care Health Plan (HMO)',
    packageId: 253862,
  },
  {
    company: 'LA Care Health Plan (Medicaid Replacement - HMO)',
    packageId: 129215,
  },
  {
    company: 'La Salle Medical Associates IPA - IEHP - Network Medical Management (Medicaid Replacement - HMO)',
    packageId: 26547,
  },
  {
    company: 'Laborers International Union of North America (Indemnity)',
    packageId: 5443,
  },
  {
    company: 'Lakeside Community Healthcare - Health Net (HMO)',
    packageId: 84979,
  },
  {
    company: 'Leading Edge Administrators - PHCS',
    packageId: 536430,
  },
  {
    company: 'Leon Medical Centers Health Plans (Medicare Replacement/Advantage - HMO)',
    packageId: 43349,
  },
  {
    company: 'LifeWise Health Plan of Oregon',
    packageId: 4340,
  },
  {
    company: 'Loma Linda University Risk Management (PPO)',
    packageId: 10145,
  },
  {
    company: 'Louisiana Healthcare Connections (Medicaid Replacement - HMO)',
    packageId: 159571,
  },
  {
    company: 'Maestro Health',
    packageId: 19010,
  },
  {
    company: 'MagnaCare',
    packageId: 11645,
  },
  {
    company: 'MagnaCare - Amalgamated Transit Union (PPO)',
    packageId: 10925,
  },
  {
    company: 'MagnaCare - First Health Network (PPO)',
    packageId: 103891,
  },
  {
    company: 'MagnaCare (PPO)',
    packageId: 17262,
  },
  {
    company: 'Maryland Physicians Care MCO - DOS prior to 1/1/2021 (Medicaid Replacement - HMO)',
    packageId: 443993,
  },
  {
    company: 'Maryland Physicians Care MCO (Medicaid Replacement - HMO)',
    packageId: 615415,
  },
  {
    company: 'MCS (Medicare Replacement/Advantage - HMO)',
    packageId: 179588,
  },
  {
    company: 'MedCost - Liberty Healthshare - Healthshare',
    packageId: 382645,
  },
  {
    company: 'MedCost - Liberty Healthshare - Healthshare (PPO)',
    packageId: 264830,
  },
  {
    company: 'Medi-Cal (Medicaid)',
    packageId: 1028,
  },
  {
    company: 'Medica Healthcare Plans - Claims with DOS 1/1/17 and forward (Medicare Replacement/Advantage - HMO)',
    packageId: 405923,
  },
  {
    company: 'Medicaid-CO (Medicaid)',
    packageId: 22523,
  },
  {
    company: 'Medicaid-DC (Medicaid)',
    packageId: 33266,
  },
  {
    company: 'Medicaid-FL (Medicaid)',
    packageId: 12299,
  },
  {
    company: 'Medicaid-LA - Institutional (Medicaid)',
    packageId: 339286,
  },
  {
    company: 'Medicaid-LA (Medicaid)',
    packageId: 2159,
  },
  {
    company: 'Medicaid-MD - CLIA Waived/PPM Labs (Medicaid)',
    packageId: 358557,
  },
  {
    company: 'Medicaid-MD (Medicaid)',
    packageId: 15157,
  },
  {
    company: 'Medicaid-MI (Medicaid)',
    packageId: 21710,
  },
  {
    company: 'Medicaid-NV (Medicaid)',
    packageId: 69455,
  },
  {
    company: 'Medicaid-NY - Institutional (Medicaid)',
    packageId: 303520,
  },
  {
    company: 'Medicaid-NY (Medicaid)',
    packageId: 1392,
  },
  {
    company: 'Medicaid-VA (Medicaid)',
    packageId: 17271,
  },
  {
    company: 'Medical Mutual',
    packageId: 12906,
  },
  {
    company: 'Medicare-AK - PBB - Part A',
    packageId: 418227,
  },
  {
    company: 'Medicare-AL (Medicare)',
    packageId: 16487,
  },
  {
    company: 'Medicare-AR (Medicare)',
    packageId: 33818,
  },
  {
    company: 'Medicare-AZ (Medicare)',
    packageId: 36797,
  },
  {
    company: 'Medicare-CA Northern (Medicare)',
    packageId: 3188,
  },
  {
    company: 'MEDICARE-CA SOUTHERN (MEDICARE)',
    packageId: 1033,
  },
  {
    company: 'Medicare-CO (Medicare)',
    packageId: 42863,
  },
  {
    company: 'Medicare-CT - Part A (Medicare)',
    packageId: 466176,
  },
  {
    company: 'Medicare-DC (Medicare)',
    packageId: 33774,
  },
  {
    company: 'Medicare-DE - Part A (Medicare)',
    packageId: 484267,
  },
  {
    company: 'Medicare-FL (Medicare)',
    packageId: 16040,
  },
  {
    company: 'Medicare-GA (Medicare)',
    packageId: 15282,
  },
  {
    company: 'Medicare-HI (Medicare)',
    packageId: 125717,
  },
  {
    company: 'Medicare-IA (Medicare)',
    packageId: 73974,
  },
  {
    company: 'Medicare-ID (Medicare)',
    packageId: 74324,
  },
  {
    company: 'Medicare-IL (Medicare)',
    packageId: 12378,
  },
  {
    company: 'Medicare-IN (Medicare)',
    packageId: 42530,
  },
  {
    company: 'Medicare-KS (Medicare)',
    packageId: 19146,
  },
  {
    company: 'Medicare-KY (Medicare)',
    packageId: 18301,
  },
  {
    company: 'Medicare-LA (Medicare)',
    packageId: 2165,
  },
  {
    company: 'Medicare-MA (Medicare)',
    packageId: 1207,
  },
  {
    company: 'Medicare-MD Montgomery/Prince George (Medicare)',
    packageId: 15159,
  },
  {
    company: 'Medicare-MD Other Counties (Medicare)',
    packageId: 15158,
  },
  {
    company: 'Medicare-ME (Medicare)',
    packageId: 98546,
  },
  {
    company: 'Medicare-MI (Medicare)',
    packageId: 21709,
  },
  {
    company: 'Medicare-MN - PBB - Part A (Medicare)',
    packageId: 413498,
  },
  {
    company: 'Medicare-MO - Medicare-Kansas City (Medicare)',
    packageId: 15923,
  },
  {
    company: 'Medicare-MS (Medicare)',
    packageId: 69010,
  },
  {
    company: 'Medicare-MT (Medicare)',
    packageId: 110937,
  },
  {
    company: 'Medicare-NC (Medicare)',
    packageId: 22065,
  },
  {
    company: 'Medicare-ND',
    packageId: 204266,
  },
  {
    company: 'Medicare-NE (Medicare)',
    packageId: 56697,
  },
  {
    company: 'Medicare-NH (Medicare)',
    packageId: 8455,
  },
  {
    company: 'Medicare-NJ (Medicare)',
    packageId: 12379,
  },
  {
    company: 'MEDICARE-NM (MEDICARE)',
    packageId: 23255,
  },
  {
    company: 'MEDICARE-NV (MEDICARE)',
    packageId: 70443,
  },
  {
    company: 'Medicare-NY - Empire (Medicare)',
    packageId: 5,
  },
  {
    company: 'Medicare-NY - Queens (Medicare)',
    packageId: 23365,
  },
  {
    company: 'Medicare-NY - Upstate (Medicare)',
    packageId: 17776,
  },
  {
    company: 'Medicare-OH (Medicare)',
    packageId: 2642,
  },
  {
    company: 'Medicare-OK (Medicare)',
    packageId: 38381,
  },
  {
    company: 'Medicare-OR (Medicare)',
    packageId: 55649,
  },
  {
    company: 'Medicare-PA (Medicare)',
    packageId: 11042,
  },
  {
    company: 'Medicare-PR (Medicare)',
    packageId: 406924,
  },
  {
    company: 'Medicare-RI - Part A (Medicare)',
    packageId: 466274,
  },
  {
    company: 'Medicare-SC (Medicare)',
    packageId: 11068,
  },
  {
    company: 'Medicare-SD (Medicare)',
    packageId: 111322,
  },
  {
    company: 'Medicare-TN (Medicare)',
    packageId: 18669,
  },
  {
    company: 'Medicare-TX (Medicare)',
    packageId: 2800,
  },
  {
    company: 'Medicare-USVI (Medicare)',
    packageId: 406151,
  },
  {
    company: 'Medicare-UT (Medicare)',
    packageId: 78620,
  },
  {
    company: 'Medicare-VA (Medicare)',
    packageId: 17272,
  },
  {
    company: 'Medicare-VT - Part A (Medicare)',
    packageId: 466281,
  },
  {
    company: 'Medicare-WA (Medicare)',
    packageId: 64369,
  },
  {
    company: 'Medicare-WI (Medicare)',
    packageId: 51187,
  },
  {
    company: 'Medicare-WV (Medicare)',
    packageId: 36214,
  },
  {
    company: 'Medicare-WY (Medicare)',
    packageId: 44336,
  },
  {
    company: 'MedImpact (PPO)',
    packageId: 162850,
  },
  {
    company: 'MedStar - Out of Network (PPO)',
    packageId: 299034,
  },
  {
    company: 'MedStar (EPO)',
    packageId: 200284,
  },
  {
    company: 'MedStar (PPO)',
    packageId: 382658,
  },
  {
    company: 'MedStar Family Choice - DC - DC Health Care Alliance (Medicaid Replacement - HMO)',
    packageId: 386959,
  },
  {
    company: 'MedStar Family Choice - DC - Healthy Families (Medicaid Replacement - HMO)',
    packageId: 206832,
  },
  {
    company: 'MedStar Family Choice - MD - Medicaid-MD (Medicaid Replacement - HMO)',
    packageId: 26028,
  },
  {
    company: 'Merchants Benefit Administrators - MultiPlan',
    packageId: 629710,
  },
  {
    company: 'Merchants Benefit Administrators - MultiPlan (PPO)',
    packageId: 481871,
  },
  {
    company: 'MercyCare Health Plans (Medicaid Replacement - HMO)',
    packageId: 26157,
  },
  {
    company: 'Meritain Health',
    packageId: 15022,
  },
  {
    company: 'Meritain Health - Aetna (POS II)',
    packageId: 294633,
  },
  {
    company: 'Meritain Health - Aetna (PPO)',
    packageId: 533737,
  },
  {
    company: 'Meritain Health - Aetna Signature Administrators (PPO)',
    packageId: 302337,
  },
  {
    company: 'Meritain Health - Welborn Health Plans (PPO)',
    packageId: 113659,
  },
  {
    company: 'Metroplus Health Plan',
    packageId: 12230,
  },
  {
    company: 'Metroplus Health Plan (HMO)',
    packageId: 60697,
  },
  {
    company: 'Metroplus Health Plan (Medicaid Replacement - HMO)',
    packageId: 25376,
  },
  {
    company: 'Metroplus Health Plan (Medicare Replacement/Advantage - HMO)',
    packageId: 72363,
  },
  {
    company: 'Moda Health - PHCS (PPO)',
    packageId: 40734,
  },
  {
    company: 'Molina Complete Care (Medicaid Replacement - HMO)',
    packageId: 448225,
  },
  {
    company: 'Molina Healthcare of FL - CHIP',
    packageId: 178972,
  },
  {
    company: 'Molina Healthcare of FL (HMO)',
    packageId: 299745,
  },
  {
    company: 'Molina Healthcare of FL (Medicaid Replacement - HMO)',
    packageId: 85253,
  },
  {
    company: 'Molina Healthcare of MS',
    packageId: 573758,
  },
  {
    company: 'Molina Healthcare of MS (Medicaid Replacement - HMO)',
    packageId: 514459,
  },
  {
    company: 'Molina Healthcare of OH (Medicaid Replacement - HMO)',
    packageId: 45614,
  },
  {
    company: 'Molina Healthcare of TX',
    packageId: 110432,
  },
  {
    company: 'Molina Healthcare of UT',
    packageId: 23335,
  },
  {
    company: 'Molina Healthcare of UT (Medicaid Replacement - HMO)',
    packageId: 83321,
  },
  {
    company: 'Molina Healthcare of WA - CHIP (Medicaid Replacement - HMO)',
    packageId: 186871,
  },
  {
    company: 'Molina Healthcare of WA (Medicaid Replacement - HMO)',
    packageId: 129699,
  },
  {
    company: 'MV Medical Management - Inland Faculty Medical Group - IEHP (Medicaid Replacement - HMO)',
    packageId: 96375,
  },
  {
    company: 'MVP Health Care of NY',
    packageId: 8907,
  },
  {
    company: 'MVP Health Care of NY - Cigna (PPO)',
    packageId: 66332,
  },
  {
    company: 'MVP Health Care of NY - Non-Par (HMO)',
    packageId: 10141,
  },
  {
    company: 'MVP Health Care of NY (EPO)',
    packageId: 20741,
  },
  {
    company: 'MVP Health Care of NY (HMO)',
    packageId: 17313,
  },
  {
    company: 'MVP Health Care of NY (Medicaid Replacement - HMO)',
    packageId: 63062,
  },
  {
    company: 'NALC Health Benefit Plan',
    packageId: 247445,
  },
  {
    company: 'NALC Health Benefit Plan (PPO)',
    packageId: 128797,
  },
  {
    company: 'NAMM California - Health Net-CA',
    packageId: 107335,
  },
  {
    company: 'NCAS - PHCS',
    packageId: 1371,
  },
  {
    company: 'Network Medical Management - BCBS-CA Blue Cross of California (Medicaid Replacement - HMO)',
    packageId: 26557,
  },
  {
    company: 'New Orleans Employers ILA',
    packageId: 2594,
  },
  {
    company: 'New York City District Council of Carpenters Welfare Fund',
    packageId: 41214,
  },
  {
    company: 'New York Hotel Trade Council - Local 6',
    packageId: 16635,
  },
  {
    company: 'New York Life (Medicare Supplement)',
    packageId: 28330,
  },
  {
    company: 'NGS TRUSTMARK HEALTH BENEFITS - TRUSTMARK HEALTH BENEFITS (PPO)',
    packageId: 62105,
  },
  {
    company: 'Northern Nevada Laborers Health and Welfare Fund (PPO)',
    packageId: 351217,
  },
  {
    company: 'Northern Nevada Operating Engineers Health and Welfare Trust Fund (PPO)',
    packageId: 164833,
  },
  {
    company: 'Northwest Administrators',
    packageId: 12165,
  },
  {
    company: 'Northwest Administrators - BCBS-WA Premera (PPO)',
    packageId: 625046,
  },
  {
    company: 'NYNM IPA - Fidelis Care (HMO)',
    packageId: 99216,
  },
  {
    company: 'NYNM IPA - WellCare of NY (Medicaid Replacement - HMO)',
    packageId: 273626,
  },
  {
    company: 'Olympus (PPO)',
    packageId: 32030,
  },
  {
    company: 'Optima Health',
    packageId: 36421,
  },
  {
    company: 'Optima Health (Medicaid Replacement - HMO)',
    packageId: 17310,
  },
  {
    company: 'Optimum HealthCare (Medicare Replacement/Advantage - HMO)',
    packageId: 70421,
  },
  {
    company: 'Optum Care Network - BCBS-NV - NV (Medicare Replacement/Advantage - HMO)',
    packageId: 560866,
  },
  {
    company: 'Optum Care Network - United Healthcare - AZ (Medicare Replacement/Advantage - HMO)',
    packageId: 133856,
  },
  {
    company: 'Optum Care Network - United Healthcare - NV (Medicare Replacement/Advantage - HMO)',
    packageId: 520115,
  },
  {
    company: 'Optum Care Network - UT (Medicare Replacement/Advantage - HMO)',
    packageId: 556806,
  },
  {
    company: 'OptumHealth - Physical Health',
    packageId: 171092,
  },
  {
    company: 'Oscar (EPO)',
    packageId: 625798,
  },
  {
    company: 'Oscar-AZ (EPO)',
    packageId: 527769,
  },
  {
    company: 'Oscar-NJ - QualCare (EPO)',
    packageId: 299062,
  },
  {
    company: 'Oscar-NY - Oscar (EPO)',
    packageId: 404783,
  },
  {
    company: 'Oscar-NY (Medicare Replacement/Advantage - HMO)',
    packageId: 572672,
  },
  {
    company: 'Oscar-TX (Medicare Replacement/Advantage - HMO)',
    packageId: 572668,
  },
  {
    company: 'Oxford Health Plans',
    packageId: 2545,
  },
  {
    company: 'Oxford Health Plans - Freedom Network',
    packageId: 5300,
  },
  {
    company: 'Oxford Health Plans - Freedom Network (EPO)',
    packageId: 270868,
  },
  {
    company: 'Oxford Health Plans - Liberty Network (EPO)',
    packageId: 289093,
  },
  {
    company: 'Oxford Health Plans - Liberty Network (HMO)',
    packageId: 9061,
  },
  {
    company: 'Oxford Health Plans - United Healthcare (EPO)',
    packageId: 441076,
  },
  {
    company: 'Oxford Health Plans - United Healthcare (HMO)',
    packageId: 323993,
  },
  {
    company: 'Oxford Health Plans - United Healthcare (PPO)',
    packageId: 34214,
  },
  {
    company: 'Oxford Health Plans (EPO)',
    packageId: 43088,
  },
  {
    company: 'Oxford Health Plans (HMO)',
    packageId: 33391,
  },
  {
    company: 'Oxford Life',
    packageId: 2394,
  },
  {
    company: 'P3 Health Partners - Alignment Health Plan (Medicare Replacement/Advantage - HMO)',
    packageId: 626272,
  },
  {
    company: 'P3 Health Partners - BCBS-NV - Nevada (Medicare Replacement/Advantage - HMO)',
    packageId: 571552,
  },
  {
    company: 'PacificSource - First Health (PPO)',
    packageId: 88186,
  },
  {
    company: 'PAI',
    packageId: 249844,
  },
  {
    company: 'PAI - First Health (Indemnity)',
    packageId: 298591,
  },
  {
    company: 'PAI (PPO)',
    packageId: 129238,
  },
  {
    company: 'Palmetto GBA - Medicare-Railroad Retirement Board (Medicare)',
    packageId: 725,
  },
  {
    company: 'Partners Health Plan - Dual Eligible (Medicare Replacement/Advantage - HMO)',
    packageId: 373801,
  },
  {
    company: 'Partnership HealthPlan of California - CHDP (Medicaid)',
    packageId: 443749,
  },
  {
    company: 'Partnership HealthPlan of California (Medicaid Replacement - HMO)',
    packageId: 11265,
  },
  {
    company: 'Peak TPA - Catholic Health - PACE - Dual Eligible (Medicare Replacement/Advantage - HMO)',
    packageId: 616047,
  },
  {
    company: 'Peak TPA - PACE - Dual Eligible (Medicare Replacement/Advantage - HMO)',
    packageId: 186140,
  },
  {
    company: 'Peak TPA - Wyoming Pace Program - PACE - Dual Eligible (Medicare Replacement/Advantage - HMO)',
    packageId: 500010,
  },
  {
    company: 'Peak TPA (Medicare Replacement/Advantage - HMO)',
    packageId: 572589,
  },
  {
    company: 'Peoples Health (Medicare Replacement/Advantage - HMO)',
    packageId: 4612,
  },
  {
    company: 'PHCS - CCMSI (PPO)',
    packageId: 43968,
  },
  {
    company: 'PHCS - Group & Pension Administrators (PPO)',
    packageId: 5624,
  },
  {
    company: 'Philadelphia American Life Insurance',
    packageId: 4851,
  },
  {
    company: 'Philadelphia American Life Insurance - PHCS (PPO)',
    packageId: 215886,
  },
  {
    company: 'Physicians Care Network-IL (PPO)',
    packageId: 20612,
  },
  {
    company: 'Pioneer Provider Medical Group - Change Healthcare - Health Net (HMO)',
    packageId: 46657,
  },
  {
    company: 'Plan De Salud',
    packageId: 51576,
  },
  {
    company: 'Plumbers and Pipefitters (Medicare Supplement)',
    packageId: 138980,
  },
  {
    company: 'Plumbers and Pipefitters (PPO)',
    packageId: 143268,
  },
  {
    company: 'Point Comfort Underwriters - Refugee Medical Assistance Program for Adults',
    packageId: 420207,
  },
  {
    company: 'PPO Plus - BeneSys (PPO)',
    packageId: 29393,
  },
  {
    company: 'PPO Plus - Insurance Systems (PPO)',
    packageId: 64515,
  },
  {
    company: 'Preferred Care Partners (Medicare Replacement/Advantage - HMO)',
    packageId: 407531,
  },
  {
    company: 'PreferredOne',
    packageId: 241074,
  },
  {
    company: 'Priority Health (HMO)',
    packageId: 76675,
  },
  {
    company: 'Priority Health (POS)',
    packageId: 33168,
  },
  {
    company: 'Prominence Administrative Services - S&S HealthCare Strategies - First Health (PPO)',
    packageId: 572889,
  },
  {
    company: 'Prominence Health Plan',
    packageId: 11548,
  },
  {
    company: 'Prominence Health Plan - First Health (PPO)',
    packageId: 617463,
  },
  {
    company: 'Prominence Health Plan - Nevada Medicare Members (Medicare Replacement/Advantage - HMO)',
    packageId: 295921,
  },
  {
    company: 'Prominence Health Plan (HMO)',
    packageId: 137311,
  },
  {
    company: 'PROSSAM',
    packageId: 106643,
  },
  {
    company: 'Protective Life Insurance',
    packageId: 904,
  },
  {
    company: 'Providence Health Plan',
    packageId: 10819,
  },
  {
    company:
      'Rady Childrens Health Network - Childrens Physicians Medical Group - BCBS-CA Blue Cross of California (HMO)',
    packageId: 264737,
  },
  {
    company:
      'RADY CHILDRENS HEALTH NETWORK - CHILDRENS PHYSICIANS MEDICAL GROUP - BLUE SHIELD OF CALIFORNIA PROMISE HEALTH PLAN (MEDICAID REPLACEMENT - HMO)',
    packageId: 524053,
  },
  {
    company: 'RBC Insurance',
    packageId: 9220,
  },
  {
    company: 'READY BENEFIT',
    packageId: 615908,
  },
  {
    company: 'Regal Medical Group - Health Net-CA (HMO)',
    packageId: 28870,
  },
  {
    company: 'Regal Medical Group - Health Net-CA (Medicaid Replacement - HMO)',
    packageId: 69094,
  },
  {
    company: 'Regence (Medicare Replacement/Advantage - HMO)',
    packageId: 281770,
  },
  {
    company: 'Regional Care Inc - PHCS (PPO)',
    packageId: 522673,
  },
  {
    company: 'Relay Health - Integra Managed Care',
    packageId: 276899,
  },
  {
    company: 'Relay Health - Integra Managed Care - MLTC (Medicaid Replacement - HMO)',
    packageId: 535556,
  },
  {
    company: 'Religious Comprehensive Trust - Christian Brothers Services',
    packageId: 18736,
  },
  {
    company: 'RPA - Harmony Health Plan (HMO)',
    packageId: 107368,
  },
  {
    company: 'Sana Benefits',
    packageId: 520290,
  },
  {
    company: 'Sanford Health Plan',
    packageId: 329608,
  },
  {
    company: 'Santa Clara Family Health Plan (Medicaid Replacement - HMO)',
    packageId: 126484,
  },
  {
    company: 'SCAN Health Plan',
    packageId: 59599,
  },
  {
    company: 'SCAN Health Plan California (Medicare Replacement/Advantage - HMO)',
    packageId: 22556,
  },
  {
    company: 'Select Benefit Administrators of America',
    packageId: 46837,
  },
  {
    company: 'SelectHealth - PHCS (POS)',
    packageId: 480905,
  },
  {
    company: 'SelectHealth (HMO)',
    packageId: 49593,
  },
  {
    company: 'SelectHealth (Medicare Replacement/Advantage - HMO)',
    packageId: 204312,
  },
  {
    company: 'SelectHealth (PPO)',
    packageId: 85611,
  },
  {
    company: 'Self Funded Plans',
    packageId: 10089,
  },
  {
    company: 'Selman & Company (TRICARE Supplement)',
    packageId: 92,
  },
  {
    company: 'Senior Whole Health (Medicaid Replacement - HMO)',
    packageId: 280367,
  },
  {
    company: 'Sentry Insurance',
    packageId: 2690,
  },
  {
    company: 'Service Ontario - Foreign Payer',
    packageId: 325026,
  },
  {
    company: 'SGIC - MultiPlan',
    packageId: 526149,
  },
  {
    company: 'SGIC - MultiPlan (Indemnity)',
    packageId: 496426,
  },
  {
    company: 'Sharity Ministries - PHCS (PPO)',
    packageId: 576522,
  },
  {
    company: 'Sharp Community Medical Group - Health Net (HMO)',
    packageId: 107677,
  },
  {
    company: 'Sierra Health & Life',
    packageId: 4240,
  },
  {
    company: 'Sierra Health & Life (Medicare Replacement/Advantage - PPO)',
    packageId: 56250,
  },
  {
    company: 'Sierra Healthcare - EBMS (PPO)',
    packageId: 248542,
  },
  {
    company: 'Significa Benefit Services',
    packageId: 11390,
  },
  {
    company: 'Simply Healthcare (Medicaid Replacement - HMO)',
    packageId: 526903,
  },
  {
    company: 'Simply Healthcare (Medicare Replacement/Advantage - HMO)',
    packageId: 526898,
  },
  {
    company: 'Somos - BCBS-NY Empire (Medicaid Replacement - HMO)',
    packageId: 610232,
  },
  {
    company: 'Somos - HIP - EmblemHealth (Medicaid Replacement - HMO)',
    packageId: 615560,
  },
  {
    company: 'Southern Benefit Administrators (Medicare Supplement)',
    packageId: 282457,
  },
  {
    company: 'Southwest Service Administrators',
    packageId: 6117,
  },
  {
    company: 'Southwest Service Administrators - Beech Street - UFCW Local 711 (PPO)',
    packageId: 497291,
  },
  {
    company: 'Southwest Service Administrators (PPO)',
    packageId: 82662,
  },
  {
    company: 'StarMed - PHCS (PPO)',
    packageId: 626815,
  },
  {
    company: 'State Farm Insurance',
    packageId: 11540,
  },
  {
    company: 'Staywell Senior Care - PACE - Dual Eligible (Medicare Replacement/Advantage - HMO)',
    packageId: 342748,
  },
  {
    company: 'Sunshine State Health Plan - CHIP',
    packageId: 272602,
  },
  {
    company: 'Sunshine State Health Plan (Medicaid Replacement - HMO)',
    packageId: 88072,
  },
  {
    company: 'Sutter Pacific Medical Foundation - Health Net-CA (Medicare Replacement/Advantage - HMO)',
    packageId: 42893,
  },
  {
    company: 'Tall Tree Administrators - Free Market Administrators - PHCS (PPO)',
    packageId: 565650,
  },
  {
    company: 'Tall Tree Administrators - PHCS (PPO)',
    packageId: 379546,
  },
  {
    company: 'Teachers Health Trust - Well Health (POS)',
    packageId: 313483,
  },
  {
    company: 'Teachers Health Trust (PPO)',
    packageId: 401249,
  },
  {
    company: 'Teamsters Local Union',
    packageId: 16696,
  },
  {
    company: 'The Health Plan',
    packageId: 90471,
  },
  {
    company: 'The Health Plan - PHCS (EPO)',
    packageId: 601644,
  },
  {
    company: 'The Loomis Company',
    packageId: 79496,
  },
  {
    company: 'The Loomis Company - HCVM',
    packageId: 10361,
  },
  {
    company: 'The Loomis Company - MultiPlan (PPO)',
    packageId: 397011,
  },
  {
    company: 'The Loomis Company - One Share Health - First Health Network (PPO)',
    packageId: 592005,
  },
  {
    company: 'The Loomis Company - PHCS',
    packageId: 544496,
  },
  {
    company: 'Transamerica',
    packageId: 31951,
  },
  {
    company: 'Tricare East - Humana Military Tricare - Prime (TRICARE)',
    packageId: 476374,
  },
  {
    company: 'Tricare East - Humana Military Tricare - Select (TRICARE - PPO)',
    packageId: 476371,
  },
  {
    company: 'Tricare East - Humana Military Tricare (TRICARE)',
    packageId: 475714,
  },
  {
    company: 'Tricare for Life',
    packageId: 633863,
  },
  {
    company: 'Tricare for Life (TRICARE - Medicare Supplement)',
    packageId: 60966,
  },
  {
    company: 'Tricare West - Health Net Federal Services - Prime (TRICARE)',
    packageId: 476401,
  },
  {
    company: 'Tricare West - Health Net Federal Services - Select (TRICARE - PPO)',
    packageId: 476381,
  },
  {
    company: 'Tricare West - Health Net Federal Services (TRICARE)',
    packageId: 476403,
  },
  {
    company: 'Tricare West - Overseas - Active Duty (TRICARE)',
    packageId: 1806,
  },
  {
    company: 'Tricare West - Overseas - Eurasia-Africa (TRICARE)',
    packageId: 9099,
  },
  {
    company: 'Tristar Benefit Administrators (Indemnity)',
    packageId: 28567,
  },
  {
    company: 'TRUSTMARK HEALTH BENEFITS KS - TRUSTMARK HEALTH BENEFITS',
    packageId: 18105,
  },
  {
    company: 'TRUSTMARK HEALTH BENEFITS KS - TRUSTMARK HEALTH BENEFITS - AETNA SIGNATURE ADMINISTRATORS (PPO)',
    packageId: 131096,
  },
  {
    company: 'Trustmark Health Benefits MD, PA, IL - Trustmark Health Benefits',
    packageId: 45352,
  },
  {
    company: 'Trustmark Health Benefits MD, PA, IL - Trustmark Health Benefits - Aetna Signature Administrators (PPO)',
    packageId: 135500,
  },
  {
    company: 'Trustmark Small Business',
    packageId: 2828,
  },
  {
    company: 'Tufts Health Plan',
    packageId: 8277,
  },
  {
    company: 'Tufts Health Plan (HMO)',
    packageId: 805,
  },
  {
    company: 'Tufts Health Plan (Medicare Replacement/Advantage - HMO)',
    packageId: 1245,
  },
  {
    company: 'Tufts Health Plan (POS)',
    packageId: 21423,
  },
  {
    company: 'UFCW',
    packageId: 559133,
  },
  {
    company: 'UFCW National Health and Welfare Fund',
    packageId: 3215,
  },
  {
    company: 'UFCW National Health and Welfare Fund - Benefit Plan Administration',
    packageId: 15476,
  },
  {
    company: 'UMR',
    packageId: 2681,
  },
  {
    company: 'UMR - Arizona Care Network (PPO)',
    packageId: 545299,
  },
  {
    company: 'UMR - Benesight',
    packageId: 10415,
  },
  {
    company: 'UMR - CBA - Healthcare Savings Network (PPO)',
    packageId: 51213,
  },
  {
    company: 'UMR - Deseret - United Healthcare (PPO)',
    packageId: 249312,
  },
  {
    company: 'UMR - PHCS',
    packageId: 59037,
  },
  {
    company: 'UMR - United Healthcare',
    packageId: 149947,
  },
  {
    company: 'UMR - United Healthcare - Options PPO (Indemnity)',
    packageId: 294567,
  },
  {
    company: 'UMR - United Healthcare (PPO)',
    packageId: 76979,
  },
  {
    company: 'UMR - USHEALTH Group (PPO)',
    packageId: 615372,
  },
  {
    company: 'UMR (POS)',
    packageId: 78672,
  },
  {
    company: 'UMR (PPO)',
    packageId: 98404,
  },
  {
    company: 'UMWA Health and Retirement Funds (Indemnity)',
    packageId: 126258,
  },
  {
    company: 'UniCare',
    packageId: 8714,
  },
  {
    company: 'Unified Life Insurance Company',
    packageId: 82297,
  },
  {
    company: 'Unified Life Insurance Company - MultiPlan (Indemnity)',
    packageId: 269708,
  },
  {
    company: 'Unified Life Insurance Company - MultiPlan (PPO)',
    packageId: 190552,
  },
  {
    company: 'Union Labor Life Insurance',
    packageId: 20,
  },
  {
    company: 'United Health Plus - PHCS',
    packageId: 587807,
  },
  {
    company: 'United Healthcare',
    packageId: 982,
  },
  {
    company: 'United Healthcare - Dual Eligible (Medicare Replacement/Advantage - HMO)',
    packageId: 300470,
  },
  {
    company: 'United Healthcare - Dual Eligible (Medicare Replacement/Advantage - PPO)',
    packageId: 355285,
  },
  {
    company: 'United Healthcare - Harvard Pilgrim Health Care',
    packageId: 39783,
  },
  {
    company: 'United Healthcare - Medica (POS)',
    packageId: 67455,
  },
  {
    company: 'United Healthcare - Neighborhood Health Partnership (HMO)',
    packageId: 545293,
  },
  {
    company: 'United Healthcare - Neighborhood Health Partnership (POS)',
    packageId: 126542,
  },
  {
    company: 'United Healthcare - Northwell Health (PPO)',
    packageId: 200576,
  },
  {
    company: 'United Healthcare - The Empire Plan',
    packageId: 3815,
  },
  {
    company: 'United Healthcare - The Empire Plan (PPO)',
    packageId: 27233,
  },
  {
    company: 'United Healthcare - United Healthcare Community Plan (Medicare Replacement/Advantage - PPO)',
    packageId: 77519,
  },
  {
    company: 'United Healthcare (EPO)',
    packageId: 40793,
  },
  {
    company: 'United Healthcare (HMO)',
    packageId: 2829,
  },
  {
    company: 'United Healthcare (Indemnity)',
    packageId: 35115,
  },
  {
    company: 'United Healthcare (Medicaid)',
    packageId: 633801,
  },
  {
    company: 'United Healthcare (Medicare Replacement/Advantage - HMO)',
    packageId: 2718,
  },
  {
    company: 'United Healthcare (Medicare Replacement/Advantage - PPO)',
    packageId: 70322,
  },
  {
    company: 'United Healthcare (POS)',
    packageId: 22045,
  },
  {
    company: 'United Healthcare (PPO)',
    packageId: 10459,
  },
  {
    company: 'UNITED HEALTHCARE COMMUNITY PLAN - BAYOU HEALTH - BEHAVIORAL HEALTH',
    packageId: 373871,
  },
  {
    company: 'United Healthcare Community Plan (Medicaid Replacement - HMO)',
    packageId: 85239,
  },
  {
    company: 'United Healthcare Community Plan (Medicare Replacement/Advantage - HMO)',
    packageId: 162856,
  },
  {
    company: 'United Healthcare Community Plan-AZ (Medicaid Replacement - HMO)',
    packageId: 24971,
  },
  {
    company: 'United Healthcare Community Plan-AZ (Medicare Replacement/Advantage - HMO)',
    packageId: 437998,
  },
  {
    company: 'United Healthcare Community Plan-FL (Medicaid Replacement - HMO)',
    packageId: 73078,
  },
  {
    company: 'United Healthcare Community Plan-LA - Bayou Health (Medicaid Replacement - HMO)',
    packageId: 316200,
  },
  {
    company: 'United Healthcare Community Plan-LA - Dual Eligible (Medicare Replacement/Advantage - HMO)',
    packageId: 407106,
  },
  {
    company: 'United Healthcare Community Plan-LA (Medicare Replacement/Advantage - HMO)',
    packageId: 622888,
  },
  {
    company: 'United Healthcare Community Plan-NY - Dual Eligible (Medicare Replacement/Advantage - HMO)',
    packageId: 289092,
  },
  {
    company: 'United Healthcare Community Plan-NY (Medicaid Replacement - HMO)',
    packageId: 11425,
  },
  {
    company: 'United Healthcare Community Plan-NY (Medicare Replacement/Advantage - HMO)',
    packageId: 11426,
  },
  {
    company: 'United Healthcare Community Plan-VA (Medicaid Replacement - HMO)',
    packageId: 448219,
  },
  {
    company: 'United Healthcare Community Plan-WA (Medicaid Replacement - HMO)',
    packageId: 173741,
  },
  {
    company: 'United Healthcare of the Mid-Atlantic',
    packageId: 52990,
  },
  {
    company: 'United Healthcare of the Mid-Atlantic - United Healthcare Community Plan-MD (Medicaid Replacement - HMO)',
    packageId: 58707,
  },
  {
    company: 'United Healthcare of the Mid-Atlantic (POS)',
    packageId: 32631,
  },
  {
    company: 'United Healthcare StudentResources - MEGA Life & Health Insurance Company - United Healthcare',
    packageId: 19028,
  },
  {
    company: 'United Healthcare StudentResources - MultiPlan (PPO)',
    packageId: 363381,
  },
  {
    company: 'United Healthcare StudentResources - United Healthcare',
    packageId: 40939,
  },
  {
    company: 'United Healthcare StudentResources - United Healthcare (PPO)',
    packageId: 65605,
  },
  {
    company: 'United Healthcare West (Medicare Replacement/Advantage - HMO)',
    packageId: 58750,
  },
  {
    company: 'United Healthcare West (Medicare Replacement/Advantage - PPO)',
    packageId: 40378,
  },
  {
    company: 'United Healthcare West (PPO)',
    packageId: 4953,
  },
  {
    company: 'United Insurance Company of America',
    packageId: 17889,
  },
  {
    company: 'UnitedHealth Shared Services - GEHA (PPO)',
    packageId: 127679,
  },
  {
    company: 'UnitedHealth Shared Services - United Healthcare',
    packageId: 163857,
  },
  {
    company: 'UnitedHealth Shared Services - United Healthcare - Harvard Pilgrim Health Care - Options PPO (HMO)',
    packageId: 297634,
  },
  {
    company: 'UnitedHealth Shared Services - United Healthcare (PPO)',
    packageId: 85234,
  },
  {
    company: 'UnitedHealthOne',
    packageId: 1467,
  },
  {
    company: 'UnitedHealthOne - MultiPlan',
    packageId: 74659,
  },
  {
    company: 'UnitedHealthOne - MultiPlan (Indemnity)',
    packageId: 581782,
  },
  {
    company: 'University Health Alliance (PPO)',
    packageId: 336893,
  },
  {
    company: 'UPMC Health Plan - UPMC Community Health Choices (Medicaid Replacement - HMO)',
    packageId: 477528,
  },
  {
    company: 'UPMC Health Plan (EPO)',
    packageId: 44879,
  },
  {
    company: 'USHEALTH Group - Freedom Life Insurance Company of America - First Health (PPO)',
    packageId: 386118,
  },
  {
    company: 'VA Premier Health Plan - Elite Plus (Medicaid Replacement - HMO)',
    packageId: 457689,
  },
  {
    company: 'VA Premier Health Plan - Medallion 4 (Medicaid Replacement - HMO)',
    packageId: 510868,
  },
  {
    company: 'Valley Health Plan',
    packageId: 48303,
  },
  {
    company: 'ValueOptions',
    packageId: 155701,
  },
  {
    company: 'Vantage Health Plan (HMO)',
    packageId: 31013,
  },
  {
    company: 'Vantage Health Plan (Medicare Replacement/Advantage - HMO)',
    packageId: 101565,
  },
  {
    company: 'Vantage Health Plan (Medicare Replacement/Advantage)',
    packageId: 563594,
  },
  {
    company: 'Vantage Health Plan (POS)',
    packageId: 300161,
  },
  {
    company: 'Vantage Health Plan (PPO)',
    packageId: 115193,
  },
  {
    company: 'VillageCareMax - Dual Eligible (Medicare Replacement/Advantage - HMO)',
    packageId: 482779,
  },
  {
    company: 'VILLAGECAREMAX - MANAGED LONG TERM CARE',
    packageId: 189435,
  },
  {
    company: 'Viva Health',
    packageId: 283757,
  },
  {
    company: 'VNSNY Choice (Medicaid Replacement - HMO)',
    packageId: 199501,
  },
  {
    company: 'VNSNY Choice (Medicaid Replacement)',
    packageId: 239497,
  },
  {
    company: 'VNSNY Choice (Medicare Replacement/Advantage - HMO)',
    packageId: 62152,
  },
  {
    company: 'WEB-TPA',
    packageId: 8112,
  },
  {
    company: 'WEB-TPA - Aetna Signature Administrators (PPO)',
    packageId: 336527,
  },
  {
    company: 'WEB-TPA - Cofinity (PPO)',
    packageId: 483553,
  },
  {
    company: 'WEB-TPA - First Choice (PPO)',
    packageId: 186294,
  },
  {
    company: 'WEB-TPA (Indemnity)',
    packageId: 153127,
  },
  {
    company: 'WEB-TPA (PPO)',
    packageId: 72821,
  },
  {
    company: 'WellCare - Dual Eligible (Medicare Replacement/Advantage - HMO)',
    packageId: 311799,
  },
  {
    company: 'WellCare (Medicare Replacement/Advantage - HMO)',
    packageId: 32989,
  },
  {
    company: 'WellCare (Medicare Replacement/Advantage - PPO)',
    packageId: 308642,
  },
  {
    company: 'WellCare of AZ (Medicare Replacement/Advantage - HMO)',
    packageId: 526775,
  },
  {
    company: 'WellCare of FL',
    packageId: 15202,
  },
  {
    company: 'WellCare of FL - Staywell - CHIP',
    packageId: 23190,
  },
  {
    company: 'WellCare of FL - Staywell - DOS Prior to 10/1/21 (Medicaid Replacement - HMO)',
    packageId: 16980,
  },
  {
    company: 'WellCare of FL (Medicare Replacement/Advantage - HMO)',
    packageId: 313623,
  },
  {
    company: 'WellCare of LA (Medicare Replacement/Advantage - HMO)',
    packageId: 253171,
  },
  {
    company: 'WellCare of NY (Medicare Replacement/Advantage - HMO)',
    packageId: 63371,
  },
  {
    company: 'Wellfleet',
    packageId: 250,
  },
  {
    company: 'WELLFLEET - FIRST HEALTH NETWORK (PPO)',
    packageId: 33519,
  },
  {
    company: 'WELLFLEET (PPO)',
    packageId: 85542,
  },
  {
    company: 'Wilson-McShane - MultiPlan (EPO)',
    packageId: 386128,
  },
  {
    company: 'Wilson-McShane - Union Construction Workers - Beech Street (PPO)',
    packageId: 119576,
  },
  {
    company: 'WorldTrips (PPO)',
    packageId: 12578,
  },
  {
    company: 'WPS Health Plan (POS)',
    packageId: 3080,
  },
  {
    company: 'WPS Health Plan (PPO)',
    packageId: 236292,
  },
  {
    company: 'Yale Health Plan (HMO)',
    packageId: 8817,
  },
  {
    company: 'Zenith American Solutions',
    packageId: 91318,
  },
  {
    company: 'Zenith American Solutions - Aetna (PPO)',
    packageId: 623436,
  },
]

export default insurancePlans
