import { FC } from 'react'

import Tokens from 'config/tokens'
import { Clock } from 'phosphor-react'
import { useTranslation } from 'react-i18next'

import Box from '@mui/system/Box'

import Spinner from 'ui/Spinner'

const SavePartnerShipEditButton: FC<{ loading: boolean; isValid: boolean }> = ({ loading, isValid }) => {
  const { t } = useTranslation()
  return (
    <Box width={175} display="flex" alignItems="center" justifyContent="center">
      {t('components:modules.Appointments.AppointmentPartnershipCard.acceptButton.label')}
      <Box ml={1}>
        {!loading ? (
          <Clock
            size={14}
            color={!isValid ? Tokens.color.neutral.grey[82] : Tokens.color.neutral.white.base}
            style={{ position: 'relative', top: 1 }}
          />
        ) : (
          <Spinner size="small" foregroundColor={loading ? Tokens.color.neutral.black.base : undefined} />
        )}
      </Box>
    </Box>
  )
}

export default SavePartnerShipEditButton
