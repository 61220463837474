import Tokens from 'config/tokens'
import styled from 'styled-components'

export const StyledSection = styled.div`
  .radio {
    display: none;
  }
  .label {
    display: block;
    padding: calc(${Tokens.rhythm} * 2) 0;
    margin: ${Tokens.rhythm} 0;
    background-color: ${Tokens.color.neutral.white.base};
    color: ${Tokens.color.brand.red.light};
    border: ${Tokens.border.size} solid;
    border-radius: ${Tokens.border.radius};
    font-size: ${Tokens.font.size.paragraph.large};
    font-weight: ${Tokens.font.weight.semiBold};
    text-align: center;
    transition: ${Tokens.transition.timing.easeOut};
    transition-duration: ${Tokens.transition.duration[2]};
    user-select: none;
  }
  input:disabled + label {
    color: ${Tokens.color.neutral.grey[219]};
  }
  input:enabled + label {
    cursor: pointer;
  }
  input:checked + label {
    background-color: ${Tokens.color.brand.red.light};
    color: ${Tokens.color.neutral.white.base};
  }
  input:disabled:checked + label {
    background-color: ${Tokens.color.neutral.grey[219]};
    color: ${Tokens.color.neutral.white.base};
  }
`

export type TimeSlotProps = {
  children: JSX.Element | string
  value: string
  name: string
  checked: boolean
  disabled?: boolean
}

function TimeSlot({ children, value, name, checked, disabled }: TimeSlotProps): JSX.Element {
  return (
    <StyledSection key={value}>
      <input
        name={name}
        type="radio"
        className="radio"
        id={value}
        value={value}
        checked={checked}
        disabled={disabled}
      />
      <label className="label" htmlFor={value}>
        {children}
      </label>
    </StyledSection>
  )
}

export default TimeSlot
