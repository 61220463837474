import * as Yup from 'yup'

// import { E164_PHONE_NUMBER_REGEXP } from '../../utils/phonenumber-utils';
import makeRequired from '../helpers/makeRequired'

const address1SchemaBase = Yup.string()

export const address1SchemaRequired = makeRequired(address1SchemaBase, 'validation:address.address1.required')

export const address2SchemaBase = Yup.string().nullable()

export const unitSchema = Yup.string().nullable()

export const specialInstructionsSchema = Yup.string().max(255).nullable()

export const gateCodeSchema = Yup.string().nullable()

const citySchemaBase = Yup.string()
export const citySchemaRequired = makeRequired(citySchemaBase, 'validation:address.city.required')

const stateSchemaBase = Yup.string()
export const stateSchemaRequired = makeRequired(stateSchemaBase, 'validation:address.state.required')

export const zipSchemaBase = Yup.string().matches(/^\d{5}(-\d{4})?$/, 'validation:address.zip.match')
export const zipSchemaRequired = makeRequired(zipSchemaBase, 'validation:address.zip.required')
export const zipsSchemaBase = Yup.array().of(zipSchemaBase)

// const phoneNumberSchemaBase = Yup.string().matches(
//   E164_PHONE_NUMBER_REGEXP,
//   'Must be a valid phone number, e.g. (555) 867-5309',
// );
// export const phoneNumberSchemaRequired = makeRequired(
//   phoneNumberSchemaBase,
//   'Phone # is required',
// );

export const addressLatSchema = Yup.number().min(-90).max(90)
export const addressLngSchema = Yup.number().min(-180).max(180)

export const addressLatRequired = makeRequired(addressLatSchema, 'validation:appointment.addressLat.required')
export const addressLngRequired = makeRequired(addressLngSchema, 'validation:appointment.addressLng.required')

export type AddressSchema = {
  address: {
    address1?: string | null
    address2?: string | null
    city?: string | null
    state?: string | null
    zip?: string | null
  }

  geoLocation: {
    addressLat?: number | null
    addressLng?: number | null
  }

  unit?: string | null
  specialInstructions?: string | null
  gateCode?: string | null

  marketId?: number | null
  zoneId?: number | null
}

export type AddressSchemaWithoutMarketAndZone = {
  address: {
    address1?: string | null
    address2?: string | null
    city?: string | null
    state?: string | null
    zip?: string | null
  }

  geoLocation: {
    addressLat?: number | null
    addressLng?: number | null
  }

  unit?: string | null
  specialInstructions?: string | null
  gateCode?: string | null
}

export const addressSchema = Yup.object().shape({
  address: Yup.object().shape({
    address1: address1SchemaRequired,
    address2: address2SchemaBase,
    city: citySchemaRequired,
    state: stateSchemaRequired,
    zip: zipSchemaRequired,
  }),

  // we should fetch geoLocation when saving if it doesn't
  geoLocation: Yup.object().shape({
    addressLat: Yup.number().min(-90).max(90).nullable(),
    addressLng: Yup.number().min(-180).max(180).nullable(),
  }),

  unit: unitSchema,
  specialInstructions: specialInstructionsSchema,
  gateCode: gateCodeSchema,

  marketId: Yup.number().required(),
  zoneId: Yup.number().required(),
})

export const addressSchemaWithoutMarketAndZone = Yup.object().shape({
  address: Yup.object().shape({
    address1: address1SchemaRequired,
    address2: address2SchemaBase,
    city: citySchemaRequired,
    state: stateSchemaRequired,
    zip: zipSchemaRequired,
  }),

  // we should fetch geoLocation when saving if it doesn't
  geoLocation: Yup.object().shape({
    addressLat: addressLatSchema.nullable(),
    addressLng: addressLngSchema.nullable(),
  }),

  unit: unitSchema,
  specialInstructions: specialInstructionsSchema,
  gateCode: gateCodeSchema,
})
