import { SexEnum } from 'generated/graphql'
import { useTranslation } from 'react-i18next'

export type SexProps = {
  sex?: SexEnum | null
}

function Sex({ sex }: SexProps): JSX.Element {
  const { t } = useTranslation()

  return <>{t('common:sex', { context: sex ?? null })}</>
}

export default Sex
