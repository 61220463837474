import { Table as TableIcon } from 'phosphor-react'
import { useTranslation } from 'react-i18next'

import { Paper } from '@mui/material'
import { Box } from '@mui/system'

import EmptyState from 'ui/EmptyState'

import { selectDispatches } from '../selectors'
import useGetAppointmentQueryContext from '../useGetAppointmentQueryContext'

import DispatchesTable from './DispatchesTable'

const Dispatches = () => {
  const { t } = useTranslation()
  const { data } = useGetAppointmentQueryContext()

  const dispatches = selectDispatches(data)

  return (
    <Paper style={{ padding: '16px' }}>
      {/* Empty State | Dispatches Table */}
      {!dispatches ? (
        <Box sx={{ pt: 8 }}>
          <EmptyState icon={TableIcon} message={t('components:modules.Appointment.Dispatches.emptyState.body')} slim />
        </Box>
      ) : (
        <DispatchesTable dispatches={dispatches} />
      )}

      {/* Add Responder button */}
      {/* <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end' }} paddingTop={4}>
        <Button disabled={true} onClick={() => {}}>
          {t('actions:appointment.addResponder')}
        </Button>
      </Box> */}
    </Paper>
  )
}

export default Dispatches
