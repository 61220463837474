import LabeledItem from 'ui/LabeledItem'
import Address from 'ui/TextFormatters/Address'

type AddressReadProps = {
  patientId: number | null
  address1?: string | null
  address2?: string | null
  city?: string | null
  state?: string | null
  zip?: string | null
  specialInstructions?: string | null
  unit?: string | null
}

const AddressRead = ({
  address1,
  address2,
  city,
  state,
  zip,
  specialInstructions,
  unit,
}: AddressReadProps): JSX.Element => (
  <>
    <LabeledItem label="Address:" inline>
      <Address
        address1={address1 ?? ''}
        address2={address2 ?? ''}
        city={city ?? ''}
        state={state ?? ''}
        zip={zip ?? ''}
      />
    </LabeledItem>
    <LabeledItem label="Apt, Suite, Unit, Bldg #:" inline>
      {unit ?? ''}
    </LabeledItem>
    <LabeledItem label="Special Instructions:" inline>
      {specialInstructions ?? ''}
    </LabeledItem>
  </>
)

export default AddressRead
