import { FC } from 'react'

import isNilOrEmpty from 'fp/isNilOrEmpty'
import { useTranslation } from 'react-i18next'

export type AppointmentUpdateReasonTypes = {
  appointmentCancellation?: string | null
  appointmentReschedule?: string | null
  cancelledReasonOther?: string | null
}

const I18N_CANCELLATION_REASON_KEY = 'glossary:appointmentCancellationReason'
const I18N_RESCHEDULE_REASON_KEY = 'glossary:rescheduleReason'

const CancellationOrRescheduledReason: FC<AppointmentUpdateReasonTypes> = ({
  appointmentCancellation,
  appointmentReschedule,
  cancelledReasonOther,
}) => {
  const { t } = useTranslation()

  if (!appointmentCancellation && !appointmentReschedule && !cancelledReasonOther) {
    return t('common:emptyValue')
  }

  if (cancelledReasonOther) {
    return (
      <>
        {t(I18N_CANCELLATION_REASON_KEY, { context: appointmentCancellation })}
        {!isNilOrEmpty(cancelledReasonOther) && <> - {cancelledReasonOther}</>}
      </>
    )
  }

  if (appointmentCancellation) {
    return t(I18N_CANCELLATION_REASON_KEY, { context: appointmentCancellation })
  }
  return t(I18N_RESCHEDULE_REASON_KEY, { context: appointmentReschedule })
}

export default CancellationOrRescheduledReason
