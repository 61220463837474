import { TypePolicy, FieldPolicy } from '@apollo/client'

const Query: TypePolicy = {
  fields: {
    appointmentsList: {
      // Don't cache separate results based on
      // any of this field's arguments.
      keyArgs: ['appointmentId'],
      // Concatenate the incoming list items with
      // the existing list items.
      merge(existing = [], incoming) {
        return [...existing, ...incoming]
      },
    } as FieldPolicy,

    searchAppointments: {
      keyArgs: ['appointmentId'],
      merge(existing = [], incoming, { args }) {
        if (args?.offset === 0) {
          return [...incoming]
        }
        return [...existing, ...incoming]
      },
    } as FieldPolicy,
  },
}

export default Query
