import { LanguagesEnum } from 'generated/graphql'

import Checkbox from '@mui/material/Checkbox'
import MenuItem from '@mui/material/MenuItem'
import TextField, { OutlinedTextFieldProps } from '@mui/material/TextField'

import { formatLanguage, LanguageNullable } from 'ui/TextFormatters/Languages/Languages'

export type PreferredLanguageSelectProps = Partial<Omit<OutlinedTextFieldProps, 'onChange'>> & {
  value?: LanguageNullable[]
  onChange: (newValue: LanguagesEnum[]) => void
}

const removeNullValues = (values: LanguageNullable[]) => values.filter((value) => value !== null)

const PreferredLanguageSelect = ({
  onChange,
  SelectProps,
  value,
  ...props
}: PreferredLanguageSelectProps): JSX.Element => {
  const handleOnChange = (e: React.ChangeEvent<{ value: unknown }>) => {
    const newValue = (e.target.value as LanguagesEnum[]) ?? []

    onChange(newValue)
  }

  const valuesWithoutNull = removeNullValues(value ?? [])

  return (
    <TextField
      fullWidth
      name="languages"
      {...props}
      value={valuesWithoutNull}
      select
      label="Preferred Language (Optional)"
      SelectProps={{
        // ...SelectProps,
        multiple: true,
        renderValue: (selected) => {
          const selectedArr = selected as LanguagesEnum
          return Object.values(selectedArr)
            .map((language) => formatLanguage[language as LanguagesEnum])
            .join(', ')
        },
      }}
      onChange={handleOnChange}
    >
      {Object.values(LanguagesEnum).map((option) => (
        <MenuItem key={option} value={option}>
          <Checkbox
            style={{
              paddingRight: '5px', // TODO better padding via theme
            }}
            checked={(valuesWithoutNull?.indexOf(option) ?? -1) > -1}
          />
          {formatLanguage[option]}
        </MenuItem>
      ))}
    </TextField>
  )
}

export default PreferredLanguageSelect
