import { FC } from 'react'

import { useAuth0 } from '@auth0/auth0-react'

import { Button, Container, Grid } from '@mui/material'

import Logo from 'ui/Logo'

type LoginWithPopupProps = {
  children: React.ReactNode
}

const LoginWithPopup: FC<LoginWithPopupProps> = ({ children }) => {
  const { loginWithPopup, isLoading, isAuthenticated } = useAuth0()

  if (isLoading) return <></>
  if (isAuthenticated) return <>{children}</>

  const opts = {
    appState: {
      returnTo: `${window.location.pathname}${window.location.search}`,
    },
  }

  return (
    <Container maxWidth="md">
      <Grid container spacing={5} direction="column" alignItems="center">
        <Grid item xs={12}>
          <Logo style={{ height: '3rem' }} light={false} />
        </Grid>
        <Grid item xs={12}>
          <Button fullWidth onClick={() => loginWithPopup(opts)}>
            Login
          </Button>
        </Grid>
      </Grid>
    </Container>
  )
}

export default LoginWithPopup
