import { ExtraErrorData, CaptureConsole } from '@sentry/integrations'
import * as Sentry from '@sentry/react'
import config, { Environment } from 'config/config'

export const sentryEnvironments = [Environment.Development, Environment.Staging, Environment.Production]
export const isSentryEnvironment = (): boolean => sentryEnvironments.includes(config.environment)

const initSentry = (): void => {
  if (!isSentryEnvironment()) return

  Sentry.init({
    dsn: config.sentryDsn,
    environment: config.environment,
    release: config.version,
    integrations: [
      new ExtraErrorData({
        depth: 5,
      }),
      ...(config.environment !== Environment.Production ? [new CaptureConsole({ levels: ['warn', 'error'] })] : []),
    ],
    beforeBreadcrumb(breadcrumb, hint) {
      if (breadcrumb.category === 'console' && breadcrumb.level === 'log') {
        return null
      }

      // pass operationName if we have a graphql fetch
      if (breadcrumb.category === 'fetch' && breadcrumb.data && breadcrumb.data.url === config.graphqlUrl) {
        const body = JSON.parse(hint?.input?.[1].body ?? null)

        if (body) {
          // eslint-disable-next-line no-param-reassign
          breadcrumb.message = `${breadcrumb.data.url}: ${body.operationName} [${breadcrumb.data.status_code}]`
          // eslint-disable-next-line no-param-reassign
          breadcrumb.data.operationName = body.operationName
        }
      }

      if (breadcrumb.category === 'ui.click') {
        const sentryLabel = hint?.event?.target?.dataset?.sentryLabel ?? null
        if (sentryLabel) {
          // eslint-disable-next-line no-param-reassign
          breadcrumb.message = `${breadcrumb.message} -- ${sentryLabel}`
        }
      }

      return breadcrumb
    },

    tracesSampleRate: 1,
    normalizeDepth: 5,
  })

  Sentry.setContext('culture', { timezone: Intl.DateTimeFormat().resolvedOptions().timeZone })
}

export default initSentry
