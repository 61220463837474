import { Patient } from 'generated/graphql'
import { useTranslation } from 'react-i18next'

export type GenderIdentities = Patient['genderIdentity']
export type GenderIdentityProps = {
  genderIdentity?: GenderIdentities
}

function GenderIdentity({ genderIdentity }: GenderIdentityProps): JSX.Element {
  const { t } = useTranslation()
  return <>{t('common:genderIdentity', { context: genderIdentity ?? null })}</>
}

export default GenderIdentity
