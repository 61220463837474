import { FieldPolicy, TypePolicy } from '@apollo/client'
import isNilOrEmpty, { isNotNilOrEmpty } from 'fp/isNilOrEmpty'
import { ZoneZipcode } from 'generated/graphql'

const Zone: TypePolicy = {
  keyFields: ['zoneId'],
  fields: {
    // Adds new @client field `stringifiedZoneZipcodeList`
    stringifiedZoneZipcodeList: {
      read(value, { readField }) {
        const zoneZipcodeList: Readonly<Array<Partial<ZoneZipcode>> | undefined> = readField('zoneZipcodesList')

        if (isNilOrEmpty(zoneZipcodeList)) return null

        return zoneZipcodeList
          ?.map((ref) => readField('zipCode', ref))
          .filter(isNotNilOrEmpty)
          .join(', ')
      },
    } as FieldPolicy,
  },
}

export default Zone
