import { FC, useState, useCallback, useEffect } from 'react'

import {
  RescheduleReasonEnum,
  useUpdateAppointmentMutation,
  useGetAppointmentQuery,
  useUpdateEncounterMutation,
} from 'generated/graphql'
import { useTranslation } from 'react-i18next'

import { Button } from '@mui/material'

import RescheduleReasonSelect from 'ui/Inputs/RescheduleReasonSelect/RescheduleReasonSelect'
import Modal from 'ui/Modal/Modal'

type RescheduleReasonModalProps = {
  appointmentId: number
  open: boolean
  onClose: () => void
}

const RescheduleReasonModal: FC<RescheduleReasonModalProps> = ({ appointmentId, open, onClose }) => {
  const { t } = useTranslation()
  const [updateAppointment] = useUpdateAppointmentMutation()
  const [rescheduledReason, setReason] = useState<RescheduleReasonEnum | null>(null)
  const { data, loading } = useGetAppointmentQuery({
    variables: {
      appointmentId,
    },
  })

  const [updateEncounterMutation] = useUpdateEncounterMutation()

  const handleClose = useCallback(() => {
    if (data?.appointment?.rescheduledReason) {
      setReason(data?.appointment?.rescheduledReason)
    }
    onClose()
  }, [onClose, data])

  const handleUpdateReason = useCallback(async () => {
    const encounterUpdates = data?.appointment?.encountersList.map((encounter) => {
      return updateEncounterMutation({
        variables: {
          encounterId: encounter.encounterId,
          encounter: {
            rescheduledReason,
          },
        },
      })
    })

    await updateAppointment({
      variables: {
        appointmentId,
        appointmentPatch: {
          rescheduledReason,
        },
      },
    })

    if (encounterUpdates) {
      await Promise.all(encounterUpdates)
    }

    onClose()
  }, [appointmentId, onClose, rescheduledReason, updateAppointment])

  useEffect(() => {
    if (data?.appointment?.rescheduledReason) {
      setReason(data.appointment.rescheduledReason)
    }
  }, [data])

  return (
    <Modal
      title="Update Reschedule Reason"
      maxWidth="xs"
      fullWidth
      open={open}
      onClose={handleClose}
      actions={
        <>
          <Button onClick={handleClose} variant="outlined" color="secondary">
            Cancel
          </Button>
          <Button
            disabled={loading || rescheduledReason === data?.appointment?.rescheduledReason}
            onClick={handleUpdateReason}
          >
            {t('components:modules.Appointment.UpdateReasonModal.button')}
          </Button>
        </>
      }
    >
      <RescheduleReasonSelect value={rescheduledReason} onChange={setReason} fullWidth showNone={false} />
    </Modal>
  )
}

export default RescheduleReasonModal
