import { FC, useState } from 'react'

import { generateAppointmentDetailsPath } from 'config/routes'
import { AppointmentStatusEnum, Maybe, SystemOfOriginEnum, User } from 'generated/graphql'
import useIsOpsAdmin from 'hooks/useIsOpsAdmin'
import { last } from 'lodash'
import { DateTime } from 'luxon'
import CancellationReasonModal from 'modules/Appointment/CancellationReasonModal'
import RescheduleReasonModal from 'modules/Appointment/RescheduleReasonModal'
import { Pencil } from 'phosphor-react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import hasFeatureFlag from 'utils/featureFlagsUtilities'

import { IconButton } from '@mui/material'
import Grid from '@mui/material/Grid'
import Skeleton from '@mui/material/Skeleton'

import LabeledItem from 'ui/LabeledItem'
import Text from 'ui/Text'
import Address from 'ui/TextFormatters/Address'
import AppointmentStatus from 'ui/TextFormatters/AppointmentStatus'
import CancellationOrRescheduledReason from 'ui/TextFormatters/CancellationOrRescheduledReason/CancellationOrRescheduledReason'
import FormattedDate from 'ui/TextFormatters/FormattedDate'

import ShowFullName from '../Appointments/components/ShowFullName'

export type AppointmentOverviewProps = {
  availabilityOverride?: boolean | null
  scheduledFor: string
  createdAt: string
  createdBy: Pick<User, 'firstName' | 'lastName' | 'userId'> | undefined | null
  status?: Maybe<AppointmentStatusEnum>
  marketName: string
  timeZone: string
  address1: string
  address2: string
  city: string
  state: string
  zip: string
  appointmentId: number | string
  systemOfOrigin?: SystemOfOriginEnum | null
  appointmentCancellation?: string | null
  appointmentReschedule?: string | null
  cancelledReasonOther?: string | null
  rescheduledFrom?: Array<number | null> | null
  rescheduledAt?: string | null
  cancelledBy: Maybe<string> | undefined

  // pass appointmentId from rescheduledTo
  rescheduledToAppointmentId?: number | null | undefined
}

export const AppointmentOverviewSkeleton: FC<unknown> = () => (
  <Grid container spacing={8}>
    <Grid item xs={12} sm={4} md={2}>
      <Skeleton
        variant="rectangular"
        animation="wave"
        width="100%"
        height={16}
        sx={{ marginBottom: 3, marginTop: 2 }}
      />
      <Skeleton variant="rectangular" animation="wave" width="100%" height={40} />
    </Grid>

    <Grid item xs={12} sm={3} md={2}>
      <Skeleton
        variant="rectangular"
        animation="wave"
        width="100%"
        height={16}
        sx={{ marginBottom: 3, marginTop: 2 }}
      />
      <Skeleton variant="rectangular" animation="wave" width="100%" height={40} />
    </Grid>

    <Grid item xs={12} sm={5} md={3}>
      <Skeleton
        variant="rectangular"
        animation="wave"
        width="100%"
        height={16}
        sx={{ marginBottom: 3, marginTop: 2 }}
      />
      <Skeleton variant="rectangular" animation="wave" width="100%" height={40} />
    </Grid>

    <Grid item xs={12} sm={7} md={5}>
      <Skeleton
        variant="rectangular"
        animation="wave"
        width="100%"
        height={16}
        sx={{ marginBottom: 3, marginTop: 2 }}
      />
      <Skeleton variant="rectangular" animation="wave" width="100%" height={40} />
    </Grid>

    <Grid item xs={12} sm={3} md={2}>
      <Skeleton
        variant="rectangular"
        animation="wave"
        width="100%"
        height={16}
        sx={{ marginBottom: 3, marginTop: 2 }}
      />
      <Skeleton variant="rectangular" animation="wave" width="100%" height={40} />
    </Grid>

    <Grid item xs={12} sm={4} md={2}>
      <Skeleton
        variant="rectangular"
        animation="wave"
        width="100%"
        height={16}
        sx={{ marginBottom: 3, marginTop: 2 }}
      />
      <Skeleton variant="rectangular" animation="wave" width="100%" height={40} />
    </Grid>

    <Grid item xs={12} sm={3} md={3}>
      <Skeleton
        variant="rectangular"
        animation="wave"
        width="100%"
        height={16}
        sx={{ marginBottom: 3, marginTop: 2 }}
      />
      <Skeleton variant="rectangular" animation="wave" width="100%" height={40} />
    </Grid>

    <Grid item xs={6} sm={2} md={2}>
      <Skeleton
        variant="rectangular"
        animation="wave"
        width="100%"
        height={16}
        sx={{ marginBottom: 3, marginTop: 2 }}
      />
      <Skeleton variant="rectangular" animation="wave" width="100%" height={40} />
    </Grid>

    <Grid item xs={12} sm={3} md={3}>
      <Skeleton
        variant="rectangular"
        animation="wave"
        width="100%"
        height={16}
        sx={{ marginBottom: 3, marginTop: 2 }}
      />
      <Skeleton variant="rectangular" animation="wave" width="100%" height={40} />
    </Grid>
  </Grid>
)

const AppointmentOverview: FC<AppointmentOverviewProps> = ({
  availabilityOverride,
  scheduledFor,
  createdAt,
  createdBy,
  status,
  marketName,
  timeZone,
  address1,
  address2,
  city,
  state,
  zip,
  appointmentId,
  systemOfOrigin,
  appointmentCancellation,
  appointmentReschedule,
  cancelledReasonOther,
  rescheduledFrom,
  rescheduledToAppointmentId,
  cancelledBy,
}) => {
  const { t } = useTranslation()
  const isOpsAdmin = useIsOpsAdmin()
  const [cancelledOrRescheduleModalOpen, setCancelledOrRescheduleModalOpen] = useState(false)

  const appointmentCancelledOrRescheduleLabel = appointmentReschedule
    ? t('common:Appointment.rescheduleReason')
    : t('common:Appointment.cancelledReason')

  const hasRescheduleId = rescheduledFrom != null && rescheduledFrom?.length > 0
  const rescheduleId = hasRescheduleId ? last(rescheduledFrom) : null

  return (
    <Grid container spacing={8}>
      {/* First row */}
      <Grid item xs={12} sm={4} md={2}>
        <LabeledItem label="Scheduled For">
          <FormattedDate dateTime={DateTime.fromISO(scheduledFor, { zone: timeZone })} />
        </LabeledItem>

        {availabilityOverride ? <Text warning>{t('actions:appointment.availabilityOverride')}</Text> : null}
      </Grid>

      <Grid item xs={12} sm={3} md={2}>
        <LabeledItem label="Market">{marketName}</LabeledItem>
      </Grid>

      <Grid item xs={12} sm={3} md={2}>
        <LabeledItem label="Status">
          <AppointmentStatus status={status} />

          {hasFeatureFlag('showRescheduledToLinkInOverview') &&
          status === AppointmentStatusEnum.Rescheduled &&
          rescheduledToAppointmentId != null ? (
            <>
              <br />

              <Link to={generateAppointmentDetailsPath(rescheduledToAppointmentId)}>
                {t('components:modules.Appointment.AppointmentOverview.rescheduledTo.link', {
                  appointmentId: rescheduledToAppointmentId,
                })}
              </Link>
            </>
          ) : null}
        </LabeledItem>
      </Grid>

      <Grid item xs={12} sm={4} md={3}>
        <LabeledItem label="Address">
          <Address address1={address1} address2={address2} city={city} state={state} zip={zip} />
        </LabeledItem>
      </Grid>

      <Grid item xs={12} sm={3} md={2}>
        <LabeledItem label="Appointment ID">{appointmentId > -1 ? appointmentId : ''}</LabeledItem>

        {hasFeatureFlag('showRescheduledFromLinkInOverview') && rescheduleId ? (
          <Link to={generateAppointmentDetailsPath(rescheduleId)}>
            {t('components:modules.Appointment.AppointmentOverview.rescheduleLink.label', { rescheduleId })}
          </Link>
        ) : null}
      </Grid>

      {/* Second row */}
      <Grid item xs={12} sm={3} md={2}>
        <LabeledItem label="Created By">
          <ShowFullName firstName={createdBy?.firstName ?? ''} lastName={createdBy?.lastName ?? ''} />
        </LabeledItem>
      </Grid>

      <Grid item xs={12} sm={4} md={2}>
        <LabeledItem label="Created At">
          <FormattedDate dateTime={DateTime.fromISO(createdAt)} />
        </LabeledItem>
      </Grid>

      <Grid item xs={12} sm={3} md={2}>
        <LabeledItem label="Platform">
          {systemOfOrigin ? t('glossary:systemOfOrigin', { context: systemOfOrigin }) : ''}
        </LabeledItem>
      </Grid>

      {appointmentCancellation || appointmentReschedule ? (
        <Grid item xs={12} sm={4} md={3}>
          <LabeledItem
            label={appointmentCancelledOrRescheduleLabel}
            labelAction={
              <>
                {isOpsAdmin && appointmentReschedule && hasFeatureFlag('editRescheduleReasonInOverview') && (
                  <IconButton
                    aria-label="edit"
                    size="small"
                    sx={{ padding: 0.25, marginLeft: 2 }}
                    onClick={() => setCancelledOrRescheduleModalOpen(true)}
                  >
                    <Pencil size={18} />
                  </IconButton>
                )}
                {isOpsAdmin && appointmentCancellation && hasFeatureFlag('editCancellationInOverview') && (
                  <IconButton
                    aria-label="edit"
                    size="small"
                    sx={{ padding: 0.25, marginLeft: 2 }}
                    onClick={() => setCancelledOrRescheduleModalOpen(true)}
                  >
                    <Pencil size={18} />
                  </IconButton>
                )}
              </>
            }
          >
            <CancellationOrRescheduledReason
              appointmentCancellation={appointmentCancellation}
              appointmentReschedule={appointmentReschedule}
              cancelledReasonOther={cancelledReasonOther}
            />
          </LabeledItem>
        </Grid>
      ) : null}
      {appointmentCancellation ? (
        <CancellationReasonModal
          appointmentId={Number(appointmentId)}
          open={cancelledOrRescheduleModalOpen}
          onClose={() => setCancelledOrRescheduleModalOpen(false)}
        />
      ) : (
        <RescheduleReasonModal
          appointmentId={Number(appointmentId)}
          open={cancelledOrRescheduleModalOpen}
          onClose={() => setCancelledOrRescheduleModalOpen(false)}
        />
      )}
      {cancelledBy && (
        <Grid item xs={12} sm={3} md={2}>
          <LabeledItem label="Cancelled By">{cancelledBy}</LabeledItem>
        </Grid>
      )}
    </Grid>
  )
}

export default AppointmentOverview
