import { memo } from 'react'

import { appointmentsRoutes } from 'config/routes'
import { Layout } from 'modules/Layout'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

import Button from '@mui/material/Button'
import Container from '@mui/material/Container'
import Paper from '@mui/material/Paper'
import Box from '@mui/system/Box'

import AppointmentFilters from './AppointmentFilters'
import AppointmentTable from './AppointmentTable'
import { AppointmentFilterContextProvider } from './useAppointmentFiltersContext'

const Appointments = (): JSX.Element => {
  const { t } = useTranslation()
  const history = useHistory()

  return (
    <AppointmentFilterContextProvider>
      <Layout
        title={t('glossary:appointment_plural')}
        breadcrumbsProps={[{ title: t('common:breadcrumb.appointments.overview'), href: appointmentsRoutes.overview }]}
        headerActions={
          <Button color="primary" size="large" onClick={() => history.push(appointmentsRoutes.create)}>
            {t('components:modules.Appointments.createNewAppointment')}
          </Button>
        }
      >
        <Container maxWidth={false}>
          <Box>
            <Paper>
              <AppointmentFilters />
            </Paper>
          </Box>

          <Box pt={4}>
            <AppointmentTable />
          </Box>
        </Container>
      </Layout>
    </AppointmentFilterContextProvider>
  )
}

export default memo(Appointments)
