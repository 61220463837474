import { GetPatientNotesQuery, PatientNoteFragment } from 'generated/graphql'
import { createSelector, Selector } from 'reselect'

const selectPatientNotesList: Selector<GetPatientNotesQuery, PatientNoteFragment[] | null> = (data) =>
  data?.patient?.patientNotesList ?? null

export const filterPatientNotesNotIsDeleted = (notes: PatientNoteFragment[] | null): PatientNoteFragment[] | null =>
  notes ? notes.filter((note) => !note.isDeleted) : null

export const sortPatientNotes = (notes: PatientNoteFragment[] | null): PatientNoteFragment[] | null =>
  notes
    ? [...notes].reverse().sort((noteA, noteB) => {
        if (noteA.isHighPriority === false || noteB.isHighPriority === true) {
          return 0
        }
        return -1
      })
    : null

/**
 * @selector
 * @state GetPatientNotesQuery
 * Select PatientNotes,
 *  - not `isDeleted`
 */
export const selectExistingPatientNotes = createSelector(selectPatientNotesList, filterPatientNotesNotIsDeleted)

/**
 * @selector
 * @state GetPatientNotesQuery
 * Select PatientNotes,
 *  - not `isDeleted`,
 *  - sorted by `isHighPriority`,
 *  - sorted by `createdAt` (DESCENDING)
 */
export const selectSortedExistingPatientNotes = createSelector(selectExistingPatientNotes, sortPatientNotes)
