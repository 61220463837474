import { TypePolicy, FieldPolicy } from '@apollo/client'
import { UserFieldsFragment, PatientNoteLabel } from 'generated/graphql'

const MAP_LABEL_FRIENDLY = {
  [PatientNoteLabel.Safety]: 'Safety',
  [PatientNoteLabel.Clinical]: 'Clinical',
}

const PatientNote: TypePolicy = {
  keyFields: ['patientNoteId'],
  fields: {
    author: {
      read(value, { readField }) {
        const user: Readonly<UserFieldsFragment> | undefined = readField('userByCreatedBy')

        if (!user) return null

        const fullName = readField('fullName', user)

        return `${fullName ?? 'Anonymous'}`
      },
    } as FieldPolicy,

    friendlyLabel: {
      read(value, { readField }) {
        const label: Readonly<PatientNoteLabel> | undefined = readField('label')

        if (!label) return null

        return MAP_LABEL_FRIENDLY[label]
      },
    } as FieldPolicy,
  },
}

export default PatientNote
