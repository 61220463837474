import { E164_PHONE_NUMBER_REGEXP } from 'utils/phone/regexp'
import * as Yup from 'yup'

import makeRequired from '../helpers/makeRequired'

export const preferredPhoneNumberSchema = Yup.string().matches(
  E164_PHONE_NUMBER_REGEXP,
  'Please enter a valid, 10-digit phone number.',
)

export const preferredPhoneNumberRequired = makeRequired(
  preferredPhoneNumberSchema,
  'Preferred Phone Number is required',
)

export const communicationsSchema = Yup.object().shape({
  preferredPhoneNumber: preferredPhoneNumberRequired,
  email: Yup.string(),
  languages: Yup.array().nullable(),
  communicationNeed: Yup.array().nullable(),
})
