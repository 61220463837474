import { FC } from 'react'

import Tokens from 'config/tokens'
import { GenderPronounEnum, Patient } from 'generated/graphql'
import { Trans, useTranslation } from 'react-i18next'

import { Box } from '@mui/system'

import Age from 'ui/TextFormatters/Age'
import FullName from 'ui/TextFormatters/FullName'
import GenderPronoun from 'ui/TextFormatters/GenderPronoun'
import Sex from 'ui/TextFormatters/Sex'

import PatientAvatar from './PatientAvatar'

type PatientProfileType = Pick<Patient, 'firstName' | 'lastName' | 'genderPronoun' | 'sex' | 'dob'>

type PatientHeaderProfileProps = {
  patient: PatientProfileType
}

const PatientHeaderProfile: FC<PatientHeaderProfileProps> = ({ patient }) => {
  const { t } = useTranslation()
  const { firstName, lastName, genderPronoun, sex, dob } = patient

  return (
    <Box px={2} pt={0} display="flex">
      <PatientAvatar firstName={firstName} lastName={lastName} />

      <Box pl={2}>
        <Box sx={{ fontSize: 'h2.fontSize' }}>
          <FullName firstName={firstName} lastName={lastName} /> (
          <GenderPronoun genderPronoun={genderPronoun ?? GenderPronounEnum.TheyThem} />)
        </Box>

        <Box sx={{ fontSize: 'body2.fontSize', color: Tokens.color.ui.slate.base }}>
          <Trans
            t={t}
            i18nKey={'components:ui.PatientHeader.details'}
            components={{ sex: <Sex sex={sex} />, age: <Age dob={dob} /> }}
          />
        </Box>
      </Box>
    </Box>
  )
}

export default PatientHeaderProfile
