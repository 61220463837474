import { memo, useState } from 'react'

import { useAuth0 } from '@auth0/auth0-react'
import Tokens from 'config/tokens'
import useSidebarContext from 'hooks/useSidebarContext'
import useUserContext from 'hooks/useUserContext'
import styled from 'styled-components'

import Footer from './components/Footer'
import Header from './components/Header'
import Navigation from './components/Navigation'

const StyledSidebar = styled.div`
  box-shadow: 0px 1px 3px ${Tokens.color.neutral.black.transparent[25]};
  color: ${Tokens.color.neutral.white.base};
  display: flex;
  flex-direction: column;
  font-size: ${Tokens.font.size.paragraph.base};
  height: 100vh;
  justify-content: space-between;
  max-height: 100%;
  position: relative;

  .overlay {
    background-color: ${Tokens.color.ui.charcoal.transparent[50]};
    bottom: 0;
    cursor: pointer;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
  }

  & > footer {
    box-shadow: 0px -1px 0px ${Tokens.color.neutral.black.transparent[25]};
    position: relative;

    &.active {
      background-color: ${Tokens.color.neutral.black.transparent[50]};
    }
  }
`

const Sidebar = (): JSX.Element => {
  const { logout } = useAuth0()
  const userContext = useUserContext()
  const sidebarContext = useSidebarContext()
  const [overlayIsVisible, setOverlayIsVisible] = useState(false)

  return (
    <StyledSidebar>
      <header>
        <Header setSidebarIsOpen={sidebarContext?.setSidebarIsOpen} />
        <Navigation />
      </header>

      {overlayIsVisible ? <div className="overlay" onClick={() => setOverlayIsVisible(false)} /> : null}

      <footer className={overlayIsVisible ? 'active' : ''}>
        <Footer
          logout={logout}
          overlayIsVisible={overlayIsVisible}
          setOverlayIsVisible={setOverlayIsVisible}
          sidebarIsOpen={sidebarContext?.sidebarIsOpen}
          user={userContext?.user}
        />
      </footer>
    </StyledSidebar>
  )
}

// Export components as subcomponents of Sidebar
Sidebar.Footer = Footer
Sidebar.Header = Header
Sidebar.Navigation = Navigation

export default memo(Sidebar)
