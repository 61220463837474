import MuiCheckbox, { CheckboxProps as MuiCheckboxProps } from '@mui/material/Checkbox'
import FormControlLabel from '@mui/material/FormControlLabel'

export type CheckboxProps = Partial<Omit<MuiCheckboxProps, 'label'>> & {
  // onChange: (value: boolean) => void;
  label: string
}

const Checkbox = ({ checked, label, ...props }: CheckboxProps): JSX.Element => (
  <FormControlLabel
    label={label}
    control={<MuiCheckbox {...props} checked={checked} color="primary" inputProps={{ 'aria-label': label }} />}
  />
)

export default Checkbox
