import { flatMap } from 'lodash'
import { createSelector } from 'reselect'

import AppointmentCreateFormSchema from '../AppointmentCreateFormSchema'

const flatMapAllPatientFieldEncounters = (patients: AppointmentCreateFormSchema['patients']) => {
  if (patients == null || patients.length === 0) return null
  return flatMap(patients, ({ encounters }) => encounters)
}

const flatMapAllPatientFieldEncountersWithPatientId = (patients: AppointmentCreateFormSchema['patients']) => {
  if (patients == null || patients.length === 0) return null
  return flatMap(patients, ({ patientId, encounters }) => encounters?.map((encounter) => ({ patientId, ...encounter })))
}

/**
 * list of all encounters from all patients
 */
export const selectAllPatientFieldsEncounters = createSelector(flatMapAllPatientFieldEncounters, (x) => x)

export const selectAllpatientFieldsEncounterWithPatientId = createSelector(
  flatMapAllPatientFieldEncountersWithPatientId,
  (x) => x,
)
