import { useRef, useState, useEffect, useMemo, DependencyList } from 'react'

import isEqual from 'lodash/isEqual'

const useDeepMemo = <T>(valueFn: () => T, dependencies?: DependencyList): T => {
  const [value, setValue] = useState<T>(valueFn())
  useEffect(() => {
    const nextValue = valueFn()
    if (isEqual(value, nextValue)) return
    setValue(nextValue)
  }, dependencies)
  return value
}

export const useDeepMemoDeps = <T>(valueFn: () => T, dependencies: DependencyList): T => {
  const depsRef = useRef(dependencies)
  if (!isEqual(depsRef.current, dependencies)) {
    depsRef.current = dependencies
  }
  const value = useMemo(valueFn, depsRef.current)
  return value
}

export default useDeepMemo
