import { FC } from 'react'

import Routes from 'config/routes'
import { Link } from 'react-router-dom'

import Box from '@mui/system/Box'

const PageNotFound: FC<unknown> = () => (
  <Box
    component="article"
    display="flex"
    flexDirection="column"
    alignItems="center"
    justifyContent="center"
    height="100vh"
  >
    <h1>404</h1>
    <p>The page you are looking for was not found.</p>
    <p>
      Click <Link to={Routes.root}>here</Link> to return to the landing page.
    </p>
  </Box>
)

export default PageNotFound
