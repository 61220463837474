/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { EncounterPatch } from 'generated/graphql'

const createUpdateEncounterOptions = (
  encounterId: number,
  patientId: number,
  valuesDelta: EncounterPatch,
  modulesFriendly = '',
) => {
  const baseOptions = {
    variables: {
      encounterId,
      encounter: {
        ...valuesDelta,
      },
    },
    optimisticResponse: {
      updateEncounter: {
        encounter: {
          encounterId,
          patientId,
          modulesFriendly,
          ...valuesDelta,
          channelAttributionId: valuesDelta?.channelAttributionId ?? -1,
        },
      },
    },
  }
  return baseOptions
}

export default createUpdateEncounterOptions
