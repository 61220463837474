import { FC, useCallback } from 'react'

import insurancePlans from 'config/insurancePlans'
import isNilOrEmpty from 'fp/isNilOrEmpty'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import ControlledField from 'utils/ControlledField/ControlledField'
import hasFeatureFlag from 'utils/featureFlagsUtilities'
import { isSelf } from 'yupSchemas/insurance'

import { TextField } from '@mui/material'

import HiddenInput from 'ui/Inputs/HiddenInput'
import InsuranceCompanySelect, { InsurancePlanOption } from 'ui/Inputs/InsuranceCompanySelect/InsuranceCompanySelect'
import InsuranceRecordSelect from 'ui/Inputs/InsuranceRecordSelect/InsuranceRecordSelect'
import InsuranceRelationshipToPolicyHolderSelect from 'ui/Inputs/InsuranceRelationshipToPolicyHolderSelect'
import SexSelect from 'ui/Inputs/SexSelect'
import BirthdaySelect from 'ui/MaterialUI/DatePicker/BirthdaySelect'
import SpacedItems from 'ui/SpacedItems'

import PatientInsuranceCardRead from './PatientInsuranceCardRead'
import usePatientInsuranceStateFromContext from './usePatientInsuranceStateFromContext'

const transformCompanyValue = (value: string): InsurancePlanOption =>
  insurancePlans.find(({ company }) => company === value) ?? {
    company: value ?? null,
  }

const PatientInsuranceCardEdit: FC<unknown> = () => {
  const { t } = useTranslation()
  const { setValue, watch } = useFormContext()
  const relationshipToPolicyHolder = watch('relationshipToPolicyHolder')
  const { patientId, setInsuranceRecord, selectedInsuranceRecord } = usePatientInsuranceStateFromContext()

  const transformCompanyOnChange = useCallback((insurancePlan: InsurancePlanOption) => {
    if (isNilOrEmpty(insurancePlan)) {
      return null
    }
    if (insurancePlan.isNew) {
      return insurancePlan?.company ?? null
    }

    // side-effects, fill other fields
    setValue('packageId', insurancePlan?.packageId ?? null)

    return insurancePlan?.company ?? null
  }, [])

  return (
    <SpacedItems direction="column">
      {hasFeatureFlag('showExistingInsuranceSelection') && (
        <InsuranceRecordSelect patientId={patientId} value={selectedInsuranceRecord} onChange={setInsuranceRecord} />
      )}
      {selectedInsuranceRecord === null && (
        <>
          <ControlledField name="packageId" Component={HiddenInput} />
          {relationshipToPolicyHolder !== null && (
            <p>
              Patient is a {t(`glossary:InsuranceRelationshipToPolicyHolder_${relationshipToPolicyHolder}`)} of the
              policy holder
            </p>
          )}
          <ControlledField
            name="company"
            Component={InsuranceCompanySelect}
            ComponentProps={{ required: true }}
            onChangeTransform={transformCompanyOnChange}
            valueTransform={transformCompanyValue}
          />
          <ControlledField
            name="memberId"
            Component={TextField}
            ComponentProps={{
              'data-testid': `memberId`,
              required: true,
              fullWidth: true,
              label: t('glossary:InsuranceRecord.memberId'),
            }}
          />
          <ControlledField
            name="groupNumber"
            Component={TextField}
            ComponentProps={{
              'data-testid': `groupNumber`,
              required: true,
              fullWidth: true,
              label: t('glossary:InsuranceRecord.groupNumber'),
            }}
          />
          <ControlledField
            name="relationshipToPolicyHolder"
            Component={InsuranceRelationshipToPolicyHolderSelect}
            ComponentProps={{
              'data-testid': `relationshipToPolicyHolder`,
              required: true,
              fullWidth: true,
              showNone: false,
            }}
          />

          {relationshipToPolicyHolder && !isSelf(relationshipToPolicyHolder) && (
            <>
              <ControlledField
                name="policyHolderFirstName"
                Component={TextField}
                ComponentProps={{
                  required: true,
                  fullWidth: true,
                  label: t('glossary:InsuranceRecord.policyHolderFirstName'),
                  'data-testid': `policyHolderFirstName`,
                }}
              />
              <ControlledField
                name="policyHolderLastName"
                Component={TextField}
                ComponentProps={{
                  required: true,
                  fullWidth: true,
                  label: t('glossary:InsuranceRecord.policyHolderLastName'),
                  'data-testid': `policyHolderLastName`,
                }}
              />
              <ControlledField
                name="policyHolderGender"
                Component={SexSelect}
                ComponentProps={{
                  required: true,
                  label: t('glossary:InsuranceRecord.policyHolderGender'),
                  'data-testid': `policyHolderGender`,
                }}
              />
              <ControlledField
                name="policyHolderDob"
                Component={BirthdaySelect}
                ComponentProps={{
                  label: t('glossary:InsuranceRecord.policyHolderDob'),
                  'data-testid': `policyHolderDob`,
                }}
              />
            </>
          )}
        </>
      )}
      {/* // <DevTool control={control} /> */}
      {selectedInsuranceRecord !== null && <PatientInsuranceCardRead />}
    </SpacedItems>
  )
}

export default PatientInsuranceCardEdit
