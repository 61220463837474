import { InsuranceSchema } from 'yupSchemas/insurance'

import InsuranceRelationshipToPolicyHolder from './InsuranceRelationshipToPolicyHolder'

export enum PatientInsuranceStateStatus {
  PreFetch = 'PRE_FETCH',
  Empty = 'EMPTY',
  Set = 'SET',
}

export const KEYS_PRIMARY_FIELDS: Readonly<
  (keyof Pick<InsuranceSchema, 'company' | 'packageId' | 'memberId' | 'groupNumber' | 'relationshipToPolicyHolder'>)[]
> = Object.freeze(['company', 'packageId', 'memberId', 'groupNumber', 'relationshipToPolicyHolder'])

export const KEYS_ADDITIONAL_FIELDS: Readonly<(keyof Partial<InsuranceSchema>)[]> = Object.freeze([
  'policyHolderFirstName',
  'policyHolderLastName',
  'policyHolderGender',
  'policyHolderDob',
])

export type InsuranceRecordFieldEnums = {
  relationshipToPolicyHolder: InsuranceRelationshipToPolicyHolder
}

export const MAP_INSURANCE_RECORD_FIELD_KEY_TO_ASSOCIATED = new Map([
  [
    'relationshipToPolicyHolder',
    {
      enum: InsuranceRelationshipToPolicyHolder,
      enumValues: Object.values(InsuranceRelationshipToPolicyHolder),
      i18nKey: 'glossary:InsuranceRelationshipToPolicyHolder',
    },
  ],
])
