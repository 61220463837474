import { memo, useEffect, useMemo, useState, useRef } from 'react'

import { yupResolver } from '@hookform/resolvers/yup'
import { Appointment, useUpdateAppointmentMutation } from 'generated/graphql'
import createUpdateAppointmentOptions from 'graphql/mutations/createUpdateAppointmentOptions'
import useToggle from 'hooks/useToggle'
import { pickBy } from 'lodash'
import { createAddressLine } from 'modules/Patient/selectors'
import { DeepMap, FormProvider, SubmitHandler, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useMountedState } from 'react-use'
import { AddressSchema, addressSchema } from 'yupSchemas/address/items'

import Alert from '@mui/material/Alert'
import Box from '@mui/system/Box'

import CardLabeled from 'ui/CardLabeled'

import AppointmentAddressEdit from './AppointmentAddressEdit'
import AppointmentAddressRead from './AppointmentAddressRead'
import SaveAppointmentAddressButton from './SaveAppointmentAddressButton'
import useGoogleMapsApiContext, { selectGeoLocation } from './useGoogleMapsApiContext'

type AppointmentAddressCardProps = {
  patientIds?: number[] | null
  appointmentId?: number | null
  marketId?: Appointment['marketId']
  zoneId?: Appointment['zoneId']
  address1?: Appointment['address1']
  address2?: Appointment['address2']
  city?: Appointment['city']
  state?: Appointment['state']
  zip?: Appointment['zip']
  unit?: Appointment['unit']
  specialInstructions?: Appointment['specialInstructions']
  addressLat?: Appointment['addressLat']
  addressLng?: Appointment['addressLng']
}

const AppointmentAddressCard = ({
  patientIds,
  appointmentId,
  address1,
  address2,
  city,
  state,
  zip,
  unit,
  specialInstructions,
  marketId,
  zoneId,
  addressLat,
  addressLng,
}: AppointmentAddressCardProps): JSX.Element => {
  const isMounted = useMountedState()
  const { t } = useTranslation()
  const [isEditing, setEditing] = useState(false)
  const [isSaving, , setIsSaving, unsetIsSaving] = useToggle(false)
  const [updateAppointment, { loading: loadingAppointment }] = useUpdateAppointmentMutation()

  // hooks relating to lat/lng
  const [hasGeocodeError, , setGeocodeError, unsetGeocodeError] = useToggle(false)
  const { fetchGeocodeComponents, status: googleMapsStatus } = useGoogleMapsApiContext()
  const isGoogleMapsReady = googleMapsStatus === 'success'

  const setEditingTrue = () => setEditing(true)

  const defaultValues: AddressSchema = useMemo(
    () => ({
      address: {
        address1: address1 ?? null,
        address2: address2 ?? null,
        city: city ?? null,
        state: state ?? null,
        zip: zip ?? null,
      },
      geoLocation: {
        addressLat: addressLat ?? null,
        addressLng: addressLng ?? null,
      },

      unit: unit ?? null,
      specialInstructions: specialInstructions ?? null,

      marketId: marketId ?? null,
      zoneId: zoneId ?? null,
    }),
    [address1, address2, city, state, zip, unit, specialInstructions, marketId, zoneId, addressLat, addressLng],
  )

  const methods = useForm<AddressSchema>({
    mode: 'onBlur',
    reValidateMode: 'onChange',
    defaultValues: useMemo(() => defaultValues, Object.values(defaultValues)),
    resolver: yupResolver(addressSchema),
  })
  const {
    reset,
    handleSubmit,
    formState: { isValid, isDirty },
  } = methods

  useEffect(() => {
    reset(defaultValues)
  }, [address1, address2, city, state, zip, unit, specialInstructions, marketId, zoneId, addressLat, addressLng])

  // dirtyFields is not available within the onSubmit handler, we store it in this ref
  const dirtyFieldsRef = useRef<DeepMap<AddressSchema, true>>()
  useEffect(() => {
    dirtyFieldsRef.current = methods.formState.dirtyFields
  })

  const handleCancel = () => {
    unsetGeocodeError()
    setEditing(false)
    reset()
  }

  const handleSave: SubmitHandler<AddressSchema> = async (values) => {
    if (!appointmentId) return
    unsetGeocodeError()
    setIsSaving()

    const dirtyKeys = Object.keys(dirtyFieldsRef.current ?? {})
    if (!dirtyKeys) {
      unsetIsSaving()
      return
    }
    const newValues = pickBy(values, (value, key) => dirtyKeys.includes(key)) as Partial<AddressSchema>

    const hasLatLng = values.geoLocation.addressLat && values.geoLocation.addressLng
    if ('address' in dirtyKeys && !hasLatLng) {
      try {
        // useless typeguard as save is disabled when google maps isn't available
        if (!fetchGeocodeComponents) {
          throw new Error(
            '<AppointmentAddressCard />: GoogleMapsApiContext was not available, this should never be reached.',
          )
        }

        const addressLine = createAddressLine(values.address)
        const { geocoderResult } = await fetchGeocodeComponents({ address: addressLine })
        const { addressLat: lat, addressLng: lng } = selectGeoLocation(geocoderResult)

        Object.assign(newValues, { addressLat: lat, addressLng: lng })
      } catch (e) {
        if (!isMounted) return
        setGeocodeError()

        // eslint-disable-next-line no-console
        console.error(e)

        return
      }
    }
    if (!isMounted) return

    const { address, geoLocation, ...remainingFields } = newValues

    updateAppointment(
      createUpdateAppointmentOptions(appointmentId, {
        ...address,
        ...geoLocation,
        ...remainingFields,
      }),
    )

    reset(undefined, { keepValues: true })
    setEditing(false)

    unsetIsSaving()
  }

  return (
    <FormProvider {...methods}>
      <CardLabeled
        title="Appointment Address"
        testId="AppointmentAddressCard"
        editing={isEditing}
        onEdit={setEditingTrue}
        onCancel={handleCancel}
        onAccept={handleSubmit(handleSave)}
        acceptText={
          <SaveAppointmentAddressButton
            loading={isSaving || loadingAppointment}
            isValid={isValid && isDirty && isGoogleMapsReady}
          />
        }
        acceptDisabled={!(isDirty && isValid && isGoogleMapsReady)}
      >
        {isEditing ? <AppointmentAddressEdit patientIds={patientIds} /> : <AppointmentAddressRead />}

        {isEditing && hasGeocodeError ? (
          <Box mt={4}>
            <Alert severity="error">
              {t('components:modules.Appointments.appointmentAddress.geocodeError.message')}
            </Alert>
          </Box>
        ) : null}
      </CardLabeled>
    </FormProvider>
  )
}

export default memo(AppointmentAddressCard)
